.contact-info-section{
    padding-top: $section_padding_base_md;
    &.sec-space-default{
        padding-bottom: rem(60px);
    }    
    .gap-section{
        height: 100%;
        #g-map{
            height: 100%;
            min-height: rem(400px);
        }
    }
    .our-contact{
        max-width: rem(500px);
        padding-left: rem(15px);
        .section-title{
            &.title-style2 {
                .title.sm-bottom{
                    margin-bottom: rem(5px);
                }
                p{
                    margin-bottom: rem(47px);
                }
            }
        }
    }
    .address-box-layout1{
        margin-bottom: rem(40px);
        .item-icon{
            padding-top: rem(5px);
            padding-right: rem(23px);
            i{
                line-height: rem(43px);
                &:before{
                    font-size: rem(43px);
                    color: $primary-color;
                }
            }
            &.location{
                position: relative;
                right: rem(7px);
                i{
                    line-height: rem(50px);
                    &:before{
                        font-size: rem(50px);
                        color: $primary-color;
                    }
                }
            }
        }
        .item-desc{
            font-size: rem(18px);
            p{
                font-size: rem(18px);
                margin-bottom: 0;
            }
            .item-title{
                font-size: rem(22px);
                font-weight: 600;
                margin-bottom: 0;
            }
            a{
                color: $body-color;
                &:hover{
                    color: $primary-color;
                }
            }
        }
    }
    &#contact-us{
        padding-top: rem(108px);
        padding-bottom: rem(120px);
        .our-contact.ipad-pt--50{
            padding-top: 0;
        }
        .section-title {
            .title.sm-bottom{
                margin-bottom: 1.5rem;
            }
            p{
                margin-bottom: rem(47px);
            }
        }
        #contact_form{
            margin-top: rem(20px);
            .form-field{
                margin-bottom: rem(35px);
            }
        }
    }
}
.contact-form-section{
    overflow: hidden;
    background: #fbfbfb;
    .section-title{
        &.title-style2 {
            .title.sm-bottom{
                margin-bottom: rem(5px);
            }
            p{
                margin-bottom: rem(47px);
            }
        }
    }
    .left-img{
        background: url(../../media/background/contact-left.jpg) no-repeat center center;
        background-size: cover;
    }
    .contact-form{
        max-width: rem(780px);
        padding: rem(92px 30px 100px 80px);
    }
}
.gap-section{
    #g-map{
        height: rem(500px);
    }
}
.contact-form{
    .form-field {
        margin-bottom: rem(52px);
        input{
            width: 100%;
            border: none;
            border-bottom: rem(1px) solid #cfcfcf;
            padding-bottom: rem(10px);
            outline: none;
            background: transparent;
        }
        textarea{
            width: 100%;
            border: none;
            border-bottom: rem(1px) solid #cfcfcf;
            padding-bottom: rem(10px);
            outline: none;
            height: rem(125px);
            background: transparent;
        }
    }
    .form-button{
        .primary-btn{
            cursor: pointer;
        }
    }
}
#form-messages{
    &.success{
        background: #2554E1;
        display: inline-block;
        color: #fff;
        padding: 9px 21px;
        margin-bottom: 35px;
    }
    &.error{
        display: inline-block;
        color: #fff;
        padding: 9px 21px;
        margin-bottom: 35px;
        background: #ccc;
        color: #ff0000;
    }
}
