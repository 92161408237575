.cta-section{
    background: url(../../media/background/cta-bg.jpg) no-repeat center top;
    position: relative;
    padding-bottom: 10.5rem;
    padding-top: 10rem;
    background-size: cover;
    h3,
    .h3,
    h2{
        color: #fff;
    }
    h3, .h3{
        font-size: 2.2rem;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: rem(8px);
        line-height: 4rem;
        letter-spacing: rem(2px);
    }
    h2{
        font-size: 3.6rem;
        font-weight: 600;
    }
    .container{
        z-index: 9;
        position: relative;
    }
    &:after{
        position: absolute;
        content: "";
        display: block;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.75);
        width: 100%;
        height: 100%;
    }
    .cta-btn{
        margin-top: 2.3rem;
    }    
}
.call-action-section{
    background: $primary-color;
    color: #fff;
    padding-top: rem(85px);
    padding-bottom: rem(84px);
    &.cta-landing{
        background: #ffdde4;
        h2{
            color: $title-color;
        }
        .cta-sub-text{
            color: #623fbd;
        }
        .cta-btn{
            .secondary-btn{
                font-weight: 600;
                font-size: rem(22px);
                border-width: rem(2px);
                min-width: rem(200px);
                margin-top: rem(15px);
                padding: 1.4rem 4rem 1.3rem;
                line-height: rem(33px);
            }
        }
    }
    h2{
        color: #fff;
        font-weight: 600;
        margin-bottom: rem(2px);
    }
    .cta-sub-text{
        font-size: rem(22px);
    }
    .cta-btn{
        .secondary-light-btn{
            margin-top: rem(17px);
            &:hover{
                background: #fff;
                &:before{
                    border: rem(1px) solid #fff;
                }
            }
        }
    }
    &.cta-style3{
        background: url(../../media/background/cta-bg2.jpg) no-repeat center top;
        background-size: cover;
        padding-top: rem(78px);
        padding-bottom: rem(79px);
        .cta-btn{
            padding-top: rem(22px);
        }
    }
}

