.why-us-section{
    .feature-item{
        position: relative;
        overflow: hidden;
        margin-bottom: rem(30px);
        img{
            transform: scale(1);
            transition: $transition;
        }
        .item-desc{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            text-align: center;
            z-index: 1;
            -webkit-transition: 0.3s all ease-in-out;
            transition: 0.3s all ease-in-out;            
            color: #fff;
            &:after{
                position: absolute;
                content: "";
                height: 100%;
                width: 100%;
                display: block;
                left: 0;
                top: 0;
                background-image: linear-gradient(to top,rgba(0,0,0,.6) 30%,rgba(255,255,255,0) 70%);
                background-image: -webkit-linear-gradient(to top,rgba(0,0,0,.6) 30%,rgba(255,255,255,0) 70%);
                background-image: -moz-linear-gradient(to top,rgba(0,0,0,.6) 30%,rgba(255,255,255,0) 70%);
                background-image: -ms-linear-gradient(to top,rgba(0,0,0,.6) 30%,rgba(255,255,255,0) 70%);
                background-image: -o-linear-gradient(to top,rgba(0,0,0,.6) 30%,rgba(255,255,255,0) 70%);
                transition: $transition;
            }            
            .desc-wrap{
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: rem(0);
                padding: rem(20px 40px);
                width: 100%;
                height: auto;
                z-index: 9;
                overflow: hidden;
                transition: $transition;
            }
            .overlay-content{
                position: absolute;
                left: 50%;
                top: 55%;
                transform: translate(-50%, -50%);
                padding: rem(20px 40px);
                width: 100%;
                height: auto;
                z-index: 9;
                overflow: hidden;
                transition: $transition;
                opacity: 0;
                visibility: hidden;
                .icon{
                    i{
                        color: #fff;
                        &:before{
                            background: $primary-color;
                        }
                    }
                }
            }
            .icon{
                margin-top: rem(20px);
                i{
                    color: $primary-color;
                    &:before{
                        font-size: rem(25px);
                        padding: rem(15px);
                        background: #fff;
                        border-radius: 100%;
                    }
                }
            }
            .item-title{
                color: #fff;
                font-weight: 600;
                font-size: rem(22px);
                margin-top: rem(26px);
                margin-bottom: rem(12px);
                transition: $transition;
            }
        }
        &:hover{
            .item-desc{
                .desc-wrap{
                    opacity: 0;
                    visibility: hidden;
                }
                .overlay-content{
                    top: 50%;
                    opacity: 1;
                    visibility: visible;
                }                
                &:after{
                    background-image: linear-gradient(to top,rgba(0,0,0,.7) 100%,rgba(255,255,255,0) 70%);
                    background-image: -webkit-linear-gradient(to top,rgba(0,0,0,.7) 100%,rgba(255,255,255,0) 70%);
                    background-image: -moz-linear-gradient(to top,rgba(0,0,0,.7) 100%,rgba(255,255,255,0) 70%);
                    background-image: -ms-linear-gradient(to top,rgba(0,0,0,.7) 100%,rgba(255,255,255,0) 70%);
                    background-image: -o-linear-gradient(to top,rgba(0,0,0,.7) 100%,rgba(255,255,255,0) 70%);
                }
            }
            img{
                transform: scale(1.1);
            }
        }
    }
}