/*========================================================================
04. Template Style
=========================================================================*/

/*--------------------------------------
	# About Style
---------------------------------------*/
@import "sections/about";

/*--------------------------------------
	# Banner Style
---------------------------------------*/
@import "sections/banner";

/*--------------------------------------
	# Slider Style
---------------------------------------*/
@import "sections/slider";


/*--------------------------------------
	# Feature Style
---------------------------------------*/
@import "sections/feature";

/*--------------------------------------
	# Faq Style
---------------------------------------*/
@import "sections/faq";

/*--------------------------------------
	# Video Style
---------------------------------------*/
@import "sections/video";

/*--------------------------------------
	# Contact Style
---------------------------------------*/
@import "sections/contact";

/*--------------------------------------
	# Team Style
---------------------------------------*/
@import "sections/team";

/*--------------------------------------
	# Animation Style
---------------------------------------*/
@import "sections/animation";

/*--------------------------------------
	# What We Do
---------------------------------------*/
@import "sections/what_we_do";

/*--------------------------------------
	# Who We Are
---------------------------------------*/
@import "sections/who_we_are";

/*--------------------------------------
	# Work Process
---------------------------------------*/
@import "sections/work_process";

/*--------------------------------------
	# WhY Choose Us
---------------------------------------*/
@import "sections/why_us";

/*--------------------------------------
	# Services Style
---------------------------------------*/
@import "sections/service";

/*--------------------------------------
	# Skill Style
---------------------------------------*/
@import "sections/skill";

/*--------------------------------------
	# Mission Style
---------------------------------------*/
@import "sections/mission";

/*--------------------------------------
	# Chatbox Style
---------------------------------------*/
@import "sections/chatbox";
/*--------------------------------------

/*--------------------------------------
	# 404 Style
---------------------------------------*/
@import "sections/404";
/*--------------------------------------

/*--------------------------------------
	# Experience Style
---------------------------------------*/
@import "sections/experience";

/*--------------------------------------
	# Testimonial Style
---------------------------------------*/
@import "sections/testimonial";

/*--------------------------------------
	# Project Style
---------------------------------------*/
@import "sections/project";

/*--------------------------------------
	# Counter Style
---------------------------------------*/
@import "sections/counter";


/*--------------------------------------
	# Blog Style
---------------------------------------*/
@import "sections/blog";

/*--------------------------------------
	# Partner Style
---------------------------------------*/
@import "sections/partner";

/*--------------------------------------
	# Call To Action
---------------------------------------*/
@import "sections/cta";

/*--------------------------------------
	# Analyze Style
---------------------------------------*/
@import "sections/analyze";

/*--------------------------------------
	# Landing Style
---------------------------------------*/
@import "sections/landing";

/*--------------------------------------
	# Preloader Style
---------------------------------------*/
@import "sections/preloader";