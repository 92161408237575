/*--------------------------------------
    # Pagination Style
---------------------------------------*/

ul.pagination-layout1 {
    margin-bottom: rem(25px);
    margin-top: rem(16px);
    display: flex;
    flex-wrap: wrap;
    align-items: left;
    justify-content: left;
    @media only screen and (max-width: 991px) {
        margin-top: rem(20px);
    }

    @media only screen and (max-width: 767px) {
        margin-top: rem(10px);
    }

    @media only screen and (max-width: 575px) {
        margin-top: rem(5px);
    }

    li {
        margin-right: rem(10px);
        margin-bottom: rem(10px);
        a {
        	i {
        		transition: all 0.3s ease-in-out;
        	}
        }

		&:first-child {
            a {
            	i {
            		color: #bbbbbb;
            	}
            }
        }

        &:last-child {
            margin-right: 0;
            a {
            	i {
            		color: #a3a0a0;
                    &:before{
                        font-size: rem(15px);
                    }
            	}
            }
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: rem(18px);
            font-weight: 600;
            font-family: $title-font;
            color: $title-color;
            background-color: transparent;
            border: rem(1px) solid #e4e4e4;
            height: rem(40px);
            width: rem(40px);
            transition: all 0.3s ease-in-out;

            &:hover {
                background-color: $primary-color;
                color: #ffffff;
                border: rem(1px) solid $primary-color;
                i {
                	color: #ffffff;
                }
            }
        }
    }

    li.active {
        a {
            background-color: $primary-color;
            color: #ffffff;
            border: rem(1px) solid $primary-color;
        }
    }
}

ul.pagination-layout2 {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
	li {
		.item-figure {
			margin-bottom: 0;
			img {
				border-radius: 50%;
			}
		}
		.item-content {
			p {
				color: $primary-color;
				margin-bottom: 0;
			}
			.item-title {
				margin-bottom: 0;
				font-weight: 500;
				font-size: rem(18px);
				color: #646464;
				line-height: 1.4;
				transition: all 0.3s ease-in-out;
			}
			@media only screen and (max-width: 767px) {
		        display: none;
		    }
		}
		&:hover {
			.item-content {
				.item-title {
					color: $primary-color;
				}
			}
		}
	}
	.next-btn {
		text-align: right;
	}
	.center-btn {
		a {
			height: rem(50px);
			width: rem(50px);
			background-color: #f3f3f3;
			color: #646464;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			transition: all 0.3s ease-in-out;
			&:hover {
				background-color: $primary-color;
				color: #fff;
			}
		}
	}
}