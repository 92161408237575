.mission-section{
    padding-top: rem(110px);
    padding-bottom: 46px;
    overflow: hidden;
    .section-title{
        &.title-style2{
            .title{
                margin-bottom: rem(6px);
            }
        }
    }
    .mission-img{
        text-align: right;
    }
    .mission-area{
        max-width: rem(545px);
    }
    .mission-type{
        padding-top: 1.6rem;
        .mission-item{          
            .mission-icon{
                box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.08);
                background: #fff;
                border-radius: 100%;
                width: rem(70px);
                height: rem(70px);
                line-height: rem(70px);
                text-align: center;
                float: left;
                transition: $transition;
                margin-top: 1rem;
                i{
                    transition: $transition;
                    &:before{
                        font-size: rem(35px);
                    }
                }
            }
            .short-desc{
                float: left;
                width: calc(100% - 7rem);
                padding-left: 2.5rem;
                .mission-title{
                    font-size: 2.2rem;
                    font-weight: 600;
                    color: $title-color;
                    font-family: $title-font;
                    margin-bottom: .4rem; 
                    font-style: italic;    
                }
                p{
                    margin-bottom: rem(20px);
                }
            }            
            &.item-one{
                .mission-icon{
                    i{
                        color: $variation-color1;
                    }
                }
            }
            &.item-two{
                .mission-icon{
                    i{
                        color: $variation-color3;
                    }
                }
            }
            &.item-three{
                .mission-icon{
                    i{
                        color: $variation-color4;
                    }
                }
            }
            &:hover{
                &.item-one{
                    .mission-icon{
                        background: $variation-color1;
                    }
                }
                &.item-two{
                    .mission-icon{
                        background: $variation-color3;
                    }
                }
                &.item-three{
                    .mission-icon{
                        background: $variation-color4;
                    }
                }
                .mission-icon{
                    background: $primary-color;
                    color: #fff;
                    i{
                        color: #fff;
                    }
                }
            }
        }
    }
}