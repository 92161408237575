/*--------------------------------------
	# Typography
---------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: $title-font;
	color: $title-color;
	font-weight: 700;
	line-height: 1.4;
	margin-bottom: 2rem;
}
h3{
    margin-bottom: 1.7rem;
}

h1,
.h1 {
	font-size: 4.2rem;
	font-weight: 700;
}

.page-title {
	font-size: 4.2rem;
}

h2,
.h2 {
	font-size: 3.6rem;
	font-weight: 700;
}

h3,
.h3 {
	font-size: 2.6rem;
	font-weight: 600;
}

h4,
.h4 {
	font-size: 2.2rem;
	font-weight: 600;
	margin-bottom: rem(12px);
}

h5,
.h5 {
	font-size: 1.8rem;
	line-height: 1.6;
	font-weight: 600;
	margin-bottom: rem(12px);
}

h6,
.h6 {
	font-size: 1.4rem;
	line-height: 1.6;
	margin-bottom: rem(10px);
}

p {
	font-family: $primary-font;
	font-size: 1.6rem;
	line-height: 3rem;

	&.big {
		font-size: 2rem;
	}

	&.mid {
		font-size: 1.6rem;
		line-height: 2.8rem;
	}

	&.small {
		font-size: 1.4rem;
		line-height: 2.4rem;
	}
}

address,
.address {
	font-family: $primary-font;;
	font-size: 2rem;
	line-height: 3.4rem;
}