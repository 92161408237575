.video-section{
    background: url(../../media/background/video-bg2.jpg) no-repeat center center;
    height: 600px;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    .play-video{        
        height: 100%;
        .play-icon{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            text-align: center;
            width: rem(100px);
            height: rem(100px);
            z-index: 99;
            background: #fff;
            border-radius: 100%;
            .popup-video{
                &:after{
                    content: "";
                    position: absolute;
                    width: 150%;
                    height: 150%;
                    -webkit-animation-delay: 0s;
                    animation-delay: 0s;
                    -webkit-animation: pulsate1 2s;
                    animation: pulsate1 2s;
                    -webkit-animation-direction: forwards;
                    animation-direction: forwards;
                    -webkit-animation-iteration-count: infinite;
                    animation-iteration-count: infinite;
                    -webkit-animation-timing-function: steps;
                    animation-timing-function: steps;
                    opacity: 1;
                    border-radius: 50%;
                    border: 5px solid #fff;
                    top: -25%;
                    left: -25%;
                    background: #fff;
                }
                &:before{
                    content: "";
                    position: absolute;
                    left: rem(32px);
                    top: rem(31px);
                    display: block;
                    width: rem(40px);
                    height: rem(40px);
                    background: url(../../media/icons/play2.png) no-repeat center center;
                    border-radius: 100%;
                    z-index: 9;
                }
                &:hover{
                    &:after{
                        background: #eee;
                    }
                }
            }
        }
    }
}
.video-section2{
    background: url(../../media/background/video-bg3.jpg) no-repeat center center;
    height: 600px;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    .play-video{        
        height: 100%;
        .play-icon{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            text-align: center;
            width: rem(100px);
            height: rem(100px);
            z-index: 99;
            background: #fff;
            border-radius: 100%;
            .popup-video{
                &:after{
                    content: "";
                    position: absolute;
                    width: 150%;
                    height: 150%;
                    -webkit-animation-delay: 0s;
                    animation-delay: 0s;
                    -webkit-animation: pulsate1 2s;
                    animation: pulsate1 2s;
                    -webkit-animation-direction: forwards;
                    animation-direction: forwards;
                    -webkit-animation-iteration-count: infinite;
                    animation-iteration-count: infinite;
                    -webkit-animation-timing-function: steps;
                    animation-timing-function: steps;
                    opacity: 1;
                    border-radius: 50%;
                    border: 5px solid #fff;
                    top: -25%;
                    left: -25%;
                    background: #fff;
                }
                &:before{
                    content: "";
                    position: absolute;
                    left: rem(32px);
                    top: rem(31px);
                    display: block;
                    width: rem(40px);
                    height: rem(40px);
                    background: url(../../media/icons/play2.png) no-repeat center center;
                    border-radius: 100%;
                    z-index: 9;
                }
                &:hover{
                    &:after{
                        background: #eee;
                    }
                }
            }
        }
    }
}
.play-video.focus-icon{
    .play-icon{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;
        width: rem(100px);
        height: rem(100px);
        z-index: 99;
        background: #fff;
        border-radius: 100%;
        .popup-video{
            position: relative;
            left: 0;
            top: 0;
            display: block;
            width: rem(100px);
            height: rem(100px);
            &:after{
                content: "";
                position: absolute;
                width: rem(120px);
                height: rem(120px);
                -webkit-animation-delay: 0s;
                animation-delay: 0s;
                -webkit-animation: pulsate1 2s;
                animation: pulsate1 2s;
                -webkit-animation-direction: forwards;
                animation-direction: forwards;
                -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
                -webkit-animation-timing-function: steps;
                animation-timing-function: steps;
                opacity: 1;
                border-radius: 50%;
                border: 5px solid #fff;
                top: rem(-10px);
                left: rem(-10px);
                background: #fff;
            }
            &:before{
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
            &:hover{
                &:after{
                    background: #eee;
                }
            }
        }
    }    
}

@-webkit-keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
    box-shadow: none;

  }
}

@keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1);
    opacity: 0;
    box-shadow: none;

  }
}