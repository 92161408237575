.portfolio-section{
    padding-bottom: 9rem;
    .loarmore{
        margin-top: 6rem;
    }
    .sec-desc{
        margin-bottom: 5rem;
    }
    .portfolio-filter{
        margin-bottom: rem(46px);
        button{
            background: none;
            border: none;
            font-weight: 500;
            font-family: $title-font;
            color: $title-color;
            font-size: rem(18px);
            outline: none;
            cursor: pointer;
            margin-right: rem(30px);
            transition: $transition;
            &.active,
            &:hover{
                color: $primary-color;
               
            }
            &:last-child{
                margin-right: 0;
            }
        }
    }
    .project-content{
        position: relative;
        .project-info{
            position: absolute;
            left: 50%;
            top: 60%;
            transform: translate(-50%, -50%);
            text-align: center;
            color: #fff;
            z-index: 99;
            visibility: hidden;
            transition: $transition;
            opacity: 0;
            width: 95%;
            .project-title{
                font-size: 2.2rem;
                line-height: 2.9rem;
                font-weight: 600;
                margin-bottom: rem(3px);
                text-transform: uppercase;
                a{
                    color: #fff;
                    &:hover{
                        color: $primary-color;
                    }
                }
            }
        }
        &:after{
            position: absolute;
            content: "";
            display: block;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.75);
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            transition: all 0.4s ease 0s;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        &:hover{
            .project-info{
                visibility: visible;
                opacity: 1;
                top: 50%;
            }
            &:after{
                width: 100%;
                height: 100%;
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &.landing-project{
        overflow: hidden;
        padding-left: rem(35px);
        padding-right: rem(35px);
        @media only screen and (max-width: 1660px) {
            padding-left: rem(0);
            padding-right: rem(0);
        }
        .project-link{
            position: absolute;
            left: 50%;
            right: auto;
            top: 30%;
            transform: translateX(-50%);
            z-index: 9;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
            a{
                background: #fff;
                color: #333333;
                height: rem(45px);
                width: rem(45px);
                font-size: rem(20px);
                display: block;
                text-align: center;
                line-height: rem(48px);
                border-radius: 100%;
                z-index: 999;
                &:hover{
                    opacity: 0.8;
                }
            }
        }
        .grid-item{
            padding: rem(0 25px);
            margin-bottom: rem(50px);
            @media only screen and (max-width: 1660px) {
                padding: rem(0 15px);
                margin-bottom: rem(30px);
            }
            .project-content{
                img{
                    width: 100%;
                }
            }
        }
        .section-title{
            padding-bottom: rem(25px);
            max-width: rem(670px);
            margin: 0 auto;
            @media only screen and (max-width: 1440px) {
                padding-left: 0;
                max-width: rem(560px);
            }
        }
        .project-content{
            .project-info{
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                top: auto;
                text-align: center;
                .project-title{
                    margin-bottom: rem(5px);
                    a{
                        color: #fff;
                        &:hover{
                            color: #f0f0f0;
                        }
                    }
                }
            }
            .overlay-link{
                position: relative;
                display: block;
                &:after{
                    position: absolute;
                    content: "";
                    display: block;
                    top: 0;
                    left: 0;
                    background: rgba(0, 0, 0, 0.75);
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    visibility: hidden;
                    transition: all 0.4s ease 0s;
                    border-radius: rem(6px);
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            &:after{
                background: rgba($primary-color, 0.9);
                display: none;
            }
            &:hover{
                .project-info{
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: rem(50px);
                    top: auto;
                }
                .overlay-link{
                    &:after{
                        visibility: visible;
                        opacity: 1;
                    }
                }
                &:after{
                    width: 100%;
                    height: 100%;
                    opacity: 1;
                    visibility: visible;
                }
                .project-link{
                    opacity: 1;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    visibility: visible;
                }
            }
        }
        .loarmore{
            margin-top: rem(30px);
        }
    }
    &.portfolio-layout2{
        .project-content{
            cursor: pointer;
            img{
                -webkit-filter: blur(0);
                filter: blur(0);
                -webkit-transition: all 0.7s ease;
                transition: all 0.7s ease;
                border-radius: rem(4px);
            }
            &:hover{
                cursor: pointer;
                img{
                    -webkit-filter: blur(5px);;
                    filter: blur(5px);
                }
            }
            &:after{
                background: rgba(0, 0, 0, 0.3);
                -webkit-transition: all 0.7s ease;
                transition: all 0.7s ease;
                border-radius: rem(4px);
            }
            .project-info{
                top: 50%;
                .project-tags{
                    font-size: rem(16px);
                    font-weight: 400;
                }
            }
        }
        .nav-list{
            position: relative;
            #ProjectNav{
                position: absolute;
                right: 0;
                top: rem(-100px);
                .owl-prev,
                .owl-next{
                    background: transparent;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    font-size: rem(25px);
                    color: #a1a3a9;
                    padding: 0;
                    transition: $transition;
                    &:hover{
                        color: $primary-color;
                    }
                    i{
                        &:before{
                            font-size: rem(33px);
                        }
                    }
                }
                .owl-prev{
                    transform: rotate(180deg);
                    position: relative;
                    margin-right: rem(15px);
                }
            }
        }
    }
    &.portfolio-layout3{
        .project-content{
            img{
                border-radius: rem(5px);
            }
            &:after{
                border-radius: rem(5px);
            }            
        }
        .loarmore{
            margin-top: rem(30px);
        }
    }
}
.portfolio-details-section{
    .project-info{
        background: #f5f7ff;
        padding: rem(10px 30px 10px 45px);
        height: 100%;
        .item-heading{
            font-size: rem(24px);
            font-weight: 600;
            margin-bottom: rem(28px);
        }
        li{
            line-height: rem(33px);
            margin-bottom: rem(12px);
        }
        .feature-title{
            font-size: rem(18px);
            font-weight: 600;
            font-family: $title-font;
            color: $title-color;
        }
    }
    .project-desc{
        h4{
            font-weight: 600;
            font-size: rem(22px);
            margin-bottom: rem(6px);
        }
    }
}
#blog-pages.portfolio-section.landing-project.demo-landing-pages .project-link a,
#inner-pages.portfolio-section.landing-project.demo-landing-pages .project-link a{
    min-width: rem(160px);
    padding-left: rem(20px);
    font-size: rem(14px);
    padding-right: rem(20px);
}