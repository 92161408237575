.mt--0 {
    margin-top: rem(0);
}

.mt--1 {
    margin-top: rem(1px);
}

.mt--2 {
    margin-top: rem(2px);
}

.mt--3 {
    margin-top: rem(3px);
}

.mt--4 {
    margin-top: rem(4px);
}

.mt--5 {
    margin-top: rem(5px);
}

.mt--6 {
    margin-top: rem(6px);
}

.mt--7 {
    margin-top: rem(7px);
}

.mt--8 {
    margin-top: rem(8px);
}

.mt--9 {
    margin-top: rem(9px);
}

.mt--10 {
    margin-top: rem(10px);
}

.mt--11 {
    margin-top: rem(11px);
}

.mt--12 {
    margin-top: rem(12px);
}

.mt--13 {
    margin-top: rem(13px);
}

.mt--14 {
    margin-top: rem(14px);
}

.mt--15 {
    margin-top: rem(15px);
}

.mt--16 {
    margin-top: rem(16px);
}

.mt--17 {
    margin-top: rem(17px);
}

.mt--18 {
    margin-top: rem(18px);
}

.mt--19 {
    margin-top: rem(19px);
}

.mt--20 {
    margin-top: rem(20px);
}

.mt--21 {
    margin-top: rem(21px);
}

.mt--22 {
    margin-top: rem(22px);
}

.mt--23 {
    margin-top: rem(23px);
}

.mt--24 {
    margin-top: rem(24px);
}

.mt--25 {
    margin-top: rem(25px);
}

.mt--26 {
    margin-top: rem(26px);
}

.mt--27 {
    margin-top: rem(27px);
}

.mt--28 {
    margin-top: rem(28px);
}

.mt--29 {
    margin-top: rem(29px);
}

.mt--30 {
    margin-top: rem(30px);
}

.mt--35 {
    margin-top: rem(35px);
}

.mt--40 {
    margin-top: rem(40px);
}

.mt--45 {
    margin-top: rem(45px);
}

.mt--50 {
    margin-top: rem(50px);
}

.mt--60 {
    margin-top: rem(60px);
}

.mt--70 {
    margin-top: rem(70px);
}

.mt--80 {
    margin-top: rem(80px);
}

.mt--90 {
    margin-top: rem(90px);
}

.mt--100 {
    margin-top: rem(100px);
}

.mb--0 {
    margin-bottom: rem(0)!important;
}

.mb--1 {
    margin-bottom: rem(1px);
}

.mb--2 {
    margin-bottom: rem(2px);
}

.mb--3 {
    margin-bottom: rem(3px);
}

.mb--4 {
    margin-bottom: rem(4px);
}

.mb--5 {
    margin-bottom: rem(5px);
}

.mb--6 {
    margin-bottom: rem(6px);
}

.mb--7 {
    margin-bottom: rem(7px);
}

.mb--8 {
    margin-bottom: rem(8px);
}

.mb--9 {
    margin-bottom: rem(9px);
}

.mb--10 {
    margin-bottom: rem(10px);
}

.mb--11 {
    margin-bottom: rem(11px);
}

.mb--12 {
    margin-bottom: rem(12px);
}

.mb--13 {
    margin-bottom: rem(13px);
}

.mb--14 {
    margin-bottom: rem(14px);
}

.mb--15 {
    margin-bottom: rem(15px);
}

.mb--16 {
    margin-bottom: rem(16px);
}

.mb--17 {
    margin-bottom: rem(17px);
}

.mb--18 {
    margin-bottom: rem(18px);
}

.mb--19 {
    margin-bottom: rem(19px);
}

.mb--20 {
    margin-bottom: rem(20px);
}

.mb--21 {
    margin-bottom: rem(21px);
}

.mb--22 {
    margin-bottom: rem(22px);
}

.mb--23 {
    margin-bottom: rem(23px);
}

.mb--24 {
    margin-bottom: rem(24px);
}

.mb--25 {
    margin-bottom: rem(25px);
}

.mb--26 {
    margin-bottom: rem(26px);
}

.mb--27 {
    margin-bottom: rem(27px);
}

.mb--28 {
    margin-bottom: rem(28px);
}

.mb--29 {
    margin-bottom: rem(29px);
}

.mb--30 {
    margin-bottom: rem(30px);
}

.mb--35 {
    margin-bottom: rem(35px);
}

.mb--40 {
    margin-bottom: rem(40px);
}

.mb--45 {
    margin-bottom: rem(45px);
}

.mb--50 {
    margin-bottom: rem(50px);
}

.mb--60 {
    margin-bottom: rem(60px);
}

.mb--70 {
    margin-bottom: rem(70px);
}

.mb--80 {
    margin-bottom: rem(80px);
}

.mb--90 {
    margin-bottom: rem(90px);
}

.mb--100 {
    margin-bottom: rem(100px);
}

.ml--0 {
    margin-left: rem(0);
}

.ml--1 {
    margin-left: rem(1px);
}

.ml--2 {
    margin-left: rem(2px);
}

.ml--3 {
    margin-left: rem(3px);
}

.ml--4 {
    margin-left: rem(4px);
}

.ml--5 {
    margin-left: rem(5px);
}

.ml--6 {
    margin-left: rem(6px);
}

.ml--7 {
    margin-left: rem(7px);
}

.ml--8 {
    margin-left: rem(8px);
}

.ml--9 {
    margin-left: rem(9px);
}

.ml--10 {
    margin-left: rem(10px);
}

.ml--11 {
    margin-left: rem(11px);
}

.ml--12 {
    margin-left: rem(12px);
}

.ml--13 {
    margin-left: rem(13px);
}

.ml--14 {
    margin-left: rem(14px);
}

.ml--15 {
    margin-left: rem(15px);
}

.ml--16 {
    margin-left: rem(16px);
}

.ml--17 {
    margin-left: rem(17px);
}

.ml--18 {
    margin-left: rem(18px);
}

.ml--19 {
    margin-left: rem(19px);
}

.ml--20 {
    margin-left: rem(20px);
}

.ml--21 {
    margin-left: rem(21px);
}

.ml--22 {
    margin-left: rem(22px);
}

.ml--23 {
    margin-left: rem(23px);
}

.ml--24 {
    margin-left: rem(24px);
}

.ml--25 {
    margin-left: rem(25px);
}

.ml--26 {
    margin-left: rem(26px);
}

.ml--27 {
    margin-left: rem(27px);
}

.ml--28 {
    margin-left: rem(28px);
}

.ml--29 {
    margin-left: rem(29px);
}

.ml--30 {
    margin-left: rem(30px);
}

.ml--35 {
    margin-left: rem(35px);
}

.ml--40 {
    margin-left: rem(40px);
}

.ml--45 {
    margin-left: rem(45px);
}

.ml--50 {
    margin-left: rem(50px);
}

.ml--60 {
    margin-left: rem(60px);
}

.ml--70 {
    margin-left: rem(70px);
}

.ml--80 {
    margin-left: rem(80px);
}

.ml--90 {
    margin-left: rem(90px);
}

.ml--100 {
    margin-left: rem(100px);
}

.mr--0 {
    margin-right: rem(0);
}

.mr--1 {
    margin-right: rem(1px);
}

.mr--2 {
    margin-right: rem(2px);
}

.mr--3 {
    margin-right: rem(3px);
}

.mr--4 {
    margin-right: rem(4px);
}

.mr--5 {
    margin-right: rem(5px);
}

.mr--6 {
    margin-right: rem(6px);
}

.mr--7 {
    margin-right: rem(7px);
}

.mr--8 {
    margin-right: rem(8px);
}

.mr--9 {
    margin-right: rem(9px);
}

.mr--10 {
    margin-right: rem(10px);
}

.mr--11 {
    margin-right: rem(11px);
}

.mr--12 {
    margin-right: rem(12px);
}

.mr--13 {
    margin-right: rem(13px);
}

.mr--14 {
    margin-right: rem(14px);
}

.mr--15 {
    margin-right: rem(15px);
}

.mr--16 {
    margin-right: rem(16px);
}

.mr--17 {
    margin-right: rem(17px);
}

.mr--18 {
    margin-right: rem(18px);
}

.mr--19 {
    margin-right: rem(19px);
}

.mr--20 {
    margin-right: rem(20px);
}

.mr--21 {
    margin-right: rem(21px);
}

.mr--22 {
    margin-right: rem(22px);
}

.mr--23 {
    margin-right: rem(23px);
}

.mr--24 {
    margin-right: rem(24px);
}

.mr--25 {
    margin-right: rem(25px);
}

.mr--26 {
    margin-right: rem(26px);
}

.mr--27 {
    margin-right: rem(27px);
}

.mr--28 {
    margin-right: rem(28px);
}

.mr--29 {
    margin-right: rem(29px);
}

.mr--30 {
    margin-right: rem(30px);
}

.mr--35 {
    margin-right: rem(35px);
}

.mr--40 {
    margin-right: rem(40px);
}

.mr--45 {
    margin-right: rem(45px);
}

.mr--50 {
    margin-right: rem(50px);
}

.mr--60 {
    margin-right: rem(60px);
}

.mr--70 {
    margin-right: rem(70px);
}

.mr--80 {
    margin-right: rem(80px);
}

.mr--90 {
    margin-right: rem(90px);
}

.mr--100 {
    margin-right: rem(100px);
}

.pt--0 {
    padding-top: rem(0);
}

.pt--1 {
    padding-top: rem(1px);
}

.pt--2 {
    padding-top: rem(2px);
}

.pt--3 {
    padding-top: rem(3px);
}

.pt--4 {
    padding-top: rem(4px);
}

.pt--5 {
    padding-top: rem(5px);
}

.pt--6 {
    padding-top: rem(6px);
}

.pt--7 {
    padding-top: rem(7px);
}

.pt--8 {
    padding-top: rem(8px);
}

.pt--9 {
    padding-top: rem(9px);
}

.pt--10 {
    padding-top: rem(10px);
}

.pt--11 {
    padding-top: rem(11px);
}

.pt--12 {
    padding-top: rem(12px);
}

.pt--13 {
    padding-top: rem(13px);
}

.pt--14 {
    padding-top: rem(14px);
}

.pt--15 {
    padding-top: rem(15px);
}

.pt--16 {
    padding-top: rem(16px);
}

.pt--17 {
    padding-top: rem(17px);
}

.pt--18 {
    padding-top: rem(18px);
}

.pt--19 {
    padding-top: rem(19px);
}

.pt--20 {
    padding-top: rem(20px);
}

.pt--21 {
    padding-top: rem(21px);
}

.pt--22 {
    padding-top: rem(22px);
}

.pt--23 {
    padding-top: rem(23px);
}

.pt--24 {
    padding-top: rem(24px);
}

.pt--25 {
    padding-top: rem(25px);
}

.pt--26 {
    padding-top: rem(26px);
}

.pt--27 {
    padding-top: rem(27px);
}

.pt--28 {
    padding-top: rem(28px);
}

.pt--29 {
    padding-top: rem(29px);
}

.pt--30 {
    padding-top: rem(30px);
}

.pt--35 {
    padding-top: rem(35px);
}

.pt--40 {
    padding-top: rem(40px);
}

.pt--45 {
    padding-top: rem(45px);
}

.pt--50 {
    padding-top: rem(50px);
}

.pt--60 {
    padding-top: rem(60px);
}

.pt--70 {
    padding-top: rem(70px);
}

.pt--80 {
    padding-top: rem(80px);
}

.pt--90 {
    padding-top: rem(90px);
}

.pt--100 {
    padding-top: rem(100px);
}

.pb--0 {
    padding-bottom: rem(0);
}

.pb--1 {
    padding-bottom: rem(1px);
}

.pb--2 {
    padding-bottom: rem(2px);
}

.pb--3 {
    padding-bottom: rem(3px);
}

.pb--4 {
    padding-bottom: rem(4px);
}

.pb--5 {
    padding-bottom: rem(5px);
}

.pb--6 {
    padding-bottom: rem(6px);
}

.pb--7 {
    padding-bottom: rem(7px);
}

.pb--8 {
    padding-bottom: rem(8px);
}

.pb--9 {
    padding-bottom: rem(9px);
}

.pb--10 {
    padding-bottom: rem(10px);
}

.pb--11 {
    padding-bottom: rem(11px);
}

.pb--12 {
    padding-bottom: rem(12px);
}

.pb--13 {
    padding-bottom: rem(13px);
}

.pb--14 {
    padding-bottom: rem(14px);
}

.pb--15 {
    padding-bottom: rem(15px);
}

.pb--16 {
    padding-bottom: rem(16px);
}

.pb--17 {
    padding-bottom: rem(17px);
}

.pb--18 {
    padding-bottom: rem(18px);
}

.pb--19 {
    padding-bottom: rem(19px);
}

.pb--20 {
    padding-bottom: rem(20px);
}

.pb--21 {
    padding-bottom: rem(21px);
}

.pb--22 {
    padding-bottom: rem(22px);
}

.pb--23 {
    padding-bottom: rem(23px);
}

.pb--24 {
    padding-bottom: rem(24px);
}

.pb--25 {
    padding-bottom: rem(25px);
}

.pb--26 {
    padding-bottom: rem(26px);
}

.pb--27 {
    padding-bottom: rem(27px);
}

.pb--28 {
    padding-bottom: rem(28px);
}

.pb--29 {
    padding-bottom: rem(29px);
}

.pb--30 {
    padding-bottom: rem(30px);
}

.pb--35 {
    padding-bottom: rem(35px);
}

.pb--40 {
    padding-bottom: rem(40px);
}

.pb--45 {
    padding-bottom: rem(45px);
}

.pb--50 {
    padding-bottom: rem(50px);
}

.pb--60 {
    padding-bottom: rem(60px);
}

.pb--70 {
    padding-bottom: rem(70px);
}

.pb--80 {
    padding-bottom: rem(80px);
}

.pb--90 {
    padding-bottom: rem(90px);
}

.pb--100 {
    padding-bottom: rem(100px);
}

.pl--0 {
    padding-left: rem(0);
}

.pl--1 {
    padding-left: rem(1px);
}

.pl--2 {
    padding-left: rem(2px);
}

.pl--3 {
    padding-left: rem(3px);
}

.pl--4 {
    padding-left: rem(4px);
}

.pl--5 {
    padding-left: rem(5px);
}

.pl--6 {
    padding-left: rem(6px);
}

.pl--7 {
    padding-left: rem(7px);
}

.pl--8 {
    padding-left: rem(8px);
}

.pl--9 {
    padding-left: rem(9px);
}

.pl--10 {
    padding-left: rem(10px);
}

.pl--11 {
    padding-left: rem(11px);
}

.pl--12 {
    padding-left: rem(12px);
}

.pl--13 {
    padding-left: rem(13px);
}

.pl--14 {
    padding-left: rem(14px);
}

.pl--15 {
    padding-left: rem(15px);
}

.pl--16 {
    padding-left: rem(16px);
}

.pl--17 {
    padding-left: rem(17px);
}

.pl--18 {
    padding-left: rem(18px);
}

.pl--19 {
    padding-left: rem(19px);
}

.pl--20 {
    padding-left: rem(20px);
}

.pl--21 {
    padding-left: rem(21px);
}

.pl--22 {
    padding-left: rem(22px);
}

.pl--23 {
    padding-left: rem(23px);
}

.pl--24 {
    padding-left: rem(24px);
}

.pl--25 {
    padding-left: rem(25px);
}

.pl--26 {
    padding-left: rem(26px);
}

.pl--27 {
    padding-left: rem(27px);
}

.pl--28 {
    padding-left: rem(28px);
}

.pl--29 {
    padding-left: rem(29px);
}

.pl--30 {
    padding-left: rem(30px);
}

.pl--35 {
    padding-left: rem(35px);
}

.pl--40 {
    padding-left: rem(40px);
}

.pl--45 {
    padding-left: rem(45px);
}

.pl--50 {
    padding-left: rem(50px);
}

.pl--60 {
    padding-left: rem(60px);
}

.pl--70 {
    padding-left: rem(70px);
}

.pl--80 {
    padding-left: rem(80px);
}

.pl--90 {
    padding-left: rem(90px);
}

.pl--100 {
    padding-left: rem(100px);
}

.pr--0 {
    padding-right: rem(0);
}

.pr--1 {
    padding-right: rem(1px);
}

.pr--2 {
    padding-right: rem(2px);
}

.pr--3 {
    padding-right: rem(3px);
}

.pr--4 {
    padding-right: rem(4px);
}

.pr--5 {
    padding-right: rem(5px);
}

.pr--6 {
    padding-right: rem(6px);
}

.pr--7 {
    padding-right: rem(7px);
}

.pr--8 {
    padding-right: rem(8px);
}

.pr--9 {
    padding-right: rem(9px);
}

.pr--10 {
    padding-right: rem(10px);
}

.pr--11 {
    padding-right: rem(11px);
}

.pr--12 {
    padding-right: rem(12px);
}

.pr--13 {
    padding-right: rem(13px);
}

.pr--14 {
    padding-right: rem(14px);
}

.pr--15 {
    padding-right: rem(15px);
}

.pr--16 {
    padding-right: rem(16px);
}

.pr--17 {
    padding-right: rem(17px);
}

.pr--18 {
    padding-right: rem(18px);
}

.pr--19 {
    padding-right: rem(19px);
}

.pr--20 {
    padding-right: rem(20px);
}

.pr--21 {
    padding-right: rem(21px);
}

.pr--22 {
    padding-right: rem(22px);
}

.pr--23 {
    padding-right: rem(23px);
}

.pr--24 {
    padding-right: rem(24px);
}

.pr--25 {
    padding-right: rem(25px);
}

.pr--26 {
    padding-right: rem(26px);
}

.pr--27 {
    padding-right: rem(27px);
}

.pr--28 {
    padding-right: rem(28px);
}

.pr--29 {
    padding-right: rem(29px);
}

.pr--30 {
    padding-right: rem(30px);
}

.pr--35 {
    padding-right: rem(35px);
}

.pr--40 {
    padding-right: rem(40px);
}

.pr--45 {
    padding-right: rem(45px);
}

.pr--50 {
    padding-right: rem(50px);
}

.pr--60 {
    padding-right: rem(60px);
}

.pr--70 {
    padding-right: rem(70px);
}

.pr--80 {
    padding-right: rem(80px);
}

.pr--90 {
    padding-right: rem(90px);
}

.pr--100 {
    padding-right: rem(100px);
}