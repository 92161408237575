.blog-section{
    padding-bottom: 5rem;
    .meta-item{
        font-family: $title-font;
        font-size: rem(14px);
    }
    &.blog-grid{
        .blog-item{
            padding-bottom: rem(40px);
            .entry-meta{
                margin-bottom: rem(20px);
                font-size: rem(14px);
                li{
                    display: inline-block;
                    margin-right: rem(25px);
                    .post-author{
                        font-size: rem(14px);
                        color: $body-color;
                        font-weight: normal;
                        img{
                            border-radius: 100%;
                            max-width: rem(27px);
                            margin-right: rem(4px);
                        }
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    i{
                        margin-right: rem(7px);
                    }
                }
            }
        }
    }
    &.blog-masonry{
        .blog-item{
            padding-bottom: rem(24px);
        }
        ul.pagination-layout1{
            margin-top: rem(28px);
            align-items: center;
            justify-content: center;
        }
    }
    &.blog-style4{
        &.blog-list{
            .blog-item{
                .blog-details{
                    padding: rem(30px 30px);
                    border: rem(1px) solid #e8e8e8;
                    border-top: none;
                    margin-top: 0;
                    .post-author{
                        font-size: rem(20px);
                    }
                    .date{
                        font-size: rem(14px);
                        line-height: rem(24px);
                    }
                    .blog-title{
                        font-size: rem(24px);
                        margin-bottom: rem(12px);
                    }
                    .read-more{
                        font-size: rem(18px);
                        &:before{
                            top: 0;
                        }
                    }
                    .short-desc{
                        margin-bottom: rem(5px);
                    }
                }
            }
            .pagination-layout1{
                margin-top: rem(28px);
            }
        }
    }
    .blog-item{
        padding-bottom: 3rem;
        .blog-img{
            position: relative;
            overflow: hidden;
            img{
                width: 100%;
                transform: scale(1);
                transition: all 2s ease 0s;
            }
            .ptags{
                position: absolute;
                top: 3rem;
                left: 3rem;
                z-index: 9;
                transition: $transition;
                li{
                    display: inline-block;
                    padding-right: .5rem;
                    a{
                        font-size: 1.2rem;
                        color: #fff;
                        background: $primary-color;
                        border-radius: 3rem;
                        padding: .3rem 1.6rem;
                        &:hover{
                            background: darken($primary-color, 3%);
                        }
                    }
                }
            }
        }
        .blog-details{
            padding: 2.1rem 2.8rem 3rem;
            border: rem(1px) solid #e9e9e9;
            border-top:  none;
            transition: $transition;
            .date{
                i{
                    font-size: rem(15px);
                    padding-right: rem(5px);
                    position: relative;
                    top: rem(-1px);
                }
            }
            .blog-title{
                font-size: 2.2rem;
                margin-top: 1rem;
                font-weight: 600;
                a{
                    color: $title-color;
                    &:hover{
                        color: $primary-color;
                    }
                }
            }
            .blog-meta{
                display: flex;
                justify-content: space-between;
                .post-author{
                    img{
                        width: auto;
                        max-width: 100%;
                        display: inline-block;
                        padding-right: 1rem;
                    }
                    span{
                        color: $title-color;
                    }
                }
                .post-comment{
                    margin-top: rem(5px);
                    i{
                        padding-right: .3rem;
                    }
                }
            }                
        }
        .blog-overlay{
            padding: 2.1rem 3rem 3rem;
            border-top:  none;
            transition: $transition;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            &:after{
                position: absolute;
                content: "";
                height: 100%;
                width: 100%;
                display: block;
                left: 0;
                top: 0;
                z-index: 1;
                background-image: linear-gradient(to top,rgba(0,0,0,.8) 60%,rgba(255,255,255,0) 95%);
                background-image: -webkit-linear-gradient(to top,rgba(0,0,0,.8) 60%,rgba(255,255,255,0) 95%);
                background-image: -moz-linear-gradient(to top,rgba(0,0,0,.8) 60%,rgba(255,255,255,0) 95%);
                background-image: -ms-linear-gradient(to top,rgba(0,0,0,.8) 60%,rgba(255,255,255,0) 95%);
                background-image: -o-linear-gradient(to top,rgba(0,0,0,.8) 60%,rgba(255,255,255,0) 95%);
                transition: $transition;
            }
            .date{
                color: #fff;
                background: $primary-color;
                display: inline-block;
                padding: rem(6px 13px 2px 13px);
                position: relative;
                font-size: rem(13px);
                z-index: 9;
                border-radius: 3px; 
                i{
                    font-size: rem(15px);
                    padding-right: rem(5px);
                    position: relative;
                   
                }
            }
            .blog-title{
                font-size: 2.2rem;
                margin-top: rem(20px);
                margin-bottom: rem(15px);
                font-weight: 500;
                position: relative;
                z-index: 9;
                a{
                    color: #fff;
                    &:hover{
                        color: $primary-color;
                    }
                }
            }
            .blog-meta{
                display: flex;
                justify-content: space-between;
                color: #fff;
                position: relative;
                z-index: 9;
                .post-author{
                    font-size: rem(14px);
                }
            }                
        }
        &:hover{
            .blog-img{
                img{
                    transform: scale(1.1);
                }
            }                
        }           
    }
    .owl-item{
        &.center{
            .blog-item{
                .blog-details{
                    box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.1);
                    border-color: #fff;
                    .blog-title{
                        a{
                            color: $primary-color;
                        }
                    }
                }
            }
        }
    }      
    
    &.blog-style1{
        padding-bottom: rem(45px);
        &#tact-news{
            padding-bottom: rem(90px);
        }
    }
    &.blog-style2{
        padding-bottom: 8.3rem;
        .section-title{
            &.title-style2 {
                .title{
                    margin-bottom: 4.8rem;
                }
            }
        }
        .blog-carousel {
            .blog-item {
                .blog-details{
                    box-shadow: none;
                    border: none;
                    padding: 0;
                    margin-top: 2rem;
                    .post-author{
                        font-size: 1.8rem;
                        color: $title-color;
                        font-weight: 600;
                    }
                    .date{
                        font-size: 1.4rem;
                    }
                    .blog-title{
                        margin-top: 1.7rem;
                        margin-bottom: 0;
                        font-weight: 600;
                    }
                }
                .read-more{
                    color: $title-color;
                    margin-top: 1.6rem;
                    &:before{
                        color: $title-color;
                    }
                    &:hover{
                        text-decoration: none;
                        color: $primary-color;
                        &:before{
                            color: $primary-color;
                        }
                    }
                }
                &:hover{
                    .blog-img {
                        img{
                            transform: scale(1.1);
                        }
                    }
                    .blog-details {
                        .blog-title {
                            a{
                                color: $title-color;
                                &:hover{
                                    color: $primary-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.blog-style3{
        padding-bottom: rem(40px);
        position: relative;
        z-index: 9;
        .blog-grid{
            .blog-item {
                position: relative;
                z-index: 9;
                .blog-wrap{
                    box-shadow: $shadow-light2;
                    .blog-details{
                        padding: 3.1rem 3rem 3.5rem;
                        border: none;
                        box-shadow: none;
                        .blog-title{
                            font-weight: 600;
                            margin-top: 0;
                            margin-bottom: rem(20px);
                        }
                        .blog-meta{
                            font-size: rem(14px);
                            i{
                                color: $primary-color;
                                padding-right: rem(10px);
                            }
                            .post-comment{
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
        .blog-wrap {
            box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
            .blog-details{
                border: none;
            }
            .blog-item {
                position: relative;
                z-index: 9;                
                .blog-wrap{
                    box-shadow: $shadow-light2;
                    .blog-details{
                        padding: 3.1rem 3rem 3rem;
                        border: none;
                        box-shadow: none;
                        .blog-title{
                            font-weight: 600;
                            margin-top: 0;
                            margin-bottom: rem(8px);
                            a{
                                color: $title-color;
                                &:hover{
                                    color: $primary-color;
                                }
                            }
                        }
                        .blog-meta{
                            color: #969696;
                            i{
                                color: $primary-color;
                                padding-right: rem(10px);
                            }
                        }
                    }
                }
            }
        }
    }
    &.blog-style4{
        padding-bottom: 8.3rem;
        .section-title{
            &.title-style2 {
                .title{
                    margin-bottom: 4.5rem;
                }
            }
        }
        .loarmore{
            margin-top: rem(25px);
        }
        .blog-item {
            .blog-details{
                box-shadow: none;
                border: none;
                padding: 0;
                margin-top: 2.5rem;
                .post-author{
                    font-size: 1.8rem;
                    color: $title-color;
                    font-weight: 600;
                }
                .date{
                    font-size: 1.4rem;
                }
                .blog-title{
                    margin-top: 1.8rem;
                    margin-bottom: 0;
                    font-weight: 600;
                }
            }
            .read-more{
                color: $title-color;
                margin-top: 2rem;
                &:before{
                    color: $title-color;
                }
                &:hover{
                    text-decoration: none;
                    color: $primary-color;
                    &:before{
                        color: $primary-color;
                    }
                }
            }
            &:hover{
                .blog-img {
                    img{
                        transform: scale(1.1);
                    }
                }
                .blog-details {
                    .blog-title {
                        a{
                            color: $title-color;
                            &:hover{
                                color: $primary-color;
                            }
                        }
                    }
                }
            }
        }
    }
    &.blog-style5{
        padding-top: 0;
        margin-top: rem(-92px);
    }
    &.blog-style6{
        .blog-carousel {
            .blog-item{
                position: relative;
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
    }
}

/* Single Blog Css */

.single-blog-section{
    .single-blog{
        padding-right: rem(20px);
        .item-main-figure{
            margin-bottom: rem(30px);
        }
        .entry-title{
            font-size: rem(26px);
            font-weight: 600;
        }
        .entry-meta{
            margin-bottom: rem(30px);
            font-size: rem(14px);
            li{
                display: inline-block;
                margin-right: rem(25px);
                .post-author{
                    img{
                        border-radius: 100%;
                        max-width: rem(27px);
                        margin-right: rem(4px);
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
                i{
                    margin-right: rem(7px);
                }
            }
        }
        .blog-tag {
            border-top: rem(1px) solid #dfdfdf;
            padding: rem(22px 0 13px);
            margin-bottom: rem(60px);
            margin-top: rem(54px);

            ul {
                display: flex;
                justify-content: space-between;

                @media only screen and (max-width: 575px) {
                    display: block;
                }

                li {
                    display: flex;
                    align-items: center;
                    margin-bottom: rem(15px);
                    line-height: 1;

                    @media only screen and (max-width: 575px) {
                        justify-content: center;
                    }
                }

                .item-tag {
                    span {
                        margin-right: rem(4px);
                        display: block;
                        a {
                            font-size: rem(15px);
                            color: $title-color;
                            &:hover {
                                color: $primary-color;
                            }
                        }
                        &:first-child {
                            font-size: rem(15px);
                            color: $title-color;
                        }
                    }
                }

                .item-social {
                    span {
                        margin-right: rem(15px);
                        display: block;
                        a {
                            color: $title-color;
                            font-weight: 500;
                            transition: all 0.3s ease-in-out;


                            &:hover {
                                color: $primary-color;
                            }
                        }
                        &:first-child {
                            font-size: rem(16px);
                            color: $title-color;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }

                .item-respons {
                    color: #a5a5a5;

                    i {
                        color: #646464;
                        font-size: rem(20px);
                        margin-right: rem(10px);
                    }
                }
            }
        }
        .blog-author {
            margin-bottom: rem(50px);
            background: #f7f7f7;
            padding: rem(30px 20px 30px 28px);

            @media only screen and (max-width: 575px) {
                text-align: center;
            }

            .media {
                img {
                    border-radius: 50%;
                }

                .media-body {
                    position: relative;
                    padding-left: rem(24px);

                    .item-title {
                        font-size: rem(18px);
                        color: $title-color;
                        font-weight: 600;
                        margin-bottom: rem(10px);
                    }
                    p{
                        margin-bottom: 0;
                    }
                    .item-subtitle {
                        line-height: rem(20px);
                        margin-bottom: 5px;
                        font-weight: 600;
                    }
                }
            }
        }
        .blog-comment {
            background-color: #ffffff;
            margin-bottom: rem(43px);
            .media-img-auto{
                margin-top: rem(5px);
            }
            .inner-title{
                margin-bottom: rem(25px);
            }
            ul {
                li {
                    position: relative;
                    margin-bottom: rem(24px);
                    border-bottom: rem(1px) solid #e8e8e8;
                    padding-bottom: rem(22px);;
                    &:last-child {
                        margin-bottom: 0;
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                    &:nth-child(odd) {
                        margin-left: 0;
                    }
                    &:nth-child(even) {
                        margin-left: rem(50px);
                    }

                    .comment-title {
                        font-weight: 600;
                        color: $title-color;
                        margin-bottom: rem(0);
                    }

                    .post-date {
                        font-weight: normal;
                        font-family: $primary-font;
                        font-size: rem(13px);
                        color: $primary-color;
                        margin-left: rem(8px);
                        position: relative;
                        bottom: rem(2px);
                    }

                    p {
                        width: 100%;
                        margin-bottom: rem(8px);
                    }

                    .item-btn {
                        color: $title-color;
                        @media only screen and (max-width: 767px) {
                            position: inherit;
                        }
                        &:hover {
                            color: $primary-color;
                        }
                    }
                }
            }
            .media-body{
                padding-left: rem(18px);
            }
        }
        .comment-box {
            .inner-title{
                margin-bottom: 0;
            }
            .form-box {
                margin-top: rem(12px);
                .form-group {
                    margin-bottom: rem(25px);

                    .form-control {
                        color: #000000;
                        padding: rem(15px 20px);
                        border: rem(1px) solid #dbdbdb;
                        background-color: transparent;
                        border-radius: 0;
                        font-size: rem(15px);
                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }
                    }

                    input {
                        height: rem(50px);
                    }
                }

                .contact-info {
                    margin-bottom: 32px;

                    li {
                        color: #111111;
                        margin-bottom: rem(15px);
                        position: relative;
                        padding-left: rem(42px);

                        &:last-child {
                            margin-bottom: 0px;
                            color: #444444;
                        }

                        &:first-child {
                            font-weight: 500;
                        }

                        i {
                            font-size: rem(22px);
                            color: $primary-color;
                            top: rem(5px);
                            left: 0;
                            position: absolute;
                        }
                    }
                }
                button{
                    cursor: pointer;
                }
            }
        }
    }
}

.blog-section .blog-item.grid-center .blog-details,
.blog-section.blog-style1 .blog-item:hover .blog-details{
    background: #fff;
    box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.1);
}