.experience-section{
    padding-top: rem(108px);
    .section-title {
        &.title-style2{
            .title{
                margin-bottom: rem(45px);
            }
        }
    }
    .experience-wrap{
        max-width: rem(570px);
    }
    .experience-area{
        h3{
            font-weight: 500;
            margin-bottom: rem(4px);
            font-size: rem(22px);
        }
        .box{
            .chart{
                margin-bottom: rem(15px);
            }
        }
    }
}
.experience-area .box {
  width: rem(100px);
}

.experience-area .box h4 {
  display: block;
  text-align: center;
  font-weight: rem(22px);
}

.experience-area .box .chart {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: 600;
  font-size: rem(22px);
  line-height: rem(100px);
  height: rem(100px);
  color: $title-color;
}

.experience-area .box canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  width: 100%;
}
