.team-section{
    background: url(../../media/team/team-texture.png) no-repeat bottom center;
    .team-item{
        text-align: center;
        transition: $transition;
        padding: 4rem 1rem 3.3rem;
        .team-image{
            margin-bottom: rem(20px);
            img{
                border-radius: 100%;
            }
        }
        .team-title{
            font-size: rem(22px);
            font-weight: 600;
            margin-bottom: rem(1px);
            a{
                color: $title-color;
                &:hover{
                    color: $primary-color;
                }
            }
        }
        .team-designation{
            color: $title-color;
        }
        .team_social_icon{
            margin-top: rem(10px);
            li{
                margin-right: rem(15px);
                a{
                    color: #787878;
                    font-size: rem(14px);
                    &:hover{
                        color: $primary-color;
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        &:hover,
        &.active{
            box-shadow: 0 1px 23px 0 rgba(0, 0, 0, 0.07);
            background: #fff;
        }
    }
    &.team-style2{
        background: transparent;
        margin-top: rem(-205px);
        .section-title.center-style {
            p{
                margin-bottom: rem(36px);
            }
        }
    }
    &.team-style3{
        .team-item{
            margin-bottom: rem(20px);
        }
    }
    &.no-bg{
        background: transparent;        
    }
}

.single-team-section{
    .single-info-wrap{
        background: #f5f7ff;
        padding: rem(40px 30px 45px 45px);
        @media only screen and (max-width: 1199px) {
            background: transparent;
            padding: (0 0 0 30px);
        }
        @media only screen and (max-width: 991px) {
            padding: (30px 0 0 0);
        }
        h3{
            margin-bottom: (25px);            
        }
    }
    @media only screen and (max-width: 991px) {
        .pr--0{
            padding-right: rem(15px);
        }
        .pl--0{
            padding-left: rem(15px);
        }
        .pl--30{
            padding-left: rem(15px);
            padding-top: rem(50px);
        }
    }
    .single-info{
        .item-heading{
            font-size: rem(24px);
            font-weight: 600;
            margin-bottom: rem(28px);
        }
        li{
            line-height: rem(33px);
            margin-bottom: rem(11px);
        }
        .feature-title{
            font-size: rem(18px);
            font-weight: 600;
            font-family: $title-font;
            color: $title-color;
        }
    }
    .team_social_icon{
        margin-top: rem(30px);
        li{
            margin-right: rem(10px);
            a{
                color: $body-color;
                border: 1px solid $body-color;
                width: rem(40px);
                height: rem(40px);
                line-height: rem(40px);
                border-radius: 100%;
                display: block;
                text-align: center;
                -webkit-transition: all 0.4s ease 0s;
                -o-transition: all 0.4s ease 0s;
                transition: all 0.4s ease 0s;
                &:hover{
                    color: #fff;
                    background: $primary-color;
                    border: 1px solid $primary-color;
                }
            }
        }
    }
    .skill-bar-wrapper{
        .skillbar-title{
            font-size: rem(18px);
        }
        .skillbar {
            .skill-bar-percent{
                font-size: rem(16px);
            }
        }
        .skill-item{
            margin-bottom: rem(20px);
        }
    }
}