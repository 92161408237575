.service-section {
    position: relative;
    &.service-layout3{
        padding-bottom: $section_padding_base_xs;
    }
}
.service-item{
    box-shadow: 0px 8px 2.2rem rgba(0, 0, 0, 0.08);
    padding: rem(32px) rem(45px) rem(25px) rem(45px);
    margin-bottom: rem(30px);
    .service-icon{
        margin-bottom: rem(18px);
        i{
            color: $primary-color;
            font-size: rem(45px);
            transition: $transition;
            line-height: rem(45px);
            height: rem(45px);
            &:before{
                font-size: rem(45px);
            }
        }
    }
    .feature-title{
        font-weight: 600;
        font-size: rem(22px);
        margin-bottom: rem(10px);
        a{
            color: $title-color;
        }
    }
    &:hover{
        .service-icon{
            i{
                color: #a7a7aa;
            }
        }
        .feature-title{
            a{
                color: $primary-color;
            }
        }
    }
}
.service-item2{
    background: transparent;
    transition: $transition;
    padding: rem(60px) rem(20px);
    .service-icon{
        line-height: rem(70px);
        height: rem(70px);
        margin-bottom: rem(17px);
        i{
            color: $primary-color;
            transition: $transition;
            font-size: 70px;
            &:before{
                font-size: rem(70px);
            }
        }
    }
    .service-title{
        font-weight: 600;
        font-size: rem(22px);
        margin-bottom: rem(5px);
        a{
            color: $title-color;
            &:hover{
                color: $primary-color;
            }
        }
    }
    .service-desc{
        font-size: rem(18px);
        margin-bottom: 0;
    }
    &.item-one{
        border-bottom: 1px solid #cdcecf;
    }
    &.item-two{
        border: 1px solid #cdcecf;
        border-top: none;
        .service-icon{
            i{
                color: $variation-color1;
            }
        }
    }
    &.item-three{
        border-bottom: 1px solid #cdcecf;
        .service-icon{
            i{
                color: $variation-color2;
            }
        }
    }
    &.item-four{
        .service-icon{
            i{
                color: $variation-color3;
            }
        }
    }
    &.item-five{
        border: 1px solid #cdcecf;
        border-top: none;
        border-bottom: none;
        .service-icon{
            i{
                color: $variation-color4;
            }
        }
    }
    &.item-six{
        .service-icon{
            i{
                color: $variation-color5;
            }
        }
    }
    &:hover{
        background: #fff;
        box-shadow: 0px 8px 2.2rem rgba(0, 0, 0, 0.06);
    }
}
.service-layout4{
    .service-row{
        padding: 0 rem(15px);
    }
    &.skew-wrap{
        padding-top: rem(145px);
        padding-bottom: rem(145px);
    }
}
.service-layout5{
    .service-shape{
        position: absolute;
        width: 0;
        height: 0;
        background: url(../../media/background/service-shape.png) no-repeat center top;
        background-size: cover;
        right: 0;
        top: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 2.5s ease-in-out;
        transition-delay: 1s;
        z-index: -1;
    }
    .set-title-wrap{
        margin-bottom: rem(30px);
        h5{
            font-size: rem(20px);
            margin-bottom: rem(10px);
        }
    }
    &.active-animation{
        .service-shape{
            width: rem(896px);
            height: rem(733px);
            opacity: 1;
            visibility: visible;
            @media only screen and (max-width: 1660px) {
                width: rem(600px);
                height: rem(491px);
            }
        }
    }
    .service-item{
        position: relative;
        padding: rem(35px 50px 50px 50px);
        min-height: rem(320px);
        border-radius: rem(5px);
        box-shadow: 0px 1px 2.2rem rgba(0, 0, 0, 0.06);
        overflow: hidden;
        background: #fff;
        &:after{
            position: absolute;
            content: "";
            display: block;
            background: rgba($primary-color, 0.95);
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            left: 50%;
            top: 50%;
            transition: $transition;
            transform: translate(-50%, -50%);
        }
        .service-number{
            font-size: rem(22px);
            font-weight: 600;
            font-family: $title-font;
            margin-bottom: rem(7px);
            position: relative;
            z-index: 9;
            transition: $transition;
            position: absolute;
            left: rem(50px);
            top: rem(50px);
        }
        .service-title{
            font-size: rem(24px);
            position: relative;
            z-index: 9;
            transition: $transition;
            margin-top: rem(50px);
            a{
                color: $title-color;
            }
        }
        .service-icon{
            margin-bottom: 0;
            position: absolute;
            left: rem(50px);
            bottom: rem(50px);
            z-index: 9;
            i{
                &:before{
                    font-size: rem(50px);
                    transition: $transition;
                }
            }
        }
        .service-icon2{
            margin-bottom: 0;
            position: absolute;
            left: auto;
            bottom: rem(-8px);
            right: rem(35px);
            z-index: 9;
            opacity: 0;
            visibility: hidden;
            i{
                &:before{
                    font-size: rem(180px);
                    color: #ffedf0;
                    opacity: 0.1;
                }
            }
        }
        .hidden-number{
            margin-bottom: 0;
            position: absolute;
            left: rem(50px);
            bottom: rem(50px);
            z-index: 9;
            font-size: rem(24px);
            font-weight: 600;
            font-family: $title-font;
            width: 100%;
            color: #fff;
            opacity: 0;
            display: block;
            visibility: hidden;
            a{
                color: #fff;
            }
            &:after{
                font-family: Flaticon;
                content: "\f130";
                position: absolute;
                left: auto;
                left: 3rem;
                font-size: 4.5rem;
                font-weight: normal;
                top: 50%;
                transform: translateY(-50%);
                z-index: 9999;
                display: block;
                cursor: pointer;
                color: #fff;
                -webkit-transition: all 0.4s ease 0s;
                -o-transition: all 0.4s ease 0s;
                transition: all 0.4s ease 0s;
            }
            i{
                &:before{
                    font-size: rem(50px);
                }
            }
        }
        &:hover{
            .service-number{
                top: 0;
                opacity: 0;
            }
            .service-icon{
                opacity: 0;
                visibility: hidden;
            }
            .service-icon2{
                opacity: 1;
                visibility: visible;
            }
            .hidden-number{
                opacity: 1;
                visibility: visible;
                &:after{
                    left: rem(40px);
                }
            }
            .service-title{
                margin-top: rem(16px);
                a{
                    color: #fff;
                }
            }
            &:after{
                opacity: 1;
                visibility: visible;
                top: 50%;
            }
        }
    }
}
.service-details-section{
    .details-text{
        max-width: rem(550px);
    }
    .who_we_are{
        .description{
            padding-right: rem(105px);
        }
    }
    .item-icon{
        margin-bottom: rem(12px);
        i{
            line-height: rem(38px);
            color: $primary-color;
            &:before{
                font-size: rem(38px);
            }
        }
        &.icon-item2{
            i{
                color: $variation-color4;
            }
        }
    }
    .service-feature{
        h4{
            margin-bottom: rem(7px);
        }
    }
}

.skill-bar-wrapper{
    .skillbar-title{
        font-family: $title-font;
        font-weight: 600;
        font-size: rem(20px);
        color: $title-color;
    }
    .skill-item{
        padding: rem(1px);
        border: rem(1px) solid #dfdfdf;
        height: rem(14px);
        margin-top: rem(5px);
        margin-bottom: rem(55px);
        &:last-child{
            margin-bottom: 0;
        }
    }
    .skillbar{
        position: relative;
        display: inline-block;
        margin: 0;
        background: transparent;
        height: rem(8px);
        width: 100%;
        .skillbar-title{
            position: absolute;
            top: 0;
            left: 0;
            font-weight: 700;
            font-size: 12px;
            color: #fff;
            height: rem(8px);
            z-index: 9;
            background: transparent;
            min-width: 150px;
        }
        .skillbar-bar{
            height: rem(8px);
            width: 0;
            background: $primary-color;
            display: inline-block;
            margin: 0;
            position: relative;
            top: rem(-10px);
            left: rem(1px);
        }
        .skill-bar-percent{
            position: absolute;
            right: rem(-25px);
            top: rem(-37px);
            font-weight: 600;
            font-size: rem(18px);
            color: $title-color;
            font-family: $title-font;
        }
    }
}
.details-feature-section{
    .max-width{
        max-width: rem(667px);
        margin-right: 0;
        margin-left: auto;
    }
    .feature-item{
        .feature-list{
            width: rem(60px);
            height: rem(60px);
            line-height: rem(60px);
            text-align: center;
            float: left;
            border: rem(1px) solid #faccd5;
            border-radius: 100%;
            font-weight: 500;
            font-family: $title-font;
            color: $title-color;
            font-size: rem(22px);
            margin-top: rem(10px);
            transition: $transition;
        }
        .short-desc{
            float: left;
            width: calc(100% - 6rem);
            padding-left: 2rem;
        }
        .feature-title{
            font-family: $title-font;
            font-weight: 600;
            color: $title-color;
            font-size: rem(20px);
        }
        &:hover{
            .feature-list{
                border-color: $primary-color;
                background: $primary-color;
                color: #fff;
            }
        }
    }
}