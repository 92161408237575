/*========================================================================
02. Page Layout
=========================================================================*/

/*--------------------------------------
	2.3.1 Footer
---------------------------------------*/
@import "page-layout/footers";

/*--------------------------------------
	2.3.2 Header
---------------------------------------*/
@import "page-layout/headers";

/*--------------------------------------
	2.3.3 Pagination
---------------------------------------*/
@import "page-layout/pagination";

/*--------------------------------------
	2.3.3 Sidebar
---------------------------------------*/
@import "page-layout/sidebar";
