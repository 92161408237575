.tact-right-sidebar-wrp{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9999;
}
.tact-right-sidebar-wrp .info_wrap{
    width: 90px;
    height: 100vh;
    background-color: #fff;
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 2;
}
.tact-right-sidebar-wrp .info_wrap:before{
    position: absolute;
    content: "";
    width: 1px;
    height: 100%;
    left: 0px;
    top: 0px;
    background-color: rgba(0,0,0,.06);
}
.tact-right-sidebar-wrp .info_wrap .inner_wrap{
    widows: 100%;
    height: 100%;
    position: relative;    
}
.tact-right-sidebar-wrp .info_wrap .inner_wrap .offcanvas-social{
    position: absolute;
    left: 50%;
    bottom: rem(200px);
    transform: translate(-50%,-50%);
}
.tact-right-sidebar-wrp .info_wrap .inner_wrap .logo{
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 90px;
    height: 90px;
    background-color: $primary-color;
    text-align: center;
    line-height: 90px;
}
.tact-right-sidebar-wrp .info_wrap .inner_wrap .link{
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
}
.tact-right-sidebar-wrp .offcanvas-cpt-wrp{
    width: 90px;
    height: 90px;
    border-bottom: 1px solid rgba(0,0,0,.06);
    top: 0px;
    left: 0px;
    position: absolute;
    @media only screen and (max-width: 1199px){
        bottom: 0;
        top: auto;
    }
}
.tact-right-sidebar-wrp .offcanvas-cpt-wrp .trigger{
    width: 30px;
    height: 12px;
    position: relative;
    transform: translateX(-50%);
    top: 37px;
    left: 50%;
}
.tact-right-sidebar-wrp .offcanvas-cpt-wrp .trigger span{
    position: absolute;
    width: 30px;
    height: 3px;
    background-color: #000;
    display: inline-block;
}
.tact-right-sidebar-wrp .offcanvas-cpt-wrp .trigger .two{margin-top: 12px;}
.tact-right-sidebar-wrp .offcanvas-cpt-menu:before{
    position: absolute;
    content: "";
    width: 1px;
    height: 100%;
    right: 0px;
    top: 0px;
    background-color: rgba(0,0,0,.06);
}
.tact-right-sidebar-wrp .offcanvas-cpt-menu{
    width: 330px;
    height: 100vh;
    position: fixed;
    top: 0px;
    right: -240px;
    background-color: #fff;
    z-index: 1;
}
.tact-right-sidebar-wrp .offcanvas-cpt-menu .inner-cover{
    width: 100%;
    height: 100%;
    position: relative;
    box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.05);
}
.tact-right-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    padding-right: 45px;
    width: 100%;
    @media only screen and (max-height: 600px) {
        height: 100%;
        overflow-y: scroll;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .right-menu-fixed{
        padding-right: rem(90px);
        overflow-x: hidden !important;
    }    
}
@media only screen and (max-width: 1300px) {
    .about-us-four .left-desc{
        padding-left: rem(15px);
    }
}
.tact-right-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list > ul{
    margin: 0px;
    list-style-type: none;
    display: flex;
    align-items: center;
    max-width: 100%;
    flex-direction: column;
    min-height: 100%;
    justify-content: center;
}
.tact-right-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list > ul > li{
    margin: 0 0 10px 0px;
    list-style-type: none;
    position: relative;
    width: 100%;
    padding-left: 55px;
    padding-right: 20px;
    text-align: left;
}
.tact-right-sidebar-wrp .nav-item-list .menu-toggle-right li.sub-menu-toggle span.em-menu-parent{
    padding: rem(8px);
}
.tact-right-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list > ul > li:last-child{margin-bottom: 0px;}
.tact-right-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list > ul > li > a{
    text-decoration: none;
    color: #000;
    text-transform: capitalize;
    font-size: rem(18px);
    font-weight: 700;
    margin-bottom: rem(10px);
    display: block;   
    position: relative;
    
    -webkit-transition: all .3s ease;
       -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
         -o-transition: all .3s ease;
            transition: all .3s ease;
}
.tact-right-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list > ul > li > a:hover{color: $primary-color;}
.tact-right-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list > ul > li.active a{color: $primary-color;}

.tact-right-sidebar-wrp .tact-shares {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    bottom: -16px;
    left:  -70px;
    z-index: 99;
}

.tact-right-sidebar-wrp .tact-shares .toggle-shares {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #111;
    font-weight: 700;
    margin-right: 2rem;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    width: 81px;
}

.tact-right-sidebar-wrp .tact-shares .toggle-shares span {
    margin-left: 1rem;
}

.tact-right-sidebar-wrp .tact-shares .toggle-shares:hover {
    color: $primary-color;
}

.social-share-wrapper {
    overflow: hidden;
}

.tact-right-sidebar-wrp  .social-share {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding: 3px;
}

.tact-right-sidebar-wrp  .social-share li {
    margin-left: -100%;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
}

.tact-right-sidebar-wrp .show-shares li {
    margin-left: 0;
}

.tact-right-sidebar-wrp .banner-share-slider-container {
    position: absolute;
    bottom: -7rem;
    left: 240px;
    z-index: 99;
}

.tact-right-sidebar-wrp .banner-share-slider-container .tact-shares {
    position: static;
    margin-top: 0;
}

@media (max-width: 991px) {
    .tact-right-sidebar-wrp .banner-share-slider-container {
        position: static;
    }
}


.tact-right-sidebar-wrp .menu-share-slider-container {
    position: absolute;
  
   
    z-index: 999;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.tact-right-sidebar-wrp .menu-share-slider-container li {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}


@media (max-width: 1199px) {
    .tact-right-sidebar-wrp .menu-share-slider-container {
        position: static;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        margin-top: 1rem;
    }

    .tact-right-sidebar-wrp .menu-share-slider-container li {
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
    }
}
.tact-right-sidebar-wrp .social-share-wc-bg li a {
    display: block;
    width: 3.2rem;
    height: 3.2rem;
    background: #111;
    border-radius: 100%;
    font-size: 1.2rem;
    line-height: 3.2rem;
    color: #fff;
    text-align: center;
}

.tact-right-sidebar-wrp .social-share-wc-bg li a:hover {
    background: $primary-color;
    color: #fff;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.tact-right-sidebar-wrp .social-share {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
}
.tact-right-sidebar-wrp .social-share li {
    margin-right: 1rem;
}
.tact-right-sidebar-wrp .social-share li a {
    font-size: 2rem;
    color: #111;
}
.tact-right-sidebar-wrp .social-share li a:hover {
    color: $primary-color;
}
.tact-right-sidebar-wrp .social-share-wc-bg li a {
    display: block;
    width: 3.2rem;
    height: 3.2rem;
    background: #111;
    border-radius: 100%;
    font-size: 1.2rem;
    line-height: 3.2rem;
    color: #fff;
    text-align: center;
}
.tact-right-sidebar-wrp .social-share-wc-bg li a:hover {
    background: $primary-color;
    color: #fff;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}