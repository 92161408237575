/*!
  Theme Name: Tact
  Template URI: https://abctechweb.com
  Description: Tact | Creative HTML5 Template for Saas, Startup & Agency
  Author: Abctechweb
  Author URI: https://abctechweb.com
  Version: 1.0
  */
/*----------------------------------------------------------------
    [Table of content]
  ----------------------------------------------------------------
    01. Template Style
        1.1 Typography
        1.2 Sections
                # about Style
                # banner Style
                # Slider Style
                # Feature Style
                # Faq Style
                # video Style
                # Contact Style
                # Team Style
                # Animation Style
                # What We Do
                # Who We Are
                # Work Process
                # WhY Choose Us
                # Services Style
                # Skill Style
                # Mission Style
                # Chatbox Style
                # 404 Style
                # Experience Style
                # Testimonial Style
                # Project Style
                # Counter Style
                # Blog Style
                # Partner Style
                # Call To Action
                # Analyze Style
                # Landing Style
                # Preloader Style
        1.3. Responsive Style

    02. Elements
        2.1 components
            # Button Style
            # Global Style
        2.2 Helper Classes
            # Global Class
            # Margin Padding
            # Section Spacing
        2.3 Page Layout
            2.3.1 Footer
                # Footer Style
            2.3.2 Header
                # Header Style
                # Topbar Style
            2.3.3 Pagination
                # Pagination Style
            2.3.3 Sidebar
                # Sidebar Style
  --------------------------------------------------------------*/
/*--imported fonts--*/
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900|Open+Sans:300,400,600,700,800&display=swap");
/*--------------------------------------
	# Typography
---------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Poppins", sans-serif;
  color: #333333;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 2rem;
}

h3 {
  margin-bottom: 1.7rem;
}

h1,
.h1 {
  font-size: 4.2rem;
  font-weight: 700;
}

.page-title {
  font-size: 4.2rem;
}

h2,
.h2 {
  font-size: 3.6rem;
  font-weight: 700;
}

h3,
.h3 {
  font-size: 2.6rem;
  font-weight: 600;
}

h4,
.h4 {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 1.2rem;
}

h5,
.h5 {
  font-size: 1.8rem;
  line-height: 1.6;
  font-weight: 600;
  margin-bottom: 1.2rem;
}

h6,
.h6 {
  font-size: 1.4rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}

p {
  font-family: "Open Sans", sans-serif;
  font-size: 1.6rem;
  line-height: 3rem;
}

p.big {
  font-size: 2rem;
}

p.mid {
  font-size: 1.6rem;
  line-height: 2.8rem;
}

p.small {
  font-size: 1.4rem;
  line-height: 2.4rem;
}

address,
.address {
  font-family: "Open Sans", sans-serif;
  font-size: 2rem;
  line-height: 3.4rem;
}

/*========================================================================
04. Template Style
=========================================================================*/
/*--------------------------------------
	# About Style
---------------------------------------*/
/*--- About Layout One ---*/
.about-section .about-img {
  padding-right: 2.6rem;
}

.about-section .about-desc {
  padding-left: 2.6rem;
}

.about-section .about-btn {
  margin-top: 5rem;
}

.about-section .about-btn .arrow-link {
  margin-left: 3.5rem;
}

/*--- About Layout Two ---*/
.about-us-two p {
  margin-bottom: 3.2rem;
}

.about-us-two .about-img {
  padding-right: 0;
}

.about-us-two .about-btn {
  margin-top: 5rem;
}

/*--- About Layout Three ---*/
.about-us-three {
  margin-top: -19rem;
}

.about-us-three p {
  margin-bottom: 3.2rem;
}

.about-us-three .about-img {
  padding-right: 0;
}

.about-us-three .about-btn {
  margin-top: 5rem;
}

.about-us-three .about-btn .primary-btn {
  min-width: 16rem;
}

.about-us-three .animated-img {
  position: relative;
}

@media only screen and (max-width: 991px) {
  .about-us-three .animated-img {
    margin-bottom: 21rem;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-three .animated-img {
    margin-bottom: 5rem;
  }
}

.about-us-three .animated-img .about-left2 {
  position: absolute;
  left: 20%;
  top: 34%;
  box-shadow: 0 0 6.2rem rgba(0, 0, 0, 0.09);
}

@media only screen and (max-width: 767px) {
  .about-us-three .animated-img .about-left2 {
    margin-bottom: 15rem;
    left: 4rem;
    top: 4rem;
  }
}

/*--- About Layout Four ---*/
.about-us-four .left-desc {
  background: #05113b;
  color: #fff;
  padding-right: 0;
}

.about-us-four .left-desc .left-wrap {
  margin-right: 0;
  padding-right: 13.5rem;
  height: 100%;
  padding-top: 11rem;
  padding-bottom: 12rem;
}

.about-us-four .left-desc .left-wrap .title-style2 .sub-title {
  color: #aaaebd;
}

.about-us-four .left-desc .left-wrap .title-style2 .title {
  color: #fff;
}

.about-us-four .left-desc .left-wrap .more-btn {
  margin-top: 5rem;
}

.about-us-four .play-video {
  background: url(../../media/background/video-bg.jpg) no-repeat center center;
  height: 640px;
  background-size: cover;
  height: 100%;
}

.about-us-four .play-video .play-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  z-index: 99;
}

.about-us-four .play-video .play-icon .popup-video {
  position: relative;
  left: -4.5rem;
  top: -4.5rem;
}

.about-us-four .play-video .play-icon .popup-video:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 12rem;
  height: 12rem;
  background: #fffcfc;
  border-radius: 100%;
}

.about-us-four .play-video .play-icon .popup-video:before {
  content: "";
  position: absolute;
  left: 4.1rem;
  top: 3.8rem;
  display: block;
  width: 4rem;
  height: 4rem;
  background: url(../../media/icons/play.png) no-repeat center center;
  border-radius: 100%;
  z-index: 9;
}

.about-us-four .play-video .play-icon .popup-video:hover:after {
  background: #eaeaea;
}

.about-us-four.landing .left-desc {
  background: url(../../media/about/about-landing.png) no-repeat center center;
  background-size: cover;
}

.about-us-four.landing .left-desc .left-wrap {
  padding-top: 16.5rem;
  padding-bottom: 14rem;
}

.about-us-four.landing .play-video {
  background: url(../../media/about/about-video-bg.jpg) no-repeat center center;
  background-size: cover;
}

.has-animation .image-shape:before {
  transform: translateX(10%);
  opacity: 0;
  transition: all 2.5s ease-in-out;
  transition-duration: 1s;
}

.has-animation.active-animation .image-shape:before {
  opacity: 1;
  transform: translateZ(0);
}

/*--------------------------------------
	# Banner Style
---------------------------------------*/
/*--- Main Banner  ---*/
.banner-section.v2 {
  background: linear-gradient(to right top, #3069f3, #275ae6, #204ad9, #193bcc, #122abe);
}

.banner-shape-inner svg {
  width: 70rem;
}

.banner-section.banner-light .banner-shape-inner.main-shap-top.shape-loaded.bgnone {
  background: none;
}

.banner-section.banner-light.height-full {
  height: 100vh;
}

.banner-section.banner-section-7 {
  background: #ff2c54;
  background-image: linear-gradient(55deg, #f7df1e 0%, #ff2c54 67%);
}

.banner-section {
  width: 100%;
  height: 100vh;
  background: linear-gradient(to right top, #3069f3, #275ae6, #204ad9, #193bcc, #122abe);
  background-size: cover;
  position: relative;
  padding-top: 7rem;
}

@media only screen and (max-height: 500px) {
  .banner-section {
    height: 600px;
  }
}

.banner-section .container {
  height: 100%;
}

.banner-section .banner-desc {
  padding-right: 3rem;
}

.banner-section .banner-desc h2 {
  color: #fff;
  font-weight: 300;
  font-size: 4rem;
  letter-spacing: .5rem;
  margin-bottom: 0;
}

.banner-section .banner-desc h2.second {
  line-height: 5rem;
  font-size: 4.5rem;
  letter-spacing: .1rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: .4rem;
}

.banner-section .banner-desc .h1 {
  font-weight: 700;
  font-size: 6rem;
  color: #fff;
  line-height: 7.2rem;
  margin-bottom: 2rem;
  letter-spacing: 0;
}

.banner-section .banner-desc h1 {
  font-weight: 700;
  font-size: 6rem;
  color: #fff;
  line-height: 7.2rem;
  margin-bottom: 2rem;
  letter-spacing: 0;
}

.banner-section .banner-desc p {
  color: #fff;
  padding-right: 2.5rem;
}

.banner-section .banner-desc .popup-video {
  position: relative;
  font-weight: 600;
  font-size: 1.8rem;
  color: #fff;
  margin-left: 2.5rem;
}

.banner-section .banner-desc .popup-video:hover {
  color: #f0f0f0;
}

.banner-section .banner-desc .popup-video i {
  font-size: 2rem;
  margin-right: 1rem;
}

.banner-section .banner-btn {
  padding-top: 2.5rem;
}

.banner-section.banner-layout1 .banner-shape-inner.shape1 {
  position: absolute;
  width: 0;
  height: 0;
  background: url(../../media/banner/banner-shape.png) no-repeat center top;
  background-size: cover;
  left: 0;
  top: -20rem;
  opacity: 0;
  visibility: hidden;
  transition: all 2s ease-in-out;
  transition-delay: 1s;
}

.banner-section.banner-layout1 .banner-shape-inner.shape1.shape-loaded {
  width: 90%;
  height: 100%;
  top: 0;
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-height: 750px) {
  .banner-section.banner-layout1 .item-figure img {
    height: 50rem;
  }
}

@media only screen and (max-height: 600px) {
  .banner-section.banner-layout1 .item-figure img {
    height: 35rem;
  }
}

.banner-section.banner-landing {
  background: linear-gradient(to right top, #3069f3, #275ae6, #204ad9, #193bcc, #122abe);
  text-align: center;
  height: 100vh;
}

@media only screen and (max-height: 830px) {
  .banner-section.banner-landing {
    height: 70rem;
  }
}

.banner-section.banner-landing .banner-desc {
  position: relative;
  z-index: 99;
  padding-right: 0;
}

.banner-section.banner-landing .banner-desc h2.second {
  line-height: 6rem;
}

.banner-section.banner-landing .banner-shape-inner.shape1 {
  position: absolute;
  width: 0;
  height: 0;
  border-radius: 50%;
  background: rgba(31, 72, 214, 0.3);
  top: -65%;
  left: 50%;
  opacity: 0;
  visibility: hidden;
  transition: all 3s ease-in-out;
  transition-delay: 1.5s;
}

.banner-section.banner-landing .banner-shape-inner.shape1.shape-loaded {
  width: 93.7rem;
  height: 93.7rem;
  opacity: 1;
  visibility: visible;
  z-index: 9;
}

@media only screen and (max-width: 1660px) {
  .banner-section.banner-landing .banner-shape-inner.shape1.shape-loaded {
    width: 42rem;
    height: 53.4rem;
  }
}

@media only screen and (max-width: 991px) {
  .banner-section.banner-landing .banner-shape-inner.shape1.shape-loaded {
    width: 42rem;
    height: 53.4rem;
  }
}

@media only screen and (max-width: 767px) {
  .banner-section.banner-landing .banner-shape-inner.shape1.shape-loaded {
    width: 42rem;
    height: 53.4rem;
  }
}

.banner-section.banner-landing .banner-shape-inner.shape2 {
  position: absolute;
  width: 0;
  height: 0;
  border-radius: 50%;
  background: rgba(31, 72, 214, 0.5);
  top: -18%;
  left: -23%;
  opacity: 0;
  visibility: hidden;
  transition: all 3s ease-in-out;
  transition-delay: 1s;
}

.banner-section.banner-landing .banner-shape-inner.shape2.shape-loaded {
  width: 93.7rem;
  height: 93.7rem;
  opacity: 1;
  visibility: visible;
  z-index: 1;
}

@media only screen and (max-width: 1660px) {
  .banner-section.banner-landing .banner-shape-inner.shape2.shape-loaded {
    width: 70rem;
    height: 100vh;
  }
}

@media only screen and (max-width: 991px) {
  .banner-section.banner-landing .banner-shape-inner.shape2.shape-loaded {
    width: 50rem;
    height: 28.4rem;
  }
}

@media only screen and (max-width: 767px) {
  .banner-section.banner-landing .banner-shape-inner.shape2.shape-loaded {
    width: 30rem;
    height: 17.1rem;
  }
}

.banner-section.banner-landing.ylp {
  padding-top: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100vh;
  position: relative;
}

.banner-section.banner-landing.ylp .mb_YTPlayer {
  display: block;
  transform: translateZ(0);
  transform-style: preserve-3d;
  perspective: 1000;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  animation-timing-function: linear;
  box-sizing: border-box;
}

.banner-section.banner-landing.ylp .banner-video-section {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}

.banner-section.banner-landing.ylp .banner-shape-inner.shape1 {
  position: absolute;
  width: 0;
  height: 0;
  background: url(../../media/banner/shape1.png) no-repeat center top;
  background-size: cover;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
  transition-delay: 1s;
}

.banner-section.banner-landing.ylp .banner-shape-inner.shape1.shape-loaded {
  width: 57rem;
  height: 73.4rem;
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 1660px) {
  .banner-section.banner-landing.ylp .banner-shape-inner.shape1.shape-loaded {
    width: 47rem;
    height: 60.5rem;
  }
}

@media only screen and (max-width: 991px) {
  .banner-section.banner-landing.ylp .banner-shape-inner.shape1.shape-loaded {
    width: 30rem;
    height: 38.6rem;
  }
}

@media only screen and (max-width: 767px) {
  .banner-section.banner-landing.ylp .banner-shape-inner.shape1.shape-loaded {
    width: 20rem;
    height: 25.8rem;
  }
}

.banner-section.banner-landing.ylp .banner-shape-inner.shape2 {
  position: absolute;
  width: 0;
  height: 0;
  background: url(../../media/banner/shape2.png) no-repeat center top;
  background-size: cover;
  right: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 2s ease-in-out;
  transition-delay: 1s;
}

.banner-section.banner-landing.ylp .banner-shape-inner.shape2.shape-loaded {
  width: 92.1rem;
  height: 52.4rem;
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 1660px) {
  .banner-section.banner-landing.ylp .banner-shape-inner.shape2.shape-loaded {
    width: 70rem;
    height: 39.8rem;
  }
}

@media only screen and (max-width: 991px) {
  .banner-section.banner-landing.ylp .banner-shape-inner.shape2.shape-loaded {
    width: 50rem;
    height: 28.4rem;
  }
}

@media only screen and (max-width: 767px) {
  .banner-section.banner-landing.ylp .banner-shape-inner.shape2.shape-loaded {
    width: 30rem;
    height: 17.1rem;
  }
}

.banner-section.banner-light {
  background: #fff;
  height: 85rem;
  overflow: hidden;
  position: relative;
}

@media only screen and (max-width: 1440px) {
  .banner-section.banner-light {
    height: 80rem;
  }
}

@media only screen and (max-width: 1360px) {
  .banner-section.banner-light {
    height: 75rem;
  }
}

.banner-section.banner-light:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  display: none;
}

.banner-section.banner-light .push-left {
  opacity: 0;
  visibility: hidden;
  transition: all 2s ease-in-out;
  transition-delay: 0.7s;
}

.banner-section.banner-light .push-left.shape-loaded {
  opacity: 1;
  visibility: visible;
}

.banner-section.banner-light .banner-shape-inner.shape2 {
  position: absolute;
  width: 35.6rem;
  height: 35.6rem;
  background: url(../../media/banner/left-c-shap.png) no-repeat center top;
  background-size: 100%;
  left: 0;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  transition: all 1.5s ease-in-out;
  transition-delay: 0.3s;
  transform: translateX(-30%) translateY(-50%);
}

.banner-section.banner-light .banner-shape-inner.shape2.shape-loaded {
  opacity: 1;
  visibility: visible;
  transform: translateX(0) translateY(-50%);
  animation: up_down 10s infinite;
  transition-delay: 3.5s;
}

@media only screen and (max-width: 1760px) {
  .banner-section.banner-light .banner-shape-inner.shape2 {
    width: 20rem;
    height: 20rem;
  }
}

.banner-section.banner-light .banner-shape-inner.main-shap {
  position: absolute;
  width: 109.8rem;
  height: 82.9rem;
  background: url(../../media/banner/main-shap.png) no-repeat center top;
  background-size: cover;
  background-position: bottom left;
  right: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 1.5s ease-in-out;
  transition-delay: 0.3s;
  transform: translateX(30%);
}

.banner-section.banner-light .banner-shape-inner.main-shap.shape-loaded {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

@media only screen and (max-width: 1760px) {
  .banner-section.banner-light .banner-shape-inner.main-shap {
    width: 100rem;
    height: 71.7rem;
  }
}

@media only screen and (max-width: 1620px) {
  .banner-section.banner-light .banner-shape-inner.main-shap {
    width: 90rem;
    height: 68rem;
  }
}

@media only screen and (max-width: 1440px) {
  .banner-section.banner-light .banner-shape-inner.main-shap {
    width: 85rem;
    height: 64.2rem;
  }
}

@media only screen and (max-width: 1360px) {
  .banner-section.banner-light .banner-shape-inner.main-shap {
    width: 80rem;
    height: 60.4rem;
  }
}

.banner-section.banner-light .banner-shape-inner.main-shap-top {
  position: absolute;
  width: 81.8rem;
  height: 58.3rem;
  background: url(../../media/banner/main-shap-top.png) no-repeat center top;
  background-size: cover;
  background-position: center left;
  right: 10rem;
  bottom: 10rem;
  opacity: 0;
  visibility: hidden;
  transition: all 1.5s ease-in-out;
  transition-delay: 2s;
  transform: translateY(-30%);
}

.banner-section.banner-light .banner-shape-inner.main-shap-top.shape-loaded {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

@media only screen and (max-width: 1760px) {
  .banner-section.banner-light .banner-shape-inner.main-shap-top {
    bottom: 15rem;
    right: 15rem;
    width: 65rem;
    height: 46.3rem;
  }
}

@media only screen and (max-width: 1620px) {
  .banner-section.banner-light .banner-shape-inner.main-shap-top {
    bottom: 20rem;
    right: 14rem;
    width: 60rem;
    height: 42.8rem;
  }
}

@media only screen and (max-width: 1440px) {
  .banner-section.banner-light .banner-shape-inner.main-shap-top {
    bottom: 18rem;
    right: 10rem;
  }
}

@media only screen and (max-width: 1360px) {
  .banner-section.banner-light .banner-shape-inner.main-shap-top {
    bottom: 16rem;
    right: 3rem;
  }
}

@media only screen and (max-width: 767px) {
  .banner-section.banner-light .banner-shape-inner.main-shap-top {
    bottom: 16rem;
    right: 0;
    width: 45rem;
    height: 32.1rem;
  }
}

@media only screen and (max-width: 450px) {
  .banner-section.banner-light .banner-shape-inner.main-shap-top {
    width: 30rem;
    height: 21.4rem;
    bottom: 20rem;
  }
}

.banner-section.banner-light .banner-shape-inner.main-shap-top-main {
  position: absolute;
  width: 58rem;
  height: 50rem;
  background: url(../../media/banner/main-shap-top-main.png) no-repeat center top;
  background-size: 100%;
  background-position: center left;
  right: 15.5rem;
  bottom: -1.5rem;
  opacity: 0;
  visibility: hidden;
  transition: all 1.5s ease-in-out;
  transition-delay: 3s;
  transform: translateY(30%);
}

.banner-section.banner-light .banner-shape-inner.main-shap-top-main.shape-loaded {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

@media only screen and (max-width: 1760px) {
  .banner-section.banner-light .banner-shape-inner.main-shap-top-main {
    width: 40rem;
    height: 35.4rem;
    bottom: 9rem;
    right: 23rem;
  }
}

@media only screen and (max-width: 1620px) {
  .banner-section.banner-light .banner-shape-inner.main-shap-top-main {
    width: 35rem;
    height: 30rem;
    bottom: 16rem;
    right: 23rem;
  }
}

@media only screen and (max-width: 1440px) {
  .banner-section.banner-light .banner-shape-inner.main-shap-top-main {
    bottom: 15rem;
    right: 20rem;
  }
}

@media only screen and (max-width: 1360px) {
  .banner-section.banner-light .banner-shape-inner.main-shap-top-main {
    bottom: 14rem;
    right: 14rem;
  }
}

@media only screen and (max-width: 767px) {
  .banner-section.banner-light .banner-shape-inner.main-shap-top-main {
    bottom: 10rem;
    right: 3.6rem;
    width: 30rem;
    height: 25.6rem;
  }
}

@media only screen and (max-width: 450px) {
  .banner-section.banner-light .banner-shape-inner.main-shap-top-main {
    bottom: 16rem;
    right: 3rem;
    width: 20rem;
    height: 17.1rem;
  }
}

.banner-section.banner-light .container-fluid {
  position: relative;
  z-index: 1;
}

.banner-section.banner-light .banner-desc {
  max-width: 100%;
}

.banner-section.banner-light .banner-desc h2 {
  color: #333333;
  font-weight: 400;
  font-size: 4rem;
  letter-spacing: 0;
  margin-bottom: .4rem;
}

.banner-section.banner-light .banner-desc h1 {
  font-weight: 600;
  font-size: 6rem;
  color: #333333;
  line-height: 7.5rem;
  margin-bottom: 1.5rem;
}

.banner-section.banner-light .banner-desc .h1 {
  font-weight: 600;
  font-size: 6rem;
  color: #333333;
  line-height: 7.5rem;
  margin-bottom: 1.5rem;
}

.banner-section.banner-light .banner-desc p {
  color: #333333;
  padding-right: 2.5rem;
}

.banner-section.banner-gradient {
  background: linear-gradient(135deg, #34df5d, #10ca9c);
  height: auto;
  padding: 20rem 0 55rem;
}

.banner-section.banner-gradient .banner-desc {
  max-width: 70rem;
  margin: 0 auto;
  padding-right: 0;
}

.banner-section.banner-gradient .banner-desc h2 {
  font-size: 6.5rem;
  line-height: 7.5rem;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 2.6rem;
}

.banner-section.banner-gradient .banner-desc p {
  padding: 0 8rem;
}

.banner-section.banner-layout4 {
  background: url(../../media/banner/banner-5.jpg) no-repeat center center;
  background-size: cover;
  position: relative;
  height: 80rem;
  padding: 0;
}

.banner-section.banner-layout4 .container {
  position: relative;
  z-index: 5;
  height: 100%;
}

.banner-section.banner-layout4:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(135deg, rgba(189, 63, 63, 0.75), rgba(16, 16, 16, 0.75));
  z-index: 1;
}

.banner-section.banner-layout4 .banner-desc {
  max-width: 100%;
  width: 100%;
  padding-right: 0;
}

.banner-section.banner-layout4 .banner-desc .p-light {
  font-size: 6rem;
  font-weight: 300;
  line-height: 7rem;
}

.banner-section.banner-layout4 .banner-desc .p-semi-bold {
  font-size: 6.5rem;
  font-weight: 600;
}

.banner-section.banner-layout4 .banner-btn {
  justify-content: center;
  padding-top: 4.5rem;
  padding-bottom: 10rem;
}

.banner-section.banner-layout4 .banner-btn a {
  font-weight: 600;
  margin-right: 2rem;
  min-width: 17rem;
}

.banner-section.banner-layout4 .banner-btn a:last-child {
  margin-right: 0;
}

.banner-shape {
  position: relative;
  background: url(../../media/background/shape1.png) no-repeat center top;
  width: 100%;
  height: 27rem;
  z-index: 30;
  margin-top: -21.8rem;
}

.ipad-screen {
  margin-top: -50rem;
  position: relative;
  z-index: 40;
}

/*--- Inner Page Banner ---*/
.page-banner {
  display: block;
  padding: 19.8rem 0 11.4rem;
  position: relative;
  z-index: 1;
  background: url(../../media/banner/banner-inner.jpg) no-repeat center top;
  background-size: cover;
}

.page-banner:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: -webkit-linear-gradient(to right, #3a6ce6, #0520d4);
  background: -o-linear-gradient(to right, #3a6ce6, #0520d4);
  background: linear-gradient(to right, #3a6ce6, #0520d4);
  opacity: 0.75;
}

@media only screen and (max-width: 991px) {
  .page-banner {
    padding: 7.4rem 0;
  }
}

@media only screen and (max-width: 767px) {
  .page-banner {
    padding: 7.2rem 0;
  }
}

@media only screen and (max-width: 575px) {
  .page-banner {
    padding: 7.2rem 0;
  }
}

.page-banner .breadcrumbs-area {
  position: relative;
  z-index: 1;
  text-align: center;
}

.page-banner .breadcrumbs-area h1 {
  font-weight: 600;
  color: #fff;
  margin-bottom: 1rem;
  font-size: 3.6rem;
}

@media only screen and (max-width: 991px) {
  .page-banner .breadcrumbs-area h1 {
    font-size: 3.2rem;
    line-height: 1.5;
  }
}

@media only screen and (max-width: 767px) {
  .page-banner .breadcrumbs-area h1 {
    font-size: 3rem;
    line-height: 1.3;
  }
}

@media only screen and (max-width: 575px) {
  .page-banner .breadcrumbs-area h1 {
    font-size: 2.8rem;
  }
}

.page-banner .breadcrumbs-area ul li {
  display: inline-block;
  position: relative;
  margin-right: 1.3rem;
  padding-right: 0.5rem;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

.page-banner .breadcrumbs-area ul li:before {
  font-family: FontAwesome;
  content: "\f105";
  position: absolute;
  font-size: 1.8rem;
  right: -1.1rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  color: #fff;
  font-weight: 600;
}

.page-banner .breadcrumbs-area ul li a {
  color: #fff;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}

.page-banner .breadcrumbs-area ul li a:hover {
  color: #ff2c54;
}

.page-banner .breadcrumbs-area ul li:last-child {
  margin-right: 0;
  padding-right: 0;
}

.page-banner .breadcrumbs-area ul li:last-child:before {
  display: none;
}

/*--------------------------------------
	# Slider Style
---------------------------------------*/
.home-slider-section .nivo-caption {
  opacity: 1;
}

.home-slider-section .slider-main {
  position: relative;
  overflow: hidden;
}

.home-slider-section .slider-main .slide-item {
  height: 100%;
}

.home-slider-section .slider-main .owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  color: #fff;
  padding: 0;
}

.home-slider-section .slider-main .owl-nav .owl-prev {
  position: absolute;
  left: -80px;
  text-align: center;
  background: transparent;
  transition: all 1s;
  outline: none;
}

.home-slider-section .slider-main .owl-nav .owl-prev i {
  font-size: 2.2rem;
  width: 6rem;
  height: 6rem;
  line-height: 5.8rem;
  border: 1px solid #fff;
  transition: all 0.4s ease 0s;
  color: #fff;
  border-radius: 100%;
  font-weight: 600;
  outline: none;
}

.home-slider-section .slider-main .owl-nav .owl-prev:hover {
  background: transparent;
}

.home-slider-section .slider-main .owl-nav .owl-prev:hover i {
  background: #ff2c54;
  border: 1px solid #ff2c54;
  color: #fff;
}

.home-slider-section .slider-main .owl-nav .owl-next {
  position: absolute;
  right: -80px;
  text-align: center;
  background: transparent;
  transition: all 1s;
  outline: none;
}

.home-slider-section .slider-main .owl-nav .owl-next i {
  font-size: 2.2rem;
  width: 6rem;
  height: 6rem;
  line-height: 5.8rem;
  border: 1px solid #fff;
  transition: all 0.4s ease 0s;
  color: #fff;
  border-radius: 100%;
  outline: none;
  font-weight: 600;
}

.home-slider-section .slider-main .owl-nav .owl-next:hover {
  background: transparent;
}

.home-slider-section .slider-main .owl-nav .owl-next:hover i {
  background: #ff2c54;
  border: 1px solid #ff2c54;
  color: #fff;
}

.home-slider-section .slider-main .nivo-directionNav {
  position: absolute;
  top: 50%;
  width: 100%;
  color: #fff;
  padding: 0;
}

.home-slider-section .slider-main .nivo-directionNav .nivo-prevNav {
  position: absolute;
  left: -80px;
  text-align: center;
  background: transparent;
  transition: all 1s;
  outline: none;
}

.home-slider-section .slider-main .nivo-directionNav .nivo-prevNav i {
  font-size: 2.2rem;
  width: 6rem;
  height: 6rem;
  line-height: 5.8rem;
  border: 1px solid #fff;
  transition: all 0.4s ease 0s;
  color: #fff;
  border-radius: 100%;
  font-weight: 600;
  outline: none;
}

.home-slider-section .slider-main .nivo-directionNav .nivo-prevNav:hover {
  background: transparent;
}

.home-slider-section .slider-main .nivo-directionNav .nivo-prevNav:hover i {
  background: #ff2c54;
  border: 1px solid #ff2c54;
  color: #fff;
}

.home-slider-section .slider-main .nivo-directionNav .nivo-nextNav {
  position: absolute;
  right: -80px;
  text-align: center;
  background: transparent;
  transition: all 1s;
  outline: none;
}

.home-slider-section .slider-main .nivo-directionNav .nivo-nextNav i {
  font-size: 2.2rem;
  width: 6rem;
  height: 6rem;
  line-height: 5.8rem;
  border: 1px solid #fff;
  transition: all 0.4s ease 0s;
  color: #fff;
  border-radius: 100%;
  outline: none;
  font-weight: 600;
}

.home-slider-section .slider-main .nivo-directionNav .nivo-nextNav:hover {
  background: transparent;
}

.home-slider-section .slider-main .nivo-directionNav .nivo-nextNav:hover i {
  background: #ff2c54;
  border: 1px solid #ff2c54;
  color: #fff;
}

.home-slider-section .slider-main:hover .owl-nav .owl-prev {
  left: 20px !important;
}

.home-slider-section .slider-main:hover .owl-nav .owl-next {
  right: 20px !important;
}

.home-slider-section .slider-main:hover .nivo-directionNav .nivo-prevNav {
  left: 50px !important;
}

@media only screen and (min-width: 1660px) {
  .home-slider-section .slider-main:hover .nivo-directionNav .nivo-prevNav {
    left: 150px !important;
  }
}

.home-slider-section .slider-main:hover .nivo-directionNav .nivo-nextNav {
  right: 50px !important;
}

@media only screen and (min-width: 1660px) {
  .home-slider-section .slider-main:hover .nivo-directionNav .nivo-nextNav {
    right: 150px !important;
  }
}

.home-slider-section .slide-item {
  position: relative;
}

.home-slider-section .slide-item .slide-caption {
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  width: 100%;
  height: 100%;
  z-index: 6;
}

.home-slider-section .slide-item .slide-caption h2 {
  color: #fff;
  font-weight: 600;
  font-size: 6.5rem;
  line-height: 7.5rem;
  margin-bottom: 1.5rem;
}

.home-slider-section .slide-item .slide-caption p {
  color: #fff;
  padding-right: 15rem;
  margin-bottom: 5rem;
}

.home-slider-section .slide-item .slide-caption .container {
  height: 100%;
}

.home-slider-section .slide-item .slide-caption .align-items-center {
  max-width: 70rem;
  position: relative;
  z-index: 1;
  height: 100%;
  text-align: left;
}

.home-slider-section .slide-item .slide-caption .align-items-center .content-box {
  margin-top: 7rem;
}

.home-slider-section .slide-item .slider-btn a {
  margin-right: 2rem;
  min-width: 17rem;
}

.home-slider-section .slide-item .slider-btn a:last-child {
  margin-right: 0;
}

.home-slider-section.slider-layout2 .slide-item .slide-caption .content-box {
  margin-top: 0;
}

.home-slider-section.slider-layout2 .slide-item .slide-caption .slider-wrap {
  max-width: 90rem;
  margin: 0 auto;
}

.home-slider-section.slider-layout2 .slide-item .slide-caption h2 {
  animation-duration: 1s;
  margin-bottom: 2.8rem;
}

.home-slider-section.slider-layout2 .slide-item .slide-caption p {
  animation-duration: 1.5s;
}

.home-slider-section.slider-layout2 .slide-item .slide-caption .slider-btn a {
  animation-duration: 2s;
  margin-right: 0;
}

.home-slider-section.slider-layout2 .slide-item .slide-caption p {
  padding-right: 3rem;
  padding-left: 3rem;
  font-size: 1.8rem;
}

/*--------------------------------------
	# Feature Style
---------------------------------------*/
.feature-section {
  background: url(../../media/background/grey-shape.png) no-repeat center top;
}

.feature-section.sec2-space120 {
  padding-bottom: 8rem;
}

.feature-section.no-bg {
  background: transparent;
}

.feature-section .feature-wrap {
  padding: 6rem 4.5rem 5.2rem;
  width: 100%;
  border: .1rem solid #f7f7f7;
  background: #fff;
  border-top: none;
  border-radius: 0 0 .5rem .5rem;
  box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.05);
  transition: all 0.4s ease 0s;
  border-radius: .6rem;
  margin-bottom: 3rem;
}

.feature-section .feature-wrap .feature-icon {
  width: 6rem;
  height: 6rem;
}

.feature-section .feature-wrap .feature-icon i {
  height: 6rem;
  line-height: 6rem;
}

.feature-section .feature-wrap .feature-icon i:before {
  font-size: 6rem;
  color: #ff2c54;
}

.feature-section .feature-wrap .feature-icon.icon-2 i:before {
  color: #00ebac;
}

.feature-section .feature-wrap .feature-icon.icon-3 i:before {
  color: #8053ca;
}

.feature-section .feature-wrap .feature-icon.icon-4 {
  width: 7rem;
  height: 6rem;
}

.feature-section .feature-wrap .feature-icon.icon-4 i {
  height: 7rem;
  line-height: 5.6rem;
}

.feature-section .feature-wrap .feature-icon.icon-4 i:before {
  font-size: 7rem;
  color: #ff930e;
}

.feature-section .feature-wrap .feature-icon.icon-5 i:before {
  color: #4f6def;
}

.feature-section .feature-wrap .feature-icon.icon-6 i:before {
  color: #25de73;
}

.feature-section .feature-wrap .feature-title {
  font-size: 2.2rem;
  margin-top: 2rem;
  margin-bottom: 2.1rem;
  font-weight: 600;
  border-bottom: .1rem solid #e4e0e1;
  position: relative;
  padding-bottom: 2.2rem;
}

.feature-section .feature-wrap .feature-title:after {
  position: absolute;
  content: "";
  display: block;
  right: 0;
  bottom: 0;
  background: #ff2c54;
  height: 2px;
  width: 0;
  transition: all 1s ease 0s;
}

.feature-section .feature-wrap .feature-title a {
  color: #333333;
}

.feature-section .feature-wrap .feature-title a:hover {
  opacity: 0.9;
  color: #ff2c54;
}

.feature-section .feature-wrap .feature-desc {
  margin-bottom: 0;
}

.feature-section .feature-wrap .read-more {
  margin-top: 2rem;
  position: relative;
  display: inline-block;
  background: linear-gradient(to right, currentColor 0%, currentColor 100%);
  background-size: 0px 2px;
  background-position: 0px 95%;
  transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  padding: 0.1% 0px;
  background-repeat: no-repeat;
}

.feature-section .feature-wrap .read-more:before {
  font-family: Flaticon;
  content: "\f10c";
  position: absolute;
  left: auto;
  right: -3rem;
  font-size: 2.1rem;
  top: -0.2rem;
  z-index: 9;
  display: block;
  cursor: pointer;
  color: #ff2c54;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.feature-section .feature-wrap .read-more:hover {
  background-size: 100% 2px;
  text-decoration: none !important;
}

.feature-section .feature-wrap:hover {
  box-shadow: 0px 1rem 4rem 0 rgba(0, 0, 0, 0.08);
}

.feature-section .feature-wrap:hover .feature-title:after {
  width: 100%;
  left: 0;
  right: auto;
}

.feature-section.layout2 {
  overflow: hidden;
  background: #f8f8f9;
}

.feature-section.layout2 .feature-btn {
  padding-top: 2.5rem;
  padding-bottom: 1rem;
}

.feature-section.layout2 .section-title.title-style2 .title {
  margin-bottom: 2rem;
}

.feature-section.layout2 .feature-img {
  text-align: right;
}

.feature-section.layout2 .feature-area {
  max-width: 54.5rem;
}

.feature-section.layout2 .feature-type .feature-item {
  background: #fff;
  padding: 4.3rem 2.5rem 2.7rem;
  transition: all 0.4s ease 0s;
}

.feature-section.layout2 .feature-type .feature-item .feature-icon {
  width: 5.2rem;
  height: 5.3rem;
  line-height: 5.3rem;
  text-align: center;
  float: left;
  transition: all 0.4s ease 0s;
  margin-top: 0.5rem;
}

.feature-section.layout2 .feature-type .feature-item .feature-icon i {
  transition: all 0.4s ease 0s;
}

.feature-section.layout2 .feature-type .feature-item .feature-icon i:before {
  font-size: 5.3rem;
}

.feature-section.layout2 .feature-type .feature-item .short-desc {
  float: left;
  width: calc(100% - 5.3rem);
  padding-left: 1.5rem;
}

.feature-section.layout2 .feature-type .feature-item .short-desc .feature-title {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: .9rem;
  transition: all 0.4s ease 0s;
}

.feature-section.layout2 .feature-type .feature-item .short-desc p {
  margin-bottom: 2rem;
}

.feature-section.layout2 .feature-type .feature-item.item-one .feature-icon i {
  color: #ff2c54;
}

.feature-section.layout2 .feature-type .feature-item.item-one:hover {
  background: #ff2c54;
}

.feature-section.layout2 .feature-type .feature-item.item-two .feature-icon i {
  color: #ff930e;
}

.feature-section.layout2 .feature-type .feature-item.item-two:hover {
  background: #ff930e;
}

.feature-section.layout2 .feature-type .feature-item.item-three .feature-icon i {
  color: #8053ca;
}

.feature-section.layout2 .feature-type .feature-item.item-three:hover {
  background: #8053ca;
}

.feature-section.layout2 .feature-type .feature-item.item-four .feature-icon i {
  color: #4f6def;
}

.feature-section.layout2 .feature-type .feature-item.item-four:hover {
  background: #4f6def;
}

.feature-section.layout2 .feature-type .feature-item:hover {
  color: #fff;
}

.feature-section.layout2 .feature-type .feature-item:hover .feature-title {
  color: #fff;
}

.feature-section.layout2 .feature-type .feature-item:hover .feature-icon {
  color: #fff;
}

.feature-section.layout2 .feature-type .feature-item:hover .feature-icon i {
  color: #fff;
}

.feature-section.layout3 {
  overflow: hidden;
  background: transparent;
  position: relative;
  z-index: 9;
  -moz-transform: translate(0, -52%);
  -webkit-transform: translate(0, -52%);
  transform: translate(0, -52%);
  padding-bottom: 5px;
  padding-top: 10px;
}

.feature-section.layout3 .feature-btn {
  padding-top: 2.5rem;
  padding-bottom: 1rem;
}

.feature-section.layout3 .section-title.title-style2 .title {
  margin-bottom: 2rem;
}

.feature-section.layout3 .feature-img {
  text-align: right;
}

.feature-section.layout3 .feature-area {
  max-width: 54.5rem;
}

.feature-section.layout3 .feature-item {
  background: #fff;
  padding: 6rem 2.5rem 4rem;
  transition: all 0.4s ease 0s;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.07);
  border-radius: 0.5rem;
  position: relative;
}

.feature-section.layout3 .feature-item:after {
  content: "";
  position: absolute;
  transition: all 0.4s ease 0s;
  width: 0;
  height: 2px;
  background: #ff2c54;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.feature-section.layout3 .feature-item .feature-icon {
  height: 4.5rem;
  line-height: 4.5rem;
  margin-bottom: 1.8rem;
  text-align: center;
  transition: all 0.4s ease 0s;
  text-align: left;
}

.feature-section.layout3 .feature-item .feature-icon i {
  transition: all 0.4s ease 0s;
}

.feature-section.layout3 .feature-item .feature-icon i:before {
  font-size: 4.5rem;
}

.feature-section.layout3 .feature-item .short-desc .feature-title {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: .9rem;
  transition: all 0.4s ease 0s;
}

.feature-section.layout3 .feature-item .short-desc p {
  margin-bottom: 2rem;
}

.feature-section.layout3 .feature-item.item-one .feature-icon i {
  color: #ff2c54;
}

.feature-section.layout3 .feature-item.item-two .feature-icon i {
  color: #00ebac;
}

.feature-section.layout3 .feature-item.item-three .feature-icon i {
  color: #8053ca;
}

.feature-section.layout3 .feature-item.item-four .feature-icon i {
  color: #4f6def;
}

.feature-section.layout3 .feature-item:hover {
  margin-top: -10px;
}

.feature-section.layout3 .feature-item:hover:after {
  width: 100%;
}

/*--------------------------------------
	# Faq Style
---------------------------------------*/
.faq-section .faq-heading {
  margin-bottom: 2.9rem;
}

.faq-section .faq-heading .faq-icon {
  position: relative;
  top: 0.6rem;
  margin-right: 1.5rem;
}

.faq-section .faq-heading .faq-icon i {
  line-height: 4.2rem;
  color: #00ebac;
}

.faq-section .faq-heading .faq-icon i:before {
  font-size: 4.2rem;
}

.faq-section .faq-heading .faq-icon.icon-item1 i {
  color: #ff930e;
}

.faq-section .faq-heading .faq-icon.icon-item2 i {
  color: #00ebac;
}

.faq-section .faq-heading .faq-icon.icon-item3 i {
  color: #4f6def;
}

.faq-section .faq-heading .faq-icon.icon-item4 {
  margin-right: 1.3rem;
}

.faq-section .faq-heading .faq-icon.icon-item5 i {
  color: #8053ca;
}

.faq-section .faq-heading .faq-title h3 {
  font-weight: 700;
  margin-bottom: 0;
  font-size: 2.2rem;
}

.faq-section .faq-heading .faq-title p {
  margin-bottom: 0;
  line-height: 2rem;
}

.faq-section .faq-tab .nav-tabs {
  border-bottom: none;
}

.faq-section .faq-tab .nav-tabs .nav-item {
  margin-bottom: 1.8rem;
}

.faq-section .faq-tab .nav-tabs .nav-link {
  border: none;
  box-shadow: 0 0 2.4rem rgba(0, 0, 0, 0.05);
  border-radius: 0;
  background-color: transparent;
  color: #666666;
  padding: 23px 28px 1px 28px;
}

.faq-section .faq-tab .nav-tabs .nav-link p {
  margin-bottom: 0;
}

.faq-section .faq-tab .nav-tabs .nav-link.active {
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
}

.faq-section .faq-tab .nav-tabs .nav-link:hover {
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12);
}

.faq-section .faq-tabs {
  margin-bottom: 2.9rem;
}

.faq-section .faq-tabs .faq-icon {
  margin-top: 0.6rem;
  margin-right: 1.3rem;
}

.faq-section .faq-tabs .faq-icon i {
  line-height: 2.7rem;
  color: #00ebac;
}

.faq-section .faq-tabs .faq-icon i:before {
  font-size: 2.7rem;
}

.faq-section .faq-tabs .faq-icon.icon-item1 i {
  color: #ff930e;
}

.faq-section .faq-tabs .faq-icon.icon-item2 {
  margin-right: 0.9rem;
  margin-left: -0.4rem;
}

.faq-section .faq-tabs .faq-icon.icon-item2 i {
  line-height: 3.5rem;
  color: #00ebac;
}

.faq-section .faq-tabs .faq-icon.icon-item2 i:before {
  font-size: 3.5rem;
}

.faq-section .faq-tabs .faq-icon.icon-item3 i {
  color: #4f6def;
}

.faq-section .faq-tabs .faq-icon.icon-item5 i {
  color: #8053ca;
}

.faq-section .faq-tabs .faq-title h3 {
  font-weight: 600;
  margin-bottom: 0.3rem;
  font-size: 1.8rem;
}

.faq-section .faq-tabs .faq-title p {
  margin-bottom: 0;
}

.faq-section .panel-group .panel {
  border-radius: 0;
  margin-bottom: 3rem;
  border: 1px solid #d3d3d3;
}

.faq-section .panel-group .panel .panel-heading {
  border: none;
  border-radius: 0;
  padding: 1.6rem 6rem 1.6rem 3.2rem;
  position: relative;
}

.faq-section .panel-group .panel .panel-heading .faq-num {
  color: #fff;
  height: 100%;
  top: 0px;
  left: 0px;
  width: 4.7rem;
  line-height: 2.4rem;
  position: absolute;
  background: transparent;
  padding: 1rem 0;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.faq-section .panel-group .panel .panel-heading .panel-title {
  margin-bottom: 0;
}

.faq-section .panel-group .panel .panel-heading .panel-title a {
  font-weight: 600;
  color: #333333;
  font-size: 1.8rem;
  display: block;
}

.faq-section .panel-group .panel .panel-heading .panel-title a:after {
  font-family: Flaticon;
  content: "\f12e";
  position: absolute;
  left: auto;
  right: 3.5rem;
  font-size: 2.2rem;
  top: 1.4rem;
  color: #888888;
  z-index: 1;
}

.faq-section .panel-group .panel .panel-heading .panel-title a.expanded:after {
  content: "\f12f";
}

.faq-section .panel-group .panel .panel-body {
  position: relative;
  padding: 1rem 6.4rem 4.5rem 3.2rem;
}

/*--------------------------------------
	# Video Style
---------------------------------------*/
.video-section {
  background: url(../../media/background/video-bg2.jpg) no-repeat center center;
  height: 600px;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}

.video-section .play-video {
  height: 100%;
}

.video-section .play-video .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  width: 10rem;
  height: 10rem;
  z-index: 99;
  background: #fff;
  border-radius: 100%;
}

.video-section .play-video .play-icon .popup-video:after {
  content: "";
  position: absolute;
  width: 150%;
  height: 150%;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate1 2s;
  animation: pulsate1 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid #fff;
  top: -25%;
  left: -25%;
  background: #fff;
}

.video-section .play-video .play-icon .popup-video:before {
  content: "";
  position: absolute;
  left: 3.2rem;
  top: 3.1rem;
  display: block;
  width: 4rem;
  height: 4rem;
  background: url(../../media/icons/play2.png) no-repeat center center;
  border-radius: 100%;
  z-index: 9;
}

.video-section .play-video .play-icon .popup-video:hover:after {
  background: #eee;
}

.video-section2 {
  background: url(../../media/background/video-bg3.jpg) no-repeat center center;
  height: 600px;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}

.video-section2 .play-video {
  height: 100%;
}

.video-section2 .play-video .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  width: 10rem;
  height: 10rem;
  z-index: 99;
  background: #fff;
  border-radius: 100%;
}

.video-section2 .play-video .play-icon .popup-video:after {
  content: "";
  position: absolute;
  width: 150%;
  height: 150%;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate1 2s;
  animation: pulsate1 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid #fff;
  top: -25%;
  left: -25%;
  background: #fff;
}

.video-section2 .play-video .play-icon .popup-video:before {
  content: "";
  position: absolute;
  left: 3.2rem;
  top: 3.1rem;
  display: block;
  width: 4rem;
  height: 4rem;
  background: url(../../media/icons/play2.png) no-repeat center center;
  border-radius: 100%;
  z-index: 9;
}

.video-section2 .play-video .play-icon .popup-video:hover:after {
  background: #eee;
}

.play-video.focus-icon .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  width: 10rem;
  height: 10rem;
  z-index: 99;
  background: #fff;
  border-radius: 100%;
}

.play-video.focus-icon .play-icon .popup-video {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  width: 10rem;
  height: 10rem;
}

.play-video.focus-icon .play-icon .popup-video:after {
  content: "";
  position: absolute;
  width: 12rem;
  height: 12rem;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate1 2s;
  animation: pulsate1 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid #fff;
  top: -1rem;
  left: -1rem;
  background: #fff;
}

.play-video.focus-icon .play-icon .popup-video:before {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.play-video.focus-icon .play-icon .popup-video:hover:after {
  background: #eee;
}

@-webkit-keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
    box-shadow: none;
  }
}

@keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1);
    opacity: 0;
    box-shadow: none;
  }
}

/*--------------------------------------
	# Contact Style
---------------------------------------*/
.contact-info-section {
  padding-top: 120px;
}

.contact-info-section.sec-space-default {
  padding-bottom: 6rem;
}

.contact-info-section .gap-section {
  height: 100%;
}

.contact-info-section .gap-section #g-map {
  height: 100%;
  min-height: 40rem;
}

.contact-info-section .our-contact {
  max-width: 50rem;
  padding-left: 1.5rem;
}

.contact-info-section .our-contact .section-title.title-style2 .title.sm-bottom {
  margin-bottom: 0.5rem;
}

.contact-info-section .our-contact .section-title.title-style2 p {
  margin-bottom: 4.7rem;
}

.contact-info-section .address-box-layout1 {
  margin-bottom: 4rem;
}

.contact-info-section .address-box-layout1 .item-icon {
  padding-top: 0.5rem;
  padding-right: 2.3rem;
}

.contact-info-section .address-box-layout1 .item-icon i {
  line-height: 4.3rem;
}

.contact-info-section .address-box-layout1 .item-icon i:before {
  font-size: 4.3rem;
  color: #ff2c54;
}

.contact-info-section .address-box-layout1 .item-icon.location {
  position: relative;
  right: 0.7rem;
}

.contact-info-section .address-box-layout1 .item-icon.location i {
  line-height: 5rem;
}

.contact-info-section .address-box-layout1 .item-icon.location i:before {
  font-size: 5rem;
  color: #ff2c54;
}

.contact-info-section .address-box-layout1 .item-desc {
  font-size: 1.8rem;
}

.contact-info-section .address-box-layout1 .item-desc p {
  font-size: 1.8rem;
  margin-bottom: 0;
}

.contact-info-section .address-box-layout1 .item-desc .item-title {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 0;
}

.contact-info-section .address-box-layout1 .item-desc a {
  color: #666666;
}

.contact-info-section .address-box-layout1 .item-desc a:hover {
  color: #ff2c54;
}

.contact-info-section#contact-us {
  padding-top: 10.8rem;
  padding-bottom: 12rem;
}

.contact-info-section#contact-us .our-contact.ipad-pt--50 {
  padding-top: 0;
}

.contact-info-section#contact-us .section-title .title.sm-bottom {
  margin-bottom: 1.5rem;
}

.contact-info-section#contact-us .section-title p {
  margin-bottom: 4.7rem;
}

.contact-info-section#contact-us #contact_form {
  margin-top: 2rem;
}

.contact-info-section#contact-us #contact_form .form-field {
  margin-bottom: 3.5rem;
}

.contact-form-section {
  overflow: hidden;
  background: #fbfbfb;
}

.contact-form-section .section-title.title-style2 .title.sm-bottom {
  margin-bottom: 0.5rem;
}

.contact-form-section .section-title.title-style2 p {
  margin-bottom: 4.7rem;
}

.contact-form-section .left-img {
  background: url(../../media/background/contact-left.jpg) no-repeat center center;
  background-size: cover;
}

.contact-form-section .contact-form {
  max-width: 78rem;
  padding: 9.2rem 3rem 10rem 8rem;
}

.gap-section #g-map {
  height: 50rem;
}

.contact-form .form-field {
  margin-bottom: 5.2rem;
}

.contact-form .form-field input {
  width: 100%;
  border: none;
  border-bottom: 0.1rem solid #cfcfcf;
  padding-bottom: 1rem;
  outline: none;
  background: transparent;
}

.contact-form .form-field textarea {
  width: 100%;
  border: none;
  border-bottom: 0.1rem solid #cfcfcf;
  padding-bottom: 1rem;
  outline: none;
  height: 12.5rem;
  background: transparent;
}

.contact-form .form-button .primary-btn {
  cursor: pointer;
}

#form-messages.success {
  background: #2554E1;
  display: inline-block;
  color: #fff;
  padding: 9px 21px;
  margin-bottom: 35px;
}

#form-messages.error {
  display: inline-block;
  color: #fff;
  padding: 9px 21px;
  margin-bottom: 35px;
  background: #ccc;
  color: #ff0000;
}

/*--------------------------------------
	# Team Style
---------------------------------------*/
.team-section {
  background: url(../../media/team/team-texture.png) no-repeat bottom center;
}

.team-section .team-item {
  text-align: center;
  transition: all 0.4s ease 0s;
  padding: 4rem 1rem 3.3rem;
}

.team-section .team-item .team-image {
  margin-bottom: 2rem;
}

.team-section .team-item .team-image img {
  border-radius: 100%;
}

.team-section .team-item .team-title {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 0.1rem;
}

.team-section .team-item .team-title a {
  color: #333333;
}

.team-section .team-item .team-title a:hover {
  color: #ff2c54;
}

.team-section .team-item .team-designation {
  color: #333333;
}

.team-section .team-item .team_social_icon {
  margin-top: 1rem;
}

.team-section .team-item .team_social_icon li {
  margin-right: 1.5rem;
}

.team-section .team-item .team_social_icon li a {
  color: #787878;
  font-size: 1.4rem;
}

.team-section .team-item .team_social_icon li a:hover {
  color: #ff2c54;
}

.team-section .team-item .team_social_icon li:last-child {
  margin-right: 0;
}

.team-section .team-item:hover, .team-section .team-item.active {
  box-shadow: 0 1px 23px 0 rgba(0, 0, 0, 0.07);
  background: #fff;
}

.team-section.team-style2 {
  background: transparent;
  margin-top: -20.5rem;
}

.team-section.team-style2 .section-title.center-style p {
  margin-bottom: 3.6rem;
}

.team-section.team-style3 .team-item {
  margin-bottom: 2rem;
}

.team-section.no-bg {
  background: transparent;
}

.single-team-section .single-info-wrap {
  background: #f5f7ff;
  padding: 4rem 3rem 4.5rem 4.5rem;
}

@media only screen and (max-width: 1199px) {
  .single-team-section .single-info-wrap {
    background: transparent;
    padding: 0 0 0 30px;
  }
}

@media only screen and (max-width: 991px) {
  .single-team-section .single-info-wrap {
    padding: 30px 0 0 0;
  }
}

.single-team-section .single-info-wrap h3 {
  margin-bottom: 25px;
}

@media only screen and (max-width: 991px) {
  .single-team-section .pr--0 {
    padding-right: 1.5rem;
  }
  .single-team-section .pl--0 {
    padding-left: 1.5rem;
  }
  .single-team-section .pl--30 {
    padding-left: 1.5rem;
    padding-top: 5rem;
  }
}

.single-team-section .single-info .item-heading {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 2.8rem;
}

.single-team-section .single-info li {
  line-height: 3.3rem;
  margin-bottom: 1.1rem;
}

.single-team-section .single-info .feature-title {
  font-size: 1.8rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #333333;
}

.single-team-section .team_social_icon {
  margin-top: 3rem;
}

.single-team-section .team_social_icon li {
  margin-right: 1rem;
}

.single-team-section .team_social_icon li a {
  color: #666666;
  border: 1px solid #666666;
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
  border-radius: 100%;
  display: block;
  text-align: center;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.single-team-section .team_social_icon li a:hover {
  color: #fff;
  background: #ff2c54;
  border: 1px solid #ff2c54;
}

.single-team-section .skill-bar-wrapper .skillbar-title {
  font-size: 1.8rem;
}

.single-team-section .skill-bar-wrapper .skillbar .skill-bar-percent {
  font-size: 1.6rem;
}

.single-team-section .skill-bar-wrapper .skill-item {
  margin-bottom: 2rem;
}

/*--------------------------------------
	# Animation Style
---------------------------------------*/
@-webkit-keyframes up_down {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  25% {
    -webkit-transform: translateY(-30px) translateX(0);
    transform: translateY(-30px) translateX(0);
  }
  75% {
    -webkit-transform: translateY(30px) translateX(0);
    transform: translateY(30px) translateX(0);
  }
}

@keyframes up_down {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  25% {
    -webkit-transform: translateY(-30px) translateX(0);
    transform: translateY(-30px) translateX(0);
  }
  75% {
    -webkit-transform: translateY(30px) translateX(0);
    transform: translateY(30px) translateX(0);
  }
}

@-webkit-keyframes left_right {
  0% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
  25% {
    -webkit-transform: translateX(-30px) translateY(0);
    transform: translateX(-30px) translateY(0);
  }
  75% {
    -webkit-transform: translateX(30px) translateY(0);
    transform: translateX(30px) translateY(0);
  }
}

@keyframes left_right {
  0% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
  25% {
    -webkit-transform: translateX(-30px) translateY(0);
    transform: translateX(-30px) translateY(0);
  }
  75% {
    -webkit-transform: translateX(30px) translateY(0);
    transform: translateX(30px) translateY(0);
  }
}

.up-down-animate {
  animation: up_down 15s infinite;
}

.has-animation .fade-right {
  transform: translate3d(100px, 0, 0);
  opacity: 0;
  transition-property: opacity,transform;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.has-animation .fade-up {
  transform: translate3d(0, 100px, 0);
  opacity: 0;
  transition-property: opacity,transform;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.has-animation .fade-down {
  transform: translate3d(0, -100px, 0);
  opacity: 0;
  transition-property: opacity,transform;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.has-animation .fade-left {
  transform: translate3d(-100px, 0, 0);
  opacity: 0;
  transition-property: opacity,transform;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.has-animation .fade-up-left {
  transform: translate3d(-100px, 100px, 0);
  opacity: 0;
  transition-property: opacity,transform;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.has-animation .fade-up-right {
  transform: translate3d(100px, 100px, 0);
  opacity: 0;
  transition-property: opacity,transform;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.has-animation .flip-left {
  transform: perspective(2500px) rotateY(-100deg);
  backface-visibility: hidden;
  transition-property: transform;
  transition-property: opacity,transform;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.has-animation .flip-up {
  transform: perspective(2500px) rotateX(-100deg);
  backface-visibility: hidden;
  transition-property: transform;
  transition-property: opacity,transform;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.has-animation .zoom-in {
  transform: scale(0.6);
  opacity: 0;
  transition-property: opacity,transform;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.has-animation .zoom-in-up {
  transform: translate3d(0, 100px, 0) scale(0.6);
  opacity: 0;
  transition-property: opacity,transform;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.has-animation .zoom-in-down {
  transform: translate3d(0, -100px, 0) scale(0.6);
  opacity: 0;
  transition-property: opacity,transform;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.has-animation .fade-top-bottom {
  transform: translate3d(0, 100px, 0);
  opacity: 0;
  transition-property: opacity,transform;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.has-animation .opacity-animation {
  opacity: 0;
  visibility: hidden;
}

.has-animation .translate-left-75 {
  transform: translateX(-75%);
}

.has-animation .translate-left-30 {
  transform: translateX(-30%);
}

.has-animation .translate-left-10 {
  transform: translateX(-10%);
}

.has-animation .translate-right-75 {
  transform: translateX(75%);
}

.has-animation .translate-right-30 {
  transform: translateX(30%);
}

.has-animation .translate-right-10 {
  transform: translateX(10%);
}

.has-animation .translate-top-75 {
  transform: translateY(-75%);
}

.has-animation .translate-bottom-75 {
  transform: translateY(75%);
}

.has-animation .translate-bottom-30 {
  transform: translateY(30%);
}

.has-animation .translate-bottom-10 {
  transform: translateY(10%);
}

.has-animation .translate-bottom-20 {
  transform: translateY(20%);
}

.has-animation .translate-bottom-30 {
  transform: translateY(30%);
}

.has-animation .fade-in-up {
  transform: translate3d(0, 100px, 0);
}

.has-animation.active-animation .fade-right,
.has-animation.active-animation .fade-up-right,
.has-animation.active-animation .fade-up-left,
.has-animation.active-animation .fade-down-right,
.has-animation.active-animation .fade-down-left,
.has-animation.active-animation .fade-left,
.has-animation.active-animation .fade-down,
.has-animation.active-animation .fade-up {
  opacity: 1;
  transform: translateZ(0);
}

.has-animation.active-animation .flip-left,
.has-animation.active-animation .flip-up {
  transform: perspective(2500px) rotateY(0);
}

.has-animation.active-animation .zoom-in-up,
.has-animation.active-animation .zoom-in,
.has-animation.active-animation .zoom-in-down {
  opacity: 1;
  transform: translateZ(0) scale(1);
}

.has-animation.active-animation .fade-top-bottom {
  opacity: 1;
  transform: translateZ(0);
}

.has-animation.active-animation .opacity-animation {
  opacity: 1;
  visibility: visible;
}

.has-animation.active-animation .translate-left-75 {
  transform: translateX(0);
}

.has-animation.active-animation .translate-left-30 {
  transform: translateX(0);
}

.has-animation.active-animation .translate-left-10 {
  transform: translateX(0);
}

.has-animation.active-animation .translate-right-75 {
  transform: translateX(0);
}

.has-animation.active-animation .translate-right-30 {
  transform: translateX(0);
}

.has-animation.active-animation .translate-right-10 {
  transform: translateX(0);
}

.has-animation.active-animation .translate-top-75 {
  transform: translateY(0);
}

.has-animation.active-animation .translate-bottom-75 {
  transform: translateY(0);
}

.has-animation.active-animation .translate-bottom-30 {
  transform: translateY(0);
}

.has-animation.active-animation .translate-bottom-10 {
  transform: translateY(0);
}

.has-animation.active-animation .translate-bottom-20 {
  transform: translateY(0);
}

.has-animation.active-animation .translate-bottom-30 {
  transform: translateY(0);
}

.has-animation.active-animation .fade-in-up {
  transform: none;
}

.has-animation.active-animation .fade-in-bottom {
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.transition-1s {
  transition: all 1s ease-in-out;
}

.transition-1-5s {
  transition: all 1.5s ease-in-out;
}

.transition-2s {
  transition: all 2s ease-in-out;
}

.transition-3s {
  transition: all 3s ease-in-out;
}

.transition-3s {
  transition: all 4s ease-in-out;
}

.transition-duration-1s {
  transition-duration: 1s;
}

.transition-duration-1-5s {
  transition-duration: 1.5s;
}

.transition-duration-2s {
  transition-duration: 2s;
}

.transition-duration-2.5s {
  transition-duration: 2.5s;
}

.transition-duration-3s {
  transition-duration: 3s;
}

/* Transition Delay */
.transition-delay-100 {
  transition-delay: 0.1s;
}

.transition-delay-200 {
  transition-delay: 0.2s;
}

.transition-delay-300 {
  transition-delay: 0.3s;
}

.transition-delay-400 {
  transition-delay: 0.4s;
}

.transition-delay-500 {
  transition-delay: 0.5s;
}

.transition-delay-600 {
  transition-delay: 0.6s;
}

.transition-delay-700 {
  transition-delay: 0.7s;
}

.transition-delay-800 {
  transition-delay: 0.8s;
}

.transition-delay-900 {
  transition-delay: 0.9s;
}

.transition-delay-1000 {
  transition-delay: 1s;
}

.transition-delay-1100 {
  transition-delay: 1.1s;
}

.transition-delay-1200 {
  transition-delay: 1.2s;
}

.transition-delay-1300 {
  transition-delay: 1.3s;
}

.transition-delay-1400 {
  transition-delay: 1.4s;
}

.transition-delay-1500 {
  transition-delay: 1.5s;
}

.transition-delay-1600 {
  transition-delay: 1.6s;
}

.transition-delay-1700 {
  transition-delay: 1.7s;
}

.transition-delay-1800 {
  transition-delay: 1.8s;
}

.transition-delay-1900 {
  transition-delay: 1.9s;
}

.transition-delay-2000 {
  transition-delay: 2s;
}

.transition-delay-2100 {
  transition-delay: 2.1s;
}

.transition-delay-2200 {
  transition-delay: 2.2s;
}

.transition-delay-2300 {
  transition-delay: 2.3s;
}

.transition-delay-2400 {
  transition-delay: 2.4s;
}

.transition-delay-2500 {
  transition-delay: 2.5s;
}

.transition-delay-2600 {
  transition-delay: 2.6s;
}

.transition-delay-2700 {
  transition-delay: 2.7s;
}

.transition-delay-2800 {
  transition-delay: 2.8s;
}

.transition-delay-2900 {
  transition-delay: 2.9s;
}

.transition-delay-3000 {
  transition-delay: 3s;
}

.transition-delay-3500 {
  transition-delay: 3.5s;
}

.transition-delay-4000 {
  transition-delay: 4s;
}

.transition-delay-4500 {
  transition-delay: 4.5s;
}

.transition-delay-5000 {
  transition-delay: 5s;
}

.animated-figure ul li {
  position: absolute;
  z-index: -1;
}

.animated-figure ul li:first-child {
  top: 50%;
  transform: translateX(0) translateY(-50%);
  left: 1rem;
}

.animated-figure ul li:last-child {
  top: 0;
  right: 0;
}

.animated-shape-big ul li {
  position: absolute;
  z-index: 1;
  bottom: 15rem;
  right: 3rem;
}

.animated-shape-small ul li {
  position: absolute;
  z-index: 1;
}

.animated-shape-small ul li:nth-child(1n) {
  top: 10%;
  right: 20%;
  animation: shape_one 30s alternate infinite linear;
}

.animated-shape-small ul li:nth-child(2n) {
  top: 50%;
  right: 10%;
  animation: shape_two 30s alternate infinite linear;
}

.animated-shape-small ul li:nth-child(3n) {
  top: 70%;
  right: 30%;
  animation: shape_three 30s alternate infinite linear;
}

@keyframes shape_one {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -100px) rotate(36deg);
  }
  50% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(40px, -72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes shape_two {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  10% {
    transform: translate(53px, -80px) rotate(36deg);
  }
  40% {
    transform: translate(161px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 112px) rotate(108deg);
  }
  70% {
    transform: translate(-60px, 92px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes shape_three {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  30% {
    transform: translate(90px, -36px) rotate(36deg);
  }
  40% {
    transform: translate(101px, 32px) rotate(72deg);
  }
  50% {
    transform: translate(103px, 102px) rotate(108deg);
  }
  80% {
    transform: translate(-76px, 62px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

.compress-right-side {
  padding-left: 0;
  padding-right: calc(calc(100% - 1170px)/2);
}

@media only screen and (max-width: 1199px) {
  .compress-right-side {
    padding-left: calc(calc(100% - 930px)/2);
    padding-right: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .compress-right-side {
    padding-right: calc(calc(100% - 690px)/2);
    padding-left: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .compress-right-side {
    padding-right: calc(calc(100% - 510px)/2);
    padding-left: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .compress-right-side {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

/*--------------------------------------
	# What We Do
---------------------------------------*/
.key-feature-section .feature-carousel .icon {
  margin-top: -3.6rem;
}

.key-feature-section .feature-carousel .icon i:before {
  font-size: 4rem;
  margin-left: 0.8rem;
  padding: 2.1rem;
  background: #fff;
  color: #ff2c54;
  border-radius: 100%;
  box-shadow: 0px 0.6rem 0rem 0px #ebebeb;
  transition: all 0.4s ease 0s;
}

.key-feature-section .feature-carousel .item-title {
  margin-top: 2.7rem;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 2.2rem;
}

.key-feature-section .feature-carousel .item-title a {
  color: #333333;
}

.key-feature-section .feature-carousel .item-title a:hover {
  color: #ff2c54;
  text-decoration: none;
}

.key-feature-section .feature-carousel .item-title a:hover:before {
  color: #ff2c54;
}

.key-feature-section .feature-carousel p {
  margin-bottom: 0.3rem;
}

.key-feature-section .feature-carousel .read-more {
  color: #777;
}

.key-feature-section .feature-carousel .read-more:before {
  color: #333333;
}

.key-feature-section .feature-carousel .read-more:hover {
  color: #ff2c54;
  text-decoration: none;
}

.key-feature-section .feature-carousel .read-more:hover:before {
  color: #ff2c54;
}

.key-feature-section .feature-carousel .feature-item {
  margin-bottom: 2.3rem;
}

.key-feature-section .feature-carousel .feature-item:hover .icon i:before {
  background: #ff2c54;
  color: #fff;
}

.key-feature-section .feature-carousel .feature-item:hover .read-more {
  color: #ff2c54;
  text-decoration: none;
}

.key-feature-section .feature-carousel .feature-item:hover .read-more:before {
  color: #ff2c54;
}

/*--------------------------------------
	# Who We Are
---------------------------------------*/
.who-are-section .polygon-clip-hexagon {
  -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  background: #f7c80a;
  width: 8rem;
  height: 9rem;
  line-height: 11rem;
  text-align: center;
  margin: 0 auto;
}

.who-are-section .about-btn {
  margin-bottom: 5rem;
}

.who-are-section .service-wrap {
  padding: 6rem 2.7rem 5.2rem;
  width: 100%;
  border: .1rem solid #f7f7f7;
  border-top-color: #f7f7f7;
  border-top-style: solid;
  border-top-width: 0.1rem;
  background: #fff;
  border-top: none;
  border-radius: 0;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.06);
  transition: all 0.4s ease 0s;
  margin-bottom: 3rem;
}

.who-are-section .service-wrap .icon-wrap {
  -webkit-filter: drop-shadow(0px 10px 0px rgba(247, 200, 10, 0.15));
  filter: drop-shadow(0px 10px 0px rgba(247, 200, 10, 0.15));
}

.who-are-section .service-wrap .icon-wrap .service-icon-svg {
  max-width: 9rem;
  position: relative;
  margin: 0 auto;
}

.who-are-section .service-wrap .icon-wrap .service-icon-svg i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.who-are-section .service-wrap .icon-wrap .service-icon-svg i:before {
  font-size: 4rem;
}

.who-are-section .service-wrap .icon-wrap .cls-1 {
  fill: #f7c80a;
  fill-rule: evenodd;
}

.who-are-section .service-wrap .icon-wrap .cls-2 {
  fill: #ff2c54;
  fill-rule: evenodd;
}

.who-are-section .service-wrap .icon-wrap .cls-3 {
  fill: #00ca90;
  fill-rule: evenodd;
}

.who-are-section .service-wrap .icon-wrap .cls-4 {
  fill: #1ec1df;
  fill-rule: evenodd;
}

.who-are-section .service-wrap.first-service .service-icon {
  background: #f7c80a;
}

.who-are-section .service-wrap.second-service {
  margin-top: 12.5rem;
}

.who-are-section .service-wrap.second-service .icon-wrap {
  -webkit-filter: drop-shadow(0px 10px 0px rgba(255, 44, 84, 0.15));
  filter: drop-shadow(0px 10px 0px rgba(255, 44, 84, 0.15));
}

.who-are-section .service-wrap.second-service .service-icon {
  background: #ff2c54;
}

.who-are-section .service-wrap.third-service {
  margin-top: -12.5rem;
}

.who-are-section .service-wrap.third-service .icon-wrap {
  -webkit-filter: drop-shadow(0px 10px 0px rgba(0, 202, 144, 0.15));
  filter: drop-shadow(0px 10px 0px rgba(0, 202, 144, 0.15));
}

.who-are-section .service-wrap.third-service .service-icon {
  background: #00ca90;
}

.who-are-section .service-wrap.fourth-service .icon-wrap {
  -webkit-filter: drop-shadow(0px 10px 0px rgba(30, 193, 223, 0.15));
  filter: drop-shadow(0px 10px 0px rgba(30, 193, 223, 0.15));
}

.who-are-section .service-wrap.fourth-service .service-icon {
  background: #1ec1df;
}

.who-are-section .service-wrap .service-icon i {
  color: #fff;
  font-size: 3.5rem;
}

.who-are-section .service-wrap .service-title {
  margin-bottom: 1.5rem;
  margin-top: 2.2rem;
  font-size: 2.4rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.who-are-section .service-wrap .service-title a {
  color: #333333;
}

.who-are-section .service-wrap .service-title a:hover {
  color: #ff2c54;
}

.who-are-section .service-wrap .service-desc {
  margin-bottom: 0;
}

.who-are-section .service-wrap .read-more {
  color: #333333;
}

.who-are-section .service-wrap .read-more:before {
  color: #333333;
}

.who-are-section .service-wrap .read-more:hover {
  color: #ff2c54;
  text-decoration: none;
}

.who-are-section .service-wrap .read-more:hover:before {
  color: #ff2c54;
}

.who-are-section .service-wrap:hover .read-more {
  color: #ff2c54;
  text-decoration: none;
}

.who-are-section .service-wrap:hover .read-more:before {
  color: #ff2c54;
  right: -3rem;
}

.what-we-do.landing-ticket {
  padding-top: 0;
}

.what-we-do.sec6-space120 {
  padding-bottom: 10rem;
}

.what-we-do .service-wrap {
  -webkit-box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.15);
  border-radius: 2rem;
  margin-bottom: 2rem;
}

.what-we-do .service-wrap img {
  border-radius: 2rem;
}

.what-we-do .service-wrap.first-service {
  margin-top: 5.3rem;
}

.what-we-do .service-wrap.fourth-service {
  box-shadow: 0px 13px 2.2rem rgba(0, 0, 0, 0.1);
  margin-top: -10rem;
}

.what-we-do .pdf-btn .btn-wrap {
  display: flex;
  justify-content: left;
  background: #ff2c54;
  box-shadow: 0px 6px 2.2rem rgba(0, 0, 0, 0.1);
  padding: 3.2rem 3.2rem;
  border-radius: 1.2rem;
  margin-bottom: 1.8rem;
  transition: all 0.4s ease 0s;
  cursor: pointer;
}

.what-we-do .pdf-btn .btn-wrap .pdf-icon i {
  font-size: 3.2rem;
  color: #fff;
  margin-top: 1.2rem;
  transition: all 0.4s ease 0s;
}

.what-we-do .pdf-btn .btn-wrap .btn-text {
  padding-left: 1.5rem;
  position: relative;
  padding-right: 1.5rem;
  color: #fff;
}

.what-we-do .pdf-btn .btn-wrap .btn-text:after {
  font-family: FontAwesome;
  content: "\f142";
  position: absolute;
  right: 0;
  font-size: 1.5rem;
  top: 1.2rem;
  color: #cad4db;
  display: block;
  transition: all 0.4s ease 0s;
}

.what-we-do .pdf-btn .btn-wrap .btn-text .pdf-title {
  margin-bottom: 0;
  font-size: 1.5rem;
  transition: all 0.4s ease 0s;
  font-weight: 600;
  color: #fff;
}

.what-we-do .pdf-btn .btn-wrap .btn-text p {
  margin-bottom: 0;
  font-size: 1.1rem;
  line-height: 1.5rem;
}

.what-we-do .pdf-btn .btn-wrap:hover {
  background: #ff2c54;
  -webkit-box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.15);
  color: #fff;
}

.what-we-do .pdf-btn .btn-wrap:hover .btn-text::after {
  color: #fff;
}

.what-we-do .pdf-btn .btn-wrap:hover .pdf-icon i {
  color: #fff;
}

.what-we-do .pdf-btn .btn-wrap:hover .pdf-title {
  color: #fff;
}

.what-we-do .section-title.title-style2 .title {
  margin-bottom: 2rem;
}

/*--------------------------------------
	# Work Process
---------------------------------------*/
.work-process-section .process-item {
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.07);
  padding: 7.5rem 4.1rem 5rem;
  margin-bottom: 3rem;
}

.work-process-section .process-item .icon {
  width: 11.2rem;
  height: 11rem;
  margin: 0 auto;
  position: relative;
}

.work-process-section .process-item .icon:after {
  content: "";
  position: absolute;
  right: -90%;
  top: 1.3rem;
  display: block;
  width: 11.4rem;
  height: 2.4rem;
  background: url(../../media/background/shape3.png) no-repeat center center;
  border-radius: 100%;
  z-index: 1;
}

.work-process-section .process-item .icon i {
  position: relative;
  z-index: 9;
}

.work-process-section .process-item .icon i:before {
  font-size: 4.5rem;
  padding: 3.2rem;
  background: #fff;
  color: #ff2c54;
  border-radius: 100%;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.02);
  transition: all 0.4s ease 0s;
  z-index: 9;
}

.work-process-section .process-item .item-title {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 2.2rem;
}

.work-process-section .process-item .item-title a {
  color: #333333;
}

.work-process-section .process-item .item-title a:hover {
  color: #ff2c54;
  text-decoration: none;
}

.work-process-section .process-item .item-title a:hover:before {
  color: #ff2c54;
}

.work-process-section .process-item p {
  margin-bottom: 0;
}

.work-process-section .process-item .process-item {
  margin-bottom: 3rem;
}

.work-process-section .process-item .process-item:hover .icon i:before {
  background: #ff2c54;
  color: #fff;
}

.work-process-section .process-item.process-one .icon i:before {
  color: #00ebac;
}

.work-process-section .process-item.process-two .icon:before {
  content: "";
  position: absolute;
  left: -88%;
  top: 6.8rem;
  display: block;
  width: 11.7rem;
  height: 2.4rem;
  background: url(../../media/background/shape4.png) no-repeat center center;
  border-radius: 100%;
  z-index: 1;
}

.work-process-section .process-item.process-three .icon i:before {
  color: #4f6def;
}

.work-process-section .process-item.process-three .icon:before {
  content: "";
  position: absolute;
  left: -90%;
  top: 6rem;
  display: block;
  width: 11.7rem;
  height: 2.4rem;
  background: url(../../media/background/shape4.png) no-repeat center center;
  border-radius: 100%;
  z-index: 1;
}

.work-process-section .process-item.process-three .icon:after {
  display: none;
}

.work-process-section .process-item:hover.process-one .icon i:before {
  background: #00ebac;
}

.work-process-section .process-item:hover.process-three .icon i:before {
  background: #4f6def;
}

.work-process-section .process-item:hover .icon i:before {
  color: #fff;
  background: #ff2c54;
}

/*--------------------------------------
	# WhY Choose Us
---------------------------------------*/
.why-us-section .feature-item {
  position: relative;
  overflow: hidden;
  margin-bottom: 3rem;
}

.why-us-section .feature-item img {
  transform: scale(1);
  transition: all 0.4s ease 0s;
}

.why-us-section .feature-item .item-desc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 1;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  color: #fff;
}

.why-us-section .feature-item .item-desc:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  display: block;
  left: 0;
  top: 0;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6) 30%, rgba(255, 255, 255, 0) 70%);
  background-image: -webkit-linear-gradient(to top, rgba(0, 0, 0, 0.6) 30%, rgba(255, 255, 255, 0) 70%);
  background-image: -moz-linear-gradient(to top, rgba(0, 0, 0, 0.6) 30%, rgba(255, 255, 255, 0) 70%);
  background-image: -ms-linear-gradient(to top, rgba(0, 0, 0, 0.6) 30%, rgba(255, 255, 255, 0) 70%);
  background-image: -o-linear-gradient(to top, rgba(0, 0, 0, 0.6) 30%, rgba(255, 255, 255, 0) 70%);
  transition: all 0.4s ease 0s;
}

.why-us-section .feature-item .item-desc .desc-wrap {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  padding: 2rem 4rem;
  width: 100%;
  height: auto;
  z-index: 9;
  overflow: hidden;
  transition: all 0.4s ease 0s;
}

.why-us-section .feature-item .item-desc .overlay-content {
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  padding: 2rem 4rem;
  width: 100%;
  height: auto;
  z-index: 9;
  overflow: hidden;
  transition: all 0.4s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.why-us-section .feature-item .item-desc .overlay-content .icon i {
  color: #fff;
}

.why-us-section .feature-item .item-desc .overlay-content .icon i:before {
  background: #ff2c54;
}

.why-us-section .feature-item .item-desc .icon {
  margin-top: 2rem;
}

.why-us-section .feature-item .item-desc .icon i {
  color: #ff2c54;
}

.why-us-section .feature-item .item-desc .icon i:before {
  font-size: 2.5rem;
  padding: 1.5rem;
  background: #fff;
  border-radius: 100%;
}

.why-us-section .feature-item .item-desc .item-title {
  color: #fff;
  font-weight: 600;
  font-size: 2.2rem;
  margin-top: 2.6rem;
  margin-bottom: 1.2rem;
  transition: all 0.4s ease 0s;
}

.why-us-section .feature-item:hover .item-desc .desc-wrap {
  opacity: 0;
  visibility: hidden;
}

.why-us-section .feature-item:hover .item-desc .overlay-content {
  top: 50%;
  opacity: 1;
  visibility: visible;
}

.why-us-section .feature-item:hover .item-desc:after {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7) 100%, rgba(255, 255, 255, 0) 70%);
  background-image: -webkit-linear-gradient(to top, rgba(0, 0, 0, 0.7) 100%, rgba(255, 255, 255, 0) 70%);
  background-image: -moz-linear-gradient(to top, rgba(0, 0, 0, 0.7) 100%, rgba(255, 255, 255, 0) 70%);
  background-image: -ms-linear-gradient(to top, rgba(0, 0, 0, 0.7) 100%, rgba(255, 255, 255, 0) 70%);
  background-image: -o-linear-gradient(to top, rgba(0, 0, 0, 0.7) 100%, rgba(255, 255, 255, 0) 70%);
}

.why-us-section .feature-item:hover img {
  transform: scale(1.1);
}

/*--------------------------------------
	# Services Style
---------------------------------------*/
.service-section {
  position: relative;
}

.service-section.service-layout3 {
  padding-bottom: 60px;
}

.service-item {
  box-shadow: 0px 8px 2.2rem rgba(0, 0, 0, 0.08);
  padding: 3.2rem 4.5rem 2.5rem 4.5rem;
  margin-bottom: 3rem;
}

.service-item .service-icon {
  margin-bottom: 1.8rem;
}

.service-item .service-icon i {
  color: #ff2c54;
  font-size: 4.5rem;
  transition: all 0.4s ease 0s;
  line-height: 4.5rem;
  height: 4.5rem;
}

.service-item .service-icon i:before {
  font-size: 4.5rem;
}

.service-item .feature-title {
  font-weight: 600;
  font-size: 2.2rem;
  margin-bottom: 1rem;
}

.service-item .feature-title a {
  color: #333333;
}

.service-item:hover .service-icon i {
  color: #a7a7aa;
}

.service-item:hover .feature-title a {
  color: #ff2c54;
}

.service-item2 {
  background: transparent;
  transition: all 0.4s ease 0s;
  padding: 6rem 2rem;
}

.service-item2 .service-icon {
  line-height: 7rem;
  height: 7rem;
  margin-bottom: 1.7rem;
}

.service-item2 .service-icon i {
  color: #ff2c54;
  transition: all 0.4s ease 0s;
  font-size: 70px;
}

.service-item2 .service-icon i:before {
  font-size: 7rem;
}

.service-item2 .service-title {
  font-weight: 600;
  font-size: 2.2rem;
  margin-bottom: 0.5rem;
}

.service-item2 .service-title a {
  color: #333333;
}

.service-item2 .service-title a:hover {
  color: #ff2c54;
}

.service-item2 .service-desc {
  font-size: 1.8rem;
  margin-bottom: 0;
}

.service-item2.item-one {
  border-bottom: 1px solid #cdcecf;
}

.service-item2.item-two {
  border: 1px solid #cdcecf;
  border-top: none;
}

.service-item2.item-two .service-icon i {
  color: #00ebac;
}

.service-item2.item-three {
  border-bottom: 1px solid #cdcecf;
}

.service-item2.item-three .service-icon i {
  color: #8053ca;
}

.service-item2.item-four .service-icon i {
  color: #ff930e;
}

.service-item2.item-five {
  border: 1px solid #cdcecf;
  border-top: none;
  border-bottom: none;
}

.service-item2.item-five .service-icon i {
  color: #4f6def;
}

.service-item2.item-six .service-icon i {
  color: #25de73;
}

.service-item2:hover {
  background: #fff;
  box-shadow: 0px 8px 2.2rem rgba(0, 0, 0, 0.06);
}

.service-layout4 .service-row {
  padding: 0 1.5rem;
}

.service-layout4.skew-wrap {
  padding-top: 14.5rem;
  padding-bottom: 14.5rem;
}

.service-layout5 .service-shape {
  position: absolute;
  width: 0;
  height: 0;
  background: url(../../media/background/service-shape.png) no-repeat center top;
  background-size: cover;
  right: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 2.5s ease-in-out;
  transition-delay: 1s;
  z-index: -1;
}

.service-layout5 .set-title-wrap {
  margin-bottom: 3rem;
}

.service-layout5 .set-title-wrap h5 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.service-layout5.active-animation .service-shape {
  width: 89.6rem;
  height: 73.3rem;
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 1660px) {
  .service-layout5.active-animation .service-shape {
    width: 60rem;
    height: 49.1rem;
  }
}

.service-layout5 .service-item {
  position: relative;
  padding: 3.5rem 5rem 5rem 5rem;
  min-height: 32rem;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 2.2rem rgba(0, 0, 0, 0.06);
  overflow: hidden;
  background: #fff;
}

.service-layout5 .service-item:after {
  position: absolute;
  content: "";
  display: block;
  background: rgba(255, 44, 84, 0.95);
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  left: 50%;
  top: 50%;
  transition: all 0.4s ease 0s;
  transform: translate(-50%, -50%);
}

.service-layout5 .service-item .service-number {
  font-size: 2.2rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0.7rem;
  position: relative;
  z-index: 9;
  transition: all 0.4s ease 0s;
  position: absolute;
  left: 5rem;
  top: 5rem;
}

.service-layout5 .service-item .service-title {
  font-size: 2.4rem;
  position: relative;
  z-index: 9;
  transition: all 0.4s ease 0s;
  margin-top: 5rem;
}

.service-layout5 .service-item .service-title a {
  color: #333333;
}

.service-layout5 .service-item .service-icon {
  margin-bottom: 0;
  position: absolute;
  left: 5rem;
  bottom: 5rem;
  z-index: 9;
}

.service-layout5 .service-item .service-icon i:before {
  font-size: 5rem;
  transition: all 0.4s ease 0s;
}

.service-layout5 .service-item .service-icon2 {
  margin-bottom: 0;
  position: absolute;
  left: auto;
  bottom: -0.8rem;
  right: 3.5rem;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
}

.service-layout5 .service-item .service-icon2 i:before {
  font-size: 18rem;
  color: #ffedf0;
  opacity: 0.1;
}

.service-layout5 .service-item .hidden-number {
  margin-bottom: 0;
  position: absolute;
  left: 5rem;
  bottom: 5rem;
  z-index: 9;
  font-size: 2.4rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  width: 100%;
  color: #fff;
  opacity: 0;
  display: block;
  visibility: hidden;
}

.service-layout5 .service-item .hidden-number a {
  color: #fff;
}

.service-layout5 .service-item .hidden-number:after {
  font-family: Flaticon;
  content: "\f130";
  position: absolute;
  left: auto;
  left: 3rem;
  font-size: 4.5rem;
  font-weight: normal;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
  display: block;
  cursor: pointer;
  color: #fff;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.service-layout5 .service-item .hidden-number i:before {
  font-size: 5rem;
}

.service-layout5 .service-item:hover .service-number {
  top: 0;
  opacity: 0;
}

.service-layout5 .service-item:hover .service-icon {
  opacity: 0;
  visibility: hidden;
}

.service-layout5 .service-item:hover .service-icon2 {
  opacity: 1;
  visibility: visible;
}

.service-layout5 .service-item:hover .hidden-number {
  opacity: 1;
  visibility: visible;
}

.service-layout5 .service-item:hover .hidden-number:after {
  left: 4rem;
}

.service-layout5 .service-item:hover .service-title {
  margin-top: 1.6rem;
}

.service-layout5 .service-item:hover .service-title a {
  color: #fff;
}

.service-layout5 .service-item:hover:after {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.service-details-section .details-text {
  max-width: 55rem;
}

.service-details-section .who_we_are .description {
  padding-right: 10.5rem;
}

.service-details-section .item-icon {
  margin-bottom: 1.2rem;
}

.service-details-section .item-icon i {
  line-height: 3.8rem;
  color: #ff2c54;
}

.service-details-section .item-icon i:before {
  font-size: 3.8rem;
}

.service-details-section .item-icon.icon-item2 i {
  color: #4f6def;
}

.service-details-section .service-feature h4 {
  margin-bottom: 0.7rem;
}

.skill-bar-wrapper .skillbar-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2rem;
  color: #333333;
}

.skill-bar-wrapper .skill-item {
  padding: 0.1rem;
  border: 0.1rem solid #dfdfdf;
  height: 1.4rem;
  margin-top: 0.5rem;
  margin-bottom: 5.5rem;
}

.skill-bar-wrapper .skill-item:last-child {
  margin-bottom: 0;
}

.skill-bar-wrapper .skillbar {
  position: relative;
  display: inline-block;
  margin: 0;
  background: transparent;
  height: 0.8rem;
  width: 100%;
}

.skill-bar-wrapper .skillbar .skillbar-title {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 700;
  font-size: 12px;
  color: #fff;
  height: 0.8rem;
  z-index: 9;
  background: transparent;
  min-width: 150px;
}

.skill-bar-wrapper .skillbar .skillbar-bar {
  height: 0.8rem;
  width: 0;
  background: #ff2c54;
  display: inline-block;
  margin: 0;
  position: relative;
  top: -1rem;
  left: 0.1rem;
}

.skill-bar-wrapper .skillbar .skill-bar-percent {
  position: absolute;
  right: -2.5rem;
  top: -3.7rem;
  font-weight: 600;
  font-size: 1.8rem;
  color: #333333;
  font-family: "Poppins", sans-serif;
}

.details-feature-section .max-width {
  max-width: 66.7rem;
  margin-right: 0;
  margin-left: auto;
}

.details-feature-section .feature-item .feature-list {
  width: 6rem;
  height: 6rem;
  line-height: 6rem;
  text-align: center;
  float: left;
  border: 0.1rem solid #faccd5;
  border-radius: 100%;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #333333;
  font-size: 2.2rem;
  margin-top: 1rem;
  transition: all 0.4s ease 0s;
}

.details-feature-section .feature-item .short-desc {
  float: left;
  width: calc(100% - 6rem);
  padding-left: 2rem;
}

.details-feature-section .feature-item .feature-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #333333;
  font-size: 2rem;
}

.details-feature-section .feature-item:hover .feature-list {
  border-color: #ff2c54;
  background: #ff2c54;
  color: #fff;
}

/*--------------------------------------
	# Skill Style
---------------------------------------*/
.skill-section {
  padding-bottom: 8.6rem;
}

.skill-section .section-title {
  max-width: 45rem;
}

.skill-section .section-title .title {
  margin-bottom: 2rem;
}

.skill-section .skill-area {
  max-width: 55.4rem;
  margin-left: auto;
  margin-right: 0;
}

.skill-section .skill-type {
  padding-top: 1.6rem;
}

.skill-section .skill-type .skill-item .skill-icon {
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.09);
  background: #fff;
  border-radius: 100%;
  width: 8.5rem;
  height: 8.5rem;
  line-height: 8.5rem;
  text-align: center;
  float: left;
  transition: all 0.4s ease 0s;
  margin-top: 1rem;
}

.skill-section .skill-type .skill-item .skill-icon i:before {
  font-size: 3.5rem;
}

.skill-section .skill-type .skill-item .skill-icon.icon-one {
  color: #00ebac;
}

.skill-section .skill-type .skill-item .skill-icon.icon-two {
  color: #ff2c54;
}

.skill-section .skill-type .skill-item .skill-icon.icon-three {
  color: #4f6def;
}

.skill-section .skill-type .skill-item .short-desc {
  float: left;
  width: calc(100% - 8.5rem);
  padding-left: 2rem;
  padding-bottom: .4rem;
  padding-right: 1rem;
}

.skill-section .skill-type .skill-item .short-desc .skill-title {
  font-size: 2.2rem;
  font-weight: 600;
  color: #333333;
  font-family: "Poppins", sans-serif;
  margin-bottom: .8rem;
}

.skill-section .skill-type .skill-item:hover .skill-icon {
  background: #ff2c54;
  color: #fff;
}

.skill-section .skill-type .skill-item:hover .skill-icon.icon-one {
  background: #00ebac;
  color: #fff;
}

.skill-section .skill-type .skill-item:hover .skill-icon.icon-two {
  background: #ff2c54;
  color: #fff;
}

.skill-section .skill-type .skill-item:hover .skill-icon.icon-three {
  background: #4f6def;
  color: #fff;
}

/*--------------------------------------
	# Mission Style
---------------------------------------*/
.mission-section {
  padding-top: 11rem;
  padding-bottom: 46px;
  overflow: hidden;
}

.mission-section .section-title.title-style2 .title {
  margin-bottom: 0.6rem;
}

.mission-section .mission-img {
  text-align: right;
}

.mission-section .mission-area {
  max-width: 54.5rem;
}

.mission-section .mission-type {
  padding-top: 1.6rem;
}

.mission-section .mission-type .mission-item .mission-icon {
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.08);
  background: #fff;
  border-radius: 100%;
  width: 7rem;
  height: 7rem;
  line-height: 7rem;
  text-align: center;
  float: left;
  transition: all 0.4s ease 0s;
  margin-top: 1rem;
}

.mission-section .mission-type .mission-item .mission-icon i {
  transition: all 0.4s ease 0s;
}

.mission-section .mission-type .mission-item .mission-icon i:before {
  font-size: 3.5rem;
}

.mission-section .mission-type .mission-item .short-desc {
  float: left;
  width: calc(100% - 7rem);
  padding-left: 2.5rem;
}

.mission-section .mission-type .mission-item .short-desc .mission-title {
  font-size: 2.2rem;
  font-weight: 600;
  color: #333333;
  font-family: "Poppins", sans-serif;
  margin-bottom: .4rem;
  font-style: italic;
}

.mission-section .mission-type .mission-item .short-desc p {
  margin-bottom: 2rem;
}

.mission-section .mission-type .mission-item.item-one .mission-icon i {
  color: #00ebac;
}

.mission-section .mission-type .mission-item.item-two .mission-icon i {
  color: #ff930e;
}

.mission-section .mission-type .mission-item.item-three .mission-icon i {
  color: #4f6def;
}

.mission-section .mission-type .mission-item:hover.item-one .mission-icon {
  background: #00ebac;
}

.mission-section .mission-type .mission-item:hover.item-two .mission-icon {
  background: #ff930e;
}

.mission-section .mission-type .mission-item:hover.item-three .mission-icon {
  background: #4f6def;
}

.mission-section .mission-type .mission-item:hover .mission-icon {
  background: #ff2c54;
  color: #fff;
}

.mission-section .mission-type .mission-item:hover .mission-icon i {
  color: #fff;
}

/*--------------------------------------
	# Chatbox Style
---------------------------------------*/
.chatbox-section .section-title.title-style2 .title {
  margin-bottom: 1.5rem;
}

.chatbox-section .chatbox-area .style-check li {
  padding-left: 2.8rem;
}

.chatbox-section .chatbox-area .style-check li:before {
  font-size: 1.8rem;
  font-weight: 600;
}

/*--------------------------------------

/*--------------------------------------
	# 404 Style
---------------------------------------*/
.error-section .item-figure.up-down-animate {
  position: relative;
  top: -50px;
}

.error-section .error-img {
  background: url(../../media/other/shape.png) no-repeat center top;
  width: 68.4rem;
  height: 43.7rem;
  margin: 0 auto;
  background-size: cover;
}

.error-section .error-content-box {
  margin: 0 auto;
  margin-top: 6rem;
}

.error-section .error-content-box h1 {
  font-weight: 700;
  font-size: 3.6rem;
  margin-bottom: 1.7rem;
}

.error-section .error-content-box p {
  padding: 0 5rem;
  color: #333333;
  font-weight: 500;
}

.error-section .error-content-box .secondary-btn {
  color: #333333;
  margin-top: 2.9rem;
}

.error-section .error-content-box .secondary-btn:hover {
  color: #fff;
}

/*--------------------------------------

/*--------------------------------------
	# Experience Style
---------------------------------------*/
.experience-section {
  padding-top: 10.8rem;
}

.experience-section .section-title.title-style2 .title {
  margin-bottom: 4.5rem;
}

.experience-section .experience-wrap {
  max-width: 57rem;
}

.experience-section .experience-area h3 {
  font-weight: 500;
  margin-bottom: 0.4rem;
  font-size: 2.2rem;
}

.experience-section .experience-area .box .chart {
  margin-bottom: 1.5rem;
}

.experience-area .box {
  width: 10rem;
}

.experience-area .box h4 {
  display: block;
  text-align: center;
  font-weight: 2.2rem;
}

.experience-area .box .chart {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 10rem;
  height: 10rem;
  color: #333333;
}

.experience-area .box canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  width: 100%;
}

/*--------------------------------------
	# Testimonial Style
---------------------------------------*/
.testimonial-section {
  position: relative;
  padding-bottom: 5.5rem;
}

.testimonial-section.style1 {
  padding-bottom: 8.5rem;
}

.testimonial-section:after {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 48.8rem;
  background: #f9f9f9;
}

.testimonial-section .container {
  z-index: 9;
  position: relative;
}

.testimonial-section .testimonial-wrap {
  padding: 3rem 4.5rem 6rem;
  width: 100%;
  background: #fff;
  border-top: none;
  border-radius: 0 0 .5rem .5rem;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.08);
  transition: all 0.4s ease 0s;
  border-radius: .6rem;
  margin-bottom: 3rem;
}

.testimonial-section .testimonial-wrap .quote-icon i {
  color: #ff2c54;
}

.testimonial-section .testimonial-wrap .quote-icon i:before {
  font-size: 6rem;
}

.testimonial-section .testimonial-wrap .author-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: .4rem;
}

.testimonial-section .testimonial-wrap .author-info .avatar {
  width: 4.5rem;
  height: 4.5rem;
  background: #ff2c54;
  text-align: center;
  border-radius: 100%;
  overflow: hidden;
}

.testimonial-section .testimonial-wrap .author-info .avatar img {
  max-width: 100%;
  width: auto;
  margin: 0 auto;
  margin-top: .6rem;
}

.testimonial-section .testimonial-wrap .author-info .name-info {
  text-align: left;
  padding-left: 1.2rem;
}

.testimonial-section .testimonial-wrap .author-info .name-info .author {
  margin-bottom: 0;
  font-size: 1.6rem;
  line-height: 2.2rem;
}

.testimonial-section .testimonial-wrap .author-info .name-info .designation {
  line-height: 2rem;
  font-size: 1.4rem;
  color: #333333;
}

.testimonial-section .testimonial-wrap.color-plate1 .quote-icon i {
  color: #00ebac;
}

.testimonial-section .testimonial-wrap.color-plate1 .author-info .avatar {
  background: #00ebac;
}

.testimonial-section .testimonial-wrap.color-plate1 .author-info .avatar img {
  margin-top: 8px;
}

.testimonial-section .testimonial-wrap.color-plate2 .quote-icon i {
  color: #4e6cef;
}

.testimonial-section .testimonial-wrap.color-plate2 .author-info .avatar {
  background: #4e6cef;
}

.testimonial-section .testimonial-wrap.color-plate2 .author-info .avatar img {
  margin-top: 8px;
}

.testimonial-section .testimonial-wrap:hover {
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.13);
}

.testimonial-section .testimonial-carousel {
  position: relative;
}

.testimonial-section.testimonial-style2:after {
  display: none;
}

.testimonial-section.testimonial-style2 .owl-item {
  padding-top: .6rem;
  padding-bottom: 0;
}

.testimonial-section.testimonial-style2 .testimonial-carousel {
  margin-top: 0.3rem;
}

.testimonial-section.testimonial-style2 .testimonial-wrap {
  padding: 5.5rem 4rem 3.8rem;
  width: 100%;
  background: #fff;
  border-top: none;
  border-radius: 0 0 .5rem .5rem;
  box-shadow: 0 8px 3.2rem rgba(0, 0, 0, 0.03);
  transition: all 0.4s ease 0s;
  border-radius: .6rem;
  margin-bottom: 3rem;
}

.testimonial-section.testimonial-style2 .testimonial-wrap .quote-icon i {
  color: #ff2c54;
}

.testimonial-section.testimonial-style2 .testimonial-wrap .quote-icon i:before {
  font-size: 6rem;
}

.testimonial-section.testimonial-style2 .testimonial-wrap .author-info {
  justify-content: left;
  padding-top: 0;
  padding-bottom: 2rem;
}

.testimonial-section.testimonial-style2 .testimonial-wrap .author-info .author-img img {
  border-radius: 100%;
}

.testimonial-section.testimonial-style2 .testimonial-wrap .author-info .name-info .author {
  font-size: 1.8rem;
  line-height: 2.7rem;
  font-weight: 700;
  color: #333333;
}

.testimonial-section.testimonial-style2 .testimonial-wrap .author-info .name-info .designation {
  color: #666666;
  font-size: 1.5rem;
}

.testimonial-section.testimonial-style2 .testimonial-wrap p {
  position: relative;
  z-index: 1;
}

.testimonial-section.testimonial-style2 .testimonial-wrap p:after {
  font-family: Flaticon;
  content: "\f108";
  position: absolute;
  left: auto;
  right: 1rem;
  font-size: 10rem;
  bottom: 1rem;
  color: #f3f3f3;
  z-index: -1;
}

.testimonial-section.testimonial-style2 #testiNav {
  padding-top: 0.5rem;
}

.testimonial-section.testimonial-style2 #testiNav .owl-prev,
.testimonial-section.testimonial-style2 #testiNav .owl-next {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #6e6e6f;
  padding: 0;
}

.testimonial-section.testimonial-style2 #testiNav .owl-prev i:before,
.testimonial-section.testimonial-style2 #testiNav .owl-next i:before {
  font-size: 3rem;
}

.testimonial-section.testimonial-style2 #testiNav .owl-prev.owl-prev,
.testimonial-section.testimonial-style2 #testiNav .owl-next.owl-prev {
  padding-left: 1rem;
  transform: rotate(180deg);
}

.testimonial-section.testimonial-style2 #testiNav .owl-prev.owl-next,
.testimonial-section.testimonial-style2 #testiNav .owl-next.owl-next {
  padding-left: 1rem;
  position: relative;
  top: 0.1rem;
}

.testimonial-section.testimonial-style2 #testiNav .owl-prev:hover,
.testimonial-section.testimonial-style2 #testiNav .owl-next:hover {
  color: #ff2c54;
}

.testimonial-section.testimonial-style3 {
  background: url(../../media/background/testimonial-bg.jpg) no-repeat center center;
  background-size: cover;
}

.testimonial-section.testimonial-style3:after {
  display: none;
}

.testimonial-section.testimonial-style3 .section-title .title {
  color: #fff;
}

.testimonial-section.testimonial-style3 .owl-item {
  padding-top: 0;
}

.testimonial-section.testimonial-style3 #testiNav {
  max-width: 57rem;
  margin: 0 auto;
  position: relative;
  z-index: 99;
}

.testimonial-section.testimonial-style3 #testiNav .owl-prev,
.testimonial-section.testimonial-style3 #testiNav .owl-next {
  background: transparent;
  border: none;
  color: #fff;
  outline: none;
  cursor: pointer;
  font-size: 5rem;
}

.testimonial-section.testimonial-style3 #testiNav .owl-prev {
  position: absolute;
  left: 0;
  top: 0;
}

.testimonial-section.testimonial-style3 #testiNav .owl-next {
  position: absolute;
  right: 0;
  top: 0;
}

.testimonial-section.testimonial-style3 .testimonial-item {
  text-align: center;
  color: #fff;
  max-width: 77rem;
  margin: 0 auto;
}

.testimonial-section.testimonial-style3 .testimonial-item .author-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 4.5rem;
}

.testimonial-section.testimonial-style3 .testimonial-item .author-info .author-img img {
  border-radius: 100%;
}

.testimonial-section.testimonial-style3 .testimonial-item .author-info .name-info {
  text-align: left;
  padding-left: 2rem;
}

.testimonial-section.testimonial-style3 .testimonial-item .author-info .name-info .author {
  font-size: 2.4rem;
  font-weight: 700;
}

.testimonial-section.testimonial-style3 .testimonial-item .author-info .name-info .designation {
  font-size: 1.8rem;
  color: #dad9d9;
}

.testimonial-section.testimonial-style3 .testimonial-item p {
  font-size: 2.4rem;
  font-style: italic;
  line-height: 4rem;
  letter-spacing: 0.1rem;
  margin-bottom: 0;
}

.testimonial-section.testimonial-layout4 .section-title.title-style2 .title {
  margin-bottom: 4.5rem;
}

.testimonial-section.testimonial-layout4:after {
  display: none;
}

.testimonial-section.testimonial-style5:after {
  display: none;
}

.testimonial-section.testimonial-style5 .owl-item {
  padding-top: 0;
  padding-bottom: 0;
}

.testimonial-section.testimonial-style5 #testiNav {
  max-width: 57rem;
  margin: 0 auto;
  position: relative;
  z-index: 99;
}

.testimonial-section.testimonial-style5 #testiNav .owl-prev,
.testimonial-section.testimonial-style5 #testiNav .owl-next {
  background: transparent;
  border: none;
  color: #d6d6d6;
  outline: none;
  cursor: pointer;
  font-size: 5rem;
}

.testimonial-section.testimonial-style5 #testiNav .owl-prev {
  position: absolute;
  left: 0;
  top: 0;
}

.testimonial-section.testimonial-style5 #testiNav .owl-next {
  position: absolute;
  right: 0;
  top: 0;
}

.testimonial-section.testimonial-style5 .testimonial-item {
  text-align: center;
  max-width: 89rem;
  margin: 0 auto;
}

.testimonial-section.testimonial-style5 .testimonial-item .author-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 2.7rem;
}

.testimonial-section.testimonial-style5 .testimonial-item .author-info .author-img img {
  border-radius: 100%;
}

.testimonial-section.testimonial-style5 .testimonial-item .author-info .name-info {
  text-align: left;
  padding-left: 3rem;
}

.testimonial-section.testimonial-style5 .testimonial-item .author-info .name-info .author {
  font-size: 2.4rem;
  font-weight: 700;
  color: #333333;
}

.testimonial-section.testimonial-style5 .testimonial-item .author-info .name-info .designation {
  font-size: 1.5rem;
  color: #333333;
  text-transform: uppercase;
  font-weight: 50;
}

.testimonial-section.testimonial-style5 .testimonial-item p {
  font-size: 2.4rem;
  font-style: italic;
  line-height: 4.5rem;
  letter-spacing: 0.1rem;
}

.testimonial-section.testimonial-style5 .testimonial-item .quote-icon {
  margin-top: 2.6rem;
  height: 9rem;
}

.testimonial-section.testimonial-style5 .testimonial-item .quote-icon i {
  height: 11rem;
  line-height: 11rem;
  color: #f5f5f5;
}

.testimonial-section.testimonial-style5 .testimonial-item .quote-icon i:before {
  font-size: 11rem;
}

.testimonial-section.testimonial-style6:after {
  display: none;
}

.testimonial-section.testimonial-style6 .testimonial-item {
  padding: 4rem 3rem 3.2rem;
  width: 100%;
  background: #fff;
  border-top: none;
  border-radius: 0 0 .5rem .5rem;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.07);
  transition: all 0.4s ease 0s;
  border-radius: .6rem;
  margin-bottom: 3rem;
}

.testimonial-section.testimonial-style6 .testimonial-item .testi-inner .avatar {
  width: 6.2rem;
  height: 6.2rem;
  background: #ff2c54;
  text-align: center;
  border-radius: 100%;
  overflow: hidden;
  float: left;
}

.testimonial-section.testimonial-style6 .testimonial-item .testi-inner .avatar.color-plate2 {
  background: #00ebac;
}

.testimonial-section.testimonial-style6 .testimonial-item .testi-inner .avatar.color-plate3 {
  background: #4e6cef;
}

.testimonial-section.testimonial-style6 .testimonial-item .testi-inner .avatar img {
  max-width: 100%;
  margin: 0 auto;
  margin-top: 1rem;
}

.testimonial-section.testimonial-style6 .testimonial-item .testi-inner .testi-desc {
  text-align: left;
  float: left;
  padding-left: 2.6rem;
  width: calc(100% - 6.2rem);
}

.testimonial-section.testimonial-style6 .testimonial-item .testi-inner .testi-desc .author {
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: 600;
}

.testimonial-section.testimonial-style6 .testimonial-item .testi-inner .testi-desc .designation {
  line-height: 2rem;
  font-size: 1.6rem;
  color: #333333;
  margin-bottom: 1.5rem;
}

.testimonial-section.testimonial-style6 .testimonial-item .testi-inner .testi-desc p {
  margin-bottom: 2rem;
}

.testimonial-section.testimonial-style6 .testimonial-item .testi-inner .author-rating {
  text-align: left;
}

.testimonial-section.testimonial-style6 .testimonial-item .testi-inner .author-rating i {
  color: #ffab18;
  margin-right: 1.2rem;
  font-size: 1.7rem;
}

.testimonial-section.testimonial-style6 .testimonial-item:hover {
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.15);
}

.nav-testi.style-arrow {
  position: relative;
  bottom: 8.5rem;
}

.nav-testi.style-arrow #testiNav {
  text-align: right;
}

.nav-testi.style-arrow #testiNav .owl-prev,
.nav-testi.style-arrow #testiNav .owl-next {
  position: static;
  border: none;
  opacity: 1;
  visibility: visible;
  cursor: pointer;
}

.nav-testi.style-arrow #testiNav .owl-prev i,
.nav-testi.style-arrow #testiNav .owl-next i {
  width: 5rem;
  height: 5rem;
  line-height: 4.8rem;
  color: #666;
}

.nav-testi.style-arrow #testiNav .owl-prev i:hover,
.nav-testi.style-arrow #testiNav .owl-next i:hover {
  color: #fff;
}

@supports (-moz-appearance: none) {
  .testimonial-section.testimonial-style2 #testiNav .owl-next.owl-next {
    top: -0.1rem;
  }
}

/*--------------------------------------
	# Project Style
---------------------------------------*/
.portfolio-section {
  padding-bottom: 9rem;
}

.portfolio-section .loarmore {
  margin-top: 6rem;
}

.portfolio-section .sec-desc {
  margin-bottom: 5rem;
}

.portfolio-section .portfolio-filter {
  margin-bottom: 4.6rem;
}

.portfolio-section .portfolio-filter button {
  background: none;
  border: none;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #333333;
  font-size: 1.8rem;
  outline: none;
  cursor: pointer;
  margin-right: 3rem;
  transition: all 0.4s ease 0s;
}

.portfolio-section .portfolio-filter button.active, .portfolio-section .portfolio-filter button:hover {
  color: #ff2c54;
}

.portfolio-section .portfolio-filter button:last-child {
  margin-right: 0;
}

.portfolio-section .project-content {
  position: relative;
}

.portfolio-section .project-content .project-info {
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  z-index: 99;
  visibility: hidden;
  transition: all 0.4s ease 0s;
  opacity: 0;
  width: 95%;
}

.portfolio-section .project-content .project-info .project-title {
  font-size: 2.2rem;
  line-height: 2.9rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
  text-transform: uppercase;
}

.portfolio-section .project-content .project-info .project-title a {
  color: #fff;
}

.portfolio-section .project-content .project-info .project-title a:hover {
  color: #ff2c54;
}

.portfolio-section .project-content:after {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease 0s;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.portfolio-section .project-content:hover .project-info {
  visibility: visible;
  opacity: 1;
  top: 50%;
}

.portfolio-section .project-content:hover:after {
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
}

.portfolio-section.landing-project {
  overflow: hidden;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

@media only screen and (max-width: 1660px) {
  .portfolio-section.landing-project {
    padding-left: 0;
    padding-right: 0;
  }
}

.portfolio-section.landing-project .project-link {
  position: absolute;
  left: 50%;
  right: auto;
  top: 30%;
  transform: translateX(-50%);
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease 0s;
}

.portfolio-section.landing-project .project-link a {
  background: #fff;
  color: #333333;
  height: 4.5rem;
  width: 4.5rem;
  font-size: 2rem;
  display: block;
  text-align: center;
  line-height: 4.8rem;
  border-radius: 100%;
  z-index: 999;
}

.portfolio-section.landing-project .project-link a:hover {
  opacity: 0.8;
}

.portfolio-section.landing-project .grid-item {
  padding: 0 2.5rem;
  margin-bottom: 5rem;
}

@media only screen and (max-width: 1660px) {
  .portfolio-section.landing-project .grid-item {
    padding: 0 1.5rem;
    margin-bottom: 3rem;
  }
}

.portfolio-section.landing-project .grid-item .project-content img {
  width: 100%;
}

.portfolio-section.landing-project .section-title {
  padding-bottom: 2.5rem;
  max-width: 67rem;
  margin: 0 auto;
}

@media only screen and (max-width: 1440px) {
  .portfolio-section.landing-project .section-title {
    padding-left: 0;
    max-width: 56rem;
  }
}

.portfolio-section.landing-project .project-content .project-info {
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  top: auto;
  text-align: center;
}

.portfolio-section.landing-project .project-content .project-info .project-title {
  margin-bottom: 0.5rem;
}

.portfolio-section.landing-project .project-content .project-info .project-title a {
  color: #fff;
}

.portfolio-section.landing-project .project-content .project-info .project-title a:hover {
  color: #f0f0f0;
}

.portfolio-section.landing-project .project-content .overlay-link {
  position: relative;
  display: block;
}

.portfolio-section.landing-project .project-content .overlay-link:after {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease 0s;
  border-radius: 0.6rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.portfolio-section.landing-project .project-content:after {
  background: rgba(255, 44, 84, 0.9);
  display: none;
}

.portfolio-section.landing-project .project-content:hover .project-info {
  left: 50%;
  transform: translateX(-50%);
  bottom: 5rem;
  top: auto;
}

.portfolio-section.landing-project .project-content:hover .overlay-link:after {
  visibility: visible;
  opacity: 1;
}

.portfolio-section.landing-project .project-content:hover:after {
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
}

.portfolio-section.landing-project .project-content:hover .project-link {
  opacity: 1;
  top: 50%;
  transform: translate(-50%, -50%);
  visibility: visible;
}

.portfolio-section.landing-project .loarmore {
  margin-top: 3rem;
}

.portfolio-section.portfolio-layout2 .project-content {
  cursor: pointer;
}

.portfolio-section.portfolio-layout2 .project-content img {
  -webkit-filter: blur(0);
  filter: blur(0);
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
  border-radius: 0.4rem;
}

.portfolio-section.portfolio-layout2 .project-content:hover {
  cursor: pointer;
}

.portfolio-section.portfolio-layout2 .project-content:hover img {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.portfolio-section.portfolio-layout2 .project-content:after {
  background: rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
  border-radius: 0.4rem;
}

.portfolio-section.portfolio-layout2 .project-content .project-info {
  top: 50%;
}

.portfolio-section.portfolio-layout2 .project-content .project-info .project-tags {
  font-size: 1.6rem;
  font-weight: 400;
}

.portfolio-section.portfolio-layout2 .nav-list {
  position: relative;
}

.portfolio-section.portfolio-layout2 .nav-list #ProjectNav {
  position: absolute;
  right: 0;
  top: -10rem;
}

.portfolio-section.portfolio-layout2 .nav-list #ProjectNav .owl-prev,
.portfolio-section.portfolio-layout2 .nav-list #ProjectNav .owl-next {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 2.5rem;
  color: #a1a3a9;
  padding: 0;
  transition: all 0.4s ease 0s;
}

.portfolio-section.portfolio-layout2 .nav-list #ProjectNav .owl-prev:hover,
.portfolio-section.portfolio-layout2 .nav-list #ProjectNav .owl-next:hover {
  color: #ff2c54;
}

.portfolio-section.portfolio-layout2 .nav-list #ProjectNav .owl-prev i:before,
.portfolio-section.portfolio-layout2 .nav-list #ProjectNav .owl-next i:before {
  font-size: 3.3rem;
}

.portfolio-section.portfolio-layout2 .nav-list #ProjectNav .owl-prev {
  transform: rotate(180deg);
  position: relative;
  margin-right: 1.5rem;
}

.portfolio-section.portfolio-layout3 .project-content img {
  border-radius: 0.5rem;
}

.portfolio-section.portfolio-layout3 .project-content:after {
  border-radius: 0.5rem;
}

.portfolio-section.portfolio-layout3 .loarmore {
  margin-top: 3rem;
}

.portfolio-details-section .project-info {
  background: #f5f7ff;
  padding: 1rem 3rem 1rem 4.5rem;
  height: 100%;
}

.portfolio-details-section .project-info .item-heading {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 2.8rem;
}

.portfolio-details-section .project-info li {
  line-height: 3.3rem;
  margin-bottom: 1.2rem;
}

.portfolio-details-section .project-info .feature-title {
  font-size: 1.8rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #333333;
}

.portfolio-details-section .project-desc h4 {
  font-weight: 600;
  font-size: 2.2rem;
  margin-bottom: 0.6rem;
}

#blog-pages.portfolio-section.landing-project.demo-landing-pages .project-link a,
#inner-pages.portfolio-section.landing-project.demo-landing-pages .project-link a {
  min-width: 16rem;
  padding-left: 2rem;
  font-size: 1.4rem;
  padding-right: 2rem;
}

/*--------------------------------------
	# Counter Style
---------------------------------------*/
.counter-section {
  background: linear-gradient(60deg, #3069f3, #122abe);
  background-size: cover;
  position: relative;
  color: #fff;
}

.counter-section .left-shape {
  position: absolute;
  width: 38.5rem;
  height: 7.8rem;
  background: url(../../media/shape/client-left.png) no-repeat center top;
  background-size: cover;
  left: 0;
  top: 40%;
  transform: translateY(-60%);
  transition: all 2s ease-in-out;
  transition-delay: 1s;
}

@media only screen and (max-width: 480px) {
  .counter-section .left-shape {
    width: 25rem;
    height: 5.1rem;
  }
}

.counter-section .right-shape {
  position: absolute;
  width: 26.7rem;
  height: 7.2rem;
  background: url(../../media/shape/client-right.png) no-repeat center top;
  background-size: cover;
  right: 9.5rem;
  top: 60%;
  transform: translateY(-40%);
  transition: all 2s ease-in-out;
  transition-delay: 1s;
}

.counter-section.counter-landing {
  background: url(../../media/background/landing-counter.jpg) no-repeat center top;
  background-size: cover;
}

.counter-section .section-title.center-style p {
  margin-bottom: 4rem;
}

.counter-section .section-title .title {
  color: #fff;
}

.counter-section .count-number {
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 5rem;
}

.counter-section .count-title {
  font-size: 1.8rem;
  font-weight: 600;
}

.counter-section.counter-style2 {
  background: url(../../media/background/map.png) no-repeat center top 30px, linear-gradient(135deg, #3069f3, #122abe);
}

.counter-section.layout4 {
  background: url(../../media/background/counter_bg.jpg) no-repeat center center;
  background-size: cover;
}

.counter-area {
  padding-top: 108px;
}

.counter-area .counter-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.counter-area .counter-wrap .counter-icon {
  height: 90px;
  line-height: 90px;
}

.counter-area .counter-wrap .counter-icon i {
  color: #e1e1e1;
}

.counter-area .counter-wrap .counter-icon i:before {
  font-size: 9rem;
}

.counter-area .counter-wrap .counter-text {
  padding-left: 2.5rem;
}

.counter-area .counter-wrap .counter-text .count-number {
  font-size: 6rem;
  font-weight: 600;
  color: #ff2c54;
  font-family: "Poppins", sans-serif;
  line-height: 7rem;
}

.counter-area .counter-wrap .counter-text .count-title {
  font-size: 2.2rem;
  font-weight: 600;
  color: #333333;
  font-family: "Poppins", sans-serif;
}

.section-counter-area .counter-bg {
  background: url(../../media/background/counter-bg2.jpg) no-repeat center center;
  background-size: cover;
  height: 40rem;
}

.section-counter-area .counter-wrap {
  box-shadow: 0 0 6.2rem rgba(0, 0, 0, 0.09);
  padding: 8rem 2rem 7.8rem;
  background: #fff;
  border-radius: 0.5rem;
}

.section-counter-area .counter-wrap .counter-icon {
  height: 9rem;
  line-height: 9rem;
  margin-bottom: 3rem;
}

.section-counter-area .counter-wrap .counter-icon i {
  color: #ff2c54;
}

.section-counter-area .counter-wrap .counter-icon i:before {
  font-size: 8rem;
}

.section-counter-area .counter-wrap .counter-text .count-number {
  font-size: 5rem;
  font-weight: 600;
  color: #333333;
  font-family: "Poppins", sans-serif;
  line-height: 6.8rem;
}

.section-counter-area .counter-wrap .counter-text .count-title {
  font-size: 2.2rem;
  font-weight: 600;
  color: #333333;
}

.counter-wrap.text-center.aos-init.aos-animate {
  margin-top: 22px;
}

/*--------------------------------------
	# Blog Style
---------------------------------------*/
.blog-section {
  padding-bottom: 5rem;
}

.blog-section .meta-item {
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
}

.blog-section.blog-grid .blog-item {
  padding-bottom: 4rem;
}

.blog-section.blog-grid .blog-item .entry-meta {
  margin-bottom: 2rem;
  font-size: 1.4rem;
}

.blog-section.blog-grid .blog-item .entry-meta li {
  display: inline-block;
  margin-right: 2.5rem;
}

.blog-section.blog-grid .blog-item .entry-meta li .post-author {
  font-size: 1.4rem;
  color: #666666;
  font-weight: normal;
}

.blog-section.blog-grid .blog-item .entry-meta li .post-author img {
  border-radius: 100%;
  max-width: 2.7rem;
  margin-right: 0.4rem;
}

.blog-section.blog-grid .blog-item .entry-meta li:last-child {
  margin-right: 0;
}

.blog-section.blog-grid .blog-item .entry-meta li i {
  margin-right: 0.7rem;
}

.blog-section.blog-masonry .blog-item {
  padding-bottom: 2.4rem;
}

.blog-section.blog-masonry ul.pagination-layout1 {
  margin-top: 2.8rem;
  align-items: center;
  justify-content: center;
}

.blog-section.blog-style4.blog-list .blog-item .blog-details {
  padding: 3rem 3rem;
  border: 0.1rem solid #e8e8e8;
  border-top: none;
  margin-top: 0;
}

.blog-section.blog-style4.blog-list .blog-item .blog-details .post-author {
  font-size: 2rem;
}

.blog-section.blog-style4.blog-list .blog-item .blog-details .date {
  font-size: 1.4rem;
  line-height: 2.4rem;
}

.blog-section.blog-style4.blog-list .blog-item .blog-details .blog-title {
  font-size: 2.4rem;
  margin-bottom: 1.2rem;
}

.blog-section.blog-style4.blog-list .blog-item .blog-details .read-more {
  font-size: 1.8rem;
}

.blog-section.blog-style4.blog-list .blog-item .blog-details .read-more:before {
  top: 0;
}

.blog-section.blog-style4.blog-list .blog-item .blog-details .short-desc {
  margin-bottom: 0.5rem;
}

.blog-section.blog-style4.blog-list .pagination-layout1 {
  margin-top: 2.8rem;
}

.blog-section .blog-item {
  padding-bottom: 3rem;
}

.blog-section .blog-item .blog-img {
  position: relative;
  overflow: hidden;
}

.blog-section .blog-item .blog-img img {
  width: 100%;
  transform: scale(1);
  transition: all 2s ease 0s;
}

.blog-section .blog-item .blog-img .ptags {
  position: absolute;
  top: 3rem;
  left: 3rem;
  z-index: 9;
  transition: all 0.4s ease 0s;
}

.blog-section .blog-item .blog-img .ptags li {
  display: inline-block;
  padding-right: .5rem;
}

.blog-section .blog-item .blog-img .ptags li a {
  font-size: 1.2rem;
  color: #fff;
  background: #ff2c54;
  border-radius: 3rem;
  padding: .3rem 1.6rem;
}

.blog-section .blog-item .blog-img .ptags li a:hover {
  background: #ff1d48;
}

.blog-section .blog-item .blog-details {
  padding: 2.1rem 2.8rem 3rem;
  border: 0.1rem solid #e9e9e9;
  border-top: none;
  transition: all 0.4s ease 0s;
}

.blog-section .blog-item .blog-details .date i {
  font-size: 1.5rem;
  padding-right: 0.5rem;
  position: relative;
  top: -0.1rem;
}

.blog-section .blog-item .blog-details .blog-title {
  font-size: 2.2rem;
  margin-top: 1rem;
  font-weight: 600;
}

.blog-section .blog-item .blog-details .blog-title a {
  color: #333333;
}

.blog-section .blog-item .blog-details .blog-title a:hover {
  color: #ff2c54;
}

.blog-section .blog-item .blog-details .blog-meta {
  display: flex;
  justify-content: space-between;
}

.blog-section .blog-item .blog-details .blog-meta .post-author img {
  width: auto;
  max-width: 100%;
  display: inline-block;
  padding-right: 1rem;
}

.blog-section .blog-item .blog-details .blog-meta .post-author span {
  color: #333333;
}

.blog-section .blog-item .blog-details .blog-meta .post-comment {
  margin-top: 0.5rem;
}

.blog-section .blog-item .blog-details .blog-meta .post-comment i {
  padding-right: .3rem;
}

.blog-section .blog-item .blog-overlay {
  padding: 2.1rem 3rem 3rem;
  border-top: none;
  transition: all 0.4s ease 0s;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.blog-section .blog-item .blog-overlay:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  display: block;
  left: 0;
  top: 0;
  z-index: 1;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8) 60%, rgba(255, 255, 255, 0) 95%);
  background-image: -webkit-linear-gradient(to top, rgba(0, 0, 0, 0.8) 60%, rgba(255, 255, 255, 0) 95%);
  background-image: -moz-linear-gradient(to top, rgba(0, 0, 0, 0.8) 60%, rgba(255, 255, 255, 0) 95%);
  background-image: -ms-linear-gradient(to top, rgba(0, 0, 0, 0.8) 60%, rgba(255, 255, 255, 0) 95%);
  background-image: -o-linear-gradient(to top, rgba(0, 0, 0, 0.8) 60%, rgba(255, 255, 255, 0) 95%);
  transition: all 0.4s ease 0s;
}

.blog-section .blog-item .blog-overlay .date {
  color: #fff;
  background: #ff2c54;
  display: inline-block;
  padding: 0.6rem 1.3rem 0.2rem 1.3rem;
  position: relative;
  font-size: 1.3rem;
  z-index: 9;
  border-radius: 3px;
}

.blog-section .blog-item .blog-overlay .date i {
  font-size: 1.5rem;
  padding-right: 0.5rem;
  position: relative;
}

.blog-section .blog-item .blog-overlay .blog-title {
  font-size: 2.2rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  font-weight: 500;
  position: relative;
  z-index: 9;
}

.blog-section .blog-item .blog-overlay .blog-title a {
  color: #fff;
}

.blog-section .blog-item .blog-overlay .blog-title a:hover {
  color: #ff2c54;
}

.blog-section .blog-item .blog-overlay .blog-meta {
  display: flex;
  justify-content: space-between;
  color: #fff;
  position: relative;
  z-index: 9;
}

.blog-section .blog-item .blog-overlay .blog-meta .post-author {
  font-size: 1.4rem;
}

.blog-section .blog-item:hover .blog-img img {
  transform: scale(1.1);
}

.blog-section .owl-item.center .blog-item .blog-details {
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.1);
  border-color: #fff;
}

.blog-section .owl-item.center .blog-item .blog-details .blog-title a {
  color: #ff2c54;
}

.blog-section.blog-style1 {
  padding-bottom: 4.5rem;
}

.blog-section.blog-style1#tact-news {
  padding-bottom: 9rem;
}

.blog-section.blog-style2 {
  padding-bottom: 8.3rem;
}

.blog-section.blog-style2 .section-title.title-style2 .title {
  margin-bottom: 4.8rem;
}

.blog-section.blog-style2 .blog-carousel .blog-item .blog-details {
  box-shadow: none;
  border: none;
  padding: 0;
  margin-top: 2rem;
}

.blog-section.blog-style2 .blog-carousel .blog-item .blog-details .post-author {
  font-size: 1.8rem;
  color: #333333;
  font-weight: 600;
}

.blog-section.blog-style2 .blog-carousel .blog-item .blog-details .date {
  font-size: 1.4rem;
}

.blog-section.blog-style2 .blog-carousel .blog-item .blog-details .blog-title {
  margin-top: 1.7rem;
  margin-bottom: 0;
  font-weight: 600;
}

.blog-section.blog-style2 .blog-carousel .blog-item .read-more {
  color: #333333;
  margin-top: 1.6rem;
}

.blog-section.blog-style2 .blog-carousel .blog-item .read-more:before {
  color: #333333;
}

.blog-section.blog-style2 .blog-carousel .blog-item .read-more:hover {
  text-decoration: none;
  color: #ff2c54;
}

.blog-section.blog-style2 .blog-carousel .blog-item .read-more:hover:before {
  color: #ff2c54;
}

.blog-section.blog-style2 .blog-carousel .blog-item:hover .blog-img img {
  transform: scale(1.1);
}

.blog-section.blog-style2 .blog-carousel .blog-item:hover .blog-details .blog-title a {
  color: #333333;
}

.blog-section.blog-style2 .blog-carousel .blog-item:hover .blog-details .blog-title a:hover {
  color: #ff2c54;
}

.blog-section.blog-style3 {
  padding-bottom: 4rem;
  position: relative;
  z-index: 9;
}

.blog-section.blog-style3 .blog-grid .blog-item {
  position: relative;
  z-index: 9;
}

.blog-section.blog-style3 .blog-grid .blog-item .blog-wrap {
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.06);
}

.blog-section.blog-style3 .blog-grid .blog-item .blog-wrap .blog-details {
  padding: 3.1rem 3rem 3.5rem;
  border: none;
  box-shadow: none;
}

.blog-section.blog-style3 .blog-grid .blog-item .blog-wrap .blog-details .blog-title {
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 2rem;
}

.blog-section.blog-style3 .blog-grid .blog-item .blog-wrap .blog-details .blog-meta {
  font-size: 1.4rem;
}

.blog-section.blog-style3 .blog-grid .blog-item .blog-wrap .blog-details .blog-meta i {
  color: #ff2c54;
  padding-right: 1rem;
}

.blog-section.blog-style3 .blog-grid .blog-item .blog-wrap .blog-details .blog-meta .post-comment {
  margin-top: 0;
}

.blog-section.blog-style3 .blog-wrap {
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
}

.blog-section.blog-style3 .blog-wrap .blog-details {
  border: none;
}

.blog-section.blog-style3 .blog-wrap .blog-item {
  position: relative;
  z-index: 9;
}

.blog-section.blog-style3 .blog-wrap .blog-item .blog-wrap {
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.06);
}

.blog-section.blog-style3 .blog-wrap .blog-item .blog-wrap .blog-details {
  padding: 3.1rem 3rem 3rem;
  border: none;
  box-shadow: none;
}

.blog-section.blog-style3 .blog-wrap .blog-item .blog-wrap .blog-details .blog-title {
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0.8rem;
}

.blog-section.blog-style3 .blog-wrap .blog-item .blog-wrap .blog-details .blog-title a {
  color: #333333;
}

.blog-section.blog-style3 .blog-wrap .blog-item .blog-wrap .blog-details .blog-title a:hover {
  color: #ff2c54;
}

.blog-section.blog-style3 .blog-wrap .blog-item .blog-wrap .blog-details .blog-meta {
  color: #969696;
}

.blog-section.blog-style3 .blog-wrap .blog-item .blog-wrap .blog-details .blog-meta i {
  color: #ff2c54;
  padding-right: 1rem;
}

.blog-section.blog-style4 {
  padding-bottom: 8.3rem;
}

.blog-section.blog-style4 .section-title.title-style2 .title {
  margin-bottom: 4.5rem;
}

.blog-section.blog-style4 .loarmore {
  margin-top: 2.5rem;
}

.blog-section.blog-style4 .blog-item .blog-details {
  box-shadow: none;
  border: none;
  padding: 0;
  margin-top: 2.5rem;
}

.blog-section.blog-style4 .blog-item .blog-details .post-author {
  font-size: 1.8rem;
  color: #333333;
  font-weight: 600;
}

.blog-section.blog-style4 .blog-item .blog-details .date {
  font-size: 1.4rem;
}

.blog-section.blog-style4 .blog-item .blog-details .blog-title {
  margin-top: 1.8rem;
  margin-bottom: 0;
  font-weight: 600;
}

.blog-section.blog-style4 .blog-item .read-more {
  color: #333333;
  margin-top: 2rem;
}

.blog-section.blog-style4 .blog-item .read-more:before {
  color: #333333;
}

.blog-section.blog-style4 .blog-item .read-more:hover {
  text-decoration: none;
  color: #ff2c54;
}

.blog-section.blog-style4 .blog-item .read-more:hover:before {
  color: #ff2c54;
}

.blog-section.blog-style4 .blog-item:hover .blog-img img {
  transform: scale(1.1);
}

.blog-section.blog-style4 .blog-item:hover .blog-details .blog-title a {
  color: #333333;
}

.blog-section.blog-style4 .blog-item:hover .blog-details .blog-title a:hover {
  color: #ff2c54;
}

.blog-section.blog-style5 {
  padding-top: 0;
  margin-top: -9.2rem;
}

.blog-section.blog-style6 .blog-carousel .blog-item {
  position: relative;
  padding-bottom: 0;
  margin-bottom: 0;
}

/* Single Blog Css */
.single-blog-section .single-blog {
  padding-right: 2rem;
}

.single-blog-section .single-blog .item-main-figure {
  margin-bottom: 3rem;
}

.single-blog-section .single-blog .entry-title {
  font-size: 2.6rem;
  font-weight: 600;
}

.single-blog-section .single-blog .entry-meta {
  margin-bottom: 3rem;
  font-size: 1.4rem;
}

.single-blog-section .single-blog .entry-meta li {
  display: inline-block;
  margin-right: 2.5rem;
}

.single-blog-section .single-blog .entry-meta li .post-author img {
  border-radius: 100%;
  max-width: 2.7rem;
  margin-right: 0.4rem;
}

.single-blog-section .single-blog .entry-meta li:last-child {
  margin-right: 0;
}

.single-blog-section .single-blog .entry-meta li i {
  margin-right: 0.7rem;
}

.single-blog-section .single-blog .blog-tag {
  border-top: 0.1rem solid #dfdfdf;
  padding: 2.2rem 0 1.3rem;
  margin-bottom: 6rem;
  margin-top: 5.4rem;
}

.single-blog-section .single-blog .blog-tag ul {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 575px) {
  .single-blog-section .single-blog .blog-tag ul {
    display: block;
  }
}

.single-blog-section .single-blog .blog-tag ul li {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  line-height: 1;
}

@media only screen and (max-width: 575px) {
  .single-blog-section .single-blog .blog-tag ul li {
    justify-content: center;
  }
}

.single-blog-section .single-blog .blog-tag ul .item-tag span {
  margin-right: 0.4rem;
  display: block;
}

.single-blog-section .single-blog .blog-tag ul .item-tag span a {
  font-size: 1.5rem;
  color: #333333;
}

.single-blog-section .single-blog .blog-tag ul .item-tag span a:hover {
  color: #ff2c54;
}

.single-blog-section .single-blog .blog-tag ul .item-tag span:first-child {
  font-size: 1.5rem;
  color: #333333;
}

.single-blog-section .single-blog .blog-tag ul .item-social span {
  margin-right: 1.5rem;
  display: block;
}

.single-blog-section .single-blog .blog-tag ul .item-social span a {
  color: #333333;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.single-blog-section .single-blog .blog-tag ul .item-social span a:hover {
  color: #ff2c54;
}

.single-blog-section .single-blog .blog-tag ul .item-social span:first-child {
  font-size: 1.6rem;
  color: #333333;
}

.single-blog-section .single-blog .blog-tag ul .item-social span:last-child {
  margin-right: 0;
}

.single-blog-section .single-blog .blog-tag ul .item-respons {
  color: #a5a5a5;
}

.single-blog-section .single-blog .blog-tag ul .item-respons i {
  color: #646464;
  font-size: 2rem;
  margin-right: 1rem;
}

.single-blog-section .single-blog .blog-author {
  margin-bottom: 5rem;
  background: #f7f7f7;
  padding: 3rem 2rem 3rem 2.8rem;
}

@media only screen and (max-width: 575px) {
  .single-blog-section .single-blog .blog-author {
    text-align: center;
  }
}

.single-blog-section .single-blog .blog-author .media img {
  border-radius: 50%;
}

.single-blog-section .single-blog .blog-author .media .media-body {
  position: relative;
  padding-left: 2.4rem;
}

.single-blog-section .single-blog .blog-author .media .media-body .item-title {
  font-size: 1.8rem;
  color: #333333;
  font-weight: 600;
  margin-bottom: 1rem;
}

.single-blog-section .single-blog .blog-author .media .media-body p {
  margin-bottom: 0;
}

.single-blog-section .single-blog .blog-author .media .media-body .item-subtitle {
  line-height: 2rem;
  margin-bottom: 5px;
  font-weight: 600;
}

.single-blog-section .single-blog .blog-comment {
  background-color: #ffffff;
  margin-bottom: 4.3rem;
}

.single-blog-section .single-blog .blog-comment .media-img-auto {
  margin-top: 0.5rem;
}

.single-blog-section .single-blog .blog-comment .inner-title {
  margin-bottom: 2.5rem;
}

.single-blog-section .single-blog .blog-comment ul li {
  position: relative;
  margin-bottom: 2.4rem;
  border-bottom: 0.1rem solid #e8e8e8;
  padding-bottom: 2.2rem;
}

.single-blog-section .single-blog .blog-comment ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.single-blog-section .single-blog .blog-comment ul li:nth-child(odd) {
  margin-left: 0;
}

.single-blog-section .single-blog .blog-comment ul li:nth-child(even) {
  margin-left: 5rem;
}

.single-blog-section .single-blog .blog-comment ul li .comment-title {
  font-weight: 600;
  color: #333333;
  margin-bottom: 0;
}

.single-blog-section .single-blog .blog-comment ul li .post-date {
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  font-size: 1.3rem;
  color: #ff2c54;
  margin-left: 0.8rem;
  position: relative;
  bottom: 0.2rem;
}

.single-blog-section .single-blog .blog-comment ul li p {
  width: 100%;
  margin-bottom: 0.8rem;
}

.single-blog-section .single-blog .blog-comment ul li .item-btn {
  color: #333333;
}

@media only screen and (max-width: 767px) {
  .single-blog-section .single-blog .blog-comment ul li .item-btn {
    position: inherit;
  }
}

.single-blog-section .single-blog .blog-comment ul li .item-btn:hover {
  color: #ff2c54;
}

.single-blog-section .single-blog .blog-comment .media-body {
  padding-left: 1.8rem;
}

.single-blog-section .single-blog .comment-box .inner-title {
  margin-bottom: 0;
}

.single-blog-section .single-blog .comment-box .form-box {
  margin-top: 1.2rem;
}

.single-blog-section .single-blog .comment-box .form-box .form-group {
  margin-bottom: 2.5rem;
}

.single-blog-section .single-blog .comment-box .form-box .form-group .form-control {
  color: #000000;
  padding: 1.5rem 2rem;
  border: 0.1rem solid #dbdbdb;
  background-color: transparent;
  border-radius: 0;
  font-size: 1.5rem;
}

.single-blog-section .single-blog .comment-box .form-box .form-group .form-control:focus {
  outline: none;
  box-shadow: none;
}

.single-blog-section .single-blog .comment-box .form-box .form-group input {
  height: 5rem;
}

.single-blog-section .single-blog .comment-box .form-box .contact-info {
  margin-bottom: 32px;
}

.single-blog-section .single-blog .comment-box .form-box .contact-info li {
  color: #111111;
  margin-bottom: 1.5rem;
  position: relative;
  padding-left: 4.2rem;
}

.single-blog-section .single-blog .comment-box .form-box .contact-info li:last-child {
  margin-bottom: 0px;
  color: #444444;
}

.single-blog-section .single-blog .comment-box .form-box .contact-info li:first-child {
  font-weight: 500;
}

.single-blog-section .single-blog .comment-box .form-box .contact-info li i {
  font-size: 2.2rem;
  color: #ff2c54;
  top: 0.5rem;
  left: 0;
  position: absolute;
}

.single-blog-section .single-blog .comment-box .form-box button {
  cursor: pointer;
}

.blog-section .blog-item.grid-center .blog-details,
.blog-section.blog-style1 .blog-item:hover .blog-details {
  background: #fff;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.1);
}

/*--------------------------------------
	# Partner Style
---------------------------------------*/
.partner-section {
  padding-bottom: 6.7rem;
}

.partner-section.partner-layout2 {
  padding-bottom: 6.7rem;
}

.partner-section.partner-layout3 {
  padding-top: 7.3rem;
  padding-bottom: 6.5rem;
}

.partner-section .display-table {
  height: 13rem;
}

.partner-section .display-table img {
  max-height: 3.2rem;
}

.partner-section .partner-item {
  text-align: center;
}

.partner-section .partner-item .display-table {
  width: 100%;
}

.partner-section .partner-item a {
  display: inline-block;
}

/*--------------------------------------
	# Call To Action
---------------------------------------*/
.cta-section {
  background: url(../../media/background/cta-bg.jpg) no-repeat center top;
  position: relative;
  padding-bottom: 10.5rem;
  padding-top: 10rem;
  background-size: cover;
}

.cta-section h3,
.cta-section .h3,
.cta-section h2 {
  color: #fff;
}

.cta-section h3, .cta-section .h3 {
  font-size: 2.2rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0.8rem;
  line-height: 4rem;
  letter-spacing: 0.2rem;
}

.cta-section h2 {
  font-size: 3.6rem;
  font-weight: 600;
}

.cta-section .container {
  z-index: 9;
  position: relative;
}

.cta-section:after {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
}

.cta-section .cta-btn {
  margin-top: 2.3rem;
}

.call-action-section {
  background: #ff2c54;
  color: #fff;
  padding-top: 8.5rem;
  padding-bottom: 8.4rem;
}

.call-action-section.cta-landing {
  background: #ffdde4;
}

.call-action-section.cta-landing h2 {
  color: #333333;
}

.call-action-section.cta-landing .cta-sub-text {
  color: #623fbd;
}

.call-action-section.cta-landing .cta-btn .secondary-btn {
  font-weight: 600;
  font-size: 2.2rem;
  border-width: 0.2rem;
  min-width: 20rem;
  margin-top: 1.5rem;
  padding: 1.4rem 4rem 1.3rem;
  line-height: 3.3rem;
}

.call-action-section h2 {
  color: #fff;
  font-weight: 600;
  margin-bottom: 0.2rem;
}

.call-action-section .cta-sub-text {
  font-size: 2.2rem;
}

.call-action-section .cta-btn .secondary-light-btn {
  margin-top: 1.7rem;
}

.call-action-section .cta-btn .secondary-light-btn:hover {
  background: #fff;
}

.call-action-section .cta-btn .secondary-light-btn:hover:before {
  border: 0.1rem solid #fff;
}

.call-action-section.cta-style3 {
  background: url(../../media/background/cta-bg2.jpg) no-repeat center top;
  background-size: cover;
  padding-top: 7.8rem;
  padding-bottom: 7.9rem;
}

.call-action-section.cta-style3 .cta-btn {
  padding-top: 2.2rem;
}

/*--------------------------------------
	# Analyze Style
---------------------------------------*/
.analyze-section {
  padding-bottom: 120px;
}

.analyze-section .section-title.title-style2 .title {
  margin-bottom: 2rem;
}

/*--------------------------------------
	# Landing Style
---------------------------------------*/
.header .main-menu-area.sticky.landing-menu {
  background-color: #6441bf;
}

.portfolio-section.landing-project.demo-landing-pages .project-link a {
  height: inherit;
  width: inherit;
  display: block;
  border-radius: inherit;
  background: #ff2c54;
  color: #fff;
  line-height: 2.1rem;
  min-width: 16rem;
  padding-left: 2rem;
  font-size: 1.4rem;
  padding-right: 2rem;
  display: block;
  text-transform: capitalize;
}

.landing-project .container {
  max-width: 156rem;
}

.portfolio-section.landing-project.demo-landing-pages .project-content {
  border-radius: .6rem;
}

.portfolio-section.landing-project.demo-landing-pages .project-content img {
  border-radius: .6rem;
}

.portfolio-section.landing-project.demo-landing-pages .project-content:after {
  background: rgba(100, 65, 191, 0.9);
  border-radius: .6rem;
}

.portfolio-section.landing-project.demo-landing-page .project-content:hover .project-link {
  top: 9rem;
}

.service-section.landing-demo {
  padding-top: 9rem;
}

.landing-demo .section-title.title-style2 .title {
  font-weight: 600;
  margin-bottom: 2.4rem;
}

.project-content.demo-landing-item {
  border: .1rem solid #f7f7f7;
  border-top: none;
  border-radius: 0 0 .5rem .5rem;
  -webkit-box-shadow: 0 0 2rem rgba(0, 0, 0, 0.09);
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.09);
  background: #fff;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  border-radius: .6rem;
}

.demo-landing-pages.section-counter-area .counter-wrap {
  padding: 0;
}

.demo-landing-pages.section-counter-area .counter-wrap img {
  border-radius: .6rem;
}

.portfolio-section .demo-landing-item .project-info .project-title {
  font-family: "Poppins", sans-serif;
  font-size: 1.8rem;
  text-transform: capitalize;
}

.demo-landing-item .page-name {
  background: rgba(100, 65, 191, 0.9);
  border-radius: 0 0 .6rem .6rem;
  margin-bottom: 0;
  padding: 10px;
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  opacity: 1;
  visibility: visible;
  transition: all 0.4s ease 0s;
}

.demo-landing-item:hover .page-name {
  opacity: 0;
  visibility: hidden;
}

.banner-section.banner-landing .container-fluid {
  padding-left: 6rem;
  padding-right: 6rem;
}

#features .service-wrap {
  padding: 4.5rem 1.5rem 4rem;
  width: 100%;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.06);
  background: #fff;
  border-radius: 0;
  transition: all 0.4s ease 0s;
  margin-bottom: 0;
}

#features .service-wrap .icon-wrap {
  -webkit-filter: drop-shadow(0px 10px 0px rgba(255, 44, 84, 0.15));
  filter: drop-shadow(0px 10px 0px rgba(255, 44, 84, 0.15));
}

#features .service-wrap .icon-wrap path {
  fill: #ff2c54;
}

#features .service-wrap .icon-wrap .service-icon-svg {
  max-width: 9rem;
  position: relative;
  margin: 0 auto;
}

#features .service-wrap .icon-wrap .service-icon-svg i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

#features .service-wrap .icon-wrap .service-icon-svg i:before {
  font-size: 4rem;
}

#features .service-wrap .service-icon i {
  color: #fff;
  font-size: 3.5rem;
}

#features .service-wrap .service-title {
  margin-bottom: 1.5rem;
  margin-top: 2.2rem;
  font-size: 2.2rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

#features .service-wrap .service-title a {
  color: #333333;
}

#features .service-wrap .service-title a:hover {
  color: #ff2c54;
}

#features .service-wrap .service-desc {
  margin-bottom: 0;
}

#features .service-wrap .read-more {
  color: #333333;
}

#features .service-wrap .read-more:before {
  color: #333333;
}

#features .service-wrap .read-more:hover {
  color: #ff2c54;
  text-decoration: none;
}

#features .service-wrap .read-more:hover:before {
  color: #ff2c54;
}

#features .service-wrap:hover {
  box-shadow: 0px 8px 2.2rem rgba(0, 0, 0, 0.08);
  background: #fff;
  border-color: transparent;
}

#features .service-wrap:hover .read-more {
  color: #ff2c54;
  text-decoration: none;
}

#features .service-wrap:hover .read-more:before {
  color: #ff2c54;
  right: -3rem;
}

.hone-banner-list-holder {
  position: absolute;
  bottom: 0;
  right: 1.5rem;
  z-index: 9;
}

@media only screen and (max-height: 830px) {
  .hone-banner-list-holder {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
}

@media only screen and (max-height: 830px) {
  .hone-banner-list-holder .hone-banner-list {
    text-align: center;
    display: block;
  }
}

@media only screen and (max-height: 830px) {
  .hone-banner-list-holder .landing-left2 img {
    max-height: 40rem;
  }
}

.hone-banner-list {
  position: relative;
  bottom: 0;
  display: flex;
  align-items: flex-end;
}

.container-fluid.landing-priview {
  padding-left: 6rem;
  padding-right: 6rem;
}

.push-left-lamding {
  padding-right: 50px;
  padding-left: calc(calc(100% - 1485px)/2);
}

/*--------------------------------------
	# Preloader Style
---------------------------------------*/
#loader-wrapper {
  background-color: #2554E1;
  height: 100%;
  width: 100%;
  position: fixed;
  margin-top: 0;
  top: 0;
  z-index: 9999999;
}

.spinner {
  height: 40px;
  text-align: center;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.spinner > div {
  background-color: #fff;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

/*--------------------------------------
    # Button Style
---------------------------------------*/
.primary-btn {
  background: #ff2c54;
  border-color: #ff2c54;
  color: #fff;
  display: inline-block;
  font-size: 1.4rem;
  padding: 1.4rem 3rem 1.3rem;
  position: relative;
  vertical-align: middle;
  z-index: 9;
  font-weight: 500;
  text-align: center;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.1rem;
  line-height: 2.1rem;
  border-radius: 0;
  border-width: .2rem;
  border-style: solid;
}

.primary-btn.shadow {
  box-shadow: 0 0.5rem 1rem rgba(255, 44, 84, 0.25) !important;
}

.primary-btn.btn-sm {
  font-size: 1.3rem;
  padding: 1.2rem 2.5rem 1rem;
  border-radius: 0;
}

.primary-btn.btn-lg {
  font-size: 1.6rem;
  padding: 1.4rem 3.5rem 1.3rem;
  line-height: inherit;
  border-radius: 0;
}

.primary-btn.border-radius-lg {
  border-radius: 3rem;
}

.primary-btn.border-radius-lg:before {
  border-radius: 3rem;
}

.primary-btn.border-radius {
  border-radius: 3px;
}

.primary-btn.border-radius:before {
  border-radius: 3px;
}

.primary-btn.no-shadow {
  box-shadow: none;
}

.primary-btn.light {
  background: #ffd5e1;
  border-color: #ffd5e1;
  color: #ff2c54;
}

.primary-btn.light:hover {
  color: #fff;
  background: #ff1d48;
}

.primary-btn:hover {
  color: #fff;
}

.primary-btn.btn-light {
  background: #fff;
  color: #333333;
}

.primary-btn.btn-light:hover {
  color: #fff;
}

.primary-btn.btn-light:hover:before {
  background: #ff2c54;
}

.primary-btn:before {
  content: " ";
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: -1;
  transition: all .5s;
}

.secondary-btn {
  border: 1px solid #ff2c54;
  color: #ff2c54;
  display: inline-block;
  font-size: 1.4rem;
  padding: 1.5rem 3.1rem 1.4rem;
  position: relative;
  z-index: 9;
  font-weight: 500;
  text-align: center;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.1rem;
  line-height: 2.1rem;
}

.secondary-btn.shadow {
  box-shadow: 0 0.5rem 1rem rgba(255, 44, 84, 0.25) !important;
}

.secondary-btn.border-radius-lg {
  border-radius: 3rem;
}

.secondary-btn.border-radius-lg:before {
  border-radius: 3rem;
}

.secondary-btn.border-radius {
  border-radius: 3px !important;
}

.secondary-btn.border-radius:before {
  border-radius: 2px;
}

.secondary-btn.btn-sm {
  font-size: 1.3rem;
  padding: 1.2rem 2.5rem 1rem;
  border-radius: 0;
}

.secondary-btn.btn-lg {
  font-size: 1.6rem;
  padding: 1.4rem 3.5rem 1.3rem;
  line-height: inherit;
  border-radius: 0;
}

.secondary-btn:hover {
  color: #fff;
}

.secondary-btn:before {
  content: " ";
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: #ff2c54;
  z-index: -1;
  transition: all .5s;
}

.secondary-btn:hover:before {
  width: 100%;
  right: auto;
  left: 0;
}

.secondary-light-btn {
  border: 2px solid #fff;
  color: #fff;
  display: inline-block;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.1rem;
  font-size: 1.4rem;
  padding: 1.2rem 3rem 1.1rem;
  position: relative;
  z-index: 9;
  font-weight: 500;
  text-align: center;
}

.secondary-light-btn.shadow {
  box-shadow: 0 0.5rem 1rem rgba(255, 44, 84, 0.25) !important;
}

.secondary-light-btn.btn-sm {
  font-size: 1.3rem;
  padding: 1.2rem 2.5rem 1rem;
  border-radius: 0;
}

.secondary-light-btn.btn-lg {
  font-size: 1.6rem;
  padding: 1.4rem 3.5rem 1.3rem;
  line-height: inherit;
  border-radius: 0;
}

.secondary-light-btn:hover {
  color: #ff2c54;
}

.secondary-light-btn:before {
  content: " ";
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  z-index: -1;
  transition: all .5s;
}

.secondary-light-btn:hover:before {
  width: 100%;
  right: auto;
  left: 0;
}

.primary-btn:hover:before,
.primary-btn:focus:before,
.hvr-shutter-out-horizontal:active:before {
  width: 100%;
  right: auto;
  left: 0;
}

.arrow-link {
  color: #09b341;
  font-size: 1.8rem;
  position: relative;
  font-weight: 600;
}

.arrow-link:before {
  font-family: FontAwesome;
  content: "\f178";
  position: absolute;
  left: auto;
  right: -3rem;
  font-size: 1.85rem;
  top: 0;
  color: #09b341;
  text-align: center;
  z-index: 9;
  display: block;
  cursor: pointer;
  transition: all 0.4s ease 0s;
}

.arrow-link:hover:before {
  color: #ff2c54;
  right: -3.5rem;
}

.read-more {
  margin-top: 2rem;
  display: inline-block;
  position: relative;
}

.read-more:before {
  font-family: Flaticon;
  content: "\f10c";
  position: absolute;
  left: auto;
  right: -3rem;
  font-size: 2.1rem;
  top: -0.2rem;
  z-index: 9999;
  display: block;
  cursor: pointer;
  color: #ff2c54;
  transition: all 0.4s ease 0s;
}

.read-more:hover {
  text-decoration: underline;
}

.read-more:hover:before {
  color: #ff2c54;
  right: -3.5rem;
}

.line-hover a {
  position: relative;
  display: inline-block;
  background: linear-gradient(to right, currentColor 0%, currentColor 100%);
  background-size: 0px 2px;
  background-position: 0px 95%;
  transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  padding: 0.1% 0px;
  background-repeat: no-repeat;
}

.line-hover a:hover {
  background-size: 100% 2px;
  text-decoration: none !important;
}

/*--------------------------------------
    # Global Style
---------------------------------------*/
html {
  font-size: 10px;
}

body {
  font-size: 1.6rem;
  line-height: 1.7;
  color: #666666;
  font-family: "Open Sans", sans-serif;
  vertical-align: baseline;
  font-weight: 400;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  margin: 0 0 2.6rem;
}

a {
  color: #ff2c54;
  transition: all 0.4s ease 0s;
  text-decoration: none;
  outline: none;
}

a:active,
a:hover {
  text-decoration: none;
  outline: 0 none;
  color: #ff2c54;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.clear {
  clear: both;
}

::-moz-selection {
  background: #ff2c54;
  text-shadow: none;
  color: #ffffff;
}

::selection {
  background: #ff2c54;
  text-shadow: none;
  color: #ffffff;
}

[class*=" flaticon-"]::before {
  margin-left: 0;
}

button, input, optgroup, select, textarea {
  font-family: "Open Sans", sans-serif;
}

.search_box {
  border-radius: 0;
  display: none;
  position: absolute;
  right: 1.5rem;
  top: 100%;
  z-index: 1000;
  width: 32rem;
}

.search_box input {
  background: #fff;
  border: 0 none;
  padding: 1rem 1.5rem;
  width: 24rem;
  box-shadow: -0.1rem 0.2rem 0.4rem rgba(0, 0, 0, 0.15);
  font-size: 1.8rem;
  color: #a7a7a7;
}

.search_box button {
  background: #ff2c54;
  border: 0 none;
  border-radius: 0 .3rem .3rem 0;
  color: #fff;
  cursor: pointer;
  float: right;
  overflow: visible;
  padding: 0;
  position: relative;
  text-shadow: 0 -0.1rem 0 rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
  width: 6.4rem;
  height: 5.1rem;
}

.search_box button:hover {
  background: #ff1d48;
}

@-webkit-keyframes sticky-animation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes sticky-animation {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.owl-carousel .owl-item img {
  width: auto;
  max-width: 100%;
}

.image-shape {
  position: relative;
  display: inline-block;
  margin-bottom: 5.5rem;
}

.image-shape img {
  position: relative;
  z-index: 1;
}

.image-shape:before {
  position: absolute;
  content: "";
  display: block;
  left: 5.5rem;
  top: 5.5rem;
  width: 100%;
  height: 100%;
  background: #ff2c54;
  z-index: -1;
}

#scrollUp {
  text-align: center;
  bottom: 40px;
  cursor: pointer;
  display: none;
  position: fixed;
  right: 40px;
  z-index: 10;
  transform: rotate(90deg);
}

#scrollUp a {
  max-width: auto;
  padding: 0;
  min-width: auto;
  padding: 0.7rem 0.7rem;
  display: none;
}

#scrollUp a i:before {
  font-size: 1.4rem;
}

#scrollUp a:hover {
  color: #fff;
}

.style-arrow .owl-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  color: #fff;
  padding: 0;
  height: 60px;
}

.style-arrow .owl-nav .owl-prev {
  position: absolute;
  left: -30rem;
  text-align: center;
  background: transparent;
  transition: all 1s;
  outline: none;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (max-width: 1680px) {
  .style-arrow .owl-nav .owl-prev {
    left: -20rem;
  }
}

@media only screen and (max-width: 1480px) {
  .style-arrow .owl-nav .owl-prev {
    left: -10rem;
  }
}

.style-arrow .owl-nav .owl-prev i {
  font-size: 2.2rem;
  width: 6rem;
  height: 6rem;
  line-height: 5.8rem;
  border: 1px solid #fff;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.12);
  background: #fff;
  transition: all 0.4s ease 0s;
  color: #ff2c54;
  border-radius: 100%;
  font-weight: 600;
  outline: none;
}

.style-arrow .owl-nav .owl-prev:hover {
  background: transparent;
}

.style-arrow .owl-nav .owl-prev:hover i {
  background: #ff2c54;
  border: 1px solid #ff2c54;
  color: #fff;
}

.style-arrow .owl-nav .owl-next {
  position: absolute;
  right: -30rem;
  text-align: center;
  background: transparent;
  transition: all 1s;
  outline: none;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (max-width: 1680px) {
  .style-arrow .owl-nav .owl-next {
    right: -20rem;
  }
}

@media only screen and (max-width: 1480px) {
  .style-arrow .owl-nav .owl-next {
    right: -10rem;
  }
}

.style-arrow .owl-nav .owl-next i {
  font-size: 2.2rem;
  width: 6rem;
  height: 6rem;
  line-height: 5.8rem;
  border: 1px solid #fff;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.12);
  background: #fff;
  transition: all 0.4s ease 0s;
  color: #ff2c54;
  border-radius: 100%;
  outline: none;
  font-weight: 600;
}

.style-arrow .owl-nav .owl-next:hover {
  background: transparent;
}

.style-arrow .owl-nav .owl-next:hover i {
  background: #ff2c54;
  border: 1px solid #ff2c54;
  color: #fff;
}

.style-arrow:hover .owl-nav .owl-prev {
  left: -20rem !important;
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 1680px) {
  .style-arrow:hover .owl-nav .owl-prev {
    left: -15rem !important;
  }
}

@media only screen and (max-width: 1480px) {
  .style-arrow:hover .owl-nav .owl-prev {
    left: -8rem !important;
  }
}

.style-arrow:hover .owl-nav .owl-next {
  right: -20rem !important;
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 1680px) {
  .style-arrow:hover .owl-nav .owl-next {
    right: -15rem !important;
  }
}

@media only screen and (max-width: 1480px) {
  .style-arrow:hover .owl-nav .owl-next {
    right: -8rem !important;
  }
}

.carousel-shadow .owl-stage-outer {
  overflow: visible;
}

.carousel-shadow .owl-item {
  opacity: 0;
  transition: opacity 500ms;
}

.carousel-shadow .owl-item.active {
  opacity: 1;
}

blockquote {
  background: #ff2c54;
  margin-top: 3rem;
  margin-bottom: 3rem;
  line-height: 3.2rem;
  position: relative;
  font-style: italic;
  z-index: 1;
  padding: 2.3rem 7rem 2.3rem 10.8rem;
  color: #fff;
  font-weight: normal;
}

blockquote:before {
  font-family: Flaticon;
  content: "\f108";
  position: absolute;
  left: 5rem;
  font-size: 9.8rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  display: block;
  cursor: pointer;
  color: #ff3b60;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  font-style: normal;
}

/* ------------------------------------
    Skew Background
---------------------------------------*/
.skew-wrap {
  margin-top: 8.5rem;
  margin-bottom: 8.5rem;
}

.skew-background:after {
  background: #f8f9fb;
  background: #f8f9fb;
  background: #f8f9fb;
  background: #f8f9fb;
  background: #f8f9fb;
  -webkit-transform: skewY(175deg);
  transform: skewY(175deg);
  content: "";
  height: 100%;
  right: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

/* ------------------------------------
    Pricing  Section
---------------------------------------*/
.pricing-section .section-title.title-style2 .title {
  margin-bottom: 4.6rem;
}

.pricing-section .pricing-wrap {
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.07);
  padding: 5rem 0 4.6rem;
  margin-left: 0;
  margin-right: 0;
}

.pricing-section .pricing-wrap .single-pricing {
  border-right: 1px solid #ebebeb;
  padding-bottom: 2.5rem;
  padding-top: 5.7rem;
}

.pricing-section .pricing-wrap .single-pricing .pricing-head {
  margin-bottom: 3rem;
}

.pricing-section .pricing-wrap .single-pricing .pricing-head .featured-text {
  background: #ff2c54;
  color: #fff;
  border-radius: .2rem;
  font-size: 1.3rem;
  display: inline-block;
  padding: .2rem 1.1rem;
  margin-bottom: 1rem;
}

.pricing-section .pricing-wrap .single-pricing .pricing-head .price-title {
  font-size: 2rem;
  line-height: 2.7rem;
  font-weight: 600;
  color: #333333;
}

.pricing-section .pricing-wrap .single-pricing .pricing-head .price {
  color: #ff2c54;
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 5rem;
}

.pricing-section .pricing-wrap .single-pricing .pricing-head .duration {
  color: #76797e;
  font-size: 1.6rem;
  text-transform: uppercase;
  margin-top: .4rem;
}

.pricing-section .pricing-wrap .single-pricing .features {
  margin-bottom: 5rem;
}

.pricing-section .pricing-wrap .single-pricing .features ul li {
  margin-bottom: 1rem;
}

.pricing-section .pricing-wrap .single-pricing .features ul li i {
  color: #16dc7d;
  padding-right: .2rem;
}

.pricing-section .pricing-wrap .single-pricing .secondary-btn {
  border-color: #c8c9cb;
  font-weight: 600;
  color: #333333;
  min-width: 19rem;
}

.pricing-section .pricing-wrap .single-pricing .secondary-btn:hover {
  border-color: #ff2c54;
  color: #fff;
}

.pricing-section .pricing-wrap .single-pricing:last-child {
  border-right: none;
}

.pricing-section .pricing-wrap .single-pricing.popular-item {
  padding-top: 1.9rem;
}

/* ------------------------------------
    02. Checkbox Css
---------------------------------------*/
.squaredTwo2 {
  border: 1px solid #3e5ad3;
  height: 1.6rem;
  margin-top: .7rem;
  margin-bottom: 0;
  position: relative;
  width: 1.6rem;
  border-radius: .2rem;
  float: left;
}

.squaredTwo2 label {
  width: 1.3rem;
  height: 1.3rem;
  cursor: pointer;
  position: absolute;
  left: -.1rem;
  top: -.1rem;
}

.squaredTwo2 label:after {
  content: '';
  width: 9px;
  height: 5px;
  position: absolute;
  top: 4px;
  left: 4px;
  border: 1px solid #3e5ad3;
  border-top: none;
  border-right: none;
  background: 0 0;
  opacity: 0;
  transform: rotate(-45deg);
}

.squaredTwo2 label:hover::after {
  opacity: .3;
}

.squaredTwo2 input[type=checkbox] {
  visibility: hidden;
}

.squaredTwo2 input[type=checkbox]:checked + label:after {
  opacity: 1;
}

/* ------------------------------------
    Popup video css
---------------------------------------*/
.YouTubePopUp-Wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  z-index: 9999999999999;
}

.YouTubePopUp-Wrap .YouTubePopUp-Content {
  max-width: 680px;
  display: block;
  margin: 0 auto;
  height: 100%;
  position: relative;
}

.YouTubePopUp-Wrap .YouTubePopUp-Content iframe {
  max-width: 100%;
  width: 100%;
  display: block;
  height: 480px;
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.YouTubePopUp-Wrap .YouTubePopUp-Content .YouTubePopUp-Close {
  position: absolute;
  top: 0;
  cursor: pointer;
  bottom: 528px;
  right: 0px;
  margin: auto 0;
  width: 24px;
  height: 24px;
  background: url(../../media/icons/close.png) no-repeat;
  background-size: 24px 24px;
  -webkit-background-size: 24px 24px;
  -moz-background-size: 24px 24px;
  -o-background-size: 24px 24px;
}

@media only screen and (min-width: 1660px) {
  .container-lg {
    max-width: 149.5rem;
  }
}

/*========================================================================
05. Helper Classes
=========================================================================*/
/*--------------------------------------
	# Global Class
---------------------------------------*/
.spaser-40 {
  height: 4rem;
  clear: both;
}

.spaser-30 {
  height: 3rem;
  clear: both;
}

.spaser-45 {
  height: 4.5rem;
  clear: both;
}

.d_inline_block {
  display: inline-block;
}

.d_flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.height-full {
  height: 100%;
}

.push-left {
  padding-right: 50px;
  padding-left: calc(calc(100% - 1140px)/2);
}

.text-upper {
  text-transform: uppercase;
}

.p-light {
  font-weight: 300;
}

.p-regular {
  font-weight: 400;
}

.overflow-hidden {
  overflow: hidden;
}

.p-semi-bold {
  font-weight: 600;
}

.bg-fixed {
  background-attachment: fixed !important;
}

.p-bold {
  font-weight: 700;
}

.p-extra-bold {
  font-weight: 800;
}

.l-space {
  letter-spacing: .3rem;
}

.line-break {
  display: block;
}

.primary-color {
  color: #ff2c54 !important;
}

.pl0 {
  padding-left: 0 !important;
}

.display-table {
  display: table;
  height: 100%;
  width: 100%;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

.table-cell-bottom {
  display: table-cell;
  vertical-align: bottom;
}

.clear-fix {
  clear: both;
}

span.primary {
  color: #ff2c54;
}

.spaser-70 {
  height: 7rem;
  clear: both;
}

.sec-spacer {
  padding: 10rem 0;
}

.sec-color {
  background-color: #f9f9f9;
}

.primary-bg {
  background: #ff2c54;
}

.grey-bg {
  background: #f0f0f0;
}

.grey2-bg {
  background: #f0f0f0;
}

.grey3-bg {
  background: #fafbfc;
}

.bg-fixed {
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.bg1 {
  background-image: url(images/bg/bg1.jpg);
  background-size: cover;
  background-attachment: fixed;
}

.mb-30 {
  margin-bottom: 30px;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.grey-bg {
  background: #f9f9f9;
}

.section-title .sub-title {
  color: #666;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1.2rem;
  font-family: "Open Sans", sans-serif;
}

.section-title .title {
  color: #333333;
  font-size: 3.6rem;
  font-weight: 700;
  margin-bottom: 1.8rem;
}

.section-title .title.sm-bottom {
  margin-bottom: 2rem;
}

.section-title.center-style {
  text-align: center;
  max-width: 72rem;
  margin: 0 auto;
}

.section-title.center-style .title {
  margin-bottom: 1.8rem;
}

.section-title.center-style p {
  margin-bottom: 5.4rem;
}

.section-title.center-style2 {
  text-align: center;
  max-width: 53rem;
  margin: 0 auto;
}

.section-title.center-style2 .title {
  margin-bottom: 1.4rem;
}

.section-title.center-style2 p {
  margin-bottom: 5.4rem;
}

.section-title.center-style3 {
  text-align: center;
  max-width: 64rem;
  margin: 0 auto;
  position: relative;
  padding-top: 1.7rem;
}

.section-title.center-style3:before {
  position: absolute;
  content: "";
  height: 4px;
  width: 40px;
  border-radius: 20px;
  display: block;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  background: #ff2c54;
}

.section-title.center-style3 .title {
  margin-bottom: 0.7rem;
  font-weight: 700;
}

.section-title.center-style3 p {
  margin-bottom: 5rem;
}

.section-title.title-style2 .sub-title {
  margin-bottom: .9rem;
}

.section-title.title-style2 .title {
  margin-bottom: 4rem;
}

.section-title.title-style2 .title.sm-bottom {
  margin-bottom: 2rem;
}

.style-check li {
  position: relative;
  padding-left: 34px;
  margin-bottom: 12px;
}

.style-check li:before {
  font-family: Flaticon;
  content: "\f135";
  position: absolute;
  left: 0;
  font-size: 2.2rem;
  top: -0.3rem;
  color: #ff2c54;
  display: block;
}

.style-check.two-col-list li {
  width: 50%;
  float: left;
}

.style-check2 li {
  position: relative;
  padding-left: 34px;
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 2rem;
  color: #333333;
}

.style-check2 li:before {
  font-family: Flaticon;
  content: "\f136";
  position: absolute;
  left: 0;
  font-size: 2.4rem;
  top: -0.3rem;
  color: #ff2c54;
  display: block;
  font-weight: normal;
}

.style-check2.two-col-list li {
  width: 50%;
  float: left;
}

.overlap-area {
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  position: relative;
  z-index: 99;
}

/*--------------------------------------
	# Margin Padding
---------------------------------------*/
.mt--0 {
  margin-top: 0;
}

.mt--1 {
  margin-top: 0.1rem;
}

.mt--2 {
  margin-top: 0.2rem;
}

.mt--3 {
  margin-top: 0.3rem;
}

.mt--4 {
  margin-top: 0.4rem;
}

.mt--5 {
  margin-top: 0.5rem;
}

.mt--6 {
  margin-top: 0.6rem;
}

.mt--7 {
  margin-top: 0.7rem;
}

.mt--8 {
  margin-top: 0.8rem;
}

.mt--9 {
  margin-top: 0.9rem;
}

.mt--10 {
  margin-top: 1rem;
}

.mt--11 {
  margin-top: 1.1rem;
}

.mt--12 {
  margin-top: 1.2rem;
}

.mt--13 {
  margin-top: 1.3rem;
}

.mt--14 {
  margin-top: 1.4rem;
}

.mt--15 {
  margin-top: 1.5rem;
}

.mt--16 {
  margin-top: 1.6rem;
}

.mt--17 {
  margin-top: 1.7rem;
}

.mt--18 {
  margin-top: 1.8rem;
}

.mt--19 {
  margin-top: 1.9rem;
}

.mt--20 {
  margin-top: 2rem;
}

.mt--21 {
  margin-top: 2.1rem;
}

.mt--22 {
  margin-top: 2.2rem;
}

.mt--23 {
  margin-top: 2.3rem;
}

.mt--24 {
  margin-top: 2.4rem;
}

.mt--25 {
  margin-top: 2.5rem;
}

.mt--26 {
  margin-top: 2.6rem;
}

.mt--27 {
  margin-top: 2.7rem;
}

.mt--28 {
  margin-top: 2.8rem;
}

.mt--29 {
  margin-top: 2.9rem;
}

.mt--30 {
  margin-top: 3rem;
}

.mt--35 {
  margin-top: 3.5rem;
}

.mt--40 {
  margin-top: 4rem;
}

.mt--45 {
  margin-top: 4.5rem;
}

.mt--50 {
  margin-top: 5rem;
}

.mt--60 {
  margin-top: 6rem;
}

.mt--70 {
  margin-top: 7rem;
}

.mt--80 {
  margin-top: 8rem;
}

.mt--90 {
  margin-top: 9rem;
}

.mt--100 {
  margin-top: 10rem;
}

.mb--0 {
  margin-bottom: 0 !important;
}

.mb--1 {
  margin-bottom: 0.1rem;
}

.mb--2 {
  margin-bottom: 0.2rem;
}

.mb--3 {
  margin-bottom: 0.3rem;
}

.mb--4 {
  margin-bottom: 0.4rem;
}

.mb--5 {
  margin-bottom: 0.5rem;
}

.mb--6 {
  margin-bottom: 0.6rem;
}

.mb--7 {
  margin-bottom: 0.7rem;
}

.mb--8 {
  margin-bottom: 0.8rem;
}

.mb--9 {
  margin-bottom: 0.9rem;
}

.mb--10 {
  margin-bottom: 1rem;
}

.mb--11 {
  margin-bottom: 1.1rem;
}

.mb--12 {
  margin-bottom: 1.2rem;
}

.mb--13 {
  margin-bottom: 1.3rem;
}

.mb--14 {
  margin-bottom: 1.4rem;
}

.mb--15 {
  margin-bottom: 1.5rem;
}

.mb--16 {
  margin-bottom: 1.6rem;
}

.mb--17 {
  margin-bottom: 1.7rem;
}

.mb--18 {
  margin-bottom: 1.8rem;
}

.mb--19 {
  margin-bottom: 1.9rem;
}

.mb--20 {
  margin-bottom: 2rem;
}

.mb--21 {
  margin-bottom: 2.1rem;
}

.mb--22 {
  margin-bottom: 2.2rem;
}

.mb--23 {
  margin-bottom: 2.3rem;
}

.mb--24 {
  margin-bottom: 2.4rem;
}

.mb--25 {
  margin-bottom: 2.5rem;
}

.mb--26 {
  margin-bottom: 2.6rem;
}

.mb--27 {
  margin-bottom: 2.7rem;
}

.mb--28 {
  margin-bottom: 2.8rem;
}

.mb--29 {
  margin-bottom: 2.9rem;
}

.mb--30 {
  margin-bottom: 3rem;
}

.mb--35 {
  margin-bottom: 3.5rem;
}

.mb--40 {
  margin-bottom: 4rem;
}

.mb--45 {
  margin-bottom: 4.5rem;
}

.mb--50 {
  margin-bottom: 5rem;
}

.mb--60 {
  margin-bottom: 6rem;
}

.mb--70 {
  margin-bottom: 7rem;
}

.mb--80 {
  margin-bottom: 8rem;
}

.mb--90 {
  margin-bottom: 9rem;
}

.mb--100 {
  margin-bottom: 10rem;
}

.ml--0 {
  margin-left: 0;
}

.ml--1 {
  margin-left: 0.1rem;
}

.ml--2 {
  margin-left: 0.2rem;
}

.ml--3 {
  margin-left: 0.3rem;
}

.ml--4 {
  margin-left: 0.4rem;
}

.ml--5 {
  margin-left: 0.5rem;
}

.ml--6 {
  margin-left: 0.6rem;
}

.ml--7 {
  margin-left: 0.7rem;
}

.ml--8 {
  margin-left: 0.8rem;
}

.ml--9 {
  margin-left: 0.9rem;
}

.ml--10 {
  margin-left: 1rem;
}

.ml--11 {
  margin-left: 1.1rem;
}

.ml--12 {
  margin-left: 1.2rem;
}

.ml--13 {
  margin-left: 1.3rem;
}

.ml--14 {
  margin-left: 1.4rem;
}

.ml--15 {
  margin-left: 1.5rem;
}

.ml--16 {
  margin-left: 1.6rem;
}

.ml--17 {
  margin-left: 1.7rem;
}

.ml--18 {
  margin-left: 1.8rem;
}

.ml--19 {
  margin-left: 1.9rem;
}

.ml--20 {
  margin-left: 2rem;
}

.ml--21 {
  margin-left: 2.1rem;
}

.ml--22 {
  margin-left: 2.2rem;
}

.ml--23 {
  margin-left: 2.3rem;
}

.ml--24 {
  margin-left: 2.4rem;
}

.ml--25 {
  margin-left: 2.5rem;
}

.ml--26 {
  margin-left: 2.6rem;
}

.ml--27 {
  margin-left: 2.7rem;
}

.ml--28 {
  margin-left: 2.8rem;
}

.ml--29 {
  margin-left: 2.9rem;
}

.ml--30 {
  margin-left: 3rem;
}

.ml--35 {
  margin-left: 3.5rem;
}

.ml--40 {
  margin-left: 4rem;
}

.ml--45 {
  margin-left: 4.5rem;
}

.ml--50 {
  margin-left: 5rem;
}

.ml--60 {
  margin-left: 6rem;
}

.ml--70 {
  margin-left: 7rem;
}

.ml--80 {
  margin-left: 8rem;
}

.ml--90 {
  margin-left: 9rem;
}

.ml--100 {
  margin-left: 10rem;
}

.mr--0 {
  margin-right: 0;
}

.mr--1 {
  margin-right: 0.1rem;
}

.mr--2 {
  margin-right: 0.2rem;
}

.mr--3 {
  margin-right: 0.3rem;
}

.mr--4 {
  margin-right: 0.4rem;
}

.mr--5 {
  margin-right: 0.5rem;
}

.mr--6 {
  margin-right: 0.6rem;
}

.mr--7 {
  margin-right: 0.7rem;
}

.mr--8 {
  margin-right: 0.8rem;
}

.mr--9 {
  margin-right: 0.9rem;
}

.mr--10 {
  margin-right: 1rem;
}

.mr--11 {
  margin-right: 1.1rem;
}

.mr--12 {
  margin-right: 1.2rem;
}

.mr--13 {
  margin-right: 1.3rem;
}

.mr--14 {
  margin-right: 1.4rem;
}

.mr--15 {
  margin-right: 1.5rem;
}

.mr--16 {
  margin-right: 1.6rem;
}

.mr--17 {
  margin-right: 1.7rem;
}

.mr--18 {
  margin-right: 1.8rem;
}

.mr--19 {
  margin-right: 1.9rem;
}

.mr--20 {
  margin-right: 2rem;
}

.mr--21 {
  margin-right: 2.1rem;
}

.mr--22 {
  margin-right: 2.2rem;
}

.mr--23 {
  margin-right: 2.3rem;
}

.mr--24 {
  margin-right: 2.4rem;
}

.mr--25 {
  margin-right: 2.5rem;
}

.mr--26 {
  margin-right: 2.6rem;
}

.mr--27 {
  margin-right: 2.7rem;
}

.mr--28 {
  margin-right: 2.8rem;
}

.mr--29 {
  margin-right: 2.9rem;
}

.mr--30 {
  margin-right: 3rem;
}

.mr--35 {
  margin-right: 3.5rem;
}

.mr--40 {
  margin-right: 4rem;
}

.mr--45 {
  margin-right: 4.5rem;
}

.mr--50 {
  margin-right: 5rem;
}

.mr--60 {
  margin-right: 6rem;
}

.mr--70 {
  margin-right: 7rem;
}

.mr--80 {
  margin-right: 8rem;
}

.mr--90 {
  margin-right: 9rem;
}

.mr--100 {
  margin-right: 10rem;
}

.pt--0 {
  padding-top: 0;
}

.pt--1 {
  padding-top: 0.1rem;
}

.pt--2 {
  padding-top: 0.2rem;
}

.pt--3 {
  padding-top: 0.3rem;
}

.pt--4 {
  padding-top: 0.4rem;
}

.pt--5 {
  padding-top: 0.5rem;
}

.pt--6 {
  padding-top: 0.6rem;
}

.pt--7 {
  padding-top: 0.7rem;
}

.pt--8 {
  padding-top: 0.8rem;
}

.pt--9 {
  padding-top: 0.9rem;
}

.pt--10 {
  padding-top: 1rem;
}

.pt--11 {
  padding-top: 1.1rem;
}

.pt--12 {
  padding-top: 1.2rem;
}

.pt--13 {
  padding-top: 1.3rem;
}

.pt--14 {
  padding-top: 1.4rem;
}

.pt--15 {
  padding-top: 1.5rem;
}

.pt--16 {
  padding-top: 1.6rem;
}

.pt--17 {
  padding-top: 1.7rem;
}

.pt--18 {
  padding-top: 1.8rem;
}

.pt--19 {
  padding-top: 1.9rem;
}

.pt--20 {
  padding-top: 2rem;
}

.pt--21 {
  padding-top: 2.1rem;
}

.pt--22 {
  padding-top: 2.2rem;
}

.pt--23 {
  padding-top: 2.3rem;
}

.pt--24 {
  padding-top: 2.4rem;
}

.pt--25 {
  padding-top: 2.5rem;
}

.pt--26 {
  padding-top: 2.6rem;
}

.pt--27 {
  padding-top: 2.7rem;
}

.pt--28 {
  padding-top: 2.8rem;
}

.pt--29 {
  padding-top: 2.9rem;
}

.pt--30 {
  padding-top: 3rem;
}

.pt--35 {
  padding-top: 3.5rem;
}

.pt--40 {
  padding-top: 4rem;
}

.pt--45 {
  padding-top: 4.5rem;
}

.pt--50 {
  padding-top: 5rem;
}

.pt--60 {
  padding-top: 6rem;
}

.pt--70 {
  padding-top: 7rem;
}

.pt--80 {
  padding-top: 8rem;
}

.pt--90 {
  padding-top: 9rem;
}

.pt--100 {
  padding-top: 10rem;
}

.pb--0 {
  padding-bottom: 0;
}

.pb--1 {
  padding-bottom: 0.1rem;
}

.pb--2 {
  padding-bottom: 0.2rem;
}

.pb--3 {
  padding-bottom: 0.3rem;
}

.pb--4 {
  padding-bottom: 0.4rem;
}

.pb--5 {
  padding-bottom: 0.5rem;
}

.pb--6 {
  padding-bottom: 0.6rem;
}

.pb--7 {
  padding-bottom: 0.7rem;
}

.pb--8 {
  padding-bottom: 0.8rem;
}

.pb--9 {
  padding-bottom: 0.9rem;
}

.pb--10 {
  padding-bottom: 1rem;
}

.pb--11 {
  padding-bottom: 1.1rem;
}

.pb--12 {
  padding-bottom: 1.2rem;
}

.pb--13 {
  padding-bottom: 1.3rem;
}

.pb--14 {
  padding-bottom: 1.4rem;
}

.pb--15 {
  padding-bottom: 1.5rem;
}

.pb--16 {
  padding-bottom: 1.6rem;
}

.pb--17 {
  padding-bottom: 1.7rem;
}

.pb--18 {
  padding-bottom: 1.8rem;
}

.pb--19 {
  padding-bottom: 1.9rem;
}

.pb--20 {
  padding-bottom: 2rem;
}

.pb--21 {
  padding-bottom: 2.1rem;
}

.pb--22 {
  padding-bottom: 2.2rem;
}

.pb--23 {
  padding-bottom: 2.3rem;
}

.pb--24 {
  padding-bottom: 2.4rem;
}

.pb--25 {
  padding-bottom: 2.5rem;
}

.pb--26 {
  padding-bottom: 2.6rem;
}

.pb--27 {
  padding-bottom: 2.7rem;
}

.pb--28 {
  padding-bottom: 2.8rem;
}

.pb--29 {
  padding-bottom: 2.9rem;
}

.pb--30 {
  padding-bottom: 3rem;
}

.pb--35 {
  padding-bottom: 3.5rem;
}

.pb--40 {
  padding-bottom: 4rem;
}

.pb--45 {
  padding-bottom: 4.5rem;
}

.pb--50 {
  padding-bottom: 5rem;
}

.pb--60 {
  padding-bottom: 6rem;
}

.pb--70 {
  padding-bottom: 7rem;
}

.pb--80 {
  padding-bottom: 8rem;
}

.pb--90 {
  padding-bottom: 9rem;
}

.pb--100 {
  padding-bottom: 10rem;
}

.pl--0 {
  padding-left: 0;
}

.pl--1 {
  padding-left: 0.1rem;
}

.pl--2 {
  padding-left: 0.2rem;
}

.pl--3 {
  padding-left: 0.3rem;
}

.pl--4 {
  padding-left: 0.4rem;
}

.pl--5 {
  padding-left: 0.5rem;
}

.pl--6 {
  padding-left: 0.6rem;
}

.pl--7 {
  padding-left: 0.7rem;
}

.pl--8 {
  padding-left: 0.8rem;
}

.pl--9 {
  padding-left: 0.9rem;
}

.pl--10 {
  padding-left: 1rem;
}

.pl--11 {
  padding-left: 1.1rem;
}

.pl--12 {
  padding-left: 1.2rem;
}

.pl--13 {
  padding-left: 1.3rem;
}

.pl--14 {
  padding-left: 1.4rem;
}

.pl--15 {
  padding-left: 1.5rem;
}

.pl--16 {
  padding-left: 1.6rem;
}

.pl--17 {
  padding-left: 1.7rem;
}

.pl--18 {
  padding-left: 1.8rem;
}

.pl--19 {
  padding-left: 1.9rem;
}

.pl--20 {
  padding-left: 2rem;
}

.pl--21 {
  padding-left: 2.1rem;
}

.pl--22 {
  padding-left: 2.2rem;
}

.pl--23 {
  padding-left: 2.3rem;
}

.pl--24 {
  padding-left: 2.4rem;
}

.pl--25 {
  padding-left: 2.5rem;
}

.pl--26 {
  padding-left: 2.6rem;
}

.pl--27 {
  padding-left: 2.7rem;
}

.pl--28 {
  padding-left: 2.8rem;
}

.pl--29 {
  padding-left: 2.9rem;
}

.pl--30 {
  padding-left: 3rem;
}

.pl--35 {
  padding-left: 3.5rem;
}

.pl--40 {
  padding-left: 4rem;
}

.pl--45 {
  padding-left: 4.5rem;
}

.pl--50 {
  padding-left: 5rem;
}

.pl--60 {
  padding-left: 6rem;
}

.pl--70 {
  padding-left: 7rem;
}

.pl--80 {
  padding-left: 8rem;
}

.pl--90 {
  padding-left: 9rem;
}

.pl--100 {
  padding-left: 10rem;
}

.pr--0 {
  padding-right: 0;
}

.pr--1 {
  padding-right: 0.1rem;
}

.pr--2 {
  padding-right: 0.2rem;
}

.pr--3 {
  padding-right: 0.3rem;
}

.pr--4 {
  padding-right: 0.4rem;
}

.pr--5 {
  padding-right: 0.5rem;
}

.pr--6 {
  padding-right: 0.6rem;
}

.pr--7 {
  padding-right: 0.7rem;
}

.pr--8 {
  padding-right: 0.8rem;
}

.pr--9 {
  padding-right: 0.9rem;
}

.pr--10 {
  padding-right: 1rem;
}

.pr--11 {
  padding-right: 1.1rem;
}

.pr--12 {
  padding-right: 1.2rem;
}

.pr--13 {
  padding-right: 1.3rem;
}

.pr--14 {
  padding-right: 1.4rem;
}

.pr--15 {
  padding-right: 1.5rem;
}

.pr--16 {
  padding-right: 1.6rem;
}

.pr--17 {
  padding-right: 1.7rem;
}

.pr--18 {
  padding-right: 1.8rem;
}

.pr--19 {
  padding-right: 1.9rem;
}

.pr--20 {
  padding-right: 2rem;
}

.pr--21 {
  padding-right: 2.1rem;
}

.pr--22 {
  padding-right: 2.2rem;
}

.pr--23 {
  padding-right: 2.3rem;
}

.pr--24 {
  padding-right: 2.4rem;
}

.pr--25 {
  padding-right: 2.5rem;
}

.pr--26 {
  padding-right: 2.6rem;
}

.pr--27 {
  padding-right: 2.7rem;
}

.pr--28 {
  padding-right: 2.8rem;
}

.pr--29 {
  padding-right: 2.9rem;
}

.pr--30 {
  padding-right: 3rem;
}

.pr--35 {
  padding-right: 3.5rem;
}

.pr--40 {
  padding-right: 4rem;
}

.pr--45 {
  padding-right: 4.5rem;
}

.pr--50 {
  padding-right: 5rem;
}

.pr--60 {
  padding-right: 6rem;
}

.pr--70 {
  padding-right: 7rem;
}

.pr--80 {
  padding-right: 8rem;
}

.pr--90 {
  padding-right: 9rem;
}

.pr--100 {
  padding-right: 10rem;
}

/*--------------------------------------
	# Section Spacing
---------------------------------------*/
/* padding-top: 108px; padding-bottom: 120px; */
.sec-space120 {
  padding-top: 108px;
  padding-bottom: 120px;
}

@media only screen and (max-width: 991px) {
  .sec-space120 {
    padding-top: 9.3rem;
    padding-bottom: 10.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .sec-space120 {
    padding-top: 8.3rem;
    padding-bottom: 9.5rem;
  }
}

@media only screen and (max-width: 575px) {
  .sec-space120 {
    padding-top: 7.3rem;
    padding-bottom: 8.5rem;
  }
}

/* padding-top: 108px; padding-bottom: 90px; */
.sec2-space120 {
  padding-top: 108px;
  padding-bottom: 90px;
}

@media only screen and (max-width: 991px) {
  .sec2-space120 {
    padding-top: 9.3rem;
    padding-bottom: 7.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .sec2-space120 {
    padding-top: 8.3rem;
    padding-bottom: 6.5rem;
  }
}

@media only screen and (max-width: 575px) {
  .sec2-space120 {
    padding-top: 7.3rem;
    padding-bottom: 5.5rem;
  }
}

/* padding-top: 108px; padding-bottom: 108px; */
.sec3-space120 {
  padding-top: 108px;
  padding-bottom: 108px;
}

@media only screen and (max-width: 991px) {
  .sec3-space120 {
    padding-top: 9.3rem;
    padding-bottom: 9.3rem;
  }
}

@media only screen and (max-width: 767px) {
  .sec3-space120 {
    padding-top: 8.3rem;
    padding-bottom: 8.3rem;
  }
}

@media only screen and (max-width: 575px) {
  .sec3-space120 {
    padding-top: 7.3rem;
    padding-bottom: 7.3rem;
  }
}

/* padding-top: 111px; padding-bottom: 108px; */
.sec3-2-space120 {
  padding-top: 111px;
  padding-bottom: 114px;
}

@media only screen and (max-width: 991px) {
  .sec3-2-space120 {
    padding-top: 9.6rem;
    padding-bottom: 9.9rem;
  }
}

@media only screen and (max-width: 767px) {
  .sec3-2-space120 {
    padding-top: 8.6rem;
    padding-bottom: 8.9rem;
  }
}

@media only screen and (max-width: 575px) {
  .sec3-2-space120 {
    padding-top: 7.6rem;
    padding-bottom: 7.9rem;
  }
}

/* padding-top: 108px; padding-bottom: 112px; */
.sec4-space120 {
  padding-top: 108px;
  padding-bottom: 112px;
}

@media only screen and (max-width: 991px) {
  .sec4-space120 {
    padding-top: 9.3rem;
    padding-bottom: 9.7rem;
  }
}

@media only screen and (max-width: 767px) {
  .sec4-space120 {
    padding-top: 8.3rem;
    padding-bottom: 8.7rem;
  }
}

@media only screen and (max-width: 575px) {
  .sec4-space120 {
    padding-top: 7.3rem;
    padding-bottom: 7.7rem;
  }
}

/* padding-top: 112px; padding-bottom: 120px; */
.sec5-space120 {
  padding-top: 112px;
  padding-bottom: 120px;
}

@media only screen and (max-width: 991px) {
  .sec5-space120 {
    padding-top: 9.7rem;
    padding-bottom: 10.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .sec5-space120 {
    padding-top: 8.7rem;
    padding-bottom: 9.5rem;
  }
}

@media only screen and (max-width: 575px) {
  .sec5-space120 {
    padding-top: 7.7rem;
    padding-bottom: 8.5rem;
  }
}

/* padding-top: 120px; padding-bottom: 90px; */
.sec6-space120 {
  padding-top: 120px;
  padding-bottom: 90px;
}

@media only screen and (max-width: 991px) {
  .sec6-space120 {
    padding-top: 10.5rem;
    padding-bottom: 7.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .sec6-space120 {
    padding-top: 9.5rem;
    padding-bottom: 6.5rem;
  }
}

@media only screen and (max-width: 575px) {
  .sec6-space120 {
    padding-top: 8.5rem;
    padding-bottom: 5.5rem;
  }
}

/* padding-top: 112px; padding-bottom: 90px; */
.sec7-space120 {
  padding-top: 112px;
  padding-bottom: 90px;
}

@media only screen and (max-width: 991px) {
  .sec7-space120 {
    padding-top: 9.7rem;
    padding-bottom: 7.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .sec7-space120 {
    padding-top: 8.7rem;
    padding-bottom: 6.5rem;
  }
}

@media only screen and (max-width: 575px) {
  .sec7-space120 {
    padding-top: 7.7rem;
    padding-bottom: 5.5rem;
  }
}

/* padding-top: 90px; padding-bottom: 120px; */
.sec8-space120 {
  padding-top: 90px;
  padding-bottom: 120px;
}

@media only screen and (max-width: 991px) {
  .sec8-space120 {
    padding-top: 7.5rem;
    padding-bottom: 10.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .sec8-space120 {
    padding-top: 6.5rem;
    padding-bottom: 9.5rem;
  }
}

@media only screen and (max-width: 575px) {
  .sec8-space120 {
    padding-top: 5.5rem;
    padding-bottom: 8.5rem;
  }
}

/* padding-top: 108px; padding-bottom: 120px; */
.sec9-space120 {
  padding-top: 108px;
  padding-bottom: 120px;
}

@media only screen and (max-width: 991px) {
  .sec9-space120 {
    padding-top: 9.3rem;
    padding-bottom: 10.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .sec9-space120 {
    padding-top: 8.3rem;
    padding-bottom: 9.5rem;
  }
}

@media only screen and (max-width: 575px) {
  .sec9-space120 {
    padding-top: 7.3rem;
    padding-bottom: 8.5rem;
  }
}

/* padding-top: 112px; padding-bottom: 112px; */
.sec10-space120 {
  padding-top: 111px;
  padding-bottom: 112px;
}

@media only screen and (max-width: 991px) {
  .sec10-space120 {
    padding-top: 9.6rem;
    padding-bottom: 9.7rem;
  }
}

@media only screen and (max-width: 767px) {
  .sec10-space120 {
    padding-top: 8.6rem;
    padding-bottom: 8.7rem;
  }
}

@media only screen and (max-width: 575px) {
  .sec10-space120 {
    padding-top: 7.6rem;
    padding-bottom: 7.7rem;
  }
}

/* padding-top: 120px; padding-bottom: 120px; */
.sec-space-default {
  padding-top: 120px;
  padding-bottom: 120px;
}

@media only screen and (max-width: 991px) {
  .sec-space-default {
    padding-top: 10.5rem;
    padding-bottom: 10.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .sec-space-default {
    padding-top: 9.5rem;
    padding-bottom: 9.5rem;
  }
}

@media only screen and (max-width: 575px) {
  .sec-space-default {
    padding-top: 8.5rem;
    padding-bottom: 8.5rem;
  }
}

/* padding-top: 120px; padding-bottom: 120px; */
.sec-space {
  padding-top: 120px;
  padding-bottom: 120px;
}

@media only screen and (max-width: 991px) {
  .sec-space {
    padding-top: 10.5rem;
    padding-bottom: 10.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .sec-space {
    padding-top: 9.5rem;
    padding-bottom: 9.5rem;
  }
}

@media only screen and (max-width: 575px) {
  .sec-space {
    padding-top: 8.5rem;
    padding-bottom: 8.5rem;
  }
}

/*========================================================================
02. Page Layout
=========================================================================*/
/*--------------------------------------
	2.3.1 Footer
---------------------------------------*/
/*--------------------------------------
	# Footer Style
---------------------------------------*/
footer.footer {
  background: url(../../media/background/footer-bg.jpg) no-repeat center top;
  background-size: cover;
}

footer.footer.landing-footer .footer-bottom {
  border-color: #6f4fc3;
}

footer.footer.landing-footer .footer-bottom .newsletter .news-form .input-field .form-input {
  background: #6f4fc3;
}

footer.footer.landing-footer .footer-bottom .newsletter .news-form .input-field input::placeholder {
  color: #ababab;
}

footer.footer.landing-footer .footer-bottom .newsletter .news-form .input-field i {
  color: #d4d3d6;
}

footer.footer.landing-footer .footer-bottom .footer-social li a {
  border-color: #7a5cc8;
}

footer.footer.landing-footer .footer-bottom .footer-social li a:hover {
  border-color: #ff2c54;
}

footer.footer.landing-footer .squaredTwo2 {
  border-color: #7a5cc8;
}

footer.footer.landing-footer .footer-copyright {
  border-color: #6f4fc3;
}

footer.footer .footer-top {
  padding: 4.1rem 0;
}

footer.footer .footer-top .footer-logo {
  margin-top: 1.3rem;
}

footer.footer .footer-top .contact-info {
  display: flex;
  color: #fff;
}

footer.footer .footer-top .contact-info .contact-icon i {
  font-size: 4.2rem;
}

footer.footer .footer-top .contact-info .contact-icon i:before {
  font-size: 4.2rem;
}

footer.footer .footer-top .contact-info .contact-text {
  padding-left: 1.7rem;
}

footer.footer .footer-top .contact-info .contact-text .contact-title {
  margin-top: .8rem;
}

footer.footer .footer-top .contact-info .contact-text .text {
  font-weight: 700;
  font-size: 1.8rem;
}

footer.footer .footer-top .contact-info .contact-text .text a {
  color: #fff;
}

footer.footer .footer-top .contact-info .contact-text .text a:hover {
  color: #ff2c54;
}

footer.footer .footer-bottom {
  padding-top: 5.5rem;
  padding-bottom: 5.8rem;
  border-top: 1px solid #2758e4;
}

footer.footer .footer-bottom .footer-social {
  margin-top: 2.8rem;
}

footer.footer .footer-bottom .footer-social li {
  display: inline-block;
  margin-right: 1rem;
}

footer.footer .footer-bottom .footer-social li:last-child {
  margin-right: 0;
}

footer.footer .footer-bottom .footer-social li a {
  color: #fff;
  border: 1px solid #566dd3;
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.3rem;
  border-radius: 100%;
  display: block;
  text-align: center;
  transition: all 0.4s ease 0s;
}

footer.footer .footer-bottom .footer-social li a:hover {
  background: #ff2c54;
  border-color: #ff2c54;
  transform: rotate(45deg);
}

footer.footer .footer-bottom .footer-social li i {
  font-weight: normal;
}

footer.footer .footer-bottom .footer-title {
  color: #fff;
  font-size: 2.4rem;
  margin-bottom: 3rem;
  font-weight: 600;
}

footer.footer .footer-bottom .sitemap-widget li {
  padding-bottom: 1.4rem;
}

footer.footer .footer-bottom .sitemap-widget li a {
  color: #fff;
  font-size: 1.7rem;
  position: relative;
  display: inline-block;
  background: linear-gradient(to right, #fff 0%, #fff 100%);
  background-size: 0px 1px;
  background-position: 0px 95%;
  transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  background-repeat: no-repeat;
}

footer.footer .footer-bottom .sitemap-widget li a:hover {
  background-size: 100% 1px;
  text-decoration: none !important;
}

footer.footer .footer-bottom .newsletter {
  padding-top: 1rem;
}

footer.footer .footer-bottom .newsletter .news-form .input-field {
  position: relative;
  margin-bottom: .8rem;
}

footer.footer .footer-bottom .newsletter .news-form .input-field .form-input {
  background: #102aa5;
  border: none;
  color: #fff;
  font-size: 1.3rem;
  height: 4.5rem;
  padding: .5rem 1.5rem .5rem 4rem;
  border-radius: .5rem;
  outline: none;
}

footer.footer .footer-bottom .newsletter .news-form .input-field i {
  position: absolute;
  color: #9fa0a4;
  left: 1rem;
  top: .8rem;
}

footer.footer .footer-bottom .newsletter .news-form .input-field .form-button {
  position: absolute;
  right: 0;
  top: 0;
  background: #ff2c54;
  font-family: "Open Sans", sans-serif;
  border: none;
  color: #fff;
  height: 4.5rem;
  font-size: 1.5rem;
  padding: 0 1.5rem;
  border-radius: 0 .5rem .5rem 0;
  cursor: pointer;
  transition: all 0.4s ease 0s;
  letter-spacing: 0;
  font-weight: normal;
  outline: none;
}

footer.footer .footer-bottom .newsletter .news-form span {
  font-size: 1.5rem;
  color: #fff;
  margin-left: 1.2rem;
}

footer.footer .footer-copyright {
  color: #fff;
  border-top: 1px solid #406eeb;
  padding: 1.5rem 0;
  font-size: 1.5rem;
}

footer.footer .footer-copyright p {
  margin-bottom: 0;
  font-size: 1.5rem;
}

footer.footer .footer-copyright .term-conditon ul li {
  display: inline-block;
  padding-left: .7rem;
}

footer.footer .footer-copyright .term-conditon ul li a {
  color: #fff;
}

footer.footer .footer-copyright .term-conditon ul li a:hover {
  text-decoration: underline;
}

footer.footer-layout2 {
  background: #fefefe;
}

footer.footer-layout2 .footer-top .contact-info {
  color: #333333;
}

footer.footer-layout2 .footer-top .contact-info .contact-icon {
  color: #b8b8b8;
}

footer.footer-layout2 .footer-top .contact-info .contact-text .text {
  color: #ff2c54;
}

footer.footer-layout2 .footer-top .contact-info .contact-text .text a {
  color: #ff2c54;
}

footer.footer-layout2 .footer-top .contact-info .contact-text .text a:hover {
  text-decoration: underline;
}

footer.footer-layout2 .footer-bottom {
  border-color: #eaeaea;
}

footer.footer-layout2 .footer-bottom .footer-social li a {
  color: #a3a3a3;
  border: 1px solid #a3a3a3;
}

footer.footer-layout2 .footer-bottom .footer-social li a:hover {
  color: #fff;
}

footer.footer-layout2 .footer-bottom .footer-title {
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

footer.footer-layout2 .footer-bottom .sitemap-widget li a {
  color: #333333;
  background: linear-gradient(to right, #2b2222 0%, #333 100%);
  background-size: 0px 1px;
  background-position: 0px 95%;
  transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  background-repeat: no-repeat;
}

footer.footer-layout2 .footer-bottom .newsletter .news-form span {
  color: #333333;
}

footer.footer-layout2 .footer-bottom .newsletter .news-form .input-field {
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.06);
}

footer.footer-layout2 .footer-bottom .newsletter .news-form .input-field .form-input {
  background: #fff;
  color: #333;
}

footer.footer-layout2 .footer-bottom .newsletter .squaredTwo2 {
  border-color: #dddddd;
}

footer.footer-layout2 .footer-bottom .newsletter .squaredTwo2 label::after {
  border-color: #666;
}

footer.footer-layout2 .footer-copyright {
  border-color: #eaeaea;
  color: #333333;
}

footer.footer-layout2 .footer-copyright .term-conditon ul li a {
  color: #333333;
  position: relative;
  display: inline-block;
  background: linear-gradient(to right, #333 0%, #333 100%);
  background-size: 0px 1px;
  background-position: 0px 95%;
  transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  background-repeat: no-repeat;
}

footer.footer-layout2 .footer-copyright .term-conditon ul li a:hover {
  background-size: 100% 1px;
  text-decoration: none !important;
}

footer.skew-footer {
  position: relative;
  background: url(../../media/footer/footer-bg.jpg) no-repeat center bottom;
  padding-top: 5rem;
  background-size: cover;
  padding-bottom: 2rem;
}

footer.skew-footer:after {
  content: "";
  height: 50rem;
  left: 0;
  position: absolute;
  top: -30rem;
  width: 100%;
  background: #fff;
  z-index: 1;
  transform: skewY(179deg);
}

@media only screen and (max-width: 2200px) {
  footer.skew-footer:after {
    top: -20rem;
    height: 40rem;
    transform: skewY(170deg);
  }
}

@media only screen and (max-width: 1680px) {
  footer.skew-footer:after {
    top: -15rem;
    height: 30rem;
  }
}

footer.skew-footer:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
}

footer.skew-footer .container {
  position: relative;
  z-index: 99;
}

footer.skew-footer .footer-top {
  max-width: 100rem;
  padding: 10rem 3rem 7.5rem 3rem;
  margin: 0 auto;
  background: #fff;
  position: relative;
  z-index: 9;
  box-shadow: 0 0 35px 15px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
}

footer.skew-footer .footer-top .section-title {
  max-width: 50.5rem;
  padding-bottom: 3rem;
  margin: 0 auto;
}

footer.skew-footer .footer-top .section-title .title {
  margin-bottom: 1.6rem;
}

footer.skew-footer .footer-top .news-form {
  max-width: 46rem;
  margin: 0 auto;
  position: relative;
}

footer.skew-footer .footer-top .news-form .form-input {
  border: 1px solid #dadada;
  height: 5rem;
  padding: 1rem 3rem;
  width: 99%;
  border-radius: 3.5rem;
  color: #7b7979;
  font-size: 1.4rem;
}

footer.skew-footer .footer-top .news-form .form-button {
  width: 18rem;
  height: 5rem;
  line-height: 2.9rem;
  padding: 1rem 1.5rem;
  box-shadow: none;
  color: #fff;
  background: #ff2c54;
  border: 1px solid #ff2c54;
  border-radius: 3.5rem;
  font-size: 1.8rem;
  font-weight: 600;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

footer.skew-footer .footer-top .news-form .form-button:before {
  border-radius: 3.5rem;
}

footer.skew-footer .footer-top .term-use {
  font-size: 1.5rem;
  color: #333333;
  margin-top: 2rem;
}

footer.skew-footer .footer-bottom {
  border-top: none;
  padding-bottom: 2rem;
}

footer.skew-footer .footer-bottom .footer-menu {
  text-align: center;
}

footer.skew-footer .footer-bottom .footer-menu ul li {
  display: inline-block;
  margin-right: 3.5rem;
}

footer.skew-footer .footer-bottom .footer-menu ul li a {
  color: #fff;
  font-size: 1.8rem;
}

footer.skew-footer .footer-bottom .footer-menu ul li a:hover {
  color: #ff2c54;
}

footer.skew-footer .footer-bottom .footer-menu ul li:last-child {
  margin-right: 0;
}

footer.skew-footer .footer-copyright {
  border-top: none;
}

footer.skew-footer .footer-copyright p {
  font-size: 1.6rem;
}

footer.footer-layout4 {
  background: #161618;
  color: #8e8e8e;
}

footer.footer-layout4 .footer-top .contact-info .contact-icon i {
  color: #ff2c54;
}

footer.footer-layout4 .footer-top .contact-info .contact-text .text {
  color: #8e8e8e;
}

footer.footer-layout4 .footer-top .contact-info .contact-text .text a {
  color: #8e8e8e;
}

footer.footer-layout4 .footer-bottom {
  border-color: #2d2e31;
}

footer.footer-layout4 .footer-bottom .newsletter .news-form span {
  color: #8e8e8e;
}

footer.footer-layout4 .footer-bottom .newsletter .news-form .input-field .form-input {
  background: #fff;
  outline: none;
  color: #666666;
}

footer.footer-layout4 .footer-bottom .newsletter .squaredTwo2 {
  border-color: #3a3b3e;
}

footer.footer-layout4 .footer-bottom .newsletter .squaredTwo2 label:after {
  border-color: #3a3b3e;
}

footer.footer-layout4 .footer-bottom .sitemap-widget li a {
  color: #8e8e8e;
  background: linear-gradient(to right, #8e8e8e 0%, #8e8e8e 100%);
  background-size: 0px 1px;
  background-position: 0px 95%;
  transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  background-repeat: no-repeat;
}

footer.footer-layout4 .footer-copyright {
  border-color: #2d2e31;
  color: #8e8e8e;
}

footer.footer-layout4 .footer-copyright .term-conditon ul li a {
  color: #8e8e8e;
}

footer.footer-layout5 {
  background: #05113b;
}

footer.footer-layout5 .footer-bottom .footer-social li a {
  border-color: #1e294f;
}

footer.footer-layout5 .footer-bottom .newsletter .news-form .input-field .form-input {
  background: #fff;
  outline: none;
  color: #333;
}

footer.footer-layout5 .footer-bottom .newsletter .squaredTwo2 {
  border-color: #2d375b;
}

footer.footer-layout5 .footer-bottom .newsletter .squaredTwo2 label:after {
  border-color: #2d375b;
}

footer.footer-layout5 .footer-bottom,
footer.footer-layout5 .footer-copyright {
  border-color: #1e294f;
}

footer.landing-footer .footer-top {
  padding: 10rem 0;
}

footer.landing-footer .footer-top .footer-logo {
  margin-top: 0;
  margin-bottom: 4rem;
}

footer.landing-footer .footer-top .purchase-btn {
  margin-top: 5.5rem;
}

footer.landing-footer .footer-title {
  color: #fff;
}

footer.landing-footer p {
  color: #fff;
}

/*--------------------------------------
	2.3.2 Header
---------------------------------------*/
/*--------------------------------------
	# Header Style
---------------------------------------*/
.header.header-layout1 .sign-btn a {
  border-color: #5a6ad2;
}

.header.header-layout4 .main-menu-area.sticky .two-icons li.sign-btn a {
  border-color: #ff2c54;
}

.header .main-menu-area {
  position: absolute;
  background: transparent;
  z-index: 9;
  width: 100%;
  padding-top: 13px;
}

.header .main-menu-area.sticky {
  background: #2554E1;
  position: fixed !important;
  top: 0px;
  z-index: 999;
  margin: 0 auto;
  padding: 0;
  left: 0;
  right: 0;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-name: sticky-animation;
  animation-name: sticky-animation;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.header .main-menu-area.sticky .header-bottom .mainmenu {
  float: none;
}

.header .main-menu-area.sticky .header-bottom .mainmenu .nav-menu > li.current_page_item > a {
  color: #ff2c54;
}

.header .main-menu-area.sticky .header-bottom .mainmenu .nav-menu > li > a {
  color: #fff;
}

.header .main-menu-area.sticky .header-bottom .mainmenu .nav-menu > li > a:hover {
  color: #ff2c54;
}

.header .main-menu-area.sticky .header-bottom .quote-section {
  float: right;
}

.header .main-menu-area.sticky .header-bottom .logo-area {
  height: 9rem;
  line-height: 8.4rem;
  float: left;
}

.header .main-menu-area.sticky .header-bottom .logo-area img {
  max-width: 26rem;
}

.header .main-menu-area.sticky .two-icons li.search .seach_bar i {
  color: #fff;
}

.header .main-menu-area.sticky .two-icons li.search .seach_bar a:hover i {
  color: #ff2c54;
}

.header .main-menu-area.sticky .two-icons li.sign-btn a {
  border-color: #fff;
}

.header .main-menu-area.sticky .logo .sticky-logo {
  display: block !important;
}

.header .main-menu-area.sticky .logo .dark-logo {
  display: none;
}

.header .main-menu-area.sticky .header-contact a {
  color: #fff !important;
}

.header .main-menu-area.sticky .header-contact a:hover {
  color: #e6e6e6 !important;
}

.header .main-menu-area .has-megamenu {
  position: relative;
}

.header .main-menu-area .has-megamenu .header-menu {
  position: static;
}

.header .main-menu-area .has-megamenu .header-menu .mega-menu-full {
  position: static;
}

.header .main-menu-area .has-megamenu .header-menu .mega-menu-full > .sub-menu {
  width: calc(100% - 30px);
  height: calc(100vh - 150px);
  left: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 1.5rem 2.5rem;
  overflow-y: scroll;
}

.header .main-menu-area .has-megamenu .header-menu .mega-menu-full > .sub-menu li {
  width: 25%;
  padding: 3rem 1.5rem 0;
  margin-bottom: 0;
  text-align: center;
}

.header .main-menu-area .has-megamenu .header-menu .mega-menu-full > .sub-menu li a {
  width: 100%;
  padding: 0;
  text-align: center;
}

.header .main-menu-area .has-megamenu .header-menu .mega-menu-full > .sub-menu li a .img {
  -webkit-box-shadow: 0px 4px 8px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 4px 8px 0px rgba(12, 0, 46, 0.06);
  position: relative;
  margin-top: 0;
  display: block;
  overflow: hidden;
}

.header .main-menu-area .has-megamenu .header-menu .mega-menu-full > .sub-menu li a .img:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: #13112d;
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}

.header .main-menu-area .has-megamenu .header-menu .mega-menu-full > .sub-menu li a:hover {
  background: transparent;
  color: #ff2c54 !important;
}

.header .main-menu-area .has-megamenu .header-menu .mega-menu-full > .sub-menu li a:hover .img:after {
  opacity: 0.5;
}

.header .main-menu-area .has-megamenu .header-menu .mega-menu-full > .sub-menu li a .text {
  margin-top: 1rem;
  display: block;
  font-size: 1.8rem;
  font-weight: 600;
}

.header .main-menu-area .has-megamenu .header-menu .mega-menu-full > .sub-menu li a .text:hover {
  color: #ff2c54 !important;
}

.header .main-menu-area .has-megamenu .header-menu .mega-menu-full.mega-menu-three > .sub-menu li {
  width: 33%;
}

.header .main-menu-area .has-megamenu .header-menu .mega-menu-full.mega-menu-six > .sub-menu li {
  width: 16.66%;
}

.header .main-menu-area .humberburg {
  position: absolute;
  left: 5rem;
  top: 2.2rem;
  width: 5.8rem;
  height: 5.8rem;
  line-height: 5.8rem;
  background: #ff2c54;
  text-align: center;
  border-radius: 100%;
  z-index: 9;
}

.header .main-menu-area .humberburg .nav-expander {
  color: #fff;
  cursor: pointer;
}

.header .main-menu-area .right-btn {
  position: absolute;
  right: 5rem;
  top: 2.2rem;
}

.header .main-menu-area .right-btn a {
  min-width: 19.5rem;
  padding: 1.2rem 2.5rem;
  border-radius: 3rem;
  box-shadow: none;
}

.header .main-menu-area .right-btn a:before {
  border-radius: 3rem;
}

.header .main-menu-area .two-icons {
  text-align: right;
}

.header .main-menu-area .two-icons li {
  font-size: 1.5rem;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  padding: 2rem 0;
}

.header .main-menu-area .two-icons li.cart {
  padding-right: 1.6rem;
}

.header .main-menu-area .two-icons li.cart span {
  background: #ff2c54;
  color: #fff;
  position: relative;
  padding: .2rem .5rem;
  bottom: .8rem;
  border-radius: 100%;
  font-size: 1rem;
  font-weight: 400;
  right: .7rem;
}

.header .main-menu-area .two-icons li.search .seach_bar {
  position: relative;
  top: .2rem;
}

.header .main-menu-area .two-icons li.search .seach_bar i {
  cursor: pointer;
  color: #8c9cf8;
  font-size: 2.2rem;
  font-weight: 400;
  transition: all 0.4s ease 0s;
}

.header .main-menu-area .two-icons li.search .seach_bar i:before {
  font-size: 2.2rem;
}

.header .main-menu-area .two-icons li.search .seach_bar a:hover i {
  color: #ff2c54;
}

.header .main-menu-area .two-icons li.sign-btn {
  padding-left: 3.5rem;
}

.header .main-menu-area .two-icons li.sign-btn a {
  color: #fff;
}

.header .main-menu-area .two-icons li.sign-btn a:hover {
  border-color: #ff224c;
  color: #fff;
}

.header .main-menu-area .navbar-collapse {
  padding-left: 0;
  padding-right: 0;
}

.header .main-menu-area .navbar {
  margin-bottom: 0;
  border: 0;
  font-family: "Open Sans", sans-serif;
}

.header .main-menu-area .navbar ul {
  margin: 0;
  padding: 0;
  text-align: left;
}

.header .main-menu-area .navbar ul li {
  height: 5.3rem;
  line-height: 5.3rem;
  position: relative;
  display: inline-block;
  transition: all 0.4s ease 0s;
}

.header .main-menu-area .navbar ul li + li {
  margin-left: 3.5rem;
}

.header .main-menu-area .navbar ul li > a {
  display: block;
  text-decoration: none;
  color: #fff;
  font-size: 1.3rem;
  letter-spacing: .1rem;
  font-weight: 600;
  transition: all 0.4s ease 0s;
  text-transform: uppercase;
}

.header .main-menu-area .navbar ul li > a i {
  margin-left: .5rem;
}

.header .main-menu-area .navbar ul li > a:after {
  content: '';
  position: absolute;
  height: .3rem;
  width: 0px;
  background-color: #2554E1;
  transition: .5s ease width;
  left: 0;
  bottom: 0;
}

.header .main-menu-area .navbar .navbar-toggle {
  background-color: #ff2c54;
  border-radius: .2rem;
  -webkit-transition: .4s ease all;
  transition: .4s ease all;
}

.header .main-menu-area .navbar .navbar-toggle .icon-bar {
  background-color: #fff;
}

.header .main-menu-area .navbar .navbar-toggle:hover {
  opacity: 0.8;
}

.header .main-menu-area .quote {
  text-align: right;
}

.header .main-menu-area .quote a {
  color: #fff;
  line-height: 5.5rem;
  font-size: 1.3rem;
  letter-spacing: .1rem;
  padding: 0 1.2rem;
  height: 5.5rem;
  display: inline-block;
  background: #2554E1;
  text-transform: uppercase;
}

.header .main-menu-area .quote a:hover {
  color: #ff2c54;
}

.header .main-menu-area .quote li {
  display: inline-block;
}

.header .main-menu-area .quote li .seach_bar {
  margin-left: 4.5rem;
}

.header .main-menu-area .quote li .seach_bar i {
  cursor: pointer;
  color: #fff;
  font-size: 1.6rem;
}

.header .main-menu-area .quote li .search_box {
  border-radius: 0;
  display: none;
  position: absolute;
  right: 1.5rem;
  top: 100%;
  z-index: 1000;
  width: 32rem;
}

.header .main-menu-area .quote li .search_box input {
  background: #eee;
  border: 0 none;
  padding: 1rem 1.5rem;
  width: 24rem;
  box-shadow: -0.1rem 0.2rem 0.4rem rgba(0, 0, 0, 0.15);
  height: 4.5rem;
  position: absolute;
  right: 1.1rem;
  width: 95%;
}

.header .main-menu-area .quote li .search_box button {
  background: #2554E1;
  border: 0 none;
  border-radius: 0 .3rem .3rem 0;
  color: #fff;
  cursor: pointer;
  float: right;
  height: 4.5rem;
  overflow: visible;
  padding: 0;
  position: relative;
  text-shadow: 0 -0.1rem 0 rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
  width: 6.4rem;
}

.header .main-menu-area .quote li .search_box button:hover {
  background: #1e4dd9;
}

.header .header-bottom .mainmenu {
  float: none;
}

.header .header-bottom .mainmenu .nav-menu li.current_page_item > a {
  color: #ff2c54;
}

.header .header-bottom .mainmenu .nav-menu li a:hover {
  color: #ff2c54;
}

.header .header-bottom .quote-section {
  float: right;
}

.header .header-bottom .logo-area {
  height: 9rem;
  line-height: 8.4rem;
  float: left;
}

.header .header-bottom .logo-area img {
  max-width: 26rem;
}

.em-menu {
  clear: both;
  float: left;
  font-size: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
}

.em-menu li {
  display: inline-block;
  font-size: 1.4rem;
  position: relative;
  vertical-align: top;
}

.em-menu li:hover > ul.mega-menu > li > ul {
  position: relative;
}

.em-menu li i {
  font-size: 1.6rem;
  margin-right: 1rem;
  transform: translate3d(0, 0, 0);
}

.em-menu a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #4a4a4a;
  display: block;
  font-weight: 400;
  padding: 1.6rem;
}

.em-menu .row {
  float: left;
  position: relative;
  width: 100%;
}

.em-menu .row .col-12 {
  width: 100%;
}

.em-menu .row .col-11 {
  width: 91.45833333333334%;
}

.em-menu .row .col-10 {
  width: 82.91666666666667%;
}

.em-menu .row .col-9 {
  width: 74.375%;
}

.em-menu .row .col-8 {
  width: 65.83333333333334%;
}

.em-menu .row .col-7 {
  width: 57.29166666666667%;
}

.em-menu .row .col-6 {
  width: 48.75%;
}

.em-menu .row .col-5 {
  width: 40.208333333333336%;
}

.em-menu .row .col-4 {
  width: 31.666666666666668%;
}

.em-menu .row .col-3 {
  width: 23.125%;
}

.em-menu .row .col-2 {
  width: 14.583333333333334%;
}

.em-menu .row .col-1 {
  width: 6.041666666666667%;
}

.em-menu ul {
  text-align: center;
}

.em-menu ul ul {
  text-align: left;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 100%;
  transform: scaleY(0);
  transform-origin: 0 0 0;
  z-index: 99;
  padding: 0;
  left: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  background: #fff;
  border-bottom: 0.3rem solid #ff2c54;
  box-shadow: 0px 1rem 4rem 0 rgba(0, 0, 0, 0.08);
}

.em-menu ul ul li a {
  color: #333333;
  font-weight: 400;
}

.em-menu ul ul li a:hover {
  background: #ff133f;
  color: #fff !important;
}

.em-menu ul ul li:last-child {
  border-bottom: 0;
}

.em-menu ul ul li.active > a {
  background: #ff133f;
  color: #fff !important;
}

.em-menu ul ul a {
  font-size: 1.5rem;
  text-align: left;
  width: 20rem;
}

.em-menu ul ul li > ul {
  left: 100%;
}

.em-menu ul ul ul {
  top: 0;
}

.em-menu ul ul.visible {
  display: block;
  opacity: 0;
  visibility: hidden;
  z-index: 17;
}

.em-menu ul .em-mega-menu ul.visible ul.sub-menu {
  display: block;
  z-index: 17;
}

.em-menu ul li a:hover {
  color: #ff2c54;
}

.em-menu img {
  -ms-interpolation-mode: bicubic;
  height: auto;
  max-width: 100%;
}

.em-menu h2 {
  font-size: 2.5rem;
  font-size: 2rem;
  line-height: 1.2em;
  line-height: 1.2em;
}

.em-menu h3 {
  font-size: 2rem;
  font-size: 3.3rem;
}

.em-menu h4 {
  font-size: 1rem;
  font-size: 2.8rem;
}

.em-menu h1 {
  font-size: 3.2rem;
  line-height: 1.2em;
}

.em-menu .row[class*="col-"] {
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  float: left;
  float: left !important;
  margin-left: 0 !important;
  margin-left: 2.5%;
  margin-right: 0 !important;
  min-height: .5rem;
  width: 100%;
  width: 100% !important;
}

.em-menu .row[class*="col-"]:first-child {
  margin-left: 0 !important;
}

.em-menu ul > li > a {
  padding: 3.7rem 0;
  position: relative;
}

.mobile-menu {
  margin-top: -4.6rem;
}

.mobile-menu .em-menu {
  margin-top: 1.5rem;
  float: none;
}

.mobile-menu .em-menu li {
  display: block;
  margin-right: 0;
}

.mobile-menu .em-menu ul {
  background: #1d49d0;
}

.mobile-menu .em-menu ul > li > a {
  color: #fff;
  padding: 1.5rem 2.5rem;
}

.mobile-menu .em-menu ul li a:hover {
  color: #ff2c54 !important;
}

.mobile-menu .em-menu ul .sub-menu li a {
  padding-left: 5rem;
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.15);
}

.mobile-menu .em-menu ul .sub-menu li a:hover {
  color: #ff2c54 !important;
}

.mobile-menu .em-menu ul .sub-menu li.active > a {
  color: #ff2c54 !important;
}

.mobile-menu .em-menu ul .sub-menu .sub-menu a {
  padding-left: 7.5rem;
}

.em-menu ul, .em-menu li, .em-menu p, .em-menu a, .em-menu div, .em-menu i {
  border: 0;
  margin: 0;
  padding: 0;
}

.em-menu > ul > li:last-child {
  margin-right: 0;
}

.em-menu .sub-menu li {
  line-height: inherit;
}

.em-menu .sub-menu li a {
  padding: 8px 14px;
}

.nav-menu > li > a {
  color: #ffffff;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: left;
}

.em-mega-menu .services-details i {
  color: #ff2c54;
}

.em-mega-menu .services-details img {
  margin-right: 1rem;
}

.nav-menu li .mega-menu li a {
  display: inline-block;
  padding: 0;
  width: auto;
}

.nav-menu .em-mega-menu {
  position: relative;
  position: static;
}

.nav-menu .em-mega-menu a {
  padding-right: 2.4rem;
}

.nav-menu .em-mega-menu a:after {
  float: right;
  margin: 0 -1.6rem 0 0;
  position: static;
}

.nav-menu .mega-menu {
  left: 0;
  position: absolute;
  width: 100%;
}

.nav-menu .mega-menu .mega-menu-container {
  float: left;
  padding: 0px;
  padding: 1.6rem 1.6rem 0 1.6rem;
  position: relative;
  width: 100%;
}

.nav-menu .mega-menu .sub-menu {
  box-shadow: none;
  display: block;
  min-height: initial;
  padding: 0;
  position: relative;
  width: auto;
}

.nav-menu .mega-menu .sub-menu a {
  padding: .9rem .5rem;
  width: 100%;
}

.nav-menu .mega-menu .sub-menu li {
  width: 100%;
}

.nav-menu .mega-menu li div.mega-menu-innner .single-magemenu {
  float: left;
  padding: .7rem 1.2rem .3rem;
  width: 25%;
  width: 100%;
}

.nav-menu .mega-menu li div.mega-menu-innner .single-magemenu ul {
  border-width: 0;
}

.nav-menu .mega-menu li div.mega-menu-img a:hover img {
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.nav-menu .mega-menu > li .mega-menu-img-meta {
  -webkit-backface-visibility: hidden;
  height: auto;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: auto;
}

.nav-menu ul {
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.nav-menu .menu-item-has-children > span {
  display: none;
}

.nav-menu .em-mega-menu > span {
  display: none;
}

.nav-menu span.em-menu-parent i {
  color: #ffffff;
  margin: 0;
}

.nav-menu > .menu-item-has-children > a span:after {
  content: "\f107";
  float: right;
  font-family: FontAwesome;
  margin: .2rem 0 0 .5rem;
}

#map {
  height: 50rem;
}

.em-menu li:active > ul,
.em-menu li:focus > ul,
.em-menu li:hover > ul,
.em-menu li:hover > ul.mega-menu > li > ul,
.em-menu li:hover ul.mega-menu .sub-menu {
  display: block;
  z-index: 17;
  opacity: 1;
  transform: scaleY(1);
  visibility: visible;
}

.em-menu > ul > li {
  margin-right: 5rem;
}

.em-menu > ul > li:hover a:before {
  width: 100%;
}

.em-menu > ul > li.current-menu-item > a:before {
  width: 100%;
}

.em-menu > ul > li.current_page_item > a:before {
  width: 100%;
}

.nav-menu > li:last-child > a {
  border-bottom: none;
}

.nav-menu > .menu-item-has-children .sub-menu .menu-item-has-children span.em-menu-parent {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-left: 0.1rem solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  display: block;
  line-height: 1.5rem;
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
}

@media only screen and (max-width: 991px) {
  .em-menu ul {
    background: #45484a;
  }
  .em-menu .row [class*="col-"] {
    float: left !important;
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .nav-menu > li > a {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: #fff;
    text-align: left;
  }
  .nav-menu > li:last-child > a {
    border-bottom: none;
  }
  .nav-menu span.em-menu-parent i {
    margin: 0;
    color: #fff;
  }
  .nav-menu > .menu-item-has-children > span.em-menu-parent, .nav-menu .em-mega-menu > span.em-menu-parent {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    padding: 1.8rem 2rem;
    cursor: pointer;
    line-height: 23px;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    /* Remove the blue Webkit background when element is tapped */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .nav-menu > .menu-item-has-children > span.em-menu-parent:hover, .nav-menu .em-mega-menu > span.em-menu-parent:hover {
    color: #fff;
  }
  .nav-menu > .menu-item-has-children .sub-menu .menu-item-has-children span.em-menu-parent {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px;
    cursor: pointer;
    line-height: 15px;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }
  /*Options Menu Closed*/
  .nav-menu div.sub-menu-close {
    float: left;
    width: 100%;
    padding: 16px;
    cursor: pointer;
    background: #f6f3ed;
    color: #000;
    font-size: 14px;
    text-align: center;
  }
  .nav-menu div.sub-menu-close i {
    font-size: 16px;
    margin-right: 5px;
  }
  .em-menu .sub-menu {
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    -o-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }
  .header .main-menu-area {
    padding: 1.3rem 0;
  }
  .header .logo {
    margin-top: 0;
  }
  /*Menu Toggle Appearance*/
  .em-menu-toggle {
    padding: 0.5rem 1.5rem;
    display: inline-block;
    border: 1px solid #fff;
    position: relative;
    cursor: pointer;
    color: #fff !important;
    font-size: 2rem;
    text-transform: uppercase;
    /* Remove the blue Webkit background when element is tapped */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .em-menu ul ul li {
    border-color: #ececec;
  }
  .em-menu ul ul li a {
    background: #fff;
    color: #101010;
  }
  .em-menu ul li a:hover,
  .em-menu ul ul li a:hover {
    color: #EEA303;
  }
  .em-menu {
    display: none;
  }
}

@media only screen and (max-width: 1080px) {
  .nav-menu > li > a i {
    display: none;
  }
}

.header-search {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #2554E1;
  transition: all 0.5s ease-in-out;
  transform: translate(0px, -100%) scale(0, 0);
  opacity: 0;
  visibility: hidden;
  z-index: 999999;
}

.header-search .close-sidenav div {
  background: #fff;
}

.header-search .header-search-form {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.header-search .header-search-form input[type="search"] {
  width: 60%;
  color: white;
  font-size: 24px;
  text-align: left;
  border: none;
  margin: 0px auto;
  padding: 2.5rem 1rem 2.5rem 3rem;
  outline: none;
  background: linear-gradient(to right, transparent, rgba(200, 200, 200, 0.5), transparent);
}

.header-search .header-search-form .search-btn {
  padding: 2.4rem 1rem;
  background-color: transparent;
  box-shadow: none;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
}

.header-search .header-search-form .search-btn i:before {
  font-size: 2.4rem;
  font-weight: 700;
  color: #ffffff;
  margin-left: 0;
}

.header-search .header-search-form .search-btn:focus {
  box-shadow: none;
  outline: none;
}

.header-search .close {
  position: fixed;
  top: 2.5rem;
  right: 2.5rem;
  color: #fff;
  background-color: #ff2c54;
  border: none;
  opacity: 1;
  visibility: visible;
  font-size: 3rem;
  font-weight: normal;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: 4rem;
  height: 4rem;
  text-shadow: none;
}

.header-search .close:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

.header-search .close:hover {
  background-color: rgba(255, 44, 84, 0.95);
}

body.open-search {
  overflow: hidden;
  position: relative;
}

.header-search.open {
  transform: translate(0px, 0px) scale(1, 1);
  opacity: 1;
  visibility: visible;
}

.menu-toggle {
  position: relative;
  float: right;
  height: 4.5rem;
  width: 5rem;
  cursor: pointer;
  overflow: hidden;
}

.menu-toggle .menu-icon {
  position: absolute;
  width: 30px;
  height: 20px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.menu-toggle .menu-icon .menu-line {
  position: absolute;
  left: 0;
  background-color: #c4ae91;
  height: 3px;
  width: 100%;
  border-radius: 3px;
  pointer-events: none;
  background-color: #eee;
  height: 2px;
}

.menu-toggle .menu-icon .menu-line.menu-line--1 {
  transition: width .2s ease-in-out .4s;
  top: 0;
}

.menu-toggle .menu-icon .menu-line.menu-line--2 {
  top: 0;
  bottom: 0;
  margin: auto;
  transition: width .2s ease-in-out .3s;
}

.menu-toggle .menu-icon .menu-line.menu-line--3 {
  transition: width .2s ease-in-out .2s;
  bottom: 0;
}

.menu-toggle .menu-icon .menu-line.menu-line--4 {
  -webkit-transform: rotate(45deg) translate3d(-30px, 0, 0);
  transform: rotate(45deg) translate3d(-30px, 0, 0);
  transition: opacity .2s ease-in-out .2s,-webkit-transform .2s ease-in-out .2s;
  transition: opacity .2s ease-in-out .2s,transform .2s ease-in-out .2s;
  transition: opacity .2s ease-in-out .2s,transform .2s ease-in-out .2s,-webkit-transform .2s ease-in-out .2s;
}

.menu-toggle .menu-icon .menu-line.menu-line--5 {
  -webkit-transform: rotate(-45deg) translate3d(30px, 0, 0);
  transform: rotate(-45deg) translate3d(30px, 0, 0);
  transition: opacity .2s ease-in-out,-webkit-transform .2s ease-in-out;
  transition: opacity .2s ease-in-out,transform .2s ease-in-out;
  transition: opacity .2s ease-in-out,transform .2s ease-in-out,-webkit-transform .2s ease-in-out;
}

.menu-toggle .menu-icon .menu-line.menu-line--4, .menu-toggle .menu-icon .menu-line.menu-line--5 {
  opacity: 0;
  top: 8.5px;
}

.menu-toggle.open {
  margin-bottom: 1rem;
}

.menu-toggle.open .menu-icon .menu-line.menu-line--1 {
  width: 0;
  transition: width .2s ease-in-out;
}

.menu-toggle.open .menu-icon .menu-line.menu-line--2 {
  width: 0;
  transition: width .2s ease-in-out .1s;
}

.menu-toggle.open .menu-icon .menu-line.menu-line--3 {
  width: 0;
  transition: width .2s ease-in-out .2s;
}

.menu-toggle.open .menu-icon .menu-line.menu-line--4 {
  opacity: 1;
  -webkit-transform: rotate(45deg) translate3d(0, 0, 0);
  transform: rotate(45deg) translate3d(0, 0, 0);
  transition: opacity .2s ease-in-out .2s,-webkit-transform .2s ease-in-out .2s;
  transition: opacity .2s ease-in-out .2s,transform .2s ease-in-out .2s;
  transition: opacity .2s ease-in-out .2s,transform .2s ease-in-out .2s,-webkit-transform .2s ease-in-out .2s;
}

.menu-toggle.open .menu-icon .menu-line.menu-line--5 {
  opacity: 1;
  -webkit-transform: rotate(-45deg) translate3d(0, 0, 0);
  transform: rotate(-45deg) translate3d(0, 0, 0);
  transition: opacity .2s ease-in-out .4s,-webkit-transform .2s ease-in-out .4s;
  transition: opacity .2s ease-in-out .4s,transform .2s ease-in-out .4s;
  transition: opacity .2s ease-in-out .4s,transform .2s ease-in-out .4s,-webkit-transform .2s ease-in-out .4s;
}

.menu-wrapper.search-wrapper {
  top: 10.1rem;
}

.landing-menu .nav-menu li.active a {
  color: #ff2c54 !important;
}

.header.header-inner .nav-menu li.no-sub-menu.active a {
  position: relative;
}

.header.header-inner .nav-menu li.no-sub-menu.active a:after {
  position: absolute;
  content: "";
  display: block;
  width: 3rem;
  height: 0.2rem;
  background: #fff;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 2.6rem;
}

.header.header-inner .header-bottom .mainmenu .nav-menu .sub-menu li.current_page_item > a {
  color: #fff;
  background: #ff2c54;
}

.header.header-inner .header-bottom .mainmenu .nav-menu .sub-menu li.current_page_item span.em-menu-parent i {
  color: #fff;
}

.header.header-inner .header-bottom .mainmenu .nav-menu .sub-menu li.current_page_item > a:after {
  display: none;
}

.menu-toggle {
  z-index: 99;
}

.mobile-menu {
  position: relative;
  z-index: 99;
}

.nav-onepage .mega-menu-full > .sub-menu {
  height: inherit !important;
}

.nav-onepage li.active a {
  color: #ff2c54 !important;
}

.nav-onepage .em-menu ul ul li.active > a,
.nav-onepage .em-menu ul ul li > a {
  color: #333333 !important;
  background: transparent;
}

#header-onepage-six .main-menu-area {
  padding-top: 0;
  background: #fff;
  position: static;
}

#header-onepage-six.header-layout2 .main-menu-area .two-icons li.sign-btn a {
  background: #ff2c54;
  border-color: #ff2c54;
  color: #fff;
}

.header.header-layout2 .em-menu-toggle {
  border-color: #333333;
  color: #333333 !important;
}

.header.header-layout2 .menu-toggle .menu-icon .menu-line {
  background-color: #333333;
}

.header.header-layout2 .logo .sticky-logo {
  display: none;
}

.header.header-layout2 .header-bottom .mainmenu {
  float: none;
}

.header.header-layout2 .header-bottom .mainmenu .nav-menu > li.current_page_item > a {
  color: #ff2c54;
}

.header.header-layout2 .header-bottom .mainmenu .nav-menu > li > a {
  color: #333333;
}

.header.header-layout2 .header-bottom .mainmenu .nav-menu > li a:hover {
  color: #ff2c54;
}

.header.header-layout2 .main-menu-area .two-icons li.search .seach_bar i {
  color: #959696;
}

.header.header-layout2 .main-menu-area .two-icons li.sign-btn a {
  background: #fff;
  border-color: #fff;
  color: #333333;
}

.header.header-layout2 .main-menu-area .two-icons li.sign-btn a:hover {
  color: #fff;
  border-color: #ff2c54;
}

.header.header-layout2 .main-menu-area.sticky {
  background: #fff;
}

.header.header-layout2 .main-menu-area.sticky .two-icons li.sign-btn a {
  background: #ff2c54;
  border-color: #ff2c54;
  color: #fff;
}

.header.header-layout2 .main-menu-area.sticky .header-bottom .mainmenu .nav-menu > li > a {
  color: #333333;
}

.header.header-layout2 .main-menu-area.sticky .header-bottom .mainmenu .nav-menu > li > a:hover {
  color: #ff2c54;
}

.header.header-layout2 .main-menu-area.sticky .header-bottom .mainmenu .nav-menu > li.current_page_item > a {
  color: #ff2c54;
}

.header.header-layout2 .mobile-menu .mainmenu .em-menu {
  margin-top: 24px;
  float: none;
}

.header.header-layout2 .mobile-menu .mainmenu .em-menu li {
  display: block;
  margin-right: 0;
}

.header.header-layout2 .mobile-menu .mainmenu .em-menu.em-menu-close {
  margin-top: 0;
}

.header.header-layout2 .mobile-menu .mainmenu .em-menu ul {
  background: #1d49d0;
}

.header.header-layout2 .mobile-menu .mainmenu .em-menu ul > li > a {
  color: #fff;
  padding: 1.5rem 2.5rem;
}

.header.header-layout2 .mobile-menu .mainmenu .em-menu ul > li > a:hover {
  color: #ff2c54;
}

.header.header-layout2 .mobile-menu .mainmenu .em-menu ul li a:hover {
  color: #ff2c54 !important;
}

.header.header-layout2 .mobile-menu .mainmenu .em-menu ul .sub-menu li {
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.15);
}

.header.header-layout2 .mobile-menu .mainmenu .em-menu ul .sub-menu li a {
  padding-left: 5rem;
}

.header.header-layout2 .mobile-menu .mainmenu .em-menu ul .sub-menu li a:hover {
  color: #ff2c54 !important;
}

.header.header-layout2 .mobile-menu .mainmenu .em-menu ul .sub-menu li:last-child {
  border-bottom: none;
}

.header.header-layout2 .mobile-menu .mainmenu .em-menu ul .sub-menu li.active a {
  color: #ff2c54 !important;
}

.header.header-layout3 .main-menu-area.sticky {
  background: linear-gradient(135deg, #2cbe4e, #0eac85);
}

.header.header-layout3 .main-menu-area .two-icons li.search .seach_bar i {
  color: #fff;
}

.header.header-layout3 .main-menu-area .two-icons li.sign-btn a {
  background: #ff2c54;
  border-color: #ff2c54;
  color: #fff;
}

.header.header-layout4 .main-menu-area .two-icons li.search .seach_bar i {
  color: #fff;
}

.header.header-layout4 .main-menu-area.sticky {
  background: #161618;
}

.header.header-layout5 .main-menu-area {
  background: transparent;
  padding-top: 0;
}

.header.header-layout5 .main-menu-area.sticky {
  background: #161618;
}

.header.header-layout5 .header-icons {
  height: 100%;
  width: 100%;
  text-align: right;
}

.header.header-layout5 .header-icons .search-box a {
  color: #fff;
}

.header.header-layout5 .header-icons .search-box a:hover {
  color: #ff2c54;
}

.header.header-layout5 .header-icons .seach_bar {
  color: #fff;
  padding-left: 1.5rem;
}

.header.header-layout5 .header-icons .seach_bar i {
  cursor: pointer;
}

.header.header-layout5 .header-social .single-item {
  margin-right: 0.8rem;
  font-size: 1.5rem;
  padding-right: 1.5rem;
  display: inline-block;
}

.header.header-layout5 .header-social .single-item:before {
  display: none;
}

.header.header-layout5 .header-social .single-item:last-child {
  margin-right: 0;
  border-right: 0;
  padding-right: 0;
}

.header.header-layout5 .header-social .single-item a {
  color: #fff;
  display: block;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition: all 0.3s ease-in-out;
}

.header.header-layout5 .header-social .single-item a:hover {
  color: #ff2c54;
}

.header.header-layout6 .main-menu-area {
  position: static;
  padding: 2.05rem 0;
}

.header.header-layout6 .main-menu-area.sticky {
  background: #05113b;
}

.header.header-layout6 .main-menu-area .logo .sticky-logo {
  display: none;
}

.header.header-layout6 .main-menu-area .header-contact {
  display: inline-block;
  padding-right: 9.2rem;
}

.header.header-layout6 .main-menu-area .header-contact .icon {
  padding-right: 1rem;
}

.header.header-layout6 .main-menu-area .header-contact .icon i:before {
  font-size: 2.5rem;
  color: #ff2c54;
}

.header.header-layout6 .main-menu-area .header-contact a {
  font-size: 2.4rem;
  font-weight: 700;
  color: #333333;
}

.header.header-layout6 .main-menu-area .header-contact a:hover {
  opacity: 0.9;
}

.header.header-layout6 .main-menu-area .nav_trigger i:before {
  font-size: 1.8rem;
  color: #6c6b6b;
  background: #eaeaea;
  border-radius: 100%;
  padding: 1.1rem;
  transition: all 0.4s ease 0s;
}

.header.header-layout6 .main-menu-area .nav_trigger:hover i:before {
  color: #fff;
  background: #ff2c54;
}

.header.header-layout6 .logo {
  margin-top: 0;
}

.tact-right-sidebar-wrp {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999;
}

.tact-right-sidebar-wrp .info_wrap {
  width: 90px;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 2;
}

.tact-right-sidebar-wrp .info_wrap:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.06);
}

.tact-right-sidebar-wrp .info_wrap .inner_wrap {
  widows: 100%;
  height: 100%;
  position: relative;
}

.tact-right-sidebar-wrp .info_wrap .inner_wrap .offcanvas-social {
  position: absolute;
  left: 50%;
  bottom: 20rem;
  transform: translate(-50%, -50%);
}

.tact-right-sidebar-wrp .info_wrap .inner_wrap .logo {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 90px;
  height: 90px;
  background-color: #ff2c54;
  text-align: center;
  line-height: 90px;
}

.tact-right-sidebar-wrp .info_wrap .inner_wrap .link {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.tact-right-sidebar-wrp .offcanvas-cpt-wrp {
  width: 90px;
  height: 90px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  top: 0px;
  left: 0px;
  position: absolute;
}

@media only screen and (max-width: 1199px) {
  .tact-right-sidebar-wrp .offcanvas-cpt-wrp {
    bottom: 0;
    top: auto;
  }
}

.tact-right-sidebar-wrp .offcanvas-cpt-wrp .trigger {
  width: 30px;
  height: 12px;
  position: relative;
  transform: translateX(-50%);
  top: 37px;
  left: 50%;
}

.tact-right-sidebar-wrp .offcanvas-cpt-wrp .trigger span {
  position: absolute;
  width: 30px;
  height: 3px;
  background-color: #000;
  display: inline-block;
}

.tact-right-sidebar-wrp .offcanvas-cpt-wrp .trigger .two {
  margin-top: 12px;
}

.tact-right-sidebar-wrp .offcanvas-cpt-menu:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  right: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.06);
}

.tact-right-sidebar-wrp .offcanvas-cpt-menu {
  width: 330px;
  height: 100vh;
  position: fixed;
  top: 0px;
  right: -240px;
  background-color: #fff;
  z-index: 1;
}

.tact-right-sidebar-wrp .offcanvas-cpt-menu .inner-cover {
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.05);
}

.tact-right-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding-right: 45px;
  width: 100%;
}

@media only screen and (max-height: 600px) {
  .tact-right-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list {
    height: 100%;
    overflow-y: scroll;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .right-menu-fixed {
    padding-right: 9rem;
    overflow-x: hidden !important;
  }
}

@media only screen and (max-width: 1300px) {
  .about-us-four .left-desc {
    padding-left: 1.5rem;
  }
}

.tact-right-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list > ul {
  margin: 0px;
  list-style-type: none;
  display: flex;
  align-items: center;
  max-width: 100%;
  flex-direction: column;
  min-height: 100%;
  justify-content: center;
}

.tact-right-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list > ul > li {
  margin: 0 0 10px 0px;
  list-style-type: none;
  position: relative;
  width: 100%;
  padding-left: 55px;
  padding-right: 20px;
  text-align: left;
}

.tact-right-sidebar-wrp .nav-item-list .menu-toggle-right li.sub-menu-toggle span.em-menu-parent {
  padding: 0.8rem;
}

.tact-right-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list > ul > li:last-child {
  margin-bottom: 0px;
}

.tact-right-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list > ul > li > a {
  text-decoration: none;
  color: #000;
  text-transform: capitalize;
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 1rem;
  display: block;
  position: relative;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.tact-right-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list > ul > li > a:hover {
  color: #ff2c54;
}

.tact-right-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list > ul > li.active a {
  color: #ff2c54;
}

.tact-right-sidebar-wrp .tact-shares {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  bottom: -16px;
  left: -70px;
  z-index: 99;
}

.tact-right-sidebar-wrp .tact-shares .toggle-shares {
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #111;
  font-weight: 700;
  margin-right: 2rem;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 81px;
}

.tact-right-sidebar-wrp .tact-shares .toggle-shares span {
  margin-left: 1rem;
}

.tact-right-sidebar-wrp .tact-shares .toggle-shares:hover {
  color: #ff2c54;
}

.social-share-wrapper {
  overflow: hidden;
}

.tact-right-sidebar-wrp .social-share {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 3px;
}

.tact-right-sidebar-wrp .social-share li {
  margin-left: -100%;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

.tact-right-sidebar-wrp .show-shares li {
  margin-left: 0;
}

.tact-right-sidebar-wrp .banner-share-slider-container {
  position: absolute;
  bottom: -7rem;
  left: 240px;
  z-index: 99;
}

.tact-right-sidebar-wrp .banner-share-slider-container .tact-shares {
  position: static;
  margin-top: 0;
}

@media (max-width: 991px) {
  .tact-right-sidebar-wrp .banner-share-slider-container {
    position: static;
  }
}

.tact-right-sidebar-wrp .menu-share-slider-container {
  position: absolute;
  z-index: 999;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.tact-right-sidebar-wrp .menu-share-slider-container li {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

@media (max-width: 1199px) {
  .tact-right-sidebar-wrp .menu-share-slider-container {
    position: static;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    margin-top: 1rem;
  }
  .tact-right-sidebar-wrp .menu-share-slider-container li {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

.tact-right-sidebar-wrp .social-share-wc-bg li a {
  display: block;
  width: 3.2rem;
  height: 3.2rem;
  background: #111;
  border-radius: 100%;
  font-size: 1.2rem;
  line-height: 3.2rem;
  color: #fff;
  text-align: center;
}

.tact-right-sidebar-wrp .social-share-wc-bg li a:hover {
  background: #ff2c54;
  color: #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.tact-right-sidebar-wrp .social-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.tact-right-sidebar-wrp .social-share li {
  margin-right: 1rem;
}

.tact-right-sidebar-wrp .social-share li a {
  font-size: 2rem;
  color: #111;
}

.tact-right-sidebar-wrp .social-share li a:hover {
  color: #ff2c54;
}

.tact-right-sidebar-wrp .social-share-wc-bg li a {
  display: block;
  width: 3.2rem;
  height: 3.2rem;
  background: #111;
  border-radius: 100%;
  font-size: 1.2rem;
  line-height: 3.2rem;
  color: #fff;
  text-align: center;
}

.tact-right-sidebar-wrp .social-share-wc-bg li a:hover {
  background: #ff2c54;
  color: #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.tact-left-sidebar-wrp {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999;
  opacity: 0;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.tact-left-sidebar-wrp.menu-sticky.sticky {
  opacity: 1;
}

.tact-left-sidebar-wrp .info_wrap {
  width: 90px;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  left: -92px;
  top: 0px;
  z-index: 2;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.tact-left-sidebar-wrp.sticky .info_wrap {
  left: 0;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.tact-left-sidebar-wrp .info_wrap:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  right: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.06);
}

.main_content.opened.left .tact-left-sidebar-wrp .info_wrap .inner_wrap {
  background-color: #fff;
}

.tact-left-sidebar-wrp .info_wrap .inner_wrap {
  widows: 100%;
  height: 100%;
  position: relative;
}

.tact-left-sidebar-wrp .info_wrap .inner_wrap .offcanvas-social {
  position: absolute;
  left: 50%;
  bottom: 20.6rem;
  transform: translate(-50%, -50%);
}

.tact-left-sidebar-wrp .info_wrap .inner_wrap .logo {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 90px;
  height: 90px;
  background-color: #ff2c54;
  text-align: center;
  line-height: 90px;
}

.tact-left-sidebar-wrp .info_wrap .inner_wrap .link {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.tact-left-sidebar-wrp .offcanvas-cpt-wrp:hover .trigger span,
.tact-right-sidebar-wrp .offcanvas-cpt-wrp:hover .trigger span {
  background: #ff2c54;
}

.tact-left-sidebar-wrp .offcanvas-cpt-wrp {
  width: 90px;
  height: 90px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  top: 0px;
  left: 0px;
  position: absolute;
}

@media only screen and (max-width: 1199px) {
  .tact-left-sidebar-wrp .offcanvas-cpt-wrp {
    bottom: 0;
    top: auto;
  }
}

.tact-left-sidebar-wrp .offcanvas-cpt-wrp .trigger {
  width: 30px;
  height: 12px;
  position: relative;
  transform: translateX(-50%);
  top: 37px;
  left: 50%;
}

.tact-left-sidebar-wrp .offcanvas-cpt-wrp .trigger span {
  position: absolute;
  width: 30px;
  height: 3px;
  background-color: #000;
  display: inline-block;
}

.tact-left-sidebar-wrp .offcanvas-cpt-wrp .trigger .two {
  margin-top: 12px;
}

.tact-left-sidebar-wrp .offcanvas-cpt-menu:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  right: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.06);
}

.tact-left-sidebar-wrp .offcanvas-cpt-menu {
  width: 330px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -330px;
  background-color: #fff;
  z-index: 1;
}

.tact-left-sidebar-wrp .offcanvas-cpt-menu .inner-cover {
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.05);
}

.tact-left-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding-right: 45px;
  width: 100%;
}

@media only screen and (max-height: 600px) {
  .tact-left-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list {
    height: 100%;
    overflow-y: scroll;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .left-menu-fixed {
    transition: all 0.4s ease 0s;
  }
  .left-menu-fixed.space-left {
    padding-left: 9rem;
    overflow-x: hidden !important;
  }
  .left-menu-fixed.space-left .header .main-menu-area {
    width: calc(100% - 90px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1279px) {
  .skill-section .push-left {
    padding-left: 1.5rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 800px) {
  .left-menu-fixed footer.footer .footer-copyright {
    padding-left: 9rem;
  }
}

@media only screen and (min-width: 576px) and (max-width: 720px) {
  .left-menu-fixed footer.footer .footer-copyright {
    padding-left: 9rem;
  }
}

@media only screen and (max-width: 575px) {
  .left-menu-fixed footer.footer .footer-copyright {
    padding-left: 7rem;
  }
}

@media only screen and (max-width: 420px) {
  body .tact-left-sidebar-wrp .offcanvas-cpt-wrp {
    width: 5rem;
    height: 5rem;
  }
  body .tact-left-sidebar-wrp .offcanvas-cpt-wrp .trigger {
    top: 1.7rem;
  }
  .left-menu-fixed footer.footer .footer-copyright {
    padding-left: 4.2rem;
  }
  footer.footer .footer-copyright p {
    line-height: 2rem;
    margin-bottom: 0.5rem;
  }
}

.tact-left-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list > ul {
  margin: 0px;
  list-style-type: none;
  display: flex;
  align-items: center;
  max-width: 100%;
  flex-direction: column;
  min-height: 100%;
  justify-content: center;
}

.tact-left-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list > ul > li {
  margin: 0 0 10px 0px;
  list-style-type: none;
  position: relative;
  width: 100%;
  padding-left: 38px;
  padding-right: 20px;
  text-align: left;
}

.tact-left-sidebar-wrp .nav-item-list .menu-toggle-left li.sub-menu-toggle span.em-menu-parent {
  padding: 0.8rem;
}

.tact-left-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list > ul > li:last-child {
  margin-bottom: 0px;
}

.tact-left-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list > ul > li > a {
  text-decoration: none;
  color: #000;
  text-transform: capitalize;
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 1rem;
  display: block;
  position: relative;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.tact-left-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list > ul > li > a:hover {
  color: #ff2c54;
}

.tact-left-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list > ul > li.active a {
  color: #ff2c54;
}

.tact-shares {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  bottom: -13px;
  left: -70px;
  z-index: 99;
}

.tact-shares .toggle-shares {
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #111;
  font-weight: 700;
  margin-right: 2rem;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 81px;
}

.tact-shares .toggle-shares span {
  margin-left: 1rem;
}

.tact-shares .toggle-shares:hover {
  color: #ff2c54;
}

.social-share-wrapper {
  overflow: hidden;
}

.social-share-wrapper .social-share {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 3px;
}

.social-share-wrapper .social-share li {
  margin-left: -100%;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

.social-share-wrapper.show-shares li {
  margin-left: 0;
}

.banner-share-slider-container {
  position: absolute;
  bottom: -7rem;
  left: 240px;
  z-index: 99;
}

.banner-share-slider-container .tact-shares {
  position: static;
  margin-top: 0;
}

@media (max-width: 991px) {
  .banner-share-slider-container {
    position: static;
  }
}

@media only screen and (max-height: 500px) {
  .tact-shares.slick-tact-shares {
    display: none;
  }
  .tact-left-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list > ul > li > a {
    font-size: 1.8rem;
  }
  .tact-left-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list > ul > li {
    margin-bottom: 0.5rem;
  }
}

@media only screen and (max-height: 400px) {
  .tact-shares.slick-tact-shares {
    display: none;
  }
  .tact-left-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list > ul > li > a {
    font-size: 1.8rem;
  }
  .tact-left-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list > ul > li {
    margin-bottom: 0.4rem;
  }
}

.menu-share-slider-container {
  position: absolute;
  z-index: 999;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.menu-share-slider-container li {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

@media (max-width: 1199px) {
  .menu-share-slider-container {
    position: static;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    margin-top: 1rem;
  }
  .menu-share-slider-container li {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

.social-share-1 li a {
  display: block;
  width: 3.2rem;
  height: 3.2rem;
  background: #111;
  border-radius: 100%;
  font-size: 1.2rem;
  line-height: 3.2rem;
  color: #fff;
  text-align: center;
}

.social-share-1 li a:hover {
  background: #ff2c54;
  color: #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.social-share-1-white li a {
  background: #fff;
  color: #111;
}

.social-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.social-share li {
  margin-right: 1rem;
}

.social-share li a {
  font-size: 2rem;
  color: #111;
}

.social-share li a:hover {
  color: #ff2c54;
}

.social-share-1 li a {
  display: block;
  width: 3.2rem;
  height: 3.2rem;
  background: #111;
  border-radius: 100%;
  font-size: 1.2rem;
  line-height: 3.2rem;
  color: #fff;
  text-align: center;
}

.social-share-1 li a:hover {
  background: #ff2c54;
  color: #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.social-share-1-white li a {
  background: #fff;
  color: #111;
}

.social-share__vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.social-share__vertical li {
  margin: 0 0 2rem;
}

.tact-right-sidebar-wrp .offcanvas-cpt-menu .inner-cover {
  background: url(../../media/banner/nav-shape2.png) no-repeat center bottom;
  background-color: #fff;
}

@media only screen and (max-height: 800px) {
  .tact-right-sidebar-wrp .offcanvas-cpt-menu .inner-cover {
    background: none;
  }
}

.tact-left-sidebar-wrp {
  opacity: 1;
  left: 0;
}

.main_content.opened .tact-left-sidebar-wrp .info_wrap {
  left: 0;
}

.main_content.opened.left {
  padding-left: 330px;
  overflow: hidden;
}

.main_content.opened.right {
  padding-right: 330px;
}

.main_content {
  width: 100%;
  height: auto;
  clear: both;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.tact-left-sidebar-wrp .inner-cover .sidebar-shape {
  position: absolute;
  width: 0;
  height: 0;
  background: url(../../media/background/service-shape.png) no-repeat center top;
  background-size: cover;
  right: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 2.5s ease-in-out;
  transition-delay: 1s;
  z-index: -1;
}

.tact-left-sidebar-wrp .inner-cover .sidebar-shape.opened {
  width: 89.6rem;
  height: 73.3rem;
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 1660px) {
  .tact-left-sidebar-wrp .inner-cover .sidebar-shape.opened {
    width: 60rem;
    height: 49.1rem;
  }
}

.sidebar-header-bg {
  position: relative;
  z-index: auto;
  background-color: #ffffff;
}

.sidebar-header-bg.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.sidebar-header-bg header {
  position: relative;
  width: 100%;
  z-index: 999999;
  text-align: center;
}

header.absolute {
  position: absolute;
  margin-bottom: 0;
}

.sidebar-header-bg header a.logo {
  text-decoration: none;
  display: block;
}

header.zindex,
footer.zindex {
  z-index: 1 !important;
}

.sidebar-header-bg.enable_fixed.fixed {
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
}

.top-menu {
  padding-bottom: 10px;
}

.full-left-menu #full-sidebar-menu {
  text-align: right;
}

.no-menu {
  display: inline-block;
  margin-top: 12px;
}

#full-sidebar-menu {
  width: 100%;
  text-align: center;
  background: #ffffff;
}

#full-sidebar-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

#full-sidebar-menu ul li {
  display: inline-block;
  position: relative;
}

#full-sidebar-menu ul li a {
  font-size: 16px;
  color: #333333;
  display: block;
  text-align: left;
  font-weight: 600;
  text-decoration: none;
  padding: 0 20px 5px;
  line-height: 45px;
  letter-spacing: 0.2px;
  transition: all .3s ease;
  -webkit-font-smoothing: antialiased;
}

.top-menu #full-sidebar-menu > ul > li > a,
.top-menu #full-sidebar-menu ul.social > li > a {
  padding: 0;
}

#full-sidebar-menu .social .fa {
  font-size: 18px;
}

#full-sidebar-menu ul ul {
  position: absolute;
  z-index: 999;
  left: 0;
  top: 50px;
  min-width: 250px;
  display: none;
  box-sizing: border-box;
}

#full-sidebar-menu ul ul li::before {
  content: '';
  display: table;
  clear: both;
}

#full-sidebar-menu ul ul li a {
  font-size: 13px;
  line-height: 26px;
  padding: 3px 30px;
  letter-spacing: 0.2px;
  display: block;
  width: 100%;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

#full-sidebar-menu > ul > li > ul > li:hover ul {
  display: block;
}

#full-sidebar-menu > ul > li > ul > li > ul {
  left: 101%;
  top: -15px;
}

.small-mob-nav {
  display: none;
  width: 20px;
  height: 20px;
  margin: 0 auto 12px;
  font-size: 14px;
  color: #222222;
  opacity: 1;
}

.small-mob-nav:hover {
  opacity: 0.7;
}

.full-left-menu .full-sidebar-menu.open .small-mob-nav .line {
  background-color: #222222;
}

.full-sidebar-social {
  display: inline-block;
  margin-left: 0px;
  position: relative;
  vertical-align: middle;
}

.full-sidebar-social .social-icon {
  display: none;
  font-size: 14px;
  color: #222222;
  opacity: 1;
  padding: 0 20px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  position: relative;
  z-index: 30;
}

.full-sidebar-social .social-icon:hover {
  opacity: 0.7;
}

#full-sidebar-menu .full-sidebar-social .social {
  margin-left: 0;
}

#full-sidebar-menu .social li {
  display: inline-block;
}

#full-sidebar-menu .full-sidebar-social .social li a {
  margin-left: 0;
  color: #222222;
  opacity: 1;
  transition: opacity 0.3s ease;
}

#full-sidebar-menu .full-sidebar-social .social li a:hover {
  opacity: 1;
}

#full-sidebar-menu .full-sidebar-social .social li {
  margin: 5px;
}

#full-sidebar-menu .full-sidebar-social .social.active {
  visibility: visible;
  opacity: 1;
}

#full-sidebar-menu .full-sidebar-social .social li a {
  line-height: 1.2;
}

#full-sidebar-menu ul > li > ul > li > ul {
  display: none;
}

@media only screen and (max-width: 1199px) {
  .full-sidebar-social {
    margin-left: 5px;
  }
}

@media (min-width: 992px) {
  .menu_light_text.sidebar-header-bg .full-left-menu #full-sidebar-menu .sub-menu li a {
    color: #222222;
  }
  .small-mob-nav-close {
    display: none;
  }
  #full-sidebar-menu ul ul {
    padding: 19px 0;
  }
  .full-left-menu .logo {
    text-align: left;
  }
  .full-left-menu .logo,
  .full-left-menu #top-menu {
    display: table-cell;
    vertical-align: middle;
  }
  .top-menu #full-sidebar-menu ul ul {
    left: -20px;
  }
  .top-menu .full-sidebar-social {
    margin-left: 10px;
  }
  #full-sidebar-menu ul ul li {
    display: block;
    margin-bottom: 5px;
  }
  #full-sidebar-menu ul ul li:last-child {
    margin-bottom: 0;
  }
  .top-menu #full-sidebar-menu > ul:not(.social) > li {
    margin: 0 10px 5px 10px;
    padding: 0;
  }
  #full-sidebar-menu ul li:hover > ul {
    display: block;
  }
  header:not(.full) #full-sidebar-menu {
    display: block !important;
  }
  #full-sidebar-menu .f-right > div {
    position: relative;
    display: inline-block;
  }
  #full-sidebar-menu .f-right > div:last-child::before {
    content: none;
  }
  #full-sidebar-menu > ul > li > ul > li > ul {
    left: -100%;
    top: -15px;
  }
  .sub-menu li a {
    z-index: 1999;
  }
  .pr30md {
    padding-right: 30px !important;
    padding-left: 0 !important;
  }
  .full-left-menu {
    width: 100%;
    margin: auto;
    display: table;
    padding: 0;
  }
  .full-left-menu .f-right {
    float: right;
  }
  .full-left-menu .f-right > div {
    position: relative;
  }
  .full-left-menu .f-right > div:last-child::before {
    content: none;
  }
  header:not(.full) .full-left-menu #full-sidebar-menu {
    text-align: center;
    display: table-cell !important;
    margin-top: 0;
    vertical-align: middle;
  }
  .full-left-menu #full-sidebar-menu ul ul {
    left: 10px;
    top: 44px;
  }
  .top-menu #full-sidebar-menu ul ul {
    left: -20px;
    top: 100%;
  }
  .full-left-menu #full-sidebar-menu > ul > li > ul > li > ul {
    left: 100%;
    top: -10px;
  }
  .top-menu #full-sidebar-menu > ul > li > ul > li > ul {
    left: 100%;
    top: -10px;
  }
  .full-left-menu #full-sidebar-menu .social {
    text-align: right;
    vertical-align: top;
  }
  .full-left-menu #full-sidebar-menu .social li a {
    padding: 0;
    margin-left: 0;
    -webkit-transition: color 350ms ease;
    -moz-transition: color 350ms ease;
    -ms-transition: color 350ms ease;
    -o-transition: color 350ms ease;
    transition: color 350ms ease;
  }
  .full-left-menu #full-sidebar-menu .social li a:hover {
    color: #ff2c54;
  }
  .full-left-menu #full-sidebar-menu .social li a::after,
  .full-left-menu #full-sidebar-menu .social li a::before {
    content: none;
  }
  .full-left-menu #full-sidebar-menu > ul > li > a {
    position: relative;
    padding: 0;
    margin: 0 23px;
  }
  .full-left-menu #full-sidebar-menu > ul > li.current-menu-item > a,
  .top-menu #full-sidebar-menu > ul > li.current-menu-item > a,
  .full-left-menu #full-sidebar-menu > ul > li.current-menu-parent > a,
  .top-menu #full-sidebar-menu > ul > li.current-menu-parent > a {
    transition: all 0.5s ease;
  }
  .full-left-menu.aside-fix .logo img {
    margin: 0;
  }
  .top-menu #full-sidebar-menu > ul > li:last-child > ul > li > ul {
    left: calc(-100% - 30px);
  }
  #full-sidebar-menu .full-sidebar-social .social {
    z-index: 25;
    text-align: left;
    transition: opacity 0.3s ease;
  }
  .fixed-side-nav {
    display: none;
  }
  .sidebar-header-bg .fixed-side-menu {
    position: fixed;
    top: 0;
    left: 0;
  }
  .fixed-side-menu .full-sidebar-menu {
    position: fixed;
    top: 0;
    left: -100%;
    height: 100%;
    width: 190px !important;
    padding: 50px 0;
    margin-left: 58px;
    text-align: center;
    background-color: #fff;
    box-sizing: border-box;
    outline: 0;
    z-index: 101;
    backface-visibility: hidden;
    transition: left 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  }
  .fixed-side-menu.static #full-sidebar-menu .sub-menu {
    margin-left: 0;
  }
  .fixed-side-menu .full-sidebar-menu.active-menu {
    left: 0;
  }
  .fixed-side-menu.active-menu {
    left: 0;
  }
  .fixed-side-menu.active-menu .fixed-side-nav .fixed-side-nav-line.line-1 {
    display: none;
  }
  .fixed-side-menu.active-menu .fixed-side-nav .fixed-side-nav-line.line-2 {
    top: 50%;
    transform: rotate(45deg);
  }
  .fixed-side-menu.active-menu .fixed-side-nav .fixed-side-nav-line.line-3 {
    top: 50%;
    transform: rotate(-45deg);
  }
  .fixed-side-menu .logo {
    position: absolute;
    z-index: 9999;
    top: 20px;
    left: 31px;
    padding: 20px 0;
  }
  .fixed-side-menu .fixed-side-nav {
    position: fixed;
    display: block;
    left: 0;
    top: 0;
    width: 58px;
    background-color: #030e28;
    height: 100%;
    z-index: 1000;
  }
  .fixed-side-menu .fixed-side-nav .fixed-side-nav-line {
    position: absolute;
    top: 50%;
    left: 18px;
    display: block;
    width: 22px;
    height: 1px;
    background-color: #fff;
    transition: transform .3s ease;
  }
  .fixed-side-menu .fixed-side-nav .fixed-side-nav-line.line-1 {
    transform: translateY(-6px);
  }
  .fixed-side-menu .fixed-side-nav .fixed-side-nav-line.line-3 {
    transform: translateY(6px);
  }
  .fixed-side-menu .fixed-side-nav:hover {
    opacity: 1;
  }
  .fixed-side-menu .fixed-side-nav:focus {
    opacity: 1;
  }
  .fixed-side-menu .fixed-side-nav:hover .fixed-side-nav-line {
    transform: rotate(45deg);
  }
  .fixed-side-menu #full-sidebar-menu {
    display: table !important;
    border-right: 1px solid #f2f2f2;
  }
  .fixed-side-menu.aside-fix #full-sidebar-menu ul.menu {
    padding-bottom: 100px;
  }
  .fixed-side-menu #full-sidebar-menu ul.menu {
    display: inline-block;
    vertical-align: middle;
    overflow-y: auto;
    width: 100%;
    max-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }
  .fixed-side-menu #full-sidebar-menu ul.menu li {
    padding: 0.9rem 2rem 0.9rem 3rem;
    position: static;
    text-align: left;
    display: block;
  }
  .fixed-side-menu.aside-fix #full-sidebar-menu ul.menu li {
    padding: 10px 15px;
    text-align: center;
  }
  .fixed-side-menu.aside-fix #full-sidebar-menu ul.menu a {
    display: inline-block;
    position: relative;
    line-height: 1.2;
  }
  .fixed-side-menu #full-sidebar-menu ul.menu a {
    display: block;
    text-align: center;
    z-index: 9999;
  }
  .fixed-side-menu.aside-fix #full-sidebar-menu ul.menu li a::before {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 8px;
    z-index: -1;
    background-color: #ffdd65;
    -webkit-transition: width .5s ease;
    -o-transition: width .5s ease;
    transition: width .5s ease;
  }
  .fixed-side-menu.aside-fix #full-sidebar-menu ul.menu li a:hover::before,
  .fixed-side-menu.aside-fix #full-sidebar-menu ul.menu .current-menu-parent > a::before,
  .fixed-side-menu.aside-fix #full-sidebar-menu ul.menu .current-menu-item > a::before {
    width: 100%;
  }
  .fixed-side-menu.aside-fix #full-sidebar-menu ul ul li a {
    width: auto;
    padding: 0;
  }
  .f-right .tact-top-social li {
    margin: 0.5rem 1.2rem 0.5rem 0;
  }
  .tact-top-social .toggle-shares {
    font-weight: 600;
    color: #999999;
  }
  .fixed-side-menu #full-sidebar-menu .f-right {
    float: none;
    position: absolute;
    left: 50%;
    width: calc(100% - 35px);
    transform: translateX(-50%);
    bottom: 40px;
    z-index: 2;
    top: auto;
  }
  .fixed-side-menu #full-sidebar-menu .f-right .full-sidebar-social {
    margin-left: 0;
  }
  .fixed-side-menu #full-sidebar-menu .f-right .full-sidebar-social li {
    margin-right: 5px;
    margin-left: 5px;
  }
  .fixed-side-menu #full-sidebar-menu .f-right .full-sidebar-social li {
    margin-left: 0;
    margin-right: 10px;
  }
  .fixed-side-menu.aside-fix #full-sidebar-menu .sub-menu {
    min-width: auto;
    position: static;
  }
  .fixed-side-menu.aside-fix #full-sidebar-menu .sub-menu a {
    font-weight: bold;
  }
  .fixed-side-menu.aside-fix #full-sidebar-menu::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: #fff;
    z-index: 1;
  }
  .fixed-side-menu.aside-fix #full-sidebar-menu .f-right {
    text-align: center;
  }
  .fixed-side-menu #full-sidebar-menu > ul > li:hover ul,
  .fixed-side-menu #full-sidebar-menu > ul > li > ul > li:hover ul {
    display: none;
  }
  .fixed-side-menu #full-sidebar-menu ul > li > ul > li > ul {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
  header:not(.fixed-side-menu):not(.full) #full-sidebar-menu ul li.mega-menu {
    position: static;
  }
  header:not(.fixed-side-menu):not(.full).full-left-menu #full-sidebar-menu ul .mega-menu > ul {
    width: 100%;
    max-width: 1140px;
    left: 50%;
    top: 60px;
    padding: 45px 0 30px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  header:not(.fixed-side-menu):not(.full) #full-sidebar-menu ul li.mega-menu:hover > ul::before {
    content: "";
    position: absolute;
    width: 5000px;
    top: 0;
    bottom: 0;
    left: -100%;
    background-color: #fff;
    box-shadow: 3px 1px 20px 0 rgba(0, 0, 0, 0.07);
    z-index: 1;
  }
  header:not(.fixed-side-menu):not(.full) #full-sidebar-menu ul li.mega-menu > ul > li {
    float: left;
    width: 25%;
  }
  header:not(.fixed-side-menu):not(.full) #full-sidebar-menu ul li.mega-menu:hover > ul > li > a {
    font-size: 18px;
    font-weight: 800;
    letter-spacing: .2px;
  }
  header:not(.fixed-side-menu):not(.full) #full-sidebar-menu ul li.mega-menu > ul > li:nth-child(1)::before {
    left: 25%;
  }
  header:not(.fixed-side-menu):not(.full) #full-sidebar-menu ul li.mega-menu > ul > li:nth-child(2)::before {
    left: 50%;
  }
  header:not(.fixed-side-menu):not(.full) #full-sidebar-menu ul li.mega-menu > ul > li:nth-child(3)::before {
    left: 75%;
  }
  header:not(.fixed-side-menu):not(.full) #full-sidebar-menu ul > li.mega-menu > ul.sub-menu > li > ul.sub-menu {
    display: block;
    position: static;
    text-align: left;
    min-width: 100%;
    box-shadow: none;
    padding: 25px 0;
    transition: all .2s ease;
  }
  header:not(.fixed-side-menu):not(.full) #full-sidebar-menu ul > li.mega-menu > ul > li > ul.sub-menu > li {
    display: block;
    padding: 8px 0;
  }
  header:not(.fixed-side-menu):not(.full) #full-sidebar-menu ul > li.mega-menu > ul > li > ul.sub-menu > li a {
    opacity: 0;
    -webkit-transform: matrix(1, 0, 0, 1, 0, 20);
    -ms-transform: matrix(1, 0, 0, 1, 0, 20);
    transform: matrix(1, 0, 0, 1, 0, 20);
    -webkit-transition: opacity .75s ease, -webkit-transform .75s ease;
    transition: opacity .75s ease, -webkit-transform .75s ease;
    -o-transition: opacity .75s ease, transform .75s ease;
    transition: opacity .75s ease, transform .75s ease;
    transition: opacity .75s ease, transform .75s ease, -webkit-transform .75s ease;
  }
  header:not(.fixed-side-menu):not(.full).full-left-menu #full-sidebar-menu ul .mega-menu ul li {
    position: static;
    display: block;
  }
  header.top-menu #full-sidebar-menu ul li.mega-menu > ul {
    top: calc(100% - 25px);
  }
  header.top-menu #full-sidebar-menu ul li.mega-menu > ul > li::before {
    display: none;
  }
  header.top-menu #full-sidebar-menu ul ul {
    left: 0;
  }
  header.top-menu #full-sidebar-menu ul li.mega-menu > ul > li:nth-child(1)::before,
  header.top-menu #full-sidebar-menu ul li.mega-menu > ul > li:nth-child(2)::before,
  header.top-menu #full-sidebar-menu ul li.mega-menu > ul > li:nth-child(3)::before {
    left: 100%;
    display: block;
    top: 0;
  }
  .top-menu #full-sidebar-menu > ul:not(.social) > li {
    margin: 0 0 5px;
    padding: 0 23px;
  }
  .top-menu #full-sidebar-menu > ul > li:last-child > ul > li > ul {
    left: calc(-100%);
  }
  .top-menu #full-sidebar-menu > ul > li > ul > li > ul {
    left: calc(100% + 23px);
  }
}

#main_content::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 400;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.sidebar-open .fixed-menu #main_content::before,
#main_content.opened::before {
  opacity: 1;
  visibility: visible;
}

#main_content.opened {
  position: relative;
  width: 100%;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .full-left-menu #full-sidebar-menu > ul > li > a {
    margin: 0 18px;
  }
}

@media (min-width: 992px) {
  .main-wrapper.unit .full-left-menu #full-sidebar-menu > ul > li > a {
    margin: 0 15px;
  }
}

@media (max-width: 991px) {
  .sidebar-header-bg header .logo img.main-logo:not(.logo-mobile) {
    display: none !important;
  }
  .sidebar-header-bg header .logo img.logo-mobile {
    display: inline;
  }
  .sidebar-header-bg header a.logo {
    display: inline-block;
  }
  .sidebar-header-bg header {
    padding: 1.7rem 1.5rem;
  }
  .fixed-side-menu.static #full-sidebar-menu .f-right .copy {
    display: none;
  }
  #full-sidebar-menu {
    overflow-x: hidden;
  }
  .main-wrapper {
    width: 100%;
  }
  #full-sidebar-menu ul li ul {
    box-shadow: none;
    font-style: normal;
  }
  #full-sidebar-menu ul {
    box-shadow: none;
    font-style: normal;
  }
  .top-menu .logo {
    margin-bottom: 0;
    margin-top: 0;
  }
  .no-padd-mob {
    padding: 0 !important;
  }
  .full-left-menu #full-sidebar-menu .menu li.menu-item-has-children,
  #full-sidebar-menu .menu li.menu-item-has-children {
    position: relative;
    text-align: left;
  }
  .full-left-menu #full-sidebar-menu .menu li.menu-item-has-children i,
  #full-sidebar-menu .menu li.menu-item-has-children i {
    position: absolute;
    top: 16px;
    right: 35px;
  }
  .full-left-menu #full-sidebar-menu .menu li.menu-item-has-children > a,
  #full-sidebar-menu .menu li.menu-item-has-children > a {
    position: relative;
    display: inline-block;
    width: 100% !important;
  }
  .small-mob-nav {
    display: block;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .small-mob-nav i::before {
    font-size: 24px;
  }
  .sidebar-open {
    height: 100vh;
  }
  .sidebar-open .canvas-wrap {
    -webkit-transform: translateX(320px);
    -moz-transform: translateX(320px);
    -ms-transform: translateX(320px);
    -o-transform: translateX(320px);
    transform: translateX(320px);
  }
  .sidebar-open .sidebar-header-bg {
    position: fixed;
  }
  .main-wrapper {
    -webkit-transition: -webkit-transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    -o-transition: transform 0.5s ease-in-out, transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  }
  .main-wrapper::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 400;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
  .sidebar-open .main-wrapper {
    position: fixed;
    -webkit-transform: translateX(320px);
    -ms-transform: translateX(320px);
    transform: translateX(320px);
    overflow: visible;
    z-index: 9999;
  }
  .sidebar-open .main-wrapper::before {
    opacity: 1;
    visibility: visible;
  }
  .small-mob-nav-close {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-decoration: none;
    border-bottom: 1px solid #f1f2f3;
  }
  .small-mob-nav-close span {
    font-weight: 800;
    letter-spacing: .2px;
  }
  .small-mob-nav-close .hamburger {
    padding: 30px 35px;
    padding-left: 15px;
  }
  .small-mob-nav-close .line {
    display: block;
    width: 24px;
    height: 3px;
    background-color: #222222;
  }
  .small-mob-nav-close .line:first-of-type {
    -webkit-transform: rotate(45deg) translateY(2px);
    -moz-transform: rotate(45deg) translateY(2px);
    -ms-transform: rotate(45deg) translateY(2px);
    -o-transform: rotate(45deg) translateY(2px);
    transform: rotate(45deg) translateY(2px);
  }
  .small-mob-nav-close .line:last-of-type {
    -webkit-transform: rotate(-45deg) translateY(-2px);
    -moz-transform: rotate(-45deg) translateY(-2px);
    -ms-transform: rotate(-45deg) translateY(-2px);
    -o-transform: rotate(-45deg) translateY(-2px);
    transform: rotate(-45deg) translateY(-2px);
  }
  #full-sidebar-menu.open {
    opacity: 1;
  }
  #full-sidebar-menu {
    display: inline-block;
    overflow-y: auto;
    position: absolute;
    text-align: left;
    padding-top: 0;
    padding-bottom: 100px;
    top: 0;
    bottom: 0;
    left: 0;
    width: 320px;
    background-color: #fff;
    -webkit-transform: translateX(-320px);
    -ms-transform: translateX(-320px);
    transform: translateX(-320px);
    height: 100vh;
    z-index: 100;
  }
  .sidebar-open #full-sidebar-menu {
    position: fixed;
  }
  #full-sidebar-menu ul ul {
    display: none;
    position: static;
  }
  #full-sidebar-menu ul.menu > li > ul > li > ul {
    display: none;
  }
  #full-sidebar-menu ul.menu {
    width: 100%;
    display: inline-block;
    padding-bottom: 30px;
    background-color: #fff;
  }
  #full-sidebar-menu ul.menu li {
    display: block !important;
    float: none;
    text-align: left;
    margin-bottom: 0;
  }
  #full-sidebar-menu ul.menu li a::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 320px;
    height: 1px;
    display: block;
    background-color: #f1f2f3;
  }
  #full-sidebar-menu ul.menu li a {
    color: #222222;
    padding: 10px 35px;
    line-height: 25px;
    display: inline-block;
    width: 100% !important;
    float: none;
    transition: all 0.5s ease;
  }
  #full-sidebar-menu ul.menu li a:hover {
    transition: all 0.5s ease;
  }
  /*1 level menu*/
  #full-sidebar-menu > ul.menu > li > a {
    font-size: 14px;
  }
  /*2 level menu*/
  #full-sidebar-menu > ul.menu > li > ul > li,
  #full-sidebar-menu > ul.menu > li > ul > li > ul > li {
    padding-left: 10px;
  }
  #full-sidebar-menu > ul.menu > li > ul > li > ul > li:last-child {
    margin-bottom: 20px;
  }
  #full-sidebar-menu .social li a {
    line-height: 25px !important;
  }
  #full-sidebar-menu .menu li a:hover,
  #full-sidebar-menu .menu .current-menu-parent > a,
  #full-sidebar-menu .menu .current-menu-item > a,
  #full-sidebar-menu .menu .current-menu-ancestor > a {
    color: #ff2c54;
  }
  .full-left-menu #full-sidebar-menu .social {
    display: block;
  }
  .full-left-menu #full-sidebar-menu .social li {
    display: inline-block;
  }
  .full-left-menu #full-sidebar-menu .social li a {
    padding: 5px;
  }
  .full-sidebar-social .social-icon {
    display: none;
  }
  .full-left-menu #full-sidebar-menu .full-sidebar-social .social {
    position: static;
    visibility: visible;
    opacity: 1;
  }
  .mini-cart-wrapper {
    display: block;
    margin: 20px 10px 30px 10px;
  }
  .awa_mini_cart {
    opacity: 1;
    visibility: visible;
    position: relative;
    right: auto;
    left: 0;
    top: 10px;
    width: 100%;
    min-width: 0;
  }
  #full-sidebar-menu ul li.mega-menu:hover > ul > li {
    width: 100%;
  }
  #full-sidebar-menu ul li.mega-menu:hover > ul > li {
    width: auto;
  }
  #full-sidebar-menu.active-socials {
    left: 0;
    right: 0;
    overflow: visible;
    opacity: 1;
    width: 100%;
  }
  #full-sidebar-menu .f-right {
    display: block;
    background: #fff;
    padding: 15px;
    text-align: center;
    z-index: 9999;
    width: 100%;
    transition: all 350ms ease;
  }
  #full-sidebar-menu .f-right.active-socials {
    opacity: 1;
    visibility: visible;
  }
  #full-sidebar-menu .f-right.active-socials a {
    visibility: visible;
  }
  .socials-mob-but {
    display: block;
    margin: 0;
    position: absolute;
    top: calc(50% + -3px);
    right: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .socials-mob-but i::before {
    font-size: 24px;
  }
  .full-sidebar-social {
    vertical-align: middle;
    margin-left: 0;
  }
  .mini-cart-wrapper .awa-shop-icon:before {
    margin-top: -3px;
    font-size: 28px;
  }
}

/*------------------------------------------------------*/
/*---------------------- STATIC ASIDE MENU ----------------------*/
@media only screen and (min-width: 992px) {
  .full-side-menu-wrapper,
  .full-side-menu-wrapper #footer {
    padding-left: 256px;
  }
  .full-side-menu-wrapper .full-left-menu .logo span {
    float: none;
  }
  .full-side-menu-wrapper.woocommerce > .main-wrapper > .container {
    padding: 0 15px !important;
  }
  .full-side-menu-wrapper.woocommerce.woocommerce-page ul.products {
    margin-top: 20px;
  }
  .full-side-menu-wrapper.woocommerce div.product {
    margin-top: 20px;
  }
  .full-side-menu-wrapper .awa-woocommerce-pagination .nav-links {
    padding: 30px 30px 70px;
  }
  .full-side-menu-wrapper .main-header-testimonial {
    margin-left: auto;
    margin-right: auto;
  }
  .full-side-menu-wrapper .single-pagination {
    padding: 15px;
  }
  .full-side-menu-wrapper .top-banner {
    height: 500px;
  }
  .full-side-menu-wrapper .row.single-share {
    margin-right: 0;
    margin-left: 0;
  }
  .full-side-menu-wrapper .portfolio-single-content .izotope-container {
    margin-top: 20px;
  }
  .full-side-menu-wrapper .pixproof-data,
  .full-side-menu-wrapper .pixproof-data .grid__item:last-child {
    margin-top: 20px;
  }
  .full-side-menu-wrapper .portfolio-single-content .single-pagination {
    padding: 50px 15px;
  }
  .full-side-menu-wrapper .banner-slider .page-view {
    max-width: 100%;
  }
  .full-side-menu-wrapper .portfolio-single-content p,
  .full-side-menu-wrapper .portfolio-single-content h1,
  .full-side-menu-wrapper .portfolio-single-content h2,
  .full-side-menu-wrapper .portfolio-single-content h3,
  .full-side-menu-wrapper .portfolio-single-content h4,
  .full-side-menu-wrapper .portfolio-single-content h5,
  .full-side-menu-wrapper .portfolio-single-content h6 {
    padding: 0 15px;
  }
  .full-side-menu-wrapper .portfolio-single-content .row.gallery-single {
    margin-right: 0;
    margin-left: 0;
  }
  .full-side-menu-wrapper .swiper-container-split .swiper-slide .slide-item.slide-text-left .wrap-slide-text {
    padding-left: 190px;
  }
  .full-side-menu-wrapper .vc_row:not([data-vc-stretch-content="true"]) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .full-side-menu-wrapper .vc_row[data-vc-full-width] {
    max-width: calc(100% + 30px) !important;
    left: 0 !important;
  }
  .full-side-menu-wrapper .top-banner .content {
    padding: 0 15px;
  }
  .full-side-menu-wrapper .flow-slider .swiper-container {
    width: 120vw;
  }
  .full-side-menu-wrapper .exhibition-wrap .container-wrap {
    max-width: 100%;
  }
  .full-side-menu-wrapper .exhibition-wrap .slide {
    max-width: calc(70vw - 90px);
  }
  .full-side-menu-wrapper #footer.fix-bottom {
    max-width: calc(100% - 254px);
    left: 254px;
  }
  .full-side-menu-wrapper .sidebar-header-bg {
    padding-bottom: 0;
  }
  .fixed-side-menu.static {
    max-width: 290px;
    left: 0;
  }
  .fixed-side-menu.static .fixed-side-nav {
    display: none;
  }
  .fixed-side-menu.static #full-sidebar-menu ul.menu {
    overflow-y: visible;
    transform: translate(-50%, -50%);
  }
  .fixed-side-menu.static #full-sidebar-menu {
    box-shadow: 3px 1px 20px 0 rgba(0, 0, 0, 0.12);
    left: 0 !important;
    margin-left: 0;
    vertical-align: top;
    border-right: none;
  }
  .fixed-side-menu.static #full-sidebar-menu .sub-menu {
    margin-left: -1px;
    left: 100%;
    top: 50%;
    background-color: #fff;
    width: 100% !important;
    transform: translateY(-50%);
    box-shadow: 3px 1px 20px 0 rgba(0, 0, 0, 0.12);
  }
  .fixed-side-menu.static #full-sidebar-menu .sub-menu .sub-menu {
    left: 99%;
  }
  .fixed-side-menu.static #full-sidebar-menu li:hover > .sub-menu {
    display: none;
  }
  .fixed-side-menu.static #full-sidebar-menu .menu li a {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    letter-spacing: .2px;
    margin: 0;
    width: auto;
    line-height: 1.1;
    padding: 0 2px 0 0;
  }
  .fixed-side-menu.static #full-sidebar-menu .menu li a:hover {
    margin-left: 10px !important;
    position: relative;
  }
  .fixed-side-menu.static #full-sidebar-menu .menu .current-menu-item > a:hover,
  .fixed-side-menu.static #full-sidebar-menu .menu .current-menu-parent > a:hover {
    margin-left: 0 !important;
  }
  .fixed-side-menu.static #full-sidebar-menu .menu .current-menu-parent li a,
  .fixed-side-menu.static #full-sidebar-menu .menu .current-menu-item li a {
    margin-left: 0 !important;
  }
  .fixed-side-menu.static #full-sidebar-menu .current-menu-parent > a::before,
  .fixed-side-menu.static #full-sidebar-menu .current-menu-item > a::before {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 8px;
    z-index: -1;
    background-color: #ffdd65;
  }
  .fixed-side-menu.static #full-sidebar-menu .f-right {
    text-align: left;
    left: 0;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    width: 100%;
    padding-left: 30px;
  }
  .fixed-side-menu.static #full-sidebar-menu .f-right .copy {
    text-transform: none;
    font-size: 14px;
    line-height: 1.57;
    color: #999999;
    margin-top: 35px;
  }
  .fixed-side-menu.static .logo {
    left: 0;
    width: 100%;
    top: 0;
    padding-top: 40px;
    text-align: left;
    padding-left: 30px;
  }
  .fixed-side-menu.static .logo span {
    font-size: 30px;
    line-height: 1;
  }
  .fixed-side-menu.static .logo img {
    max-width: 100%;
  }
}

/*------------------------------------------------------*/
/*---------------------- ASIDE MENU VERTICAL LOGO ----------------------*/
@media only screen and (min-width: 992px) {
  header.fixed-side-menu {
    padding: 0;
  }
  .fixed-side-menu.vertical .logo {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    transform-origin: left top 0;
    transform: rotate(-90deg);
    padding: 0;
    height: 58px;
  }
  .fixed-side-menu.vertical .logo span,
  .fixed-side-menu.vertical .logo img {
    max-height: 58px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/*------------------------------------------------------*/
/*---------------------- LEFT ALIGN MENU ----------------------*/
@media only screen and (min-width: 992px) {
  .full-width-menu .full-left-menu.left {
    padding: 0;
  }
  .full-left-menu.left {
    padding: 0 85px;
  }
  .unit .full-left-menu.left {
    padding: 0 15px;
  }
  .full-left-menu.left .site-search {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.9);
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity .7s ease, visibility .7s ease;
    -o-transition: opacity .7s ease, visibility .7s ease;
    transition: opacity .7s ease, visibility .7s ease;
  }
  .full-left-menu.left .site-search.open {
    opacity: 1;
    visibility: visible;
  }
  .full-left-menu.left .site-search .form-container {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .full-left-menu.left .site-search .form-container .input-group {
    width: 100%;
  }
  .full-left-menu.left .site-search .form-container .input-group input {
    font-size: 18px;
  }
  .full-left-menu.left .site-search .close-search {
    position: absolute;
    top: 80px;
    right: 80px;
    width: 30px;
    height: 30px;
  }
  .full-left-menu.left .site-search .line {
    width: 18px;
    height: 3px;
    background-color: #222222;
    display: block;
    margin: 2px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .full-left-menu.left .site-search .line:nth-of-type(1) {
    -webkit-transform: translateY(1px) rotate(45deg);
    -ms-transform: translateY(1px) rotate(45deg);
    -o-transform: translateY(1px) rotate(45deg);
    transform: translateY(1px) rotate(45deg);
  }
  .full-left-menu.left .site-search .line:nth-of-type(2) {
    -webkit-transform: translateY(-4px) rotate(-45deg);
    -ms-transform: translateY(-4px) rotate(-45deg);
    -o-transform: translateY(-4px) rotate(-45deg);
    transform: translateY(-4px) rotate(-45deg);
  }
  .full-left-menu.left #full-sidebar-menu .menu li a {
    font-size: 15px;
    font-weight: 800;
    letter-spacing: .2px;
    line-height: 1.1;
    z-index: 1;
  }
  .full-left-menu.left #full-sidebar-menu .menu > li {
    padding: 30px 0;
  }
  .full-left-menu.left #full-sidebar-menu .sub-menu {
    top: 75px;
    left: -35px;
    min-width: 270px;
    padding: 30px 0;
    background-color: #ffffff;
    -webkit-box-shadow: 3px 1px 20px 0 rgba(0, 0, 0, 0.07);
    box-shadow: 3px 1px 20px 0 rgba(0, 0, 0, 0.07);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity .5s ease, visibility .5s ease;
    -o-transition: opacity .5s ease, visibility .5s ease;
    transition: opacity .5s ease, visibility .5s ease;
    display: block;
  }
  .full-left-menu.left #full-sidebar-menu .menu li:hover > ul {
    opacity: 1;
    visibility: visible;
  }
  .full-left-menu.left #full-sidebar-menu .menu .mega-menu:hover > ul > li > ul {
    opacity: 1;
    visibility: visible;
  }
  .full-left-menu.left #full-sidebar-menu .menu > li ul a {
    opacity: 0;
    -webkit-transform: matrix(1, 0, 0, 1, 0, 20);
    -ms-transform: matrix(1, 0, 0, 1, 0, 20);
    transform: matrix(1, 0, 0, 1, 0, 20);
    -webkit-transition: opacity .75s ease, -webkit-transform .75s ease;
    transition: opacity .75s ease, -webkit-transform .75s ease;
    -o-transition: opacity .75s ease, transform .75s ease;
    transition: opacity .75s ease, transform .75s ease;
    transition: opacity .75s ease, transform .75s ease, -webkit-transform .75s ease;
  }
  .full-left-menu.left .full-sidebar-menu .sub-menu .not-hover a:before {
    display: none;
  }
  .full-left-menu.left #full-sidebar-menu .menu > li:hover ul a,
  .full-left-menu.left #full-sidebar-menu .menu > li.mega-menu:hover ul > li > ul.sub-menu > li a {
    opacity: 1;
    -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
    -ms-transform: matrix(1, 0, 0, 1, 0, 0);
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  .full-left-menu.left #full-sidebar-menu .sub-menu .sub-menu {
    top: 0;
    left: 100%;
    padding: 40px 15px;
  }
  .full-left-menu.left #full-sidebar-menu .menu li:last-of-type .sub-menu .sub-menu,
  .full-left-menu.left #full-sidebar-menu .menu li:nth-last-of-type(2) .sub-menu .sub-menu,
  .full-left-menu.left #full-sidebar-menu .menu li:nth-last-of-type(3) .sub-menu .sub-menu {
    left: -100%;
  }
  .full-left-menu.left #full-sidebar-menu .sub-menu li {
    padding: 8px 35px;
    text-align: left;
  }
  .full-left-menu.left #full-sidebar-menu .sub-menu li a {
    width: auto;
    display: inline-block;
    padding: 0;
    font-weight: 600;
  }
  .full-left-menu.left #full-sidebar-menu li a::before {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 8px;
    z-index: -1;
    background-color: #ffdd65;
    -webkit-transition: width .3s ease;
    -o-transition: width .3s ease;
    transition: width .3s ease;
  }
  .full-left-menu.left #full-sidebar-menu .current-menu-parent > a,
  .full-left-menu.left #full-sidebar-menu .current-menu-item > a {
    position: relative;
  }
  .full-left-menu.left #full-sidebar-menu .menu li a:hover::before,
  .full-left-menu.left #full-sidebar-menu .current-menu-parent > a::before,
  .full-left-menu.left #full-sidebar-menu .current-menu-item > a::before,
  .full-left-menu.left #full-sidebar-menu .current-menu-ancestor > a::before {
    width: 100%;
  }
  .full-left-menu.left .logo span {
    margin-top: 2px;
  }
  .full-left-menu.left #full-sidebar-menu {
    padding-left: 23px;
    text-align: right;
    vertical-align: middle;
    display: table-cell !important;
  }
  .full-left-menu.left #full-sidebar-menu > ul > li > a {
    margin: 0 18px 0 0;
  }
  .full-left-menu.left #full-sidebar-menu .search-icon-wrapper {
    margin-left: 30px;
    cursor: pointer;
  }
  .sidebar-header-bg.center-menu #full-sidebar-menu > ul {
    width: 100%;
    text-align: center;
  }
  .sidebar-header-bg.center-menu .full-left-menu.left .f-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .sidebar-header-bg.center-menu .full-left-menu.left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.sidebar-open #main_content {
  position: fixed;
  -webkit-transform: translateX(320px);
  -ms-transform: translateX(320px);
  transform: translateX(320px);
  overflow: visible;
}

@media only screen and (max-width: 991px) {
  header {
    position: static;
  }
  #full-sidebar-menu ul.menu {
    max-height: 300000px !important;
  }
}

@media only screen and (max-width: 991px) {
  .sidebar-header-bg {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
  }
  .full-left-menu .small-mob-nav .line {
    width: 18px;
    height: 3px;
    background-color: #222222;
    display: block;
    float: left;
    margin: 2px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .full-left-menu .small-mob-nav .hamburger {
    display: inline-block;
    width: 20px;
  }
  .full-left-menu .small-mob-nav.active .line {
    margin: 0;
    background-color: #222222;
  }
  .full-left-menu .small-mob-nav.active .line:nth-of-type(2) {
    opacity: 0;
  }
  .full-left-menu .small-mob-nav.active .line:nth-of-type(1) {
    width: 24px;
    -webkit-transform: translateY(2px) rotate(45deg);
    -ms-transform: translateY(2px) rotate(45deg);
    -o-transform: translateY(2px) rotate(45deg);
    transform: translateY(2px) rotate(45deg);
  }
  .full-left-menu .small-mob-nav.active .line:nth-of-type(3) {
    width: 24px;
    -webkit-transform: translateY(-4px) rotate(-45deg);
    -ms-transform: translateY(-4px) rotate(-45deg);
    -o-transform: translateY(-4px) rotate(-45deg);
    transform: translateY(-4px) rotate(-45deg);
  }
  .full-left-menu .small-mob-nav .line:nth-of-type(2) {
    width: 24px;
  }
}

@media (min-width: 1440px) {
  .fixed-side-menu .full-sidebar-menu {
    width: 255px !important;
  }
  .fixed-side-menu.static .logo {
    padding-left: 4.5rem;
  }
  .fixed-side-menu #full-sidebar-menu ul.menu li {
    padding-left: 4.5rem;
  }
  .fixed-side-menu.static #full-sidebar-menu .f-right {
    padding-left: 4.5rem;
  }
}

.logo a {
  font-weight: 600;
  color: #eee;
  font-size: 18px;
}

.menu-wrapper {
  position: fixed;
  width: calc(100% - 100px);
  height: 0;
  top: 50px;
  left: 50px;
  overflow: hidden;
  z-index: 999;
  background-color: #ff2c54;
  transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  -ms-transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  -o-transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.menu-wrapper.show {
  height: calc(100% - 100px);
  transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -ms-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -o-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.link-item {
  transform: translateY(75px);
  -webkit-transform: translateY(75px);
  -moz-transform: translateY(75px);
  -ms-transform: translateY(75px);
  -o-transform: translateY(75px);
  opacity: 0;
}

.link-item-box {
  background-color: #ff2c54;
  margin-bottom: 15px;
}

.link-item li {
  background-color: #ff2c54;
  margin-top: 15px;
}

.link-item li a {
  background-color: #ff2c54;
  padding: 20px;
}

.link-item.show {
  transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -ms-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -o-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition-delay: .3s;
  transition-delay: .3s;
  transform: translateY(20px);
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  -ms-transform: translateY(20px);
  -o-transform: translateY(20px);
  opacity: 1;
}

.link-item-2.show {
  -webkit-transition-delay: .5s;
  transition-delay: .5s;
}

.menu-wrapper.show .link-item {
  opacity: 1;
}

.link-item a {
  display: block;
  color: #fff;
  position: relative;
  z-index: 1;
}

.link-item-box a {
  display: block;
  padding: 50px 40px;
  color: #fff;
  position: relative;
  z-index: 1;
}

.link-item a::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 0;
  top: 0;
  right: 0;
  background-color: #ff2c54;
  transition: .3s;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -ms-transition: .3s;
  -o-transition: .3s;
  z-index: -1;
}

.link-item a:hover::after {
  width: 100%;
  right: auto;
  left: 0;
  transition: .15s;
  -webkit-transition: .15s;
  -moz-transition: .15s;
  -ms-transition: .15s;
  -o-transition: .15s;
}

.link-item a:hover {
  color: #c4ae91;
}

.link-item img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.link-item h3 {
  font-size: 24px;
  margin: 20px 0;
  font-weight: 600;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  -ms-transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  -o-transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: skewX(-8deg);
  -webkit-transform: skewX(-8deg);
  -moz-transform: skewX(-8deg);
  -ms-transform: skewX(-8deg);
  -o-transform: skewX(-8deg);
}

.link-item a:hover h3 {
  transform: skewX(0deg);
  -webkit-transform: skewX(0deg);
  -moz-transform: skewX(0deg);
  -ms-transform: skewX(0deg);
  -o-transform: skewX(0deg);
}

.link-item p {
  margin-bottom: 15px;
}

.link-item .arrow-right {
  margin-bottom: -12px;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  -ms-transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  -o-transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.link-item a:hover .arrow-right {
  margin-left: 20px;
}

.menu-wrapper {
  position: fixed;
  width: calc(100% - 100px);
  height: 0;
  top: 116px;
  left: 50px;
  overflow: hidden;
  z-index: 999;
  background-color: #111;
  transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  -ms-transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  -o-transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.menu-wrapper.show {
  height: calc(100% - 20%);
  transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -ms-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -o-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.main-menu-area.menu-sticky,
.header .main-menu-area.sticky {
  z-index: 999999;
}

.menu-wrapper.show {
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.06);
}

@media only screen and (max-height: 700px) {
  .menu-wrapper.show {
    overflow-y: scroll;
  }
}

.logo a {
  font-weight: 600;
  color: #eee;
  font-size: 18px;
}

.menu-wrapper {
  position: fixed;
  width: calc(100% - 100px);
  height: 0;
  top: 50px;
  left: 50px;
  overflow: hidden;
  z-index: 999;
  background-color: #ff2c54;
  transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  -ms-transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  -o-transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.header-layout3 .menu-wrapper {
  top: 16rem;
}

.header-layout3 .menu-wrapper.sticky-search {
  top: 10rem;
}

.header-layout5 .menu-wrapper {
  top: 20rem;
}

.menu-wrapper.show .hamburgernav-navigation li {
  opacity: 1;
  left: 0;
}

.menu-wrapper.show {
  height: calc(100% - 100px);
  transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -ms-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -o-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.menu-wrapper.show.search-wrapper {
  height: 35rem;
  background: transparent;
  box-shadow: none;
}

.menu-wrapper.show.search-wrapper .hamburgernav-inner-search {
  padding: 1rem 3rem 3rem 3rem;
  position: relative;
}

.menu-wrapper.show.search-wrapper .hamburgernav-inner-search .hamburgernav-nav-inner {
  padding: 5rem 1.5rem 0;
}

.menu-wrapper.show.search-wrapper .hamburgernav-inner-search form {
  margin-bottom: 5rem;
}

.menu-wrapper.search-wrapper {
  background: transparent;
  left: 50%;
  transform: translateX(-50%);
}

.link-item {
  transform: translateY(75px);
  -webkit-transform: translateY(75px);
  -moz-transform: translateY(75px);
  -ms-transform: translateY(75px);
  -o-transform: translateY(75px);
  opacity: 0;
}

.link-item-box {
  background-color: #ff2c54;
  margin-bottom: 15px;
}

.link-item li {
  background-color: #ff2c54;
  margin-top: 15px;
}

.link-item li a {
  background-color: #ff2c54;
  padding: 20px;
}

.link-item.show {
  transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -ms-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -o-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition-delay: .3s;
  transition-delay: .3s;
  transform: translateY(20px);
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  -ms-transform: translateY(20px);
  -o-transform: translateY(20px);
  opacity: 1;
}

.link-item-2.show {
  -webkit-transition-delay: .5s;
  transition-delay: .5s;
}

.menu-wrapper.show .link-item {
  opacity: 1;
}

.link-item a {
  display: block;
  color: #fff;
  position: relative;
  z-index: 1;
}

.link-item-box a {
  display: block;
  padding: 50px 40px;
  color: #fff;
  position: relative;
  z-index: 1;
}

.link-item a::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 0;
  top: 0;
  right: 0;
  background-color: #ff2c54;
  transition: .3s;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -ms-transition: .3s;
  -o-transition: .3s;
  z-index: -1;
}

.link-item a:hover::after {
  width: 100%;
  right: auto;
  left: 0;
  transition: .15s;
  -webkit-transition: .15s;
  -moz-transition: .15s;
  -ms-transition: .15s;
  -o-transition: .15s;
}

.link-item a:hover {
  color: #c4ae91;
}

.link-item img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.link-item h3 {
  font-size: 24px;
  margin: 20px 0;
  font-weight: 600;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  -ms-transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  -o-transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: skewX(-8deg);
  -webkit-transform: skewX(-8deg);
  -moz-transform: skewX(-8deg);
  -ms-transform: skewX(-8deg);
  -o-transform: skewX(-8deg);
}

.link-item a:hover h3 {
  transform: skewX(0deg);
  -webkit-transform: skewX(0deg);
  -moz-transform: skewX(0deg);
  -ms-transform: skewX(0deg);
  -o-transform: skewX(0deg);
}

.link-item p {
  margin-bottom: 15px;
}

.link-item .arrow-right {
  margin-bottom: -12px;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  -ms-transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  -o-transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.link-item a:hover .arrow-right {
  margin-left: 20px;
}

.menu-wrapper {
  position: fixed;
  width: calc(100% - 100px);
  height: 0;
  top: 116px;
  left: 50px;
  overflow: hidden;
  z-index: 999;
  background-color: #fff;
  transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  -ms-transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  -o-transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.menu-wrapper.show {
  height: calc(100% - 20%);
  transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -ms-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -o-transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.main-menu-area.menu-sticky,
.header .main-menu-area.sticky {
  z-index: 999999;
}

/* ------------------------------------
    Off Canvas Menu
---------------------------------------*/
.hamburgernav-inner-search {
  background: #fff;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.06);
  padding: 5rem;
}

.hamburgernav-inner-search .contact-social-share ul li {
  display: inline-block;
  margin-right: 1rem;
}

.hamburgernav-inner-search .contact-social-share ul li a {
  color: #333333;
}

.hamburgernav-inner-search .contact-social-share ul li a:hover {
  color: #ff2c54;
}

.hamburgernav-inner-search .tact-contact-info a {
  color: #333333;
  font-size: 1.6rem;
}

.hamburgernav-inner-search .tact-contact-info a i {
  padding-right: 1rem;
}

.hamburgernav-inner-search .tact-contact-info a:hover {
  color: #ff2c54;
}

.hamburgernav-inner-search .main-navigation li a {
  color: #333333;
}

.hamburgernav-inner-search .main-navigation li a:hover {
  color: #ff2c54;
}

.hamburgernav-inner-search ::-webkit-input-placeholder {
  color: #C1C6C9;
  opacity: 1;
}

.hamburgernav-inner-search :-ms-input-placeholder {
  color: #C1C6C9;
  opacity: 1;
}

.hamburgernav-nav-content .address-title {
  font-size: 1.8rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.hamburgernav-inner-search ::-ms-input-placeholder {
  color: #C1C6C9;
  opacity: 1;
}

.hamburgernav-inner-search ::placeholder {
  color: #C1C6C9;
  opacity: 1;
}

.hamburgernav-inner-search :-ms-input-placeholder {
  color: #C1C6C9;
}

.hamburgernav-inner-search ::-ms-input-placeholder {
  color: #C1C6C9;
}

.hamburgernav-inner-search.opened {
  right: 0;
}

@media (max-width: 1199px) {
  .hamburgernav-inner-search {
    width: 100vw;
    right: -100vw;
    padding: 2rem;
  }
  .hamburgernav-inner-search.opened {
    right: 0;
  }
}

.hamburgernav-nav__left {
  right: auto;
  left: -61vw;
}

.hamburgernav-nav__left.opened {
  left: 0;
}

@media (max-width: 1199px) {
  .hamburgernav-nav__left {
    left: -100vw;
  }
}

.hamburgernav-inner-search .hamburgernav-navigation {
  padding: 0;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.hamburgernav-inner-search .hamburgernav-navigation li {
  font-size: 3.6rem;
  line-height: 5rem;
  color: #121213;
  font-weight: 600;
  margin-bottom: 3rem;
  padding: 0;
  text-align: left;
}

.hamburgernav-inner-search .hamburgernav-navigation li a {
  background: none;
}

.hamburgernav-inner-search .hamburgernav-navigation li.hovered {
  color: #121213;
}

.hamburgernav-inner-search .hamburgernav-navigation li.hover-removed {
  color: #D3D7DA;
}

.hamburgernav-inner-search .hamburgernav-navigation a::before {
  display: none;
}

.hamburgernav-inner-search .hamburgernav-navigation a:hover {
  color: inherit;
}

.hamburgernav-inner-search .hamburgernav-navigation a::after {
  bottom: -3.5rem;
}

@media (max-width: 991px) {
  .hamburgernav-inner-search .hamburgernav-navigation {
    visibility: visible;
    opacity: 1;
    margin-bottom: 1.5rem;
  }
  .hamburgernav-inner-search .hamburgernav-navigation li {
    font-size: 2rem;
    line-height: 2rem;
  }
  .hamburgernav-inner-search .hamburgernav-navigation a {
    color: inherit;
  }
}

@media (max-width: 991px) {
  .hamburgernav-inner-search .hamburgernav-navigation li {
    font-size: 1.6rem;
    line-height: 2.6rem;
    margin-bottom: 1rem;
  }
}

.hamburgernav-inner-search form {
  margin-bottom: 8rem;
}

@media (max-width: 991px) {
  .hamburgernav-inner-search form {
    margin-bottom: 4rem;
  }
}

.hamburgernav-inner-search form .input-border {
  stroke-width: 4px;
}

@media (max-width: 991px) {
  .hamburgernav-inner-search form {
    font-size: 2rem;
    bottom: 0.5rem;
  }
}

.hamburgernav-inner-search .hamburgernav-nav-search-btn {
  position: absolute;
  right: 0;
  bottom: 0.9rem;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.hamburgernav-inner-search .hamburgernav-nav-search-btn i {
  font-size: 2rem;
  color: #C1C6C9;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  font-weight: 400;
}

.hamburgernav-inner-search .hamburgernav-nav-search-btn:hover i {
  color: #ff2c54;
}

@media (max-width: 767px) {
  .hamburgernav-inner-search .hamburgernav-nav-search-btn {
    bottom: 1rem;
    right: 2rem;
  }
  .hamburgernav-inner-search .hamburgernav-nav-search-btn i {
    font-size: 1.6rem;
  }
}

@media (max-width: 767px) {
  .hamburgernav-inner-search input {
    font-size: 1.6rem;
    padding: 0.9rem 0;
  }
}

.hamburgernav-inner-search .focused input {
  border-color: #ff2c54;
}

.hamburgernav-inner-search .form-group.focused .input-txt {
  -webkit-transform: translateY(6rem);
  -ms-transform: translateY(6rem);
  transform: translateY(6rem);
}

@media (max-width: 767px) {
  .hamburgernav-inner-search .form-group.focused .input-txt {
    -webkit-transform: translateY(4rem);
    -ms-transform: translateY(4rem);
    transform: translateY(4rem);
  }
}

.navbar-extra-features {
  display: inline-block;
}

@media (max-width: 767px) {
  .navbar-extra-features .btn-small {
    padding: 0.5rem 1rem;
  }
}

.close-sidenav {
  position: absolute;
  top: 5rem;
  right: 10.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 4.6rem;
  height: 4.6rem;
  border-radius: 100%;
  padding: 0;
  cursor: pointer;
  z-index: 9;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  -o-transition: transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.hamburgernav-nav-opened {
  position: relative;
  overflow: hidden;
  padding-right: 17px;
}

.hamburgernav-navigation li {
  opacity: 0;
  left: -5rem;
}

.hamburgernav-nav-inner {
  height: 100%;
  padding: 5rem 1.5rem;
  overflow-x: hidden;
}

.hamburgernav-inner-search .hamburgernav-nav-search-btn {
  position: absolute;
  right: 0;
  bottom: 0.9rem;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.hamburgernav-inner-search .hamburgernav-nav-search-btn i {
  font-size: 2rem;
  color: #c1c6c9;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  font-weight: 400;
}

.hamburgernav-inner-search .hamburgernav-nav-search-btn:hover i {
  color: #ff2c54;
}

.hamburgernav-nav-search-form .form-group {
  position: relative;
  margin-bottom: 3rem;
}

.hamburgernav-inner-search input {
  font-size: 2.2rem;
  line-height: 3.2rem;
  color: #333333;
  font-weight: 500;
  width: 100%;
  padding: 1.9rem 0 1rem;
  border: none;
  border-bottom: 1px solid #c1c6c9;
  outline: none;
}

@media (max-width: 767px) {
  .hamburgernav-inner-search input {
    font-size: 1.6rem;
    padding: 0.9rem 0;
  }
}

.hamburgernav-inner-search .focused input {
  border-color: #ff2c54;
}

.hamburgernav-inner-search .form-group.focused .input-txt {
  -webkit-transform: translateY(6rem);
  -ms-transform: translateY(6rem);
  transform: translateY(6rem);
}

@media (max-width: 767px) {
  .hamburgernav-inner-search .hamburgernav-nav-search-btn {
    bottom: 1rem;
    right: 2rem;
  }
  .hamburgernav-inner-search .hamburgernav-nav-search-btn i {
    font-size: 1.6rem;
  }
}

.hamburgernav-nav-opened::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
}

.close-sidenav div {
  width: 2rem;
  height: 0.2rem;
  background: #333333;
}

.close-sidenav div:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.close-sidenav div:nth-of-type(2) {
  -webkit-transform: rotate(-45deg) translate(1px, -1px);
  -ms-transform: rotate(-45deg) translate(1px, -1px);
  transform: rotate(-45deg) translate(1px, -1px);
}

.close-sidenav:hover {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.hamburgernav-nav-toggler {
  position: relative;
  top: 0.3rem;
  display: block;
}

.hamburgernav-nav-toggler span {
  position: relative;
  display: block;
  width: 1.8rem;
  height: 0.2rem;
  background: #6c6b6b;
  cursor: pointer;
  margin: 0.5rem 0;
  z-index: 9;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.hamburgernav-nav-toggler::after {
  content: " ";
  position: absolute;
  width: 4rem;
  height: 4rem;
  background: #eaeaea;
  top: 50%;
  left: 50%;
  margin: -2rem;
  border-radius: 100%;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.hamburgernav-nav-toggler:hover:after {
  background: #ff2c54;
}

.hamburgernav-nav-toggler:hover span {
  background: #fff;
}

.hamburgernav-nav-toggler:hover span {
  -webkit-animation: menu-toggler-anim 0.2s ease-in-out 0s forwards;
  animation: menu-toggler-anim 0.2s ease-in-out 0s forwards;
}

.hamburgernav-nav-toggler:hover span:nth-of-type(2) {
  -webkit-animation: menu-toggler-anim 0.2s ease-in-out 0.05s forwards;
  animation: menu-toggler-anim 0.2s ease-in-out 0.05s forwards;
}

.hamburgernav-nav-toggler:hover span:nth-of-type(3) {
  -webkit-animation: menu-toggler-anim 0.2s ease-in-out 0.1s forwards;
  animation: menu-toggler-anim 0.2s ease-in-out 0.1s forwards;
}

.hamburgernav-nav-toggler:hover::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.header.header-inner .header-bottom .mainmenu .nav-menu li.current_page_item > a {
  color: #fff;
  position: relative;
}

.header.header-inner .header-bottom .mainmenu .nav-menu li.current_page_item > a:after {
  position: absolute;
  content: "";
  display: block;
  width: 3rem;
  height: 0.2rem;
  background: #fff;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2.6rem;
}

.header.header-inner .header-bottom .mainmenu ul > li > a {
  position: relative;
}

.header.header-inner .header-bottom .mainmenu ul > li > a:after {
  position: absolute;
  content: "";
  display: block;
  width: 0;
  height: 0.2rem;
  background: #fff;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2.6rem;
  transition: all 0.4s ease 0s;
}

.header.header-inner .header-bottom .mainmenu ul > li > a:hover {
  color: #fff;
}

.header.header-inner .header-bottom .mainmenu ul > li > a:hover:after {
  width: 3rem;
}

.header.header-inner .header-bottom .mainmenu ul > li .sub-menu li a:after {
  display: none;
}

.header.header-inner .main-menu-area .two-icons li.sign-btn a {
  border-color: #5a6ad2;
}

.header.header-inner .main-menu-area .two-icons li.sign-btn a:hover {
  border-color: #ff2c54;
}

.header.header-inner .sticky .header-bottom .mainmenu .nav-menu li.current_page_item > a {
  color: #fff;
  position: relative;
}

.header.header-inner .sticky .header-bottom .mainmenu .nav-menu li.current_page_item > a:after {
  bottom: 0;
}

.header.header-inner .sticky .header-bottom .mainmenu ul > li > a {
  position: relative;
}

.header.header-inner .sticky .header-bottom .mainmenu ul > li > a:after {
  bottom: 0;
}

.header.header-inner .sticky .header-bottom .mainmenu ul > li > a:hover:after {
  width: 3rem;
  bottom: 0;
}

.header.header-inner .sticky .two-icons li.sign-btn a {
  border-color: #fff;
}

.header.header-inner .sticky .two-icons li.sign-btn a:hover {
  border-color: #ff2c54;
}

/* ------------------------------------
    Off Canvas Menu
---------------------------------------*/
.hamburgernav-nav {
  width: 60vw;
  height: 100vh;
  background: #fff;
  position: fixed;
  top: 0;
  right: -61vw;
  z-index: 9999;
  padding: 10.5rem;
  -webkit-transition: all 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition: all 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  transition: all 0.8s cubic-bezier(0.86, 0, 0.07, 1);
}

.hamburgernav-nav .contact-social-share ul li {
  display: inline-block;
  margin-right: 1rem;
}

.hamburgernav-nav .contact-social-share ul li a {
  color: #333333;
}

.hamburgernav-nav .contact-social-share ul li a:hover {
  color: #ff2c54;
}

.hamburgernav-nav .tact-contact-info a {
  color: #333333;
  font-size: 1.6rem;
}

.hamburgernav-nav .tact-contact-info a i {
  padding-right: 1rem;
}

.hamburgernav-nav .tact-contact-info a:hover {
  color: #ff2c54;
}

.hamburgernav-nav .main-navigation li a {
  color: #333333;
}

.hamburgernav-nav .main-navigation li a:hover {
  color: #ff2c54;
}

.hamburgernav-nav ::-webkit-input-placeholder {
  color: #C1C6C9;
  opacity: 1;
}

.hamburgernav-nav :-ms-input-placeholder {
  color: #C1C6C9;
  opacity: 1;
}

.hamburgernav-nav ::-ms-input-placeholder {
  color: #C1C6C9;
  opacity: 1;
}

.hamburgernav-nav ::placeholder {
  color: #C1C6C9;
  opacity: 1;
}

.hamburgernav-nav :-ms-input-placeholder {
  color: #C1C6C9;
}

.hamburgernav-nav ::-ms-input-placeholder {
  color: #C1C6C9;
}

.hamburgernav-nav.opened {
  right: 0;
}

@media (max-width: 1199px) {
  .hamburgernav-nav {
    width: 100vw;
    right: -100vw;
    padding: 2rem;
  }
  .hamburgernav-nav.opened {
    right: 0;
  }
}

.hamburgernav-nav__left {
  right: auto;
  left: -61vw;
}

.hamburgernav-nav__left.opened {
  left: 0;
}

@media (max-width: 1199px) {
  .hamburgernav-nav__left {
    left: -100vw;
  }
}

.hamburgernav-nav .hamburgernav-navigation {
  padding: 0;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.hamburgernav-nav .hamburgernav-navigation li {
  font-size: 3.6rem;
  line-height: 5rem;
  color: #121213;
  font-weight: 600;
  margin-bottom: 3rem;
  padding: 0;
  text-align: left;
}

.hamburgernav-nav .hamburgernav-navigation li a {
  background: none;
}

.hamburgernav-nav .hamburgernav-navigation li.hovered {
  color: #121213;
}

.hamburgernav-nav .hamburgernav-navigation li.hover-removed {
  color: #D3D7DA;
}

.hamburgernav-nav .hamburgernav-navigation a::before {
  display: none;
}

.hamburgernav-nav .hamburgernav-navigation a:hover {
  color: inherit;
}

.hamburgernav-nav .hamburgernav-navigation a::after {
  bottom: -3.5rem;
}

@media (max-width: 991px) {
  .hamburgernav-nav .hamburgernav-navigation {
    visibility: visible;
    opacity: 1;
    margin-bottom: 1.5rem;
  }
  .hamburgernav-nav .hamburgernav-navigation li {
    font-size: 2rem;
    line-height: 2rem;
  }
  .hamburgernav-nav .hamburgernav-navigation a {
    color: inherit;
  }
}

@media (max-width: 991px) {
  .hamburgernav-nav .hamburgernav-navigation li {
    font-size: 1.6rem;
    line-height: 2.6rem;
    margin-bottom: 1rem;
  }
}

.hamburgernav-nav form {
  margin-bottom: 8rem;
}

@media (max-width: 991px) {
  .hamburgernav-nav form {
    margin-bottom: 4rem;
  }
}

.hamburgernav-nav form .input-border {
  stroke-width: 4px;
}

@media (max-width: 991px) {
  .hamburgernav-nav form {
    font-size: 2rem;
    bottom: 0.5rem;
  }
}

.hamburgernav-nav .hamburgernav-nav-search-btn {
  position: absolute;
  right: 0;
  bottom: 1.8rem;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.hamburgernav-nav .hamburgernav-nav-search-btn i {
  font-size: 2rem;
  color: #C1C6C9;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  font-weight: 400;
}

.hamburgernav-nav .hamburgernav-nav-search-btn:hover i {
  color: #ff2c54;
}

@media (max-width: 767px) {
  .hamburgernav-nav .hamburgernav-nav-search-btn {
    bottom: 1rem;
    right: 2rem;
  }
  .hamburgernav-nav .hamburgernav-nav-search-btn i {
    font-size: 1.6rem;
  }
}

.hamburgernav-nav input {
  font-size: 3rem;
  line-height: 3.2rem;
  color: #121213;
  font-weight: 500;
  padding: 1.9rem 0;
  border: none;
  border-bottom: 2px solid #C1C6C9;
}

@media (max-width: 767px) {
  .hamburgernav-nav input {
    font-size: 1.6rem;
    padding: 0.9rem 0;
  }
}

.hamburgernav-nav .focused input {
  border-color: #ff2c54;
}

.hamburgernav-nav .form-group.focused .input-txt {
  -webkit-transform: translateY(6rem);
  -ms-transform: translateY(6rem);
  transform: translateY(6rem);
}

@media (max-width: 767px) {
  .hamburgernav-nav .form-group.focused .input-txt {
    -webkit-transform: translateY(4rem);
    -ms-transform: translateY(4rem);
    transform: translateY(4rem);
  }
}

.navbar-extra-features {
  display: inline-block;
}

@media (max-width: 767px) {
  .navbar-extra-features .btn-small {
    padding: 0.5rem 1rem;
  }
}

.hamburgernav-nav-inner {
  padding-bottom: 0;
}

.close-sidenav {
  position: absolute;
  top: 2rem;
  right: 3.6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 4.6rem;
  height: 4.6rem;
  border-radius: 100%;
  padding: 0;
  cursor: pointer;
  z-index: 9;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  -o-transition: transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.hamburgernav-nav-opened {
  position: relative;
  overflow: hidden;
  padding-right: 17px;
}

.hamburgernav-navigation li {
  opacity: 0;
  left: -5rem;
}

.hamburgernav-nav-inner {
  height: 100%;
  padding: 5rem 1.5rem;
  overflow-x: hidden;
}

.hamburgernav-nav .hamburgernav-nav-search-btn {
  position: absolute;
  right: 0;
  bottom: 1.8rem;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.hamburgernav-nav .hamburgernav-nav-search-btn i {
  font-size: 2rem;
  color: #c1c6c9;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  font-weight: 400;
}

.hamburgernav-nav .hamburgernav-nav-search-btn:hover i {
  color: #ff2c54;
}

.hamburgernav-nav-search-form .form-group {
  position: relative;
  margin-bottom: 3rem;
}

.hamburgernav-nav input {
  font-family: #ff2c54;
  font-size: 3rem;
  line-height: 3.2rem;
  color: #333333;
  font-weight: 500;
  width: 100%;
  padding: 1.9rem 0;
  border: none;
  border-bottom: 2px solid #c1c6c9;
}

@media (max-width: 767px) {
  .hamburgernav-nav input {
    font-size: 1.6rem;
    padding: 0.9rem 0;
  }
}

.hamburgernav-nav .focused input {
  border-color: #ff2c54;
}

.hamburgernav-nav .form-group.focused .input-txt {
  -webkit-transform: translateY(6rem);
  -ms-transform: translateY(6rem);
  transform: translateY(6rem);
}

@media (max-width: 767px) {
  .hamburgernav-nav .hamburgernav-nav-search-btn {
    bottom: 1rem;
    right: 2rem;
  }
  .hamburgernav-nav .hamburgernav-nav-search-btn i {
    font-size: 1.6rem;
  }
}

.hamburgernav-nav-opened::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
}

.close-sidenav div {
  width: 2rem;
  height: 0.2rem;
  background: #333333;
}

.close-sidenav div:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.close-sidenav div:nth-of-type(2) {
  -webkit-transform: rotate(-45deg) translate(1px, -1px);
  -ms-transform: rotate(-45deg) translate(1px, -1px);
  transform: rotate(-45deg) translate(1px, -1px);
}

.close-sidenav:hover {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.hamburgernav-nav-toggler {
  position: relative;
  top: 0.3rem;
  display: block;
}

.hamburgernav-nav-toggler span {
  position: relative;
  display: block;
  width: 1.8rem;
  height: 0.2rem;
  background: #6c6b6b;
  cursor: pointer;
  margin: 0.5rem 0;
  z-index: 9;
  transition: all 0.4s ease 0s;
}

.hamburgernav-nav-toggler::after {
  content: " ";
  position: absolute;
  width: 4rem;
  height: 4rem;
  background: #eaeaea;
  top: 50%;
  left: 50%;
  margin: -2rem;
  border-radius: 100%;
  transition: all 0.4s ease 0s;
}

.hamburgernav-nav-toggler:hover:after {
  background: #ff2c54;
}

.hamburgernav-nav-toggler:hover span {
  background: #fff;
}

.hamburgernav-nav-toggler:hover span {
  -webkit-animation: menu-toggler-anim 0.2s ease-in-out 0s forwards;
  animation: menu-toggler-anim 0.2s ease-in-out 0s forwards;
}

.hamburgernav-nav-toggler:hover span:nth-of-type(2) {
  -webkit-animation: menu-toggler-anim 0.2s ease-in-out 0.05s forwards;
  animation: menu-toggler-anim 0.2s ease-in-out 0.05s forwards;
}

.hamburgernav-nav-toggler:hover span:nth-of-type(3) {
  -webkit-animation: menu-toggler-anim 0.2s ease-in-out 0.1s forwards;
  animation: menu-toggler-anim 0.2s ease-in-out 0.1s forwards;
}

.hamburgernav-nav-toggler:hover::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.nav-item-list .menu-toggle-right ul.sub-menu.opened span.em-menu-parent {
  color: #ff2c54;
}

.nav-item-list .menu-toggle-right ul.sub-menu.opened span.em-menu-parent i {
  color: #ff2c54;
}

.nav-item-list .menu-toggle-right ul.sub-menu {
  padding-left: 10px;
  padding-bottom: 20px;
}

.nav-item-list .menu-toggle-right ul.sub-menu li {
  color: #111;
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.nav-item-list .menu-toggle-right ul.sub-menu li a {
  color: #111;
}

.nav-item-list .menu-toggle-right ul.sub-menu li a:hover {
  color: #ff2c54;
}

.nav-item-list .menu-toggle-right li.sub-menu-toggle span.em-menu-parent {
  border: 0;
  cursor: pointer;
  display: block;
  line-height: 1.5rem;
  padding: 1.3rem;
  position: absolute;
  right: 0;
  top: 0;
}

.nav-item-list .menu-toggle-left ul.sub-menu {
  padding-left: 10px;
  padding-bottom: 20px;
}

.nav-item-list .menu-toggle-left ul.sub-menu li {
  color: #111;
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.nav-item-list .menu-toggle-left ul.sub-menu li a {
  color: #111;
}

.nav-item-list .menu-toggle-left ul.sub-menu li a:hover {
  color: #ff2c54;
}

.nav-item-list .menu-toggle-left li.sub-menu-toggle span.em-menu-parent {
  border: 0;
  cursor: pointer;
  display: block;
  line-height: 1.5rem;
  padding: 1.3rem;
  position: absolute;
  right: 0;
  top: 0;
}

.menu-toggle-right ul.sub-menu {
  display: none;
}

.menu-toggle-right .sub-menu.show {
  display: block;
}

.menu-toggle-left ul.sub-menu {
  display: none;
}

.menu-toggle-left .sub-menu.show {
  display: block;
}

/*--------------------------------------
	# Topbar Style
---------------------------------------*/
.topbar-layout1 {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 100;
  background: linear-gradient(135deg, #2cbe4e, #0eac85);
  transition: all 0.3s ease-in-out;
}

.topbar-layout1 .header-address-info {
  display: flex;
  align-items: center;
}

.topbar-layout1 .header-address-info .single-item {
  color: #fff;
  margin-right: 3.5rem;
  font-size: 1.4rem;
  padding-right: 1.5rem;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
}

.topbar-layout1 .header-address-info .single-item:last-child {
  margin-right: 0;
  border-right: 0;
  padding-right: 0;
}

.topbar-layout1 .header-address-info .single-item i {
  color: #fff;
  margin-right: 1rem;
}

.topbar-layout1 .header-address-info .single-item i:before {
  font-size: 1.6rem;
}

.topbar-layout1 .header-action-items {
  display: flex;
  align-items: center;
}

.topbar-layout1 .header-action-items .single-item {
  margin-right: 0.8rem;
  font-size: 1.5rem;
  padding-right: 1.5rem;
}

.topbar-layout1 .header-action-items .single-item:before {
  display: none;
}

.topbar-layout1 .header-action-items .single-item:last-child {
  margin-right: 0;
  border-right: 0;
  padding-right: 0;
}

.topbar-layout1 .header-action-items .single-item a {
  color: #fff;
  display: block;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition: all 0.3s ease-in-out;
}

.topbar-layout1 .header-action-items .single-item a:hover {
  color: #09c7f7;
}

.topbar-layout2 .topbar-info {
  padding: 2.4rem 0;
}

.topbar-layout2 .topbar-info .contact-info {
  display: flex;
  padding-right: 6.5rem;
}

.topbar-layout2 .topbar-info .contact-info .contact-icon i {
  font-size: 3rem;
  height: 3rem;
  color: #ff2c54;
}

.topbar-layout2 .topbar-info .contact-info .contact-icon i:before {
  font-size: 3.1rem;
}

.topbar-layout2 .topbar-info .contact-info .contact-text {
  padding-left: 1.5rem;
}

.topbar-layout2 .topbar-info .contact-info .contact-text:last-child {
  padding-right: 0;
}

.topbar-layout2 .topbar-info .contact-info .contact-text .contact-title {
  font-weight: 700;
  font-size: 1.5rem;
  color: #333333;
  line-height: 2rem;
}

.topbar-layout2 .topbar-info .contact-info .contact-text .text {
  font-size: 1.5rem;
  color: #333333;
}

.topbar-layout2 .topbar-info .contact-info .contact-text .text a {
  color: #333333;
}

.topbar-layout2 .topbar-info .contact-info .contact-text .text a:hover {
  color: #ff2c54;
}

.topbar-layout2 .sign-btn {
  width: 100%;
  text-align: right;
}

/*--------------------------------------
	2.3.3 Pagination
---------------------------------------*/
/*--------------------------------------
    # Pagination Style
---------------------------------------*/
ul.pagination-layout1 {
  margin-bottom: 2.5rem;
  margin-top: 1.6rem;
  display: flex;
  flex-wrap: wrap;
  align-items: left;
  justify-content: left;
}

@media only screen and (max-width: 991px) {
  ul.pagination-layout1 {
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 767px) {
  ul.pagination-layout1 {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 575px) {
  ul.pagination-layout1 {
    margin-top: 0.5rem;
  }
}

ul.pagination-layout1 li {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

ul.pagination-layout1 li a i {
  transition: all 0.3s ease-in-out;
}

ul.pagination-layout1 li:first-child a i {
  color: #bbbbbb;
}

ul.pagination-layout1 li:last-child {
  margin-right: 0;
}

ul.pagination-layout1 li:last-child a i {
  color: #a3a0a0;
}

ul.pagination-layout1 li:last-child a i:before {
  font-size: 1.5rem;
}

ul.pagination-layout1 li a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #333333;
  background-color: transparent;
  border: 0.1rem solid #e4e4e4;
  height: 4rem;
  width: 4rem;
  transition: all 0.3s ease-in-out;
}

ul.pagination-layout1 li a:hover {
  background-color: #ff2c54;
  color: #ffffff;
  border: 0.1rem solid #ff2c54;
}

ul.pagination-layout1 li a:hover i {
  color: #ffffff;
}

ul.pagination-layout1 li.active a {
  background-color: #ff2c54;
  color: #ffffff;
  border: 0.1rem solid #ff2c54;
}

ul.pagination-layout2 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

ul.pagination-layout2 li .item-figure {
  margin-bottom: 0;
}

ul.pagination-layout2 li .item-figure img {
  border-radius: 50%;
}

ul.pagination-layout2 li .item-content p {
  color: #ff2c54;
  margin-bottom: 0;
}

ul.pagination-layout2 li .item-content .item-title {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 1.8rem;
  color: #646464;
  line-height: 1.4;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  ul.pagination-layout2 li .item-content {
    display: none;
  }
}

ul.pagination-layout2 li:hover .item-content .item-title {
  color: #ff2c54;
}

ul.pagination-layout2 .next-btn {
  text-align: right;
}

ul.pagination-layout2 .center-btn a {
  height: 5rem;
  width: 5rem;
  background-color: #f3f3f3;
  color: #646464;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

ul.pagination-layout2 .center-btn a:hover {
  background-color: #ff2c54;
  color: #fff;
}

/*--------------------------------------
	2.3.3 Sidebar
---------------------------------------*/
/*--------------------------------------
    # Sidebar Style
---------------------------------------*/
.sidebar-area .widget {
  margin-bottom: 4.7rem;
}

.sidebar-area .widget.bs-search {
  margin-bottom: 5.2rem;
}

.sidebar-area .widget.bs-caterories {
  margin-bottom: 3.6rem;
}

.sidebar-area .bs-widget-title {
  font-size: 2rem;
  margin-bottom: 3rem;
  font-weight: 600;
}

.sidebar-area .bs-caterories .bs-widget-title {
  margin-bottom: 2.1rem;
}

.sidebar-area .bs-search {
  overflow: hidden;
  position: relative;
}

.sidebar-area .bs-search input {
  border: none;
  background: #f8f8f8;
  color: #333333;
  padding: 1.5rem 3.4rem;
  font-size: 1.8rem;
  width: 100%;
  outline: none;
}

.sidebar-area .bs-search button {
  background: transparent;
  border: medium none;
  color: #989898;
  position: absolute;
  right: 2.3rem;
  top: 0;
  z-index: 9;
  font-size: 1.6rem;
  outline: none;
  width: 4rem;
  height: 100%;
  cursor: pointer;
}

.sidebar-area .bs-search button i {
  font-size: 1.8rem;
}

.sidebar-area .bs-caterories ul li {
  padding: 0.9rem 0 0.9rem 0;
}

.sidebar-area .bs-caterories ul li a {
  color: #333333;
  font-size: 1.6rem;
  font-weight: 600;
}

.sidebar-area .bs-caterories ul li a:hover {
  color: #ff2c54;
}

.sidebar-area .bs-caterories ul li span {
  float: right;
}

.sidebar-area .bs-caterories ul li:first-child {
  border-top: none;
  padding-top: 0;
}

.sidebar-area .bs-latest-post .post-item {
  margin-bottom: 2.4rem;
  overflow: hidden;
}

.sidebar-area .bs-latest-post .post-item .post-images {
  float: left;
  width: 8rem;
  margin-right: 2rem;
}

.sidebar-area .bs-latest-post .post-item .post-desc {
  overflow: hidden;
}

.sidebar-area .bs-latest-post .post-item .post-desc .title {
  display: block;
  margin: 0 0 3px;
  font-size: 1.6rem;
  font-weight: 600;
  color: #333333;
  font-family: "Poppins", sans-serif;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  line-height: 1.6;
}

.sidebar-area .bs-latest-post .post-item .post-desc .title:hover {
  color: #ff2c54;
}

.sidebar-area .bs-latest-post .post-item .post-desc span {
  display: block;
  font-size: 1.4rem;
  color: #666666;
  margin-bottom: 0.5rem;
}

.sidebar-area .bs-latest-post .post-item .post-desc i {
  color: #777777;
  font-size: 13px;
}

.sidebar-area .bs-latest-post .post-item .post-desc i.fa-star-o {
  color: #2554E1;
}

.sidebar-area .bs-tags a {
  display: inline-block;
  margin: 0 0.5rem 0.7rem 0;
  padding: 0.65rem 2.2rem;
  font-size: 1.5rem;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  color: #333333;
  border: 1px solid #e3e3e3;
}

.sidebar-area .bs-tags a:hover {
  background-color: #ff2c54;
  border-color: #ff2c54;
  color: #fff;
}

.sidebar-area .sidebar-meta ul#meta-link {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
}

.sidebar-area .sidebar-meta ul#meta-link a {
  display: block;
  font-size: 14px;
  padding: 0 0 10px;
  margin: 0 0 10px;
  border-bottom: 1px solid #e8e8e8;
  color: #666666;
  transition: all 0.4s ease 0s;
}

.sidebar-area .sidebar-meta ul#meta-link a:hover {
  color: #ff2c54;
}

/*--------------------------------------
	# Responsive Style
---------------------------------------*/
@media only screen and (max-width: 1760px) {
  .banner-below {
    padding-top: 1rem;
  }
}

@media only screen and (max-width: 1660px) {
  .banner-below {
    padding-top: 1rem;
    margin-top: -5rem;
  }
  .service-layout5 .service-item {
    padding: 2.5rem 3rem 3rem 3rem;
  }
  .service-layout5 .service-item .service-number,
  .service-layout5 .service-item .service-icon,
  .service-layout5 .service-item .hidden-number {
    left: 3rem;
  }
  .service-layout5 .service-item .service-number {
    font-size: 2.2rem;
  }
  .service-layout5 .service-item .service-title {
    font-size: 2rem;
  }
  .service-layout5 .service-item .service-icon2 i:before {
    font-size: 15rem;
  }
}

@media only screen and (max-width: 1440px) {
  .portfolio-section.landing-project .project-content .project-info,
  .portfolio-section.landing-project .project-content:hover .project-info {
    left: 50%;
    padding-right: 0;
    transform: translateX(-50%);
    font-size: 1.4rem;
  }
  .portfolio-section .project-content .project-info .project-title {
    font-size: 2rem;
  }
  .portfolio-section.landing-project .project-content .project-info .project-title {
    margin-bottom: 0;
  }
  .about-us-four.landing .left-desc .left-wrap {
    padding-top: 10rem;
    padding-bottom: 8rem;
  }
}

@media only screen and (max-width: 1199px) {
  .push-left {
    padding-right: 1.5rem;
    padding-left: calc(calc(100% - 930px)/2);
  }
  .contact-toggle-btn .contact-toggle {
    display: none;
  }
  .analyze-desc {
    padding-right: 3rem;
  }
  .tact-left-sidebar-wrp .offcanvas-cpt-wrp:hover .trigger span,
  .tact-right-sidebar-wrp .offcanvas-cpt-wrp:hover .trigger span {
    background: #fff;
  }
  .tact-banner-shares,
  .social-share-wrapper.show-shares,
  .toggle-shares {
    display: none;
  }
  .fixed-side-menu .social-share-wrapper.show-shares {
    display: block;
  }
  .call-action-section.cta-landing .cta-btn .secondary-btn {
    font-size: 2rem;
    padding: 1.2rem 2rem 1.1rem;
  }
  .tact-left-sidebar-wrp .offcanvas-cpt-wrp {
    border: none;
    background: #ff2c54;
  }
  .tact-left-sidebar-wrp .info_wrap .inner_wrap .logo,
  .tact-right-sidebar-wrp .info_wrap .inner_wrap .logo {
    display: none;
  }
  .tact-right-sidebar-wrp .offcanvas-cpt-menu .inner-cover {
    background: transparent;
  }
  .tact-left-sidebar-wrp .info_wrap,
  .tact-right-sidebar-wrp .info_wrap,
  .tact-right-sidebar-wrp .offcanvas-cpt-menu:before,
  .tact-right-sidebar-wrp .offcanvas-cpt-menu,
  .tact-right-sidebar-wrp .info_wrap:before,
  .tact-left-sidebar-wrp .info_wrap:before {
    background-color: transparent;
  }
  .opened.right .tact-right-sidebar-wrp .info_wrap::before {
    background-color: rgba(0, 0, 0, 0.06);
  }
  .opened.right .tact-right-sidebar-wrp .info_wrap {
    background: #fff;
  }
  .tact-right-sidebar-wrp .offcanvas-cpt-menu .inner-cover {
    box-shadow: none;
    background-color: transparent;
  }
  .tact-right-sidebar-wrp .offcanvas-cpt-wrp .trigger span {
    background: #fff;
  }
  .tact-right-sidebar-wrp .offcanvas-cpt-wrp {
    background-color: #ff2c54;
  }
  .tact-right-sidebar-wrp .offcanvas-cpt-wrp {
    border: 0.1rem solid rgba(0, 0, 0, 0.06);
  }
  .tact-left-sidebar-wrp .offcanvas-cpt-wrp .trigger span {
    background-color: #fff;
  }
  .hamburgernav-nav #close-sidenav {
    right: 2.5rem;
    top: 3rem;
  }
  .banner-section.banner-light:after {
    display: block;
  }
  .header-icons .two-icons {
    display: none;
  }
  .header .header-bottom .mainmenu .nav-menu {
    text-align: right;
  }
  .section-title .line-break {
    display: initial;
  }
  .service-layout4 .section-title .line-break {
    display: block;
  }
  .section-title .sub-title {
    font-size: 1.8rem;
  }
  .section-title .title {
    font-size: 3.2rem;
  }
  .newsletter .news-form {
    max-width: 27rem;
  }
  .blog-section.blog-style4 .loarmore {
    margin-top: 0;
  }
  .banner-section {
    height: 70rem;
  }
  .about-section .about-btn .arrow-link {
    margin-left: 2rem;
  }
  .portfolio-layout2 .section-title {
    max-width: 45rem;
  }
  .experience-wrap {
    padding-bottom: 4rem;
  }
  .col-lg-hide {
    display: none;
  }
  .testimonial-section.testimonial-style5 .testimonial-item p {
    font-size: 1.8rem;
    line-height: 3.2rem;
  }
  .about-us-four .left-desc .left-wrap {
    padding-right: 3rem;
  }
}

/* Layout: Min 992px Max 1199px. */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  footer.footer .footer-top .contact-info .contact-icon i:before {
    font-size: 2.8rem;
  }
  .mission-section .mission-area {
    padding-right: 3rem;
  }
  .about-section.about-us-three {
    padding-bottom: 8rem;
  }
  .sidebar-area .bs-latest-post .post-item .post-desc .title {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
  .sidebar-area .bs-latest-post .post-item .post-images {
    margin-right: 1rem;
  }
  .portfolio-details-section .project-info {
    padding: 1rem 1rem 1rem 1.6rem;
  }
  footer.footer .footer-bottom .newsletter .news-form .input-field .form-button {
    font-size: 1.3rem;
    padding: 0 1rem;
  }
  footer.footer .footer-bottom .newsletter .news-form .input-field .form-input {
    padding-right: 0;
  }
  .why-us-section .feature-item .item-desc .overlay-content p {
    display: none;
  }
  footer.footer .footer-top .contact-info .contact-icon i {
    font-size: 3.5rem;
  }
  footer.footer .footer-top .contact-info .contact-text .contact-title {
    font-size: 1.5rem;
  }
  footer.footer .footer-top .contact-info .contact-text .text {
    font-size: 1.6rem;
    font-weight: 600;
  }
  footer.footer .footer-top .contact-info .contact-text {
    padding-left: 1.5rem;
  }
  footer.footer .footer-bottom .newsletter .news-form span {
    font-size: 1.4rem;
  }
  .feature-section .feature-wrap {
    padding: 6rem 3.2rem 5.2rem;
  }
  .style-arrow:hover .owl-nav .owl-prev {
    left: -3rem !important;
  }
  .style-arrow:hover .owl-nav .owl-next {
    right: -3rem !important;
  }
  .feature-section.layout2 .feature-type .feature-item .feature-icon {
    width: 3.5rem;
    margin-top: 0;
  }
  .feature-section.layout2 .feature-type .feature-item .short-desc {
    width: calc(100% - 35px);
  }
  .feature-section.layout2 .feature-type .feature-item .feature-icon i::before {
    font-size: 3.5rem;
  }
  .feature-section.layout2 .feature-type .feature-item .short-desc .feature-title {
    font-size: 2rem;
  }
  .chatbox-section .chatbox-area .style-check li {
    width: 100%;
    float: none;
  }
  .chatbox-section .chatbox-area {
    padding-left: 3rem;
  }
  .topbar-layout2 .topbar-info .contact-info {
    padding-right: 3.5rem;
  }
  .topbar-layout2 .sign-btn .primary-btn {
    padding: 0.8rem 1.6rem;
  }
  .about-us-three {
    margin-top: -25rem;
  }
}

/* Min Width: 991px. */
@media only screen and (min-width: 992px) {
  .header-menu .header-bottom .em-menu-toggle {
    display: none;
  }
  .menu-toggle {
    display: none;
  }
  .menu-wrapper.show.search-wrapper {
    overflow-y: hidden;
  }
  .nav-menu > .menu-item-has-children .sub-menu .menu-item-has-children span.em-menu-parent {
    padding: 1.3rem;
    border-left: none;
  }
  .nav-menu > .menu-item-has-children .sub-menu .menu-item-has-children span.em-menu-parent i {
    color: #333;
  }
  .nav-menu > .menu-item-has-children .sub-menu .active span.em-menu-parent i,
  .nav-menu > .menu-item-has-children .sub-menu .menu-item-has-children:hover span.em-menu-parent i {
    color: #ddd;
  }
  .about-section.about-us-three .about-btn {
    padding-bottom: 4rem;
  }
  .chatbox-area .section-title {
    margin-top: 4.2rem;
  }
  .min992-pl--30 {
    padding-left: 2rem;
  }
}

/* Medium Layout: 991px. */
@media only screen and (max-width: 991px) {
  .ipad-pt--60 {
    padding-top: 6rem;
  }
  .ipad-pt--50 {
    padding-top: 5rem;
  }
  .ipad-pt--40 {
    padding-top: 4rem;
  }
  .ipad-pt--30 {
    padding-top: 3rem;
  }
  .ipad-mt--50 {
    margin-top: 5rem;
  }
  .ipad-mt--40 {
    margin-top: 4rem;
  }
  .ipad-mt--30 {
    margin-top: 3rem;
  }
  .ipad-pb--50 {
    padding-bottom: 5rem;
  }
  .ipad-pb--40 {
    padding-bottom: 4rem;
  }
  .ipad-pb--30 {
    padding-bottom: 3rem;
  }
  .ipad-mb--50 {
    margin-bottom: 5rem;
  }
  .ipad-mb--40 {
    margin-bottom: 4rem;
  }
  .sidebar-area .widget:last-child {
    margin-bottom: 0;
  }
  #header-onepage-six .main-menu-area {
    padding-top: 1.3rem;
  }
  .header .main-menu-area .has-megamenu .header-menu .mega-menu-full > .sub-menu li a .text:hover,
  .header .main-menu-area .has-megamenu .header-menu .mega-menu-full > .sub-menu li a .text {
    color: #fff !important;
    padding-bottom: 2rem;
  }
  .header.header-layout2 .has-megamenu .mobile-menu .mainmenu .em-menu ul .sub-menu li {
    border-bottom: none;
  }
  .header.header-layout2 .has-megamenu .mobile-menu .mainmenu .em-menu ul .sub-menu li a {
    padding-left: 0;
  }
  .counter-area .counter-wrap.last-counter {
    margin-bottom: 0;
  }
  .contact-info-section#contact-us .contact-form {
    padding-top: 3rem;
  }
  .banner-section.banner-layout4 .banner-btn {
    padding-bottom: 0;
  }
  .why-us-section .feature-item .item-desc .item-title {
    margin-top: 1.6rem;
  }
  .why-us-section .feature-item .item-desc .item-title {
    font-size: 1.8rem;
  }
  .ipad-mb--30 {
    margin-bottom: 3rem;
  }
  .mobile-menu .mainmenu .em-menu {
    max-height: 50rem;
    overflow-y: scroll;
  }
  .header.header-layout6 .main-menu-area .header-contact {
    padding-right: 0;
  }
  .header.header-layout2 .main-menu-area.sticky .header-bottom .mainmenu .nav-menu > li > a {
    color: #fff;
  }
  .banner-section.banner-layout1 .banner-shape-inner.shape1.shape-loaded {
    width: 100%;
  }
  .banner-section.banner-layout1 .item-figure {
    display: none;
  }
  .banner-section.banner-layout1 .banner-desc p {
    max-width: 60rem;
  }
  .header .main-menu-area .has-megamenu .header-menu .mega-menu-full > .sub-menu {
    border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
    width: 100%;
    height: auto;
    display: none;
  }
  .header .main-menu-area .has-megamenu .header-menu .mega-menu-full > .sub-menu.opened {
    display: block;
  }
  .header .main-menu-area .has-megamenu .header-menu .mega-menu-full {
    position: relative;
  }
  .header .main-menu-area .has-megamenu .header-menu .mega-menu-full.mega-menu-three > .sub-menu li {
    width: 33.33%;
    float: left;
  }
  .header .main-menu-area .has-megamenu .header-menu .mega-menu-full > .sub-menu li a .text {
    font-size: 1.6rem;
  }
  .header .main-menu-area .has-megamenu .header-menu .mega-menu-full ul > li > a {
    border-bottom: none;
  }
  .home-slider-section .slider-main .nivo-directionNav .nivo-prevNav,
  .home-slider-section .slider-main .nivo-directionNav .nivo-nextNav {
    display: none;
  }
  .call-action-section.cta-style3 .cta-btn a {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .fixed-menu #main_content {
    padding-left: 0 !important;
  }
  .header.header-layout3 .header-action-items {
    display: none;
  }
  .contact-info-section .our-contact {
    padding-left: 0;
  }
  .header.header-layout5 .main-menu-area {
    padding-top: 1.3rem;
  }
  .push-left {
    padding-right: 1.5rem;
    padding-left: calc(calc(100% - 690px)/2);
  }
  .skill-section .skill-area {
    max-width: 68rem;
    padding-right: 1.5rem;
    margin: 0 auto;
  }
  .skill-section .skill-img img {
    max-width: 68rem;
    padding-left: 1.5rem;
    margin: 0 auto;
    display: block;
    width: 100%;
  }
  .sidebar-area .widget.bs-search {
    margin-bottom: 4rem;
  }
  .faq-section #accordion .panel .panel-heading .panel-title a::after {
    right: 2rem;
  }
  .sidebar-area .widget {
    max-width: 50rem;
  }
  .feature-section.layout3 {
    transform: translate(0);
    padding-top: 10.5rem;
    padding-bottom: 1rem;
    overflow: visible;
  }
  .portfolio-details-section .project-info .item-heading {
    margin-bottom: 1.5rem;
  }
  .portfolio-details-section .project-info {
    padding: 4rem 3rem 4rem 3.5rem;
  }
  .about-us-three {
    margin-top: 0;
    padding-bottom: 8rem;
  }
  .blog-section.blog-style4 {
    padding-bottom: 5rem;
  }
  .experience-section .experience-wrap {
    margin: 0 auto;
  }
  .topbar-layout2 .topbar-info .contact-info {
    padding-right: 3rem;
  }
  .mission-section .mission-area {
    margin: 0 auto;
    max-width: 67rem;
  }
  .service-layout4.skew-wrap {
    padding-top: 12rem;
    padding-bottom: 10rem;
  }
  .header-layout3 #topbar-wrap {
    border-bottom: 0.1rem solid #a5cac1;
  }
  .header.header-layout3 .main-menu-area,
  .header.header-layout3 .main-menu-area.sticky {
    background: linear-gradient(135deg, #2cbe4e, #0eac85);
  }
  .blog-section.blog-style2 {
    padding-bottom: 6.5rem;
  }
  .mission-section {
    padding-top: 7rem;
    padding-bottom: 1.5rem;
  }
  .partner-section.partner-layout2 {
    padding-bottom: 3rem;
  }
  .skew-wrap {
    margin-bottom: 4rem;
  }
  .banner-layout1.banner-section {
    height: auto;
    padding: 11.2rem 0 11rem;
  }
  .banner-section.banner-landing .banner-desc h2.second {
    font-size: 3rem;
    line-height: 4.5rem;
  }
  .banner-section.banner-landing {
    height: 56rem;
    overflow: hidden;
  }
  .banner-section {
    height: 60rem;
    padding-top: 0;
  }
  .banner-section.banner-light {
    height: 60rem;
    background-position: bottom center;
  }
  .banner-section .banner-desc h2 {
    font-size: 3rem;
  }
  .banner-section .banner-desc h2.second {
    font-size: 4rem;
    margin-bottom: 0;
  }
  .banner-section .banner-desc h1,
  .banner-section .banner-desc .h1 {
    font-size: 5rem;
  }
  .banner-section.banner-light .banner-desc h2 {
    font-size: 3rem;
    line-height: 4rem;
  }
  .banner-section.banner-light .banner-desc h1,
  .banner-section.banner-light .banner-desc .h1 {
    font-size: 5rem;
    line-height: 6rem;
  }
  .banner-section .banner-btn {
    padding-top: 2rem;
  }
  .banner-section.banner-light {
    margin-bottom: 1.2rem;
  }
  .banner-section.banner-gradient {
    padding: 13rem 0 55rem;
  }
  .banner-section.banner-gradient .banner-desc h2 {
    font-size: 5rem;
    line-height: 6rem;
  }
  .banner-section.banner-layout4 {
    height: 60rem;
  }
  .banner-section.banner-layout4 .banner-desc .p-light {
    font-size: 4.5rem;
    line-height: 5.5rem;
  }
  .banner-section.banner-layout4 .banner-desc .p-semi-bold {
    font-size: 4.7rem;
    line-height: 6rem;
  }
  .section-title .title {
    font-size: 3rem;
  }
  .about-section .about-desc {
    padding-left: 0;
  }
  .what-we-do .about-btn {
    margin-bottom: 0;
  }
  .who-are-section .about-btn {
    margin-bottom: 1.8rem;
  }
  footer.footer .footer-bottom .footer-title {
    margin-top: 3rem;
  }
  footer.footer .footer-bottom {
    padding-top: 3rem;
  }
  .analyze-section .analyze-desc {
    padding-right: 0;
  }
  .section-counter-area .counter-wrap .counter-text .count-number {
    font-size: 4.5rem;
  }
  .section-counter-area .counter-wrap .counter-icon {
    height: 5.5rem;
    line-height: 5.5rem;
    margin-bottom: 1.5rem;
  }
  .section-counter-area .counter-wrap .counter-icon i::before {
    font-size: 6rem;
  }
  .section-counter-area .counter-wrap .counter-text .count-title {
    font-size: 1.6rem;
  }
  .team-section .section-title.title-style2 .title {
    margin-bottom: 0;
  }
  .cta-section h3 {
    font-size: 2rem;
    margin-bottom: 0;
  }
  .cta-section h2 {
    font-size: 3rem;
  }
  .section-title.title-style2 .title {
    margin-bottom: 2rem;
  }
  .about-section .about-img {
    padding-right: 0;
  }
  .about-section.about-layout5 .about-img {
    padding-top: 7rem;
  }
  .video-section {
    height: 40rem;
  }
  .header .main-menu-area {
    position: static !important;
    background: #2554E1;
    animation: none;
  }
  .header.header-layout4 .main-menu-area {
    background: transparent;
    position: absolute !important;
  }
  .header .main-menu-area.sticky {
    position: fixed !important;
    padding: 1.3rem 0;
  }
  .header.header-layout5 .main-menu-area {
    position: absolute !important;
  }
  .header.header-layout5 .main-menu-area.sticky {
    position: fixed !important;
  }
  .header.header-layout2 .main-menu-area,
  .header.header-layout2 .main-menu-area.sticky {
    background: #fff;
  }
  .header-menu .header-bottom {
    text-align: right;
  }
  .header.header-inner .header-bottom .mainmenu ul > li > a:hover::after,
  .header.header-inner .header-bottom .mainmenu .nav-menu li.current_page_item > a::after {
    display: none;
  }
  .mobile-menu .em-menu ul ul {
    visibility: visible;
    opacity: 1;
    position: static;
    transform: scaleY(1);
    box-shadow: none;
    border-bottom: none;
  }
  .mobile-menu .em-menu ul ul.sub-menu {
    display: none;
  }
  .mobile-menu .em-menu ul ul li a {
    background: #1d49d0;
    width: 100%;
    color: #fff;
  }
  .what-we-do .pr--0 {
    padding-right: 1.5rem;
  }
  .what-we-do .pl--0 {
    padding-left: 1.5rem;
  }
  .footer-top .pl0 {
    padding-left: 1.5rem !important;
  }
  .portfolio-section .project-content .project-info .project-title {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
  .portfolio-section .project-content .project-info .project-tags {
    font-size: 1.4rem;
  }
  .header.header-layout2 .logo .sticky-logo {
    display: block;
  }
  .header.header-layout2 .logo .dark-logo {
    display: none;
  }
  footer.footer .footer-top .contact-info .contact-icon i:before {
    font-size: 3.2rem;
  }
  footer.footer .footer-top .contact-info .contact-icon i {
    font-size: 3.9rem;
  }
  .service-section.service-layout3 {
    padding-bottom: 0;
  }
  .testimonial-section.testimonial-style3 .testimonial-item .author-info .name-info .author {
    font-size: 2rem;
  }
  .testimonial-section.testimonial-style3 .testimonial-item .author-info .name-info .designation {
    font-size: 1.6rem;
  }
  .testimonial-section.testimonial-style3 .testimonial-item p {
    font-size: 2rem;
    line-height: 3.4rem;
  }
  .counter-area .counter-wrap .counter-icon i:before {
    font-size: 6rem;
  }
  .counter-area .counter-wrap .counter-text .count-number {
    font-size: 4rem;
  }
  .counter-area .counter-wrap .counter-text .count-title {
    font-size: 2rem;
    line-height: 3rem;
  }
  .counter-area .counter-wrap .counter-text {
    padding-left: 2rem;
  }
  .counter-area {
    padding-top: 6rem;
  }
  .counter-area .counter-wrap {
    justify-content: left;
    max-width: 28.5rem;
    margin: 0 auto 2rem;
  }
  .call-action-section h2 {
    font-size: 3rem;
  }
  .call-action-section .cta-sub-text {
    font-size: 2rem;
  }
  footer.skew-footer::after {
    display: none;
  }
  footer.skew-footer {
    padding-top: 0;
  }
  footer.skew-footer .footer-top {
    max-width: 85%;
    transform: translateY(-50px);
  }
  .blog-section.blog-style3 {
    padding-bottom: 13rem;
  }
  footer.skew-footer .footer-bottom {
    padding-top: 0;
  }
  .home-slider-section .slide-item .slide-caption .align-items-center .content-box {
    margin-top: 3rem;
  }
  .home-slider-section .slide-item .slide-caption h2 {
    font-size: 5rem;
    line-height: 6rem;
  }
  .home-slider-section .slide-item .slide-caption p {
    margin-bottom: 3rem;
  }
  .col-md-hide {
    display: none;
  }
  .portfolio-section.portfolio-layout3 .project-content {
    max-width: 36rem;
    margin: 0 auto;
  }
  .portfolio-section.portfolio-layout3 .grid-item:last-child {
    margin-bottom: 0;
  }
  .header.header-layout6 .main-menu-area, .header .main-menu-area.sticky {
    background: #fff;
  }
  .header.header-layout1 .main-menu-area.sticky {
    background: #2554E1;
  }
  .header.header-inner .main-menu-area.sticky {
    background: #2554E1;
  }
  .service-details-section .who_we_are .description {
    padding-right: 0;
  }
  .details-feature-section .max-width {
    margin: 0 auto;
    padding-bottom: 4rem;
  }
  .page-banner .breadcrumbs-area ul li {
    font-size: 1.6rem;
  }
  .header .logo img {
    position: relative;
    z-index: 9;
  }
  .portfolio-section .portfolio-filter button {
    font-size: 1.8rem;
    margin-right: 1.5rem;
  }
  .contact-form-section .contact-form {
    max-width: 69.5rem;
    padding: 9rem 1.5rem 8rem;
    margin: 0 auto;
  }
  .contact-info-section {
    padding-top: 8rem;
  }
}

/* Tablet Layout: 768px. */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-layout3 .service-item .feature-title {
    font-size: 1.8rem;
  }
  .service-layout3 .service-item {
    padding: 3.2rem 2.5rem 3.2rem 2.5rem;
  }
  .what-we-do.sec8-space120 {
    padding-bottom: 9rem;
  }
  .call-action-section .cta-btn .secondary-light-btn {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .blog-section.blog-style3 .blog-grid .blog-item .blog-wrap .blog-details .blog-meta {
    display: block;
  }
  .blog-section .blog-item .blog-details .blog-title {
    font-size: 1.8rem;
  }
  .blog-section.blog-style3 .blog-grid .blog-item .blog-wrap .blog-details {
    padding: 2rem;
  }
  .why-us-section .feature-item .item-desc .overlay-content p {
    display: none;
  }
  .why-us-section .feature-item .item-desc .overlay-content {
    padding: 0.5rem;
  }
  .faq-section .faq-tabs {
    display: block !important;
  }
  .faq-section .faq-tabs .faq-icon {
    margin-bottom: 0.6rem;
  }
  .banner-landing.banner-section .item-figure {
    max-width: 50rem;
    padding-top: 10rem;
  }
}

/* Min Width Layout: 768px. */
/* Mobile Layout: 767px. */
@media only screen and (max-width: 767px) {
  .ipad-sm-pt--50 {
    padding-top: 5rem;
  }
  .ipad-sm-pr--70 {
    padding-right: 7rem;
  }
  .ipad-sm-pt--40 {
    padding-top: 4rem;
  }
  .ipad-sm-pt--30 {
    padding-top: 3rem;
  }
  .ipad-sm-mt--50 {
    margin-top: 5rem;
  }
  .ipad-sm-mt--40 {
    margin-top: 4rem;
  }
  .ipad-sm-mt--30 {
    margin-top: 3rem;
  }
  .experience-section {
    padding-top: 7rem;
  }
  .contact-info-section#contact-us {
    padding-top: 6.8rem;
    padding-bottom: 8rem;
  }
  .blog-section.blog-style1#tact-news {
    padding-bottom: 6rem;
  }
  footer.footer .footer-bottom .footer-title {
    font-size: 2.2rem;
  }
  .portfolio-section.portfolio-layout2 {
    padding-bottom: 8rem;
  }
  .style-check li::before {
    font-size: 1.7rem;
    top: 0;
  }
  .blog-section.blog-style3 {
    padding-bottom: 11rem;
  }
  .single-blog-section .single-blog .entry-meta li {
    margin-bottom: 0.5rem;
  }
  .service-layout4.skew-wrap {
    padding-top: 9rem;
    padding-bottom: 8rem;
    margin-top: 8rem;
  }
  .single-blog-section .single-blog .entry-meta {
    margin-bottom: 1.8rem;
  }
  footer.footer .footer-bottom .footer-title {
    margin-bottom: 2rem;
  }
  .call-action-section {
    padding-top: 7rem;
    padding-bottom: 8rem;
  }
  .banner-section.banner-light .banner-desc p {
    padding-right: 0;
  }
  .banner-section.banner-light .banner-desc {
    padding-right: 0;
  }
  .who-are-section.banner-below {
    margin-top: 0;
  }
  .feature-section.layout2 .feature-btn {
    padding-top: 1.5rem;
  }
  footer.footer .footer-top {
    padding: 5.5rem 0;
  }
  footer.footer .footer-bottom {
    padding-top: 3rem;
    padding-bottom: 7rem;
  }
  footer.skew-footer .footer-bottom {
    padding-top: 0;
    padding-bottom: 2rem;
  }
  .who-are-section .about-btn {
    margin-top: 4rem;
  }
  .about-us-four .left-desc .left-wrap .more-btn {
    margin-top: 4rem;
  }
  .about-section .about-btn {
    margin-top: 4rem;
  }
  .section-title.center-style p {
    margin-bottom: 4.4rem;
  }
  .error-section .error-content-box p {
    padding: 0;
  }
  .why-us-section .feature-item .item-desc .item-title {
    font-size: 2rem;
  }
  .partner-section .display-table {
    height: 8rem;
  }
  .what-we-do .service-wrap {
    text-align: center;
  }
  .what-we-do .pdf-btn .btn-wrap {
    display: block;
    text-align: center;
  }
  .tact-right-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list {
    padding-right: 0;
    padding-left: 4.5rem;
  }
  .container-fluid.landing-priview {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .banner-section.banner-light .banner-desc .line-break {
    display: inline;
  }
  .error-section .error-img {
    width: 35rem;
    height: 22.4rem;
  }
  .error-section .error-img img {
    max-width: 22rem;
  }
  .error-section .error-content-box {
    margin-top: 3rem;
  }
  .experience-section .section-title.title-style2 .title {
    text-align: center;
  }
  .experience-section .experience-area {
    text-align: center;
  }
  .experience-section .experience-area .box {
    margin: 0 auto;
  }
  .header.header-inner .main-menu-area.sticky {
    background: #2554E1;
  }
  .header.header-layout5 .main-menu-area {
    background: static !important;
  }
  .banner-section.banner-layout4 {
    padding-top: 5.8rem;
  }
  .banner-section .banner-desc h2 {
    letter-spacing: 0.3rem;
  }
  .style-check li {
    padding-left: 2.8rem;
  }
  .testimonial-section.style1 {
    padding-bottom: 5.5rem;
  }
  .portfolio-section {
    padding-bottom: 5.5rem;
  }
  .style-arrow .owl-nav {
    display: none;
  }
  .what-we-do .service-wrap.first-service {
    margin-top: 0;
  }
  .what-we-do .service-wrap.fourth-service {
    margin-top: 0;
  }
  .banner-layout1.banner-section .item-figure {
    display: none;
  }
  .banner-landing.banner-section .item-figure {
    display: none;
  }
  .banner-layout1.banner-section {
    height: auto;
    padding: 9.5rem 0 10rem;
  }
  .push-left {
    padding-right: 1.5rem;
    padding-left: calc(calc(100% - 510px)/2);
  }
  .portfolio-section .portfolio-filter button {
    font-size: 1.6rem;
    margin-right: 0.8rem;
  }
  .portfolio-section .portfolio-filter {
    margin-bottom: 3.5rem;
  }
  .error-section .error-content-box h2 {
    font-size: 3rem;
  }
  .service-details-section .text-right {
    text-align: center !important;
  }
  .why-us-section .feature-item {
    max-width: 36rem;
    margin: 0 auto 3rem;
  }
  .about-us-four .left-desc .left-wrap {
    max-width: 100%;
    padding-left: 3rem;
    padding-top: 7rem;
    padding-bottom: 8rem;
  }
  .about-us-four .play-video {
    height: 40rem;
  }
  .header-layout3 #topbar-wrap {
    display: none;
  }
  .header.header-layout6 .header-right {
    margin-top: -4.6rem;
  }
  .what-we-do.sec6-space120 {
    padding-bottom: 7rem;
  }
  .term-conditon.text-right {
    text-align: left !important;
  }
  .testimonial-section.testimonial-style5 .testimonial-item p {
    font-size: 1.6rem;
    line-height: 3rem;
  }
  .topbar-layout2 {
    display: none;
  }
  .chatbox-section .chatbox-area {
    padding: 0 1.5rem;
  }
  .portfolio-section.portfolio-layout2 .nav-list #ProjectNav {
    display: none;
  }
  .style-check.two-col-list li {
    width: 100%;
    float: none;
  }
  .feature-section.layout2 .feature-type .feature-item {
    margin-bottom: 3rem;
  }
  .call-action-section .cta-btn {
    text-align: center !important;
    margin-top: 2rem;
  }
  .call-action-section h2 {
    text-align: center;
  }
  .call-action-section .cta-sub-text {
    text-align: center;
  }
  .blog-section.blog-style2 {
    padding-bottom: 5rem;
  }
  .what-we-do .service-wrap.mt--80 {
    margin-top: 0;
  }
  footer.footer .footer-copyright .term-conditon ul li {
    padding-left: 0;
    padding-right: 0.7rem;
  }
  footer.footer .footer-top .contact-info {
    margin-bottom: 1rem;
  }
  footer.footer .footer-top .footer-logo {
    margin-bottom: 2rem;
  }
  footer.skew-footer .footer-top {
    padding: 6rem 2rem 3.5rem 2rem;
  }
  footer.skew-footer .sub-text {
    font-size: 1.3rem;
  }
  footer.skew-footer .footer-top .term-use {
    font-size: 1.3rem;
  }
  .who-are-section .service-wrap.second-service,
  .who-are-section .service-wrap.third-service {
    margin-top: 0;
  }
  .banner-section.banner-light {
    background-position: bottom right -30rem;
    height: 50rem;
  }
  .video-section {
    height: 30rem;
  }
  .pricing-section .pricing-wrap .single-pricing {
    border-right: none;
    border-bottom: 0.1rem solid #ebebeb;
    padding-bottom: 4.3rem;
  }
  .pricing-section .pricing-wrap .single-pricing:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
  .pricing-section .pricing-wrap {
    padding-top: 2.2rem;
  }
  .pricing-section .pricing-wrap .single-pricing.popular-item,
  .pricing-section .pricing-wrap .single-pricing {
    padding-top: 4rem;
  }
  .pricing-section .pricing-wrap .single-pricing .features {
    margin-bottom: 4rem;
  }
  .section-title.title-style2 .title {
    margin-bottom: 2.2rem;
  }
  .section-counter-area .counter-bg {
    background: none;
    height: 0;
  }
  .section-counter-area .counter-wrap {
    margin-bottom: 3rem;
    padding: 5rem 2rem 4rem;
  }
  .section-counter-area .overlap-area {
    transform: translate(0);
  }
  .service-layout4 .service-item2 {
    border: 0.1rem solid #cdcecf;
    margin-bottom: 3rem;
  }
  .team-section.team-style2 {
    margin-top: 0;
  }
  .style-check2 li {
    font-size: 1.6rem;
  }
  .style-check2 li:before {
    font-size: 1.8rem;
  }
  .style-arrow:hover .owl-nav .owl-prev {
    left: -3rem !important;
  }
  .style-arrow:hover .owl-nav .owl-next {
    right: -3rem !important;
  }
  .portfolio-section .loarmore {
    display: none;
  }
  .portfolio-masonry .project-content img {
    width: 100%;
  }
  .counter-section .count-title {
    margin-bottom: 2rem;
  }
  .banner-section {
    height: 50rem;
  }
  .banner-section .banner-desc h2 {
    font-size: 2rem;
  }
  .banner-section .banner-desc h2.second {
    font-size: 2.5rem;
    line-height: 3.5rem;
    margin-bottom: 0.4rem;
  }
  .banner-section .banner-desc h1,
  .banner-section .banner-desc .h1 {
    font-size: 3.5rem;
    line-height: 4rem;
  }
  .banner-section.banner-light .banner-desc h2 {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
  .banner-section.banner-light .banner-desc h1,
  .banner-section.banner-light .banner-desc .h1 {
    font-size: 3rem;
    line-height: 5rem;
  }
  .banner-section .banner-btn {
    padding-top: 1.5rem;
  }
  .banner-section.banner-gradient {
    padding: 8rem 0 40rem;
  }
  .banner-section.banner-gradient .banner-desc p {
    padding: 0 2rem;
  }
  .banner-section.banner-layout4 {
    height: 48rem;
  }
  .banner-section.banner-layout4 .banner-desc .p-light {
    font-size: 3rem;
    line-height: 4rem;
  }
  .banner-section.banner-layout4 .banner-desc .p-semi-bold {
    font-size: 3.4rem;
    line-height: 4.5rem;
  }
  .banner-section.banner-layout4 .banner-btn {
    padding-top: 3rem;
  }
  .home-slider-section .slide-item .slide-caption h2 {
    font-size: 4rem;
    line-height: 5rem;
  }
  .home-slider-section .slide-item .slide-caption p {
    display: none !important;
  }
  .ipad-screen {
    margin-top: -37rem;
  }
  .banner-section.banner-gradient .banner-desc h2 {
    font-size: 4rem;
    line-height: 5rem;
  }
  .home-slider-section .slide-item .slide-caption h2 {
    margin-bottom: 2.5rem;
  }
  .nivo-directionNav {
    display: none;
  }
  .mission-section .container-fluid {
    padding: 0 1.5rem;
  }
  .blog-section.blog-style4 .loarmore {
    display: none;
  }
  .testimonial-section.testimonial-style5 .testimonial-item .author-info .name-info .author {
    font-size: 2rem;
  }
  .testimonial-section.testimonial-style5 .testimonial-item .author-info .name-info .designation {
    font-size: 1.6rem;
  }
  .blog-section.blog-style5 {
    padding-top: 8rem;
    margin-top: 0;
  }
  blockquote {
    padding-left: 4rem;
    padding-right: 3.5rem;
  }
  blockquote::before {
    left: 1rem;
  }
}

/* Device Size : 590px. */
@media only screen and (max-width: 590px) {
  .banner-section.banner-gradient {
    padding: 6rem 0 20rem;
  }
  .ipad-screen {
    margin-top: -19rem;
  }
  .key-feature-section .feature-carousel .feature-item {
    max-width: 36rem;
    margin: 0 auto 3rem;
  }
  .call-action-section h2 {
    font-size: 3rem;
  }
  .section-title .title {
    font-size: 3rem;
  }
  .header.header-layout6 .main-menu-area .header-contact a {
    font-size: 2rem;
  }
  .header.header-layout6 .header-right {
    margin-top: -4rem;
  }
  .header.header-layout6 .logo img {
    max-width: 10.5rem;
  }
  .call-action-section .cta-sub-text {
    font-size: 1.8rem;
  }
  footer.skew-footer .footer-top .news-form .form-button {
    position: relative;
  }
  footer.skew-footer .footer-top .news-form {
    max-width: 27rem;
  }
  footer.skew-footer .footer-top .news-form .form-button {
    width: 80%;
    max-width: 27rem;
    margin-top: 1rem;
  }
  footer.skew-footer .footer-bottom .footer-menu ul li a {
    font-size: 1.5rem;
  }
  footer.skew-footer .copyright-text {
    font-size: 1.5rem;
  }
  footer.skew-footer .footer-bottom {
    padding-bottom: 0;
  }
  .error-section .error-content-box h2 {
    font-size: 2.5rem;
  }
  .testimonial-section.testimonial-style3 .testimonial-item p {
    font-size: 1.6rem;
    line-height: 2.8rem;
  }
  .testimonial-style3 #testiNav {
    display: none;
  }
  .service-layout3 .service-item {
    padding: 3.2rem 2.5rem 2rem 2.5rem;
  }
  .home-slider-section .slide-item .slide-caption h2 {
    font-size: 3rem;
    line-height: 4rem;
    margin-bottom: 2.5rem;
  }
  .header-layout4 .home-slider-section .slide-item .slide-caption .align-items-center .content-box {
    margin-top: 4.5rem;
  }
  .home-slider-section .slide-item .slide-caption h2,
  .home-slider-section.slider-layout2 .slide-item .slide-caption h2 {
    margin-bottom: 0;
  }
  .home-slider-section .slide-item .slider-btn {
    display: none;
  }
  .banner-section.banner-layout4 {
    height: 42rem;
  }
  .banner-section.banner-layout4 .banner-desc .p-light {
    font-size: 2.2rem;
    line-height: 3rem;
  }
  .banner-section.banner-layout4 .banner-desc .p-semi-bold {
    font-size: 3rem;
    line-height: 4rem;
  }
  .banner-section.banner-layout4 .banner-btn a {
    margin-bottom: 1.2rem;
  }
  .page-banner .breadcrumbs-area ul li {
    font-size: 1.4rem;
  }
  .single-blog-section .single-blog .entry-title {
    margin-bottom: 1.5rem;
  }
  .tact-left-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list {
    left: 40%;
  }
  .tact-left-sidebar-wrp .inner-cover .sidebar-shape.opened {
    display: none;
  }
}

/* Device Size : 575px. */
@media only screen and (max-width: 575px) {
  .grid-3 .project-content {
    max-width: 36rem;
    margin: 0 auto;
  }
  .grid-4 .project-content {
    max-width: 26.3rem;
    margin: 0 auto;
  }
  .portfolio-section .pl--30 {
    padding-left: 1.5rem;
  }
  .what-we-do.sec8-space120 {
    padding-bottom: 5.5rem;
  }
  .analyze-section {
    padding-bottom: 8rem;
  }
  .about-us-four .left-desc .left-wrap {
    padding-left: 0;
    padding-right: 1.5rem;
  }
  .portfolio-section .pr--30 {
    padding-right: 1.5rem;
  }
  .grid-masonry-3 .project-content {
    max-width: 36rem;
    margin: 0 auto;
  }
  .testimonial-section.style1 {
    padding-bottom: 4.5rem;
  }
  .error-section .error-content-box h1 {
    font-size: 2.5rem;
  }
  .service-details-section .mt--100 {
    margin-top: 6rem;
  }
  .push-left {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .header .main-menu-area .has-megamenu .header-menu .mega-menu-full.mega-menu-three > .sub-menu li {
    width: 50%;
  }
  .single-blog-section .single-blog .blog-author {
    text-align: left;
  }
  .single-blog-section .single-blog .blog-author .media img {
    max-width: 10rem;
  }
  .single-blog-section .single-blog .blog-author .media {
    display: block;
  }
  .single-blog-section .single-blog .blog-author .media .media-body {
    padding-left: 0;
    padding-top: 2rem;
  }
  .single-blog-section .single-blog .blog-comment ul li p,
  .single-blog-section .single-blog .blog-comment ul li .item-btn {
    font-size: 1.4rem;
  }
  .single-blog-section .single-blog .blog-comment ul li:nth-child(2n) {
    margin-left: 1.5rem;
  }
  .single-blog-section .single-blog .blog-tag ul li {
    justify-content: left;
  }
  .tact-right-sidebar-wrp .offcanvas-cpt-wrp,
  .tact-left-sidebar-wrp .offcanvas-cpt-wrp {
    height: 7rem;
    width: 7rem;
  }
  .tact-right-sidebar-wrp .info_wrap {
    padding-left: 2rem;
  }
  .tact-right-sidebar-wrp .offcanvas-cpt-wrp .trigger,
  .tact-left-sidebar-wrp .offcanvas-cpt-wrp .trigger {
    top: 2.8rem;
  }
  .opened.right .tact-right-sidebar-wrp .info_wrap::before {
    background-color: transparent;
  }
  .primary-btn {
    font-size: 1.3rem;
    padding: 1.3rem 2.8rem 1.2rem;
  }
  .secondary-btn {
    font-size: 1.3rem;
    padding: 1.4rem 2.8rem 1.3rem;
  }
  .primary-btn.btn-lg {
    font-size: 1.5rem;
    padding: 1.4rem 3rem 1.2rem;
  }
  .secondary-btn.btn-lg {
    font-size: 1.5rem;
    padding: 1.4rem 3rem 1.2rem;
  }
}

/* Small Device : 480px. */
@media only screen and (max-width: 480px) {
  .section-title .title {
    font-size: 2.6rem;
  }
  .banner-section.banner-landing .banner-desc h2.second {
    font-size: 2.2rem;
    line-height: 3rem;
  }
  .banner-section.banner-landing {
    height: 46rem;
  }
  footer.skew-footer .sub-text {
    display: none;
  }
  footer.skew-footer .footer-top .section-title .title {
    margin-bottom: 0.5rem;
  }
  .sidebar-area .bs-latest-post .post-item .post-desc .title {
    font-size: 1.5rem;
  }
  .home-slider-section .slide-item .slider-btn a {
    margin-right: 2rem;
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }
  .home-slider-section .slide-item .slide-caption .align-items-center .content-box {
    margin-top: 4rem;
  }
  .banner-section.banner-light .banner-desc h2 {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
  .banner-section.banner-light .banner-desc h1,
  .banner-section.banner-light .banner-desc .h1 {
    font-size: 3.5rem;
    line-height: 4.5rem;
  }
  .cta-section h3 {
    font-size: 1.8rem;
  }
  .cta-section .cta-btn a {
    font-size: 1.5rem;
  }
  .style-check2.two-col-list li {
    width: 100%;
    float: none;
  }
  .about-section .about-btn .arrow-link {
    margin-left: 0;
  }
  .cont-btn {
    display: block;
    margin-top: 2rem;
  }
}

/* Small Device : 400px. */
@media only screen and (max-width: 400px) {
  .banner-section.banner-gradient .banner-desc p {
    display: none;
  }
  .banner-section.banner-gradient .banner-desc h2 {
    font-size: 3rem;
    line-height: 4rem;
    margin-bottom: 1.4rem;
  }
  .banner-section.banner-layout4 .banner-btn a.secondary-light-btn {
    display: none;
  }
  .banner-section.banner-layout4 .banner-btn a {
    display: block;
    margin: 0 auto 15px !important;
    max-width: 171px;
  }
  .home-slider-section .slide-item .slide-caption h2 {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
  .banner-section.banner-layout4 .banner-btn.d_flex {
    display: block;
  }
  .banner-section.banner-gradient .banner-btn a {
    display: block;
    max-width: 17rem;
    margin: 0 auto 2.5rem;
  }
  .header.header-layout6 .main-menu-area .header-contact a {
    font-size: 1.6rem;
  }
  .error-section .error-img {
    width: 28rem;
    height: 18rem;
  }
  .error-section .error-img img {
    max-width: 18rem;
  }
  .header.header-layout6 .main-menu-area {
    padding: 1.5rem 0;
  }
  .header.header-layout6 .header-right {
    margin-top: -3rem;
  }
  .header.header-layout6 .main-menu-area .header-contact .icon i::before {
    font-size: 1.6rem;
  }
  .header.header-layout6 .logo img {
    max-width: 8.5rem;
  }
  .ipad-screen {
    margin-top: -22rem;
  }
  .testimonial-section.testimonial-style5 #testiNav {
    display: none;
  }
  .why-us-section .feature-item .item-desc .overlay-content p {
    display: none;
  }
  .why-us-section .feature-item .item-desc .item-title {
    font-size: 2rem;
  }
  .header .main-menu-area .has-megamenu .header-menu .mega-menu-full.mega-menu-three > .sub-menu li {
    width: 100%;
    float: none;
    padding-left: 0;
    padding-right: 0;
  }
  .header .main-menu-area .has-megamenu .header-menu .mega-menu-full > .sub-menu li a .text {
    text-align: left;
  }
  .blog-section.blog-style4 .blog-item .blog-details .blog-title {
    margin-top: 1.5rem;
  }
  .blog-section.blog-style4 .blog-item .read-more {
    margin-top: 1.2rem;
    margin-bottom: 1rem;
  }
}

/* Extra Small Device : 320px. */
@media only screen and (max-width: 350px) {
  .banner-section.banner-light .banner-desc h2 {
    font-size: 2rem;
    line-height: 3rem;
  }
  .banner-section.banner-landing {
    height: 40rem;
  }
  .tact-right-sidebar-wrp .offcanvas-cpt-menu {
    max-width: 28rem;
  }
  .home-slider-section .slide-item .slide-caption h2 {
    font-size: 2rem;
    line-height: 3rem;
  }
  .tact-right-sidebar-wrp .offcanvas-cpt-menu .inner-cover .nav-item-list {
    padding-right: 1rem;
  }
  .banner-section.banner-light .banner-desc h1,
  .banner-section.banner-light .banner-desc .h1 {
    font-size: 3rem;
    line-height: 4rem;
  }
  .single-blog-section .single-blog .blog-comment ul li .post-date {
    display: block;
    margin-left: 0;
  }
  .single-blog-section .single-blog .blog-comment .media-body {
    padding-left: 1rem;
  }
  .sidebar-area .bs-latest-post .post-item .post-desc .title {
    font-size: 1.4rem;
    line-height: 2rem;
  }
  .sidebar-area .bs-latest-post .post-item .post-images {
    margin-right: 1rem;
  }
}
