.error-section{
   .item-figure.up-down-animate{
    position: relative;
    top: -50px;
   }
    .error-img{
        background: url(../../media/other/shape.png) no-repeat center top;
        width: rem(684px);
        height: rem(437px);
        margin: 0 auto;
        background-size: cover;
    }
    .error-content-box{   
        margin: 0 auto;
        margin-top: rem(60px);
        h1{
            font-weight: 700;
            font-size: rem(36px);
            margin-bottom: rem(17px);
        }
        p{
            padding: rem(0 50px);
            color: $title-color;
            font-weight: 500;
        }
        .secondary-btn{
            color: $title-color;
            margin-top: rem(29px);
            &:hover{
                color: #fff;
            }
        }
    }
}